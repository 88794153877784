export const sampleProfileData = [
    {
        "personInfo": {
            "name": "San Damo",
            "dob": "12-12-12",
            "gender": "Male",
            "email": "san@gmail.com",
            "effectiveDate": "12-12-2017",
            "phoneNumbers": "9749090494",
            "currentLocation": "Las Vegas",
            "currencyPreference": "IT",
            "about":"Software Developer",
            "summary":"",
            "marritialStatus":"",
            "diversity": {
            },
            "languages": [
                {
                    "name":"",
                    "proficiency":"",
                    "reading":"",
                    "writing":"",
                    "understanding":""
                }
            ],
            "address": "" 
        },
        "meta": {
            "created":"",
            "updated":"",
            "lastLogin":"",
            "emailVerifiedAt": 0,
            "phoneNumberVerifiedAt": 0,
        },
        "profileSections": [
            {
                "type":"diversity",
                "title":"",
                "position":0,
                "data" :  {
                    "differentlyAbled":false,
                    "careerBreak" : {
                        "startDate":"",
                        "endDate":""
                    }, 
                    "defence":{ 
                        "startDate":"",
                        "endDate":"",
                        "rank":"",
                        "cadre":""
                    }, 
                    "woman" : {
                        "workingMother" : true,
                        "sigleMother":true
                    },
                    "backward": {
                        "type":"",
                        "title":""
                    },
                    "speciallyAbled": {
                        "type":"",
                        "info":""
                    }
                }
    
            },
            {
                "type":"skills",
                "title":"",
                "position":0,
                "data":  [
                    {
                        "name": "Java",
                        "key": "java",
                        "rating": "3",
                        "experience": "1",
                        "startDate":"",
                        "endDate":""
                    },
                    {
                        "name": "HTML",
                        "key": "html",
                        "rating": "4",
                        "experience": "2",
                        "startDate":"",
                        "endDate":""
                    }
                ]
            },
            {
                "type":"info",
                "title":"Personal Info",
                "position":0,
                "data":  {
                    "designation":"Frontend Developer",
                    "location":"Bangalore",
                    "functionalArea": "Software",
                    "industry": "IT",
                    "isOnNotice": "No",
                    "relievingDate": "",
                    "noticePeriodDuration":"",
                    "currentCTC": "12",
                    "workPermit": "No",
                    "workingDays": "No"
                }
            },
            {
                "type":"certificates",
                "title":"Certificates",
                "data":  {
                    "name": "",
                    "key": "",
                    "expires": ""
                }
            },{
                "type":"experience",
                "title":"Experience",
                "data" : [
                    {
                        "company": "",
                        "designation": "",
                        "startDate": "",
                        "endDate": "",
                        "description": ""
                    }
                ]
            },
            {
                "type":"education",
                "title":"Education",
                "data" : [
                    {
                        "type": "",
                        "degree": "",
                        "startDate": "",
                        "endDate": "",
                        "institution": ""
                    }
                ]
            },
            {
                "type":"achievements",
                "data": [
                    {
                        "name":"iit Rank",
                        "value":"",
                        "intVal":"",
                    }
                ]
            },
            {
                "type":"projects" ,
                "title":"Projects",
                "data":  [
                    {
                        "title": "",
                        "url": "",
                        "company": "",
                        "startDate": "",
                        "endDate": "",
                        "description": "",
                        "manageTeam":true,
                        "skills": []
                    }
                ]
            },
            {
                "type":"portfolio",
                "title":"Portfolio",
                "data" :  {
                    "title": "",
                    "url": "",
                    "description": ""
                }
            }
        ],
        "workPreference": {
            "locations": [
                {
                    "name": "",
                    "lat": "",
                    "lon": "",
                }
            ],
            "willingToRelocate":true,
            "earlyStartUp":"",
            "mnc":"",
            "willingTravel": "Extensive",
            "industry": "",
            "functionalArea": "",
            "expectedCTC": "2",
            "ctcConfidential":true
        },
        "social" : {
            "stackOverflow": {
                "about": {
                    "userName": "",
                    "reputation": "",
                    "userLocation": "",
                    "userExperience": "",
                },
                "userId": 0,
                "topTags": [
                    {
                       "name": "",
                       "tagDetails": {
                          "posts": "",
                          "score": ""
                       }
                   }
                ],
                "userBadges": []
            },
            "behance": {
            },
            "github" : {
        
            },
            "twitter": {
    
            },
            "linkedIn": {
    
            }
        },
        "employer": {
            "channelName": 'Monster'
        }
    },
    {
        "personInfo": {
            "name": "Sangeet",
            "dob": "12-12-12",
            "gender": "Male",
            "email": "sangeet@gmail.com",
            "effectiveDate": "12-12-2019",
            "phoneNumbers": "9749090494",
            "currentLocation": "Las Vegas",
            "currencyPreference": "IT",
            "about":"Software Developer",
            "summary":"",
            "marritialStatus":"",
            "diversity": {
            },
            "languages": [
                {
                    "name":"",
                    "proficiency":"",
                    "reading":"",
                    "writing":"",
                    "understanding":""
                }
            ],
            "address": "" 
        },
        "meta": {
            "created":"",
            "updated":"",
            "lastLogin":"",
            "emailVerifiedAt": 0,
            "phoneNumberVerifiedAt": 0,
        },
        "profileSections": [
            {
                "type":"diversity",
                "title":"",
                "position":0,
                "data" :  {
                    "differentlyAbled":false,
                    "careerBreak" : {
                        "startDate":"",
                        "endDate":""
                    }, 
                    "defence":{ 
                        "startDate":"",
                        "endDate":"",
                        "rank":"",
                        "cadre":""
                    }, 
                    "woman" : {
                        "workingMother" : true,
                        "sigleMother":true
                    },
                    "backward": {
                        "type":"",
                        "title":""
                    },
                    "speciallyAbled": {
                        "type":"",
                        "info":""
                    }
                }
    
            },
            {
                "type":"skills",
                "title":"",
                "position":0,
                "data":  [
                    {
                        "name": "Laravel",
                        "key": "laravel",
                        "rating": "2",
                        "experience": "10",
                        "startDate":"",
                        "endDate":""
                    },
                    {
                        "name": "Node Js",
                        "key": "node",
                        "rating": "4",
                        "experience": "1",
                        "startDate":"",
                        "endDate":""
                    }
                ]
            },
            {
                "type":"info",
                "title":"Personal Info",
                "position":0,
                "data":  {
                    "designation":"Backend Developer",
                    "location":"Bangalore",
                    "functionalArea": "Software",
                    "industry": "IT",
                    "isOnNotice": "No",
                    "relievingDate": "",
                    "noticePeriodDuration":"",
                    "currentCTC": "12",
                    "workPermit": "No",
                    "workingDays": "No"
                }
            },
            {
                "type":"certificates",
                "title":"Certificates",
                "data":  {
                    "name": "",
                    "key": "",
                    "expires": ""
                }
            },{
                "type":"experience",
                "title":"Experience",
                "data" : [
                    {
                        "company": "",
                        "designation": "",
                        "startDate": "",
                        "endDate": "",
                        "description": ""
                    }
                ]
            },
            {
                "type":"education",
                "title":"Education",
                "data" : [
                    {
                        "type": "",
                        "degree": "",
                        "startDate": "",
                        "endDate": "",
                        "institution": ""
                    }
                ]
            },
            {
                "type":"achievements",
                "data": [
                    {
                        "name":"iit Rank",
                        "value":"",
                        "intVal":"",
                    }
                ]
            },
            {
                "type":"projects" ,
                "title":"Projects",
                "data":  [
                    {
                        "title": "",
                        "url": "",
                        "company": "",
                        "startDate": "",
                        "endDate": "",
                        "description": "",
                        "manageTeam":true,
                        "skills": []
                    }
                ]
            },
            {
                "type":"portfolio",
                "title":"Portfolio",
                "data" :  {
                    "title": "",
                    "url": "",
                    "description": ""
                }
            }
        ],
        "workPreference": {
            "locations": [
                {
                    "name": "",
                    "lat": "",
                    "lon": "",
                }
            ],
            "willingToRelocate":true,
            "earlyStartUp":"",
            "mnc":"",
            "willingTravel": "Extensive",
            "industry": "",
            "functionalArea": "",
            "expectedCTC": "2",
            "ctcConfidential":true
        },
        "social" : {
            "stackOverflow": {
                "about": {
                    "userName": "",
                    "reputation": "",
                    "userLocation": "",
                    "userExperience": "",
                },
                "userId": 0,
                "topTags": [
                    {
                       "name": "",
                       "tagDetails": {
                          "posts": "",
                          "score": ""
                       }
                   }
                ],
                "userBadges": []
            },
            "behance": {
            },
            "github" : {
        
            },
            "twitter": {
    
            },
            "linkedIn": {
    
            }
        },
        "employer": {
            "channelName": 'Naukri'
        }
    },
    {
        "personInfo": {
            "name": "Kane Williamson",
            "dob": "",
            "gender": "Male",
            "email": "kan@gmail.com",
            "effectiveDate": "10-12-2018",
            "phoneNumbers": "",
            "currentLocation": "New Zealand",
            "currencyPreference": "",
            "about":"Cricketer",
            "summary":"",
            "marritialStatus":"",
            "diversity": {
            },
            "languages": [
                {
                    "name":"",
                    "proficiency":"",
                    "reading":"",
                    "writing":"",
                    "understanding":""
                }
            ],
            "address": "" 
        },
        "meta": {
            "created":"",
            "updated":"",
            "lastLogin":"",
            "emailVerifiedAt": 0,
            "phoneNumberVerifiedAt": 0,
        },
        "profileSections": [
            {
                "type":"diversity",
                "title":"",
                "position":0,
                "data" :  {
                    "differentlyAbled":false,
                    "careerBreak" : {
                        "startDate":"",
                        "endDate":""
                    }, 
                    "defence":{ 
                        "startDate":"",
                        "endDate":"",
                        "rank":"",
                        "cadre":""
                    }, 
                    "woman" : {
                        "workingMother" : true,
                        "sigleMother":true
                    },
                    "backward": {
                        "type":"",
                        "title":""
                    },
                    "speciallyAbled": {
                        "type":"",
                        "info":""
                    }
                }
    
            },
            {
                "type":"skills",
                "title":"",
                "position":0,
                "data":  [
                    {
                        "name": "Batting",
                        "key": "batting",
                        "rating": "4",
                        "experience": "12",
                        "startDate":"",
                        "endDate":""
                    },
                    {
                        "name": "Fielding",
                        "key": "fielding",
                        "rating": "4",
                        "experience": "12",
                        "startDate":"",
                        "endDate":""
                    }
                ]
            },
            {
                "type":"info",
                "title":"Personal Info",
                "position":0,
                "data":  {
                    "designation":"Captain",
                    "location":"New Zealand",
                    "functionalArea": "",
                    "industry": "",
                    "isOnNotice": "",
                    "relievingDate": "",
                    "noticePeriodDuration":"",
                    "currentCTC": "20",
                    "workPermit": "No",
                    "workingDays": "No"
                }
            },
            {
                "type":"certificates",
                "title":"Certificates",
                "data":  {
                    "name": "",
                    "key": "",
                    "expires": ""
                }
            },{
                "type":"experience",
                "title":"Experience",
                "data" : [
                    {
                        "company": "",
                        "designation": "",
                        "startDate": "",
                        "endDate": "",
                        "description": ""
                    }
                ]
            },
            {
                "type":"education",
                "title":"Education",
                "data" : [
                    {
                        "type": "",
                        "degree": "",
                        "startDate": "",
                        "endDate": "",
                        "institution": ""
                    }
                ]
            },
            {
                "type":"achievements",
                "data": [
                    {
                        "name":"iit Rank",
                        "value":"",
                        "intVal":"",
                    }
                ]
            },
            {
                "type":"projects" ,
                "title":"Projects",
                "data":  [
                    {
                        "title": "",
                        "url": "",
                        "company": "",
                        "startDate": "",
                        "endDate": "",
                        "description": "",
                        "manageTeam":true,
                        "skills": []
                    }
                ]
            },
            {
                "type":"portfolio",
                "title":"Portfolio",
                "data" :  {
                    "title": "",
                    "url": "",
                    "description": ""
                }
            }
        ],
        "workPreference": {
            "locations": [
                {
                    "name": "",
                    "lat": "",
                    "lon": "",
                }
            ],
            "willingToRelocate":true,
            "earlyStartUp":"",
            "mnc":"",
            "willingTravel": "Extensive",
            "industry": "",
            "functionalArea": "",
            "expectedCTC": "39",
            "ctcConfidential":true
        },
        "social" : {
            "stackOverflow": {
                "about": {
                    "userName": "",
                    "reputation": "",
                    "userLocation": "",
                    "userExperience": "",
                },
                "userId": 0,
                "topTags": [
                    {
                       "name": "",
                       "tagDetails": {
                          "posts": "",
                          "score": ""
                       }
                   }
                ],
                "userBadges": []
            },
            "behance": {
            },
            "github" : {
        
            },
            "twitter": {
    
            },
            "linkedIn": {
    
            }
        },
        "employer": {
            "channelName": 'Indeed'
        }
    }
];