import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie'

export default class PieComponent extends Component {
    static propTypes = {
        data: PropTypes.array,
        pieLabel:PropTypes.string
    };

    render() {
        const { data, pieLabel } = this.props;
        const defs = [
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ];

        const fill = data.map(item => {
            return {
                match: {
                    id: item.id
                },
                id: item.type
            }
        })
        
        return (
            <Fragment>
                <h6 className="h7t-modal-title h7t-margin-0">{pieLabel}</h6>
                <Paper style={{ height: '320px' }} className="w3-padding h7t-table-data">
                    <ResponsivePie
                        data={data}
                        margin={{ top: 20, right: 50, bottom: 50, left: 0 }}
                        colors={{ scheme: 'nivo' }}
                        borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
                        enableRadialLabels={false}
                        radialLabelsSkipAngle={0}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={-24}
                        radialLabelsLinkDiagonalLength={3}
                        radialLabelsLinkHorizontalLength={0}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        enableSlicesLabels={true}
                        slicesLabelsSkipAngle={0}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        isInteractive={false}
                        innerRadius={0.6}
                        defs={defs}
                        fill={fill}
                        legends={[
                            {

                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 60,
                                translateY: 35,
                                itemsSpacing: 1,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </Paper>
            </Fragment>
        )
    }
}