import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Pagination extends Component {
    static propTypes = {
        paginationDetails: PropTypes.object,
        handlePagination: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.customPageLimit = 3;
        this.handlePrevPage = this.handlePrevPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
    }

    handlePagination(pageValue) {
        const { handlePagination, paginationDetails } = this.props;
        if (paginationDetails.current_page !== pageValue) {
            handlePagination(pageValue);
            if (document.body.scrollTop > 0) {
                document.body.scrollTop = 0;
            }
        }
    }

    handlePrevPage(pageValue) {
        const { paginationDetails, handlePagination } = this.props;
        if (paginationDetails.prev_page_url) {
            handlePagination(pageValue);
            if (document.body.scrollTop > 0) {
                document.body.scrollTop = 0;
            }
        }
    }

    handleNextPage(pageValue) {
        const { paginationDetails, handlePagination } = this.props;
        if (paginationDetails.next_page_url) {
            handlePagination(pageValue);
            if (document.body.scrollTop > 0) {
                document.body.scrollTop = 0;
            }
        }
    }

    render() {
        const { paginationDetails } = this.props;
        const paginationList = [];
        const customPageLimit = this.customPageLimit;

        const currentPage = paginationDetails.current_page;
        const lastPage = paginationDetails.last_page;

        const disablePrev = (currentPage === 1) ? 'w3-disabled' : '';
        const disableNext = (currentPage === lastPage) ? 'w3-disabled' : '';

        const prevPage = (currentPage !== 1) ? (currentPage - 1) : currentPage;
        const nextPage = (lastPage !== currentPage) ? (currentPage + 1) : currentPage;

        const initialPage = (currentPage - customPageLimit) < 0 ? 1 : (currentPage !== lastPage) ? prevPage : prevPage - 1;
        const finalPage = (currentPage - customPageLimit) >= 0 ? nextPage : (lastPage < customPageLimit) ? lastPage : customPageLimit;

        for (let i = initialPage; i <= finalPage; i++) {
            const activeCls = currentPage === i ? 'w3-blue-grey w3-hover-blue-grey' : 'w3-hover-blue-grey';
            paginationList.push(
                <a key={i} onClick={() => this.handlePagination(i)} className={`w3-button w3-padding-small h-margin-1 ${activeCls}`}>{i}</a>,
            );
        }

        return (
            <div className="w3-center w3-margin-top">
                <div className="w3-small">
                    <a
                        onClick={() => this.handlePrevPage(prevPage)}
                        className={`w3-bar-item w3-button w3-margin-right w3-padding-small w3-hover-blue-grey  h-margin-1 ${disablePrev}`}
                    >&laquo; Prev</a>
                    {paginationList}
                    <a
                        onClick={() => this.handleNextPage(nextPage)}
                        className={`w3-button w3-margin-left w3-padding-small w3-hover-blue-grey  h-margin-1 ${disableNext}`}
                    >Next &raquo;</a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

export default connect(mapStateToProps)(Pagination);
