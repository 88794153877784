import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getJobById,
    getCurrentUser,
    setAppMessage,
    postAddBookmark,
} from '../js/actions';
import JobDescriptionModal from './Dashboard/JobDescriptionModal';
import NewLoginSignupModal from './Home/NewLoginSignupModal';

class ShareJob extends Component {
    static propTypes = {
        appMessage: PropTypes.object,
    };
    constructor(args){
        super(args);
        this.state = {
            showJobDescription: '',
            bookmarkIds: [],
            loginSignup: false,
            jobSelected: null,
            actionSelected: null,

        }
        this.triggerResumeSelect = this.triggerResumeSelect.bind(this);
        this.handleBookmarkJob = this.handleBookmarkJob.bind(this);

    }
    componentDidMount() {

        const menuBar = document.getElementById('h7t_menu_bar');
        if (menuBar) {
            menuBar.style.display = 'none';
        }

        this.props.getCurrentUser().then((resp) => {
            const response = resp.data.data;
            if (response && response.profile && response.profile.bookmark_ids) {
                const bookmarkIds = response.profile.bookmark_ids.split(',')
                    .map((x) => {
                        return parseInt(x)
                    });
                this.setState({
                    bookmarkIds,
                });
            }
        });

        const urlParams = new URLSearchParams(window.location.search);
        const jobId = urlParams.get('jobId');
        var data = this.getCompanyAndOfficeId();
        this.props.getJobById(jobId, data.companyId, data.officeId).then((resp) => {
            const jobDetails = resp && resp.data && resp.data.data;
            jobDetails && jobDetails.map((jd) => {
                this.setState({ showJobDescription: jd });
            });
        });
    }

    getCompanyAndOfficeId() {
        const pathUrl = window.location.pathname;
        const companyId = pathUrl.split('/')[2];
        const officeId = pathUrl.split('/')[3];
        const data ={
            companyId,
            officeId
        }
        return data;
    }

    handleBookmarkJob(jobSelected) {
        const data = this.getCompanyAndOfficeId();
        let bookmarkIds = [];
        this.props.getCurrentUser().then((resp) => {
            const loginUser = resp.data.data;
            if (loginUser && loginUser.user) {
                if (loginUser.profile && loginUser.profile.bookmark_ids) {
                    bookmarkIds = loginUser.profile.bookmark_ids.split(',')
                        .map((x) => {
                            return parseInt(x)
                        });
                }
                if (bookmarkIds.includes(jobSelected.id)) {
                    this.props.setAppMessage('fail', 'You have already saved this job...!');
                    this.props.getJobById(jobSelected.id,data.companyId,data.officeId).then(() => {
                        this.setState({
                            loginSignUp: '',
                            bookmarkIds,
                            actionSelected: null,
                        });
                    });
                    return;
                }
                bookmarkIds.push(jobSelected.id);
                const bookmark_ids = bookmarkIds.join();
                this.props.postAddBookmark(bookmark_ids).then((response) => {
                    if (response && response.data && response.data.success) {
                        this.props.setAppMessage('success', 'Job saved to your profile...!');
                        this.props.getJobById(jobSelected.id,data.companyId,data.officeId).then(() => {
                            this.setState({
                                loginSignup: '',
                                bookmarkIds,
                                actionSelected: null,
                            });
                            this.props.history.push('/s/job-dashboard');
                            window.location.reload();
                        })
                    }
                });
            } else {
                this.setState({
                    loginSignup: true,
                    jobSelected,
                    actionSelected: 'bookmark',
                });
            }
        });
    }

    triggerResumeSelect(jobSelected) {
        const data = this.getCompanyAndOfficeId();
        this.props.getCurrentUser().then((resp) => {
            const loginUser = resp.data.data;
            if (loginUser && loginUser.user) {
                let bookmarkIds = [];
                if (loginUser.profile && loginUser.profile.bookmark_ids) {
                    bookmarkIds = loginUser.profile.bookmark_ids.split(',')
                        .map((x) => {
                            return parseInt(x)
                        });
                }
                this.props.getJobById(jobSelected.id,data.companyId,data.officeId).then((res) => {
                    const result = res && res.data;
                    if (result && result.success) {
                        result.data.map((jobs) => {
                            this.props.history.push('/s/job-dashboard');
                        });
                    }
                    this.setState({
                        loginSignup: '',
                        jobSelected,
                        bookmarkIds,
                    });
                });
            } else {
                this.setState({
                    loginSignup: true,
                    jobSelected,
                    actionSelected: 'applyjob'
                });
            }
        });
    }

    render() {

        const { showJobDescription, bookmarkIds, loginSignup, jobSelected, actionSelected } = this.state;
        const { location: {search} } = this.props;
        var data = this.getCompanyAndOfficeId();

        return (
            <div>
                <div className="w3-row content w3-white h7t-share-job-bg" style={{ minHeight: '100vh' }}>
                
                    {showJobDescription ? <JobDescriptionModal 
                        bookmarkIds={bookmarkIds}
                        jobDescription={showJobDescription}
                        onModalClose={() => this.setState({ showJobDescription: '' }) }
                        triggerResumeSelect={this.triggerResumeSelect}
                        handleBookmarkJob={this.handleBookmarkJob}
                        disableClose = {search!==null ? true : false }
                        id = "share_job_modal"
                    /> : '' }

                    {loginSignup
                        ? (
                            <NewLoginSignupModal
                                homePageView="login"
                                shareJobId={jobSelected.id}
                                shareJobCompanyId={data.companyId}
                                shareJobOfficeId={data.officeId}
                                handleShareOnSuccess = {
                                    (actionSelected == 'bookmark') ? () => this.handleBookmarkJob(jobSelected) : () => window.location.reload(this.props.history.push(
                                        {
                                            pathname:`/s/my-resume`,
                                            state: {
                                                jobId:jobSelected.id,
                                                companyId:data.companyId,
                                                officeId:data.officeId,
                                            }
                                        })
                                    )}
                                onModalClose={() => this.setState({ loginSignup: false })}
                                actionSelected={actionSelected}
                                disableClose = {search!==null ? true : false }
                                id="share_login_modal"
                            />
                        ) : null}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        appMessage: state.appMessage,
    };
}

export default connect(mapStateToProps, {
    getJobById,
    getCurrentUser,
    setAppMessage,
    postAddBookmark,
})(withRouter(ShareJob));

