import React, { Component } from 'react';
import { render } from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import { Provider } from 'react-redux';
import AppRouter from './routes';
import clientStore from './clientStore';
const appName = 'HiringCrazy';

require('./../styles/style.scss');

window.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('app');
    render(
        <Provider store={clientStore}> 
        <BrowserRouter >
            <AppRouter /> 
        </BrowserRouter>
    </Provider>, container);
});
