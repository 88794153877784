import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
    getCurrentOfficeList,
    getCurrentCompany,
    postAddOffice,
    getCurrentUser,
    postEditOffice,
    getMetaData,
    setAppMessage,
    getCompanyPackageLimits,
    setData,
} from '../../js/actions';
import { InputTextField, InputSelectField } from '../Common/MaterialUI/HelperInputs';
import { LoadingIcon } from '../Common/HelperComponents';

class OfficeEditModal extends Component {
    static propTypes = {
        getCurrentOfficeList: PropTypes.func,
        postAddOffice: PropTypes.func,
        postEditOffice: PropTypes.func,
        getMetaData: PropTypes.func,
        getCompanyPackageLimits: PropTypes.func,
        setAppMessage: PropTypes.func,
        office_data: PropTypes.object,
        office_errors: PropTypes.object,
        officeEditData: PropTypes.object,
        saveCompanyOffice: PropTypes.func,
        metaData: PropTypes.object,
        onModalClose: PropTypes.func,
        currentOfficeList: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            reachOfficeLimit: false,
            errors: {
                name: '',
                address_line_1: '',
                address_line_2: '',
                address_line_3: '',
                latitude: '',
                longitude: '',
                country: '',
                state: '',
                city: '',
                pin_code: '',
                phone_number: '',
                time_zone: '',
                currency_code: '',
                preference_order: '',
            },
            formData: {
                name: '',
                address_line_1: '',
                address_line_2: '',
                address_line_3: '',
                latitude: '',
                longitude: '',
                country: '',
                state: '',
                city: '',
                pin_code: '',
                phone_number: '',
                time_zone: '',
                currency_code: '',
                preference_order: '',
            },
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    componentDidMount() {
        const { office_data, office_errors, officeEditData } = this.props;
        if (officeEditData && !officeEditData.id) {
            this.setState({
                reachOfficeLimit: 'checking',
            }, () => {
                this.props.getCompanyPackageLimits('office').then((response) => {
                    if (response && !response.data) {
                        this.setState({
                            reachOfficeLimit: true,
                        });
                    } else {
                        this.setState({
                            reachOfficeLimit: false,
                        });
                    }
                });
            });
        }
        const currencyData = {};
        const countryData = {};
        const timezoneData = {};
        currencyData.type = 'currency';
        countryData.type = 'country';
        timezoneData.type = 'timezone';
        this.props.getMetaData(currencyData);
        this.props.getMetaData(countryData);
        this.props.getMetaData(timezoneData);

        if (office_data) {
            const errors = office_errors != null ? office_errors : {};
            this.setState({
                formData: Object.assign({}, office_data),
                errors,
            });
        }

        if (officeEditData) {
            const stateData = {};
            stateData.type = 'state';
            stateData.parent_id = officeEditData.country;
            this.props.getMetaData(stateData).then(() => {
                const cityData = {};
                cityData.type = 'city';
                cityData.parent_id = officeEditData.state;
                this.props.getMetaData(cityData).then(() => {
                    this.setState({
                        formData: Object.assign({}, officeEditData),
                    });
                });
            });
        }
        if (officeEditData && (officeEditData.latitude || officeEditData.longitude)) {
            this.initialiseGMap(parseFloat(officeEditData.latitude), parseFloat(officeEditData.longitude), true);
        } else {
            this.initialiseGMap(12.95396, 77.4908552, false);
        }
    }

    onKeyPress(e) {
        const key = e.charCode || e.keyCode || 0;
        if (key == 13) {
            e.preventDefault();
        }
    }

    initialiseGMap(latitude, longitude, locationSet) {
        let marker = null;

        setTimeout(() => {
            const element = document.getElementById('google-map');
            const map = new google.maps.Map(element, {
                center: {
                    lat: latitude,
                    lng: longitude,
                },
                zoom: 15,
                styles: [{
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }], // Turn off points of interest.
                }, {
                    featureType: 'transit.station',
                    stylers: [{ visibility: 'off' }], // Turn off bus stations, train stations, etc.
                }],
                disableDoubleClickZoom: true,
            });

            if (locationSet) {
                marker = new google.maps.Marker({
                    position: {
                        lat: latitude,
                        lng: longitude,
                    },
                    map,
                });
            }

            // Create the search box and link it to the UI element.
            const input = document.getElementById('pac-input-search');
            const searchBox = new google.maps.places.SearchBox(input);
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', () => {
                searchBox.setBounds(map.getBounds());
            });

            let markers = [];
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];

                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach((place) => {
                    if (!place.geometry) {
                        console.log('Returned place contains no geometry');
                        return;
                    }
                    const icon = {
                        url: place.icon,
                        size: new google.maps.Size(71, 71),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(17, 34),
                        scaledSize: new google.maps.Size(25, 25),
                    };

                    // Create a marker for each place.
                    markers.push(new google.maps.Marker({
                        map,
                        icon,
                        title: place.name,
                        position: place.geometry.location,
                    }));

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });

            map.addListener('click', (e) => {
                if (marker == null) {
                    marker = new google.maps.Marker({
                        position: {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                        },
                        map,
                    });
                } else {
                    marker.setPosition({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                    });
                }

                const { formData } = this.state;

                formData.latitude = e.latLng.lat();
                formData.longitude = e.latLng.lng();

                this.setState({ formData });
            });
        }, 2000);
    }

    validateData() {
        const { formData, errors } = this.state;
        let hasErrors = false;
        const ignoreList = [
            'address_line_2',
            'address_line_3',
            'latitude',
            'longitude',
            'countryName',
            'stateName',
            'cityName',
        ];

        if (!/^\d{10}$/.test(formData.phone_number)) {
            hasErrors = true;
            errors.phone_number = 'Enter a valid phone number';
        }

        if (!/^\d{6}$/.test(formData.pin_code)) {
            hasErrors = true;
            errors.pin_code = 'Enter a valid pin code';
        }

        Object.keys(formData).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (formData[key]) === 'string') {
                    if (!formData[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return formData;
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { formData, errors } = this.state;

        formData[name] = value;
        errors[name] = '';

        if (name === 'country') {
            const stateData = {};
            stateData.type = 'state';
            stateData.parent_id = value;
            formData.state = '';
            formData.city = '';
            this.props.getMetaData(stateData).then(() => {
                const cityData = {};
                cityData.type = 'city';
                cityData.parent_id = 'state_id';
                this.props.getMetaData(cityData).then(() => {
                    this.setState({ formData, errors });
                });
            });
            return;
        }
        if (name === 'state') {
            const cityData = {};
            cityData.type = 'city';
            cityData.parent_id = value;
            formData.city = '';
            this.props.getMetaData(cityData).then(() => {
                this.setState({ formData, errors });
            });
            return;
        }
        this.setState({ formData, errors });
    }

    updateResponse(resp, message) {
        const { hideSideBarMenu } = this.props;
        const response = resp && resp.data;
        const successMessage = message ? 'Office added successfully!' : 'Office edited successfully!';
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getCurrentOfficeList().then((resp) => {
                const officeData = resp.data;
                const result = officeData.data.some(ofc => ofc.city === null);
                this.props.setData('hideSideBarMenu', result);
                    this.props.getCurrentCompany().then((resp) => {
                        const companyData = resp.data;
                        if(companyData.data.allowed_domains == null) {
                            this.props.setData('hideSideBarMenu', true);
                        }
                    });
            });
            this.props.getCurrentUser().then(() => {
                if (message) {
                    const companyId = response.data.company_id;
                    const officeId = response.data.id;
                    const url = `/e/${companyId}/${officeId}/manage-company`;
                    this.props.history.push({
                        pathname: url,
                        state: {
                            companyId,
                            officeId
                        }
                    });
                }
                this.props.onModalClose();
                $('.close-button').click();
            }).catch((err) => {
                alert('Something went wrong!');
                window.location.reload();
            });
        } else if (response && response.data) {
            this.setState({ errors: response.data });
        }
    }

    handleFormSubmit(evt) {
        evt.preventDefault();
        const validData = this.validateData();
        if (validData) {
            if (this.props.saveCompanyOffice) {
                this.props.saveCompanyOffice(validData);
                return;
            }

            if (validData.id) {
                validData.office_id = validData.id;
                validData.company_id = validData.company_id;
                this.props.postEditOffice(validData).then((resp) => {
                    this.updateResponse(resp);
                });
            } else {
                this.props.postAddOffice(validData).then((resp) => {
                    this.updateResponse(resp, 'Add');
                });
            }
        }
    }

    render() {
        const { formData, errors, reachOfficeLimit } = this.state;
        const { onModalClose } = this.props;

        if (reachOfficeLimit === 'checking') {
            return (
                <LoadingIcon />
            );
        }
        if (reachOfficeLimit === true) {
            return (
                <div className="w3-text-red w3-center w3-padding-32">
                    Sorry! You have reached the package limit. Upgrade to a new plan to add more offices!
                </div>
            );
        }

        let countrySelect = [];
        countrySelect = [{ val: '', name: '-Select Country-' }];

        let stateSelect = [];
        stateSelect = [{ val: '', name: '-Select State-' }];

        let citySelect = [];
        citySelect = [{ val: '', name: '-Select City-' }];

        let timeZone = [];
        timeZone = [{ val: '', name: '-Select Timezone-' }];

        let currencySelect = [];
        currencySelect = [{ val: '', name: '-Select Currency Code-' }];

        if (this.props.metaData) {
            const {
                metaData: {
                    currency, country, timezone, state, city,
                },
            } = this.props;

            if (country && country.length) {
                country.map((countryData) => {
                    countrySelect.push({ val: countryData.id, name: countryData.term });
                });
            }

            if (currency && currency.length) {
                currency.map((currencyData) => {
                    currencySelect.push({ val: currencyData.term, name: currencyData.term });
                });
            }

            if (timezone && timezone.length) {
                timezone.map((zone) => {
                    timeZone.push({ val: zone.term, name: zone.term });
                });
            }

            if (state && state.length) {
                state.map((stateData) => {
                    stateSelect.push({ val: stateData.id, name: stateData.term });
                });
            }

            if (city && city.length) {
                city.map((cityData) => {
                    citySelect.push({ val: cityData.id, name: cityData.term });
                });
            }
        }

        let preferenceOrder = [];
        preferenceOrder = [{ val: '', name: '-Select Preference Order-' }];

        const { currentOfficeList } = this.props;

        let length = 1;
        if (currentOfficeList && currentOfficeList.data && currentOfficeList.data.length > 0) {
            length = currentOfficeList.data.length;

            if (!formData.id) {
                length += 1;
            }
        }
        for (let i = 1; i <= length; i++) {
            preferenceOrder.push({ val: i, name: i });
        }

        return (
            <div className="w3-text-black">
                <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">{formData.id ? 'Edit Office' : 'Add Office'}</h4>
                <form className="form-horizontal" noValidate onSubmit={this.handleFormSubmit}>
                    <div className="w3-row">
                        <div className="w3-col m6">

                            <InputTextField
                                autoFocus
                                label="Name"
                                name="name"
                                id="name"
                                required
                                value={formData.name}
                                onChange={this.handleChange}
                                errors={errors.name}
                            />

                            <InputTextField
                                label="Address line 1"
                                name="address_line_1"
                                id="address_line_1"
                                required
                                value={formData.address_line_1}
                                onChange={this.handleChange}
                                errors={errors.address_line_1}
                            />

                            <InputTextField
                                label="Address line 2"
                                name="address_line_2"
                                id="address_line_2"
                                value={formData.address_line_2}
                                onChange={this.handleChange}
                                errors={errors.address_line_2}
                            />

                            <InputTextField
                                label="Address line 3"
                                name="address_line_3"
                                id="address_line_3"
                                value={formData.address_line_3}
                                onChange={this.handleChange}
                                errors={errors.address_line_3}
                            />
                            <div className="w3-panel">
                                <label>Location</label>
                                <div className="w3-border">
                                    <input id="pac-input-search" className="controls h7t-map-input" type="text" placeholder="Search Box" onKeyPress={this.onKeyPress} />
                                    
                                    <div style={{ height: '176px' }} id="google-map" />
                                </div>
                            </div>
                        </div>
                        <div className="w3-col m6">
                            <InputTextField
                                label="Phone Number"
                                name="phone_number"
                                id="phone_number"
                                required
                                value={formData.phone_number}
                                onChange={this.handleChange}
                                errors={errors.phone_number}
                            />

                            <InputSelectField
                                label="Country"
                                name="country"
                                id="country"
                                required
                                value={formData.country ? formData.country : ''}
                                onChange={this.handleChange}
                                options={countrySelect}
                                errors={errors.country}
                            />

                            <InputSelectField
                                label="State"
                                name="state"
                                id="state"
                                required
                                value={formData.state ? formData.state : ''}
                                onChange={this.handleChange}
                                options={stateSelect}
                                errors={errors.state}
                            />

                            <InputSelectField
                                label="City"
                                name="city"
                                id="city"
                                required
                                value={formData.city ? formData.city : ''}
                                onChange={this.handleChange}
                                options={citySelect}
                                errors={errors.city}
                            />

                            <InputTextField
                                label="Pin Code"
                                name="pin_code"
                                id="pin_code"
                                required
                                value={formData.pin_code}
                                onChange={this.handleChange}
                                errors={errors.pin_code}
                            />

                            <InputSelectField
                                label="Time Zone"
                                name="time_zone"
                                id="time_zone"
                                required
                                value={formData.time_zone ? formData.time_zone : ''}
                                onChange={this.handleChange}
                                options={timeZone}
                                errors={errors.time_zone}
                            />

                            <InputSelectField
                                label="Currency Code"
                                name="currency_code"
                                id="currency_code"
                                required
                                value={formData.currency_code ? formData.currency_code : ''}
                                onChange={this.handleChange}
                                options={currencySelect}
                                errors={errors.currency_code}
                            />

                            <InputSelectField
                                label="Preference Order"
                                name="preference_order"
                                id="preference_order"
                                required
                                value={formData.preference_order ? formData.preference_order : ''}
                                onChange={this.handleChange}
                                options={preferenceOrder}
                                errors={errors.preference_order}
                            />
                        </div>
                    </div>
                    <div className="w3-bar w3-panel">
                        <Button
                            variant="contained"
                            className="w3-margin-right h7t-secondary-btn"
                            onClick={onModalClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            className="h7t-primary-btn"
                        >
                            {formData.id ? 'Update' : 'Add Office'}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        listCountries: state.listCountries,
        listStates: state.listStates,
        listCities: state.listCities,
        currentOfficeList: state.currentOfficeList,
        currentSavedOffice: state.addOffice,
        metaData: state.meta,
        hideSideBarMenu: state.hideSideBarMenu,
    };
}
export default withRouter(connect(mapStateToProps, {
    getCurrentOfficeList,
    getCurrentCompany,
    postAddOffice,
    postEditOffice,
    getMetaData,
    getCurrentUser,
    setAppMessage,
    getCompanyPackageLimits,
    setData,
})(OfficeEditModal));
