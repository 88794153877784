const JobSeekers = () => {
    return (
        <div className="w3-container">
            <div className="w3-panel">
                <h3>Job search</h3>
                <div>
                    <ul>
                        <li><h4>Better Co-ordination</h4></li>
                        <li><h4>Get status updates</h4></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default JobSeekers;
