import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import {
    Grid, Drawer, Divider, List, Button,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { InputSelectField } from '../Common/MaterialUI/HelperInputs';


class JobFilters extends Component {
    static propTypes = {
        jobTitleList: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            right: false,
            filterOn: {
                job_id: '',
                posted_on: '',
                location: '',
                opened: '',
                closed: '',
                search: '',
                filter: 'all',
                owner: '',
            },
        };
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.triggerFilter = this.triggerFilter.bind(this);
    }

    toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            right: open,
        });
    };

    handleClearFilter() {
        const filterOn = {
            job_id: '',
            posted_on: '',
            location: '',
            opened: '',
            closed: '',
            search: '',
            filter: 'all',
            owner: '',
        };
        this.setState({
            filterOn,
            right: false,
        }, () => {
            this.props.clearFilterValues(filterOn);
        });
    }

    handleSelectFilter(event) {
        const { name, value } = event.target;
        const { filterOn } = this.state;
        filterOn[name] = value;
        this.setState({ filterOn });
    }

    triggerFilter() {
        const { filterOn } = this.state;
        this.props.handleJobFilters(filterOn);
        this.setState({
            right: false,
        });
    }


    render() {
        const {
            jobTitleList,
        } = this.props;
        const {
            right,
            filterOn,
        } = this.state;

        const postedOnSelect = [{ vale: '', name: '-Select Posted On-' }];
        const jobTitleSelect = [{ val: '', name: '-Select Job Title-' }];
        const locationSelect = [{ val: '', name: '-Select Location-' }];
        const openedSelect = [{ val: '', name: '-Select Positions Open-' }];
        const closedSelect = [{ val: '', name: '-Select Positions Closed-' }];
        const ownerSelect = [{ val: '', name: '-Select Owner-' }];
        const statusSelect = [
            { val: 'all', name: 'All' },
            { val: 'saved', name: 'Saved' },
            { val: 'active', name: 'Published' },
            { val: 'unpublish', name: 'UnPublished' },
        ];

        if (jobTitleList && jobTitleList.data && jobTitleList.data.length) {
            jobTitleList.data.map((item) => {
                jobTitleSelect.push({ val: item.id, name: item.job_title });
                const idxOpen = openedSelect.findIndex((opened) => {
                    return opened.val === item.positions_open;
                });
                if (idxOpen === -1) {
                    openedSelect.push({ val: item.positions_open, name: item.positions_open });
                }
                if (item.positions_filled) {
                    const idxClose = closedSelect.findIndex((closed) => {
                        return closed.val === item.positions_filled;
                    });
                    if (idxClose === -1) {
                        closedSelect.push({ val: item.positions_filled, name: item.positions_filled });
                    }
                }
            });
            jobTitleList.data.filter((obj, id, arr) => (
                arr.findIndex((item) => moment(item.created_at).format('MMM Do, YYYY') === moment(obj.created_at).format('MMM Do, YYYY')) === id
            )).map((item) => {
                postedOnSelect.push({ val: item.created_at, name: moment(item.created_at).format('MMM Do, YYYY') });
            });

            jobTitleList.data.filter((obj, id, arr) => (
                arr.findIndex((item) => item.current_office && item.current_office.city_info && item.current_office.city_info.term != obj.current_office && obj.current_office.city_info && obj.current_office.city_info.term) === id
            )).map((item) => {
                locationSelect.push({ val: item.current_office.city_info.term, name: item.current_office.city_info.term });
            });

            jobTitleList.data.filter((obj, id, arr) => (
                arr.findIndex((item) => item.display_name === obj.display_name) === id
            )).map((item) => {
                ownerSelect.push({ val: item.creator_user_id, name: item.display_name });
            });
        }

        const sideList = (
            <div
                className="h7t-filter-container"
                role="presentation"
            >
                <h3>Filters</h3>
                <InputSelectField
                    label="Posted On"
                    id="posted_on"
                    name="posted_on"
                    value={filterOn.posted_on}
                    options={postedOnSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Job Title"
                    id="job_id"
                    name="job_id"
                    value={filterOn.job_id}
                    options={jobTitleSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                />
                {/* <Divider className="h-margin-16" />
                <InputSelectField
                    label="Location"
                    id="location"
                    name="location"
                    value={filterOn.location}
                    options={locationSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                /> */}
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Open Positions"
                    id="opened"
                    name="opened"
                    value={filterOn.opened}
                    options={openedSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Closed Positions"
                    id="closed"
                    name="closed"
                    value={filterOn.closed}
                    options={closedSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Owner"
                    id="owner"
                    name="owner"
                    value={filterOn.owner}
                    options={ownerSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Status"
                    id="filter"
                    name="filter"
                    value={filterOn.filter}
                    options={statusSelect}
                    onChange={e => this.handleSelectFilter(e)}
                    fullWidth
                />
                <Divider className="h-margin-16" />
                <List>
                    <Grid container justify="space-between" alignItems="center">
                        <Button
                            variant="contained"
                            size="small"
                            className="h7t-secondary-btn"
                            onClick={this.handleClearFilter}
                        >
                            Clear
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={this.triggerFilter}
                            className="w3-center h7t-primary-btn"
                        >
                            Filter
                        </Button>
                    </Grid>
                </List>
            </div>
        );

        return (
            <div>
                <IconButton
                    className="w3-right h7t-cursor-pointer"
                    style={{ marginTop: '12px', marginRight: '20px' }}
                    onClick={this.toggleDrawer(true)}
                >
                    <FilterListIcon />
                </IconButton>
                <Drawer
                    anchor="right"
                    open={right}
                    onClose={this.toggleDrawer(false)}
                >
                    {sideList}
                </Drawer>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        jobTitleList: state.jobTitleList,
    };
}
export default connect(mapStateToProps, {
})(JobFilters);
