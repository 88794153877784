import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Box, MenuItem, Menu, Grid, Paper, Select, FormHelperText,FormControl,InputLabel } from '@material-ui/core';
import { getLatestForEmployer, postResetCurrentCompanyOffice, getDashboardCount, getChannelResumesList, getHRList, getPanelMemberList, getMonthlyHiringStatusList } from '../../js/actions';
import CompanyOfficeForm from './CompanyOfficeForm';
import moment from 'moment';
import DashboardFilter from '../JobApplication/dashboardFilter';
import BlocksComponent from './BlocksComponent';
import TwoTableComponent from './TwoTableComponent';
import PieComponent from './PieComponent';
import BarComponent from './BarComponent';
import TableComponent from './TableComponent';
import { newCalculateAgeing } from '../Common/Constants';
import { LoadingIcon } from '../Common/HelperComponents';

const today = `Today: ${moment().format('MMM D')}`;
class Dashboard extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        latestForEmployer: PropTypes.object,
        params: PropTypes.shape({
            companyId: PropTypes.string,
            officeId: PropTypes.string,
        }),
    };

    constructor(args) {
        super(args);
        this.state = {
            companyOffice: '',
            anchorEl: null,
            // displayDay: today
            displayDay: 'LifeTime',
        };
        this.handleCompanyOfficeModal = this.handleCompanyOfficeModal.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        const {
            match: { params: { companyId, officeId } },
        } = this.props;
        if (companyId && officeId) {
            this.props.getLatestForEmployer();
        }
        this.props.getDashboardCount();
        this.props.getChannelResumesList();
        this.props.getHRList();
        this.props.getPanelMemberList();
        this.props.getMonthlyHiringStatusList();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.match.params.officeId &&
            newProps.match.params.officeId !== this.props.match.params.officeId) {
            this.props.getDashboardCount();
            this.props.getChannelResumesList();
            this.props.getHRList();
            this.props.getPanelMemberList();
            this.props.getMonthlyHiringStatusList();
        }
    }

    handleCompanyOfficeModal() {
        this.props.postResetCurrentCompanyOffice().then(() => {
            this.setState({ companyOffice: 'show' });
        });
    }

    handleDateChange(evt) {
        this.setState({
            anchorEl: evt.currentTarget
        })
    }

    handleDays(key) {
        let displayDay
        if (key === 'seven') {
            displayDay = `Last 7 days`
        } else if (key === 'ten') {
            displayDay = `Last 10 days`
        } else if (key === 'thisMonth') {
            displayDay = `This Month`
        } else if (key === 'lastMonth') {
            displayDay = `Last Month`
        } else {
            // displayDay = today;
            displayDay = 'LifeTime'
        }

        this.setState({ displayDay });
        this.handleClose();
    }

    

    render() {
        const {
            location: { state: { companyId, officeId } },
            currentUser,
            currentUser: { user, companies },
            latestForEmployer,
            dashboardCount,
            channelResumesList,
            hrList,
            panelMemberList,
            monthlyHiringStatusList,
        } = this.props;
        const { companyOffice, anchorEl, displayDay, location } = this.state;
        const NewDate = new Date();
        const handleClose = () => {
            this.setState({ anchorEl: null })
        }
        let blocksData = [];
        let twoColTableData = [];
        let fiveTableData = [];
        let dateData = [];
        let open = 0, closed = 0, inProgress = 0, rejected = 0;
        let published = 0, unpublished = 0, saved = 0;
        const finalChannelData = [];
        const hrData = [];
        let panelData = [];
        const monthlyData = [];

        if (!dashboardCount || !dashboardCount.data) {
            return <LoadingIcon />
        }

        if (dashboardCount && dashboardCount.data) {

            open = dashboardCount.data.open_positions
            closed = dashboardCount.data.closed_positions
            inProgress = dashboardCount.data.inProgress
            rejected = dashboardCount.data.rejected
            published = dashboardCount.data.tot_published
            unpublished = dashboardCount.data.tot_unpublished
            saved = dashboardCount.data.tot_saved

            twoColTableData = [
                { name: 'Open', value: open },
                { name: 'In Progress', value: inProgress },
                { name: 'Hired', value: dashboardCount.data.hired },
                { name: 'Closed', value: dashboardCount.data.closed },
                { name: 'Rejected', value: rejected }
            ],
                blocksData = [
                    { title: 'Published Jobs', count: published },
                    { title: 'Open Positions', count: dashboardCount.data.open_positions },
                    { title: 'Applicants', count: dashboardCount.data.totalApplicants },
                    { title: 'Closed Positions', count: dashboardCount.data.closed },                    
                ],
                fiveTableData = dashboardCount.data.jobList.map((item, idx) => {
                const date = newCalculateAgeing(item.date);
                    return {
                        name: item.job_title,
                        id: item.job_id,
                        days : date,
                        applied : item.applied,
                        open: item.open,
                        ip: item.in_progress,
                        hired: item.hired,
                        closed: item.closed,
                        rejected: item.rejected,
                    }
                })
        }

        const pieDataKey = [
            { id: 'Open', label: 'Open', value: open, color: 'hsl(92, 70%, 50%)', type: 'dots' },
            { id: 'Hired', label: 'Hired', value: closed, color: 'hsl(353, 70%, 50%)', type: 'dots' },
            { id: 'Rejected', label: 'Rejected', value: rejected, color: 'hsl(209, 70%, 50%)', type: 'dots' },
            { id: 'In Progress', label: 'In Progress', value: inProgress, color: 'hsl(317, 70%, 50%)', type: 'lines' },
            
        ];

        const pieData = []
        pieDataKey.filter(item => {
            if (item.value > 0) {
                pieData.push({
                    "id": item.id,
                    "label": item.label,
                    "value": item.value,
                    "color": item.color,
                    "type": item.type,

                })
            }
        })

        const jobStatusKey = [
            { id: 'Published', label: 'Published', value: published, color: 'hsl(92, 70%, 50%)', type: 'dots' },
            { id: 'UnPublished', label: 'UnPublished', value: unpublished, color: 'hsl(353, 70%, 50%)', type: 'dots' },
            { id: 'Saved', label: 'Saved', value: saved, color: 'hsl(209, 70%, 50%)', type: 'dots' }
            
        ];

        const jobStatus = []
        jobStatusKey.filter(item => {
            if (item.value > 0) {
                jobStatus.push({
                    "id": item.id,
                    "label": item.label,
                    "value": item.value,
                    "color": item.color,
                    "type": item.type,

                })
            }
        })
        if(channelResumesList && channelResumesList.length){

            channelResumesList.forEach(item => {
                const channelData = {};

                channelData.month = item.channel;
                channelData.Resumes = item.resumes===0?'0.1':item.resumes;
                channelData.InProgress = item.in_progress===0?'0.1':item.in_progress;
                channelData.Hired = item.hired===0?'0.1':item.hired;
                channelData.Closed = item.closed===0?'0.1':item.closed;
                channelData.Rejected = item.rejected===0?'0.1':item.rejected;
                channelData.ResumesColor = "hsl(47, 70%, 50%)";
                channelData.InProgressColor = "hsl(344, 70%, 50%)";
                channelData.HiredColor = "hsl(120,70%, 50%)";
                channelData.ClosedColor = "hsl(100,70%, 50%";
                channelData.RejectedColor = "hsl(47, 70%, 50%)";
                

                finalChannelData.push(channelData);

            })
        }

        if(hrList && hrList.length){
            hrList.forEach(item => {
                const data = {};

                data.month = item.display_name;
                data.Open = item.open===0?'0.1':item.open;
                data.Hired = item.hired===0?'0.1':item.hired;
                data.Closed = item.closed===0?'0.1':item.closed;
                data.Rejected = item.rejected===0?'0.1':item.rejected;
                data.OpenColor = "hsl(47, 70%, 50%)";
                data.HiredColor = "hsl(120,70%, 50%)";
                data.ClosedColor = "hsl(100,70%, 50%";
                data.RejectedColor = "hsl(147, 70%, 50%)";

                hrData.push(data);

            })
        }

        if(panelMemberList && panelMemberList.data){
            panelData = panelMemberList.data.map(item => {
                return {
                    name: item.display_name,
                    written: item.written,
                    f2f: item.f2f,
                    tele: item.telephonic
                }
            })
        }

        if(monthlyHiringStatusList && monthlyHiringStatusList.length){
            monthlyHiringStatusList.forEach(item => {
                const month = {};

                month.month = item.month;
                month.Open = item.open===0?'0.1':item.open;
                month.Closed = item.close===0?'0.1':item.close;
                month.OpenColor = "hsl(47, 70%, 50%)";
                month.ClosedColor = "hsl(120,70%, 50%)";

                monthlyData.push(month);
            })
        }

        



        return (
            <div className="content-margin w3-margin-top h7t-margin-bottom-40">
                <div className="w3-row">
                    <Grid container className="h7t-margin-20">
                        <Grid item sm={12} md={9} alignItems="center">
                            <strong className="w3-left h7t-modal-title" style={{ marginRight: '8px' }}>{`Welcome back, ${user.display_name}! `} <span style={{ fontSize: '15px' }}>{moment(NewDate).format('dddd, D MMMM YYYY')}</span></strong>
                            
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <Box display="flex" justifyItems="space-between" alignItems="center" className="w3-right">
                                {/* <DashboardFilter
                                // filterOn={filterOn}
                                // clearFilterValues={this.clearFilters}
                                // handleJobFilters={this.handleJobFilters}
                                /> */}
                                <div className="h7t-margin-bottom-10 h7t-margin-right">
                                <FormControl style={{minWidth: 120}}>
                                {/* <InputLabel>Location</InputLabel> */}
                                    <Select className="center-align"
                                        value={location}
                                        displayEmpty
                                        
                                    >
                                        <MenuItem defaultValue={dashboardCount && dashboardCount.data.officeLocation && dashboardCount.data.officeLocation.cityName}>{dashboardCount && dashboardCount.data.officeLocation && dashboardCount.data.officeLocation.cityName}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                                <Button variant="contained" className="w3-right h7t-cursor-pointer h7t-primary-btn " aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleDateChange}>
                                    {displayDay}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {/* {displayDay !== today ? <MenuItem onClick={() => this.handleDays('today')}>Today</MenuItem> : null} */}
                                    <MenuItem disabled={true} onClick={() => this.handleDays('seven')}>Last 7 days</MenuItem>
                                    <MenuItem disabled={true} onClick={() => this.handleDays('ten')}>Last 10 days</MenuItem>
                                    <MenuItem disabled={true} onClick={() => this.handleDays('thisMonth')}>This Month</MenuItem>
                                    <MenuItem disabled={true} onClick={() => this.handleDays('lastMonth')}>Last Month</MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <div className="h7t-margin-bottom-20">
                    <Grid container className="h7t-margin-20" spacing={1}>
                        <Grid item sm={12} lg={6} md={6}>
                            <BlocksComponent data={blocksData} />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TwoTableComponent 
                            data={twoColTableData}
                            heading={'Hiring Stats'}
                            keys={['Status','Number']} />
                        </Grid>
                    </Grid>
                </div>

                <div className="h7t-margin-bottom-20">
                    <Grid container className="h7t-margin-20" spacing={2}>
                        <Grid item sm={12} lg={8} md={8}>
                            {fiveTableData.length > 0 ?<TableComponent 
                            data={fiveTableData} 
                            heading={'Job Positions'}
                            keys={[
                                { label: 'Job ID', value: 'id' },
                                { label: 'Job Position', value: 'name' },
                                { label: 'Ageing Days', value: 'days' },
                                { label: 'Open', value: 'open' },
                                { label: 'Applied', value: 'applied' },
                                { label: 'In Progress', value: 'ip' },
                                { label: 'Hired', value: 'hired' },
                                { label: 'Rejected', value: 'rejected' },
                                { label: 'Closed', value: 'closed' }]} /> : <Fragment>
                                <h6 className="h7t-margin-0 h7t-modal-title">Job Positions</h6>
                                <Paper style={{ height: '320px' }} className="w3-padding h7t-table-data">
                                    <h4 className="center-align h-margin-top-140"></h4>
                                </Paper>
                            </Fragment>
                            }
                        </Grid>
                        <Grid item sm={12} lg={4} md={4}>
                            {jobStatus.length > 0 ? < PieComponent data={jobStatus} pieLabel={'Job Position Status'} /> : <Fragment>
                                <h6 className="h7t-modal-title h7t-margin-0">Job Position Status</h6>
                                <Paper style={{ height: '320px' }} className="w3-padding h7t-table-data">
                                    <h4 className="center-align h-margin-top-140"></h4>
                                </Paper>
                            </Fragment>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div className="h7t-margin-bottom-20">
                    <Grid container className="h7t-margin-20" spacing={1}>
                        <Grid item sm={12} lg={12} md={12}>
                            {finalChannelData.length > 0 ? <BarComponent
                                data={finalChannelData}
                                heading={'Channel Source'}
                                keys={['Resumes','InProgress','Hired', 'Closed', 'Rejected']} /> : <Fragment>
                                <h6 className="h7t-modal-title h7t-margin-0">Channel Source</h6>
                                <Paper style={{ height: '300px' }} className="w3-padding h7t-table-data">
                                    <h4 className="center-align h-margin-top-140"></h4>
                                </Paper>
                            </Fragment>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div>
                    <Grid container className="h7t-margin-20" spacing={1}>
                        <Grid item sm={12} lg={12} md={12}>
                        {monthlyData.length > 0 ?
                                <BarComponent
                                    data={monthlyData}
                                    heading={'Monthly Hiring Status'}
                                    keys={['Open', 'Closed']} /> : <Fragment>
                                    <h6 className="h7t-modal-title h7t-margin-0">Monthly Hiring Status</h6>
                                    <Paper style={{ height: '320px' }} className="w3-padding h7t-table-data">
                                        <h4 className="center-align h-margin-top-140"></h4>
                                    </Paper>
                                </Fragment>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div className="h7t-margin-bottom-20">
                    <Grid container className="h7t-margin-20" spacing={2}>
                        <Grid item sm={12} lg={12} md={12}>
                        {/* {panelData.length > 0 ?<TableComponent 
                            data={panelData}
                            heading={'Panel Members'}
                            keys={[
                                { label: 'InterViewer Name', value: 'name' },
                                { label: 'Telephonic', value: 'tele' },
                                { label: 'Written', value: 'written' },
                                { label: 'F2F', value: 'f2f' }]} /> : <Fragment>
                                <h6 className="w3-text-black h7t-margin-0">Panel Members</h6>
                                <Paper style={{ height: '320px' }} className="w3-padding h7t-table-data">
                                    <h4 className="center-align h-margin-top-140"></h4>
                                </Paper>
                            </Fragment>
                            } */}
                            {hrData.length > 0 ? <BarComponent
                                data={hrData}
                                heading={'HR Owner'}
                                keys={['Open', 'Hired', 'Closed', 'Rejected']} /> : <Fragment>
                                    <h6 className="h7t-modal-title h7t-margin-0">HR Owner</h6>
                                    <Paper style={{ height: '300px' }} className="w3-padding h7t-table-data">
                                        <h4 className="center-align h-margin-top-140"></h4>
                                    </Paper>
                                </Fragment>
                            }
                        </Grid>
                    </Grid>
                </div>

               

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
        latestForEmployer: state.latestForEmployer,
        dashboardCount: state.dashboardCount,
        channelResumesList: state.channelResumesList && state.channelResumesList.data ? state.channelResumesList.data: null,
        hrList: state.hrList && state.hrList.data ? state.hrList.data: null,
        panelMemberList: state.panelMemberList,
        monthlyHiringStatusList: state.monthlyHiringStatusList && state.monthlyHiringStatusList.data ? state.monthlyHiringStatusList.data: null,
    };
};

export default connect(mapStateToProps, {
    getLatestForEmployer,
    postResetCurrentCompanyOffice,
    getDashboardCount,
    getChannelResumesList,
    getHRList,
    getPanelMemberList,
    getMonthlyHiringStatusList,
})(Dashboard);
