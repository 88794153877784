import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import CalendarEventsModal from './CalendarEventsModal';
import { getScheduleWorkflowCalendarList, getCurrentUser } from '../../js/actions';


class MyCalendar extends Component {
	static propTypes = {
		scheduleWorkflowCalendarList: PropTypes.func,
		getScheduleWorkflowCalendarList: PropTypes.func,
		currentUser: PropTypes.object,
	};

	constructor(args) {
		super(args);
		this.state = {
			right: false,
			CalData: {},
		};
		this.viewCalendarDetails = this.viewCalendarDetails.bind(this);
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.sideList = this.sideList.bind(this);
	}

	componentDidMount() {
		const { getScheduleWorkflowCalendarList } = this.props;
		getScheduleWorkflowCalendarList().then((resp) => {
			const res = resp && resp.data;
			if (res && res.data) {
				this.setState({
					CalendarData: res.data,
				});
			}
		});
	}

	toggleDrawer = (open) => {
		this.setState({
			right: open,
		});
	};

	sideList = () => (
		<div
			style={{ width: '400px', minHeight: '400', maxHeight:'450px' }}
			role="presentation"
			onClick={() => this.toggleDrawer(false)}
			onKeyDown={() => this.toggleDrawer(false)}
		>
			{this.state.CalData ? (
				<div className="w3-container h-margin-top-35" style={{ padding: '0px 40px' }}>
					<CalendarEventsModal
						CalendarData={this.state.CalData}
					/>
				</div>
			) : null}
		</div>
	);

	viewCalendarDetails(evt) {
		if (evt) {
			this.setState({
				CalData: evt,
			});
			this.toggleDrawer(true);
		}
	}

	render() {
		const {
			right, CalendarData,
		} = this.state;
		const localizer = momentLocalizer(moment);
		let events = [];
		if (CalendarData) {
			events = CalendarData.map((data) => {
				const timeDetails = new Date(data.action_time);
				const dateDetails = new Date(data.action_date);
				return {
					'title': data.state_name,
					'start': new Date(dateDetails.getFullYear(), dateDetails.getMonth(), dateDetails.getDate(), timeDetails.getHours(), timeDetails.getMinutes()),
					'end': new Date(dateDetails.getFullYear(), dateDetails.getMonth(), dateDetails.getDate(), timeDetails.getHours(), timeDetails.getMinutes()),
					'eventData': data,
				}
			});
		}

		return (
			<div style={{ height: '700px', marginTop: '50px', marginLeft: '50px', marginRight: '50px' }}>
				<div>
					<Drawer 
						anchor="right"
						open={right}
						onClose={() => this.toggleDrawer(false)}
						className="cal-side-drawer"
					>
						{this.sideList('right')}
					</Drawer>
				</div>
				<Calendar
					className="mod-big-calendar"
					localizer={localizer}
					events={events}
					onSelectEvent={this.viewCalendarDetails}
					drilldownView="agenda"
					getDrilldownView={(targetDate, currentViewName, configuredViewNames) => {
						if (currentViewName === 'month' && configuredViewNames.includes('agenda'))
							return 'agenda'
						return null;
					}}
					views={['month', 'week', 'day', 'agenda']}
					startAccessor="start"
					endAccessor="end"
					style={{ height: 700 }}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
	return {
		currentUser,
		scheduleWorkflowCalendarList: state.scheduleWorkflowCalendarList,
	};
}

export default withRouter(connect(mapStateToProps, {
	getScheduleWorkflowCalendarList,
	getCurrentUser,
})(MyCalendar));
