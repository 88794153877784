import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormHelperText, Button } from '@material-ui/core';
import {
    getCurrentSkillList,
    getCurrentDetailsList,
    getMetaData,
    postUserResumeBlockEdit,
    postUserResumeBlockCreate,
    setAppMessage,
    getCurrentUser,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import { InputTextField, SearchDropdown, CheckboxMUI } from '../../Common/MaterialUI/HelperInputs';

class AddDetailsModal extends Component {
    static propTypes = {
        metaData: PropTypes.object,
        editData: PropTypes.object,
        getMetaData: PropTypes.func,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getCurrentUser: PropTypes.func,
        currentSkillList: PropTypes.object,
        getCurrentSkillList: PropTypes.func,
        getCurrentDetailsList: PropTypes.func,
        postUserResumeBlockEdit: PropTypes.func,
        postUserResumeBlockCreate: PropTypes.func,
        resumeDetail: PropTypes.object,
    }

    constructor(args) {
        super(args);
        this.state = {
            errors: {},
            form: {},
            skills: [],
            loc: '',
            skilllist: [],
            searchKey: '',
            checkCurrentlyWorking: false,
            projectError: {},
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSkillChange = this.handleSkillChange.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCurrentWorking = this.handleCurrentWorking.bind(this);
        this.addNewProject = this.addNewProject.bind(this);
    }

    componentDidMount() {
        const { editData } = this.props;
        this.props.getCurrentSkillList().then((resp) => {
            this.setState({
                skilllist: resp.data.data,
            });
        });
        let skillSelected = [];

        if (editData) {
            const formData = Object.assign({}, editData);
            formData.skills = editData.skills ? editData.skills.split(',') : [];
            skillSelected = formData.skills.slice(0);
            if (formData.projects.length) {
                formData.projects.map((item) => {
                    item.checkProject = item.date_to ? false : item.id ? true : false;
                    return item;
                });
            }
            this.setState({
                errors: {},
                form: formData,
                loc: editData.arrloc,
                checkCurrentlyWorking: formData.end_date ? false : formData.id ? true : false,
            });
        }
        const skillsList = {};
        skillsList.type = 'skills';
        skillsList.ids = skillSelected;
        this.props.getMetaData(skillsList).then(() => {
            this.forceUpdate();
        });
    }

    handleChange(evt) {
        const targetElement = $(evt.target).attr('id');
        const { form, errors } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({ errors });
        }

        form[targetElement] = $(evt.target).val();
        this.setState({ form });
    }

    handleDatePicker(evt, date, id) {
        const { form, errors } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');

        if (errors[id]) {
            errors[id] = null;
        }
        if (id === 'end_date' && errors.currently_work) {
            errors.currently_work = null;
        }

        form[id] = formatedDate;
        this.setState({ form, errors });
    }

    handleSkillChange(option) {
        const { form, errors } = this.state;
        let value = null;
        if (option && Array.isArray(option)) {
            if (option.length) {
                value = [];
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        }
        form.skills = value;

        if (errors.skills) {
            errors.skills = null;
        }
        const skillsList = {};
        skillsList.type = 'skills';
        skillsList.ids = value;
        this.props.getMetaData(skillsList).then(() => {
            this.setState({
                form,
                skills: value,
                errors,
                searchKey: '',
            });
        });
    }

    handleSearch(evt, val) {
        if (evt && evt.type === 'change') {
            const { form } = this.state;
            this.setState({ searchKey: val });
            const skillsList = {};
            skillsList.type = 'skills';
            skillsList.search = val;
            skillsList.ids = form.skills;
            this.props.getMetaData(skillsList).then(() => {
                this.forceUpdate();
            });
        }
    }

    arrayString(list) {
        const result = [];
        const skills = [];
        let tempskills = '';

        list.forEach((val) => {
            if (val) {
                tempskills = val.split(',');
                for (let j = 0; j < tempskills.length; j++) {
                    skills.push(tempskills[j]);
                }
            }
        });
        skills.forEach((skill) => {
            if ($.inArray(skill, result) === -1) {
                result.push(skill);
            }
        });

        return result;
    }

    updateResponse(response, successMessage) {
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getCurrentDetailsList();
            this.props.getCurrentUser();
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                errors: response.data,
            });
        }
    }

    validateData() {
        const {
            form, errors, checkCurrentlyWorking, checkProject,
        } = this.state;
        let hasErrors = false;
        const ignoreList = [
            'arrloc',
            'skillsets',
            'description',
            'json_data',
            'entity_term_id',
            'date_to',
            'date_from',
            'certificate',
            'year_of_expire',
        ];

        if (checkCurrentlyWorking) {
            ignoreList.push('end_date');
        }
        if (checkProject) {
            ignoreList.push('date_to');
        }

        if (form.start_date && new Date(form.start_date) > new Date()) {
            hasErrors = true;
            errors.start_date = 'Start date must be less than today\'s';
        }

        if (form.skills && form.skills.length === 0) {
            hasErrors = true;
            errors.skills = 'This field is mandatory';
        }
        Object.keys(form).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (form[key]) === 'string') {
                    if (!form[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!form[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (form.end_date && (form.start_date >= form.end_date)) {
            hasErrors = true;
            errors.end_date = 'The end date must be a date after start date.';
        }
        if (form.end_date && new Date(form.end_date) > new Date()) {
            hasErrors = true;
            errors.end_date = 'The end date must be less than today\'s';
        }
        const validProjects = this.validateProjects(form);
        if (hasErrors || !validProjects) {
            this.setState({ errors });
            return false;
        }
        return form;
    }

    handleSubmit(e) {
        e.preventDefault();
        const validData = this.validateData();
        const { loc, skilllist, skills } = this.state;
        if (validData) {
            validData.skills = validData.skills.join();
            let tmpSkillsets = validData.skills;
            if (skills.length > 0) {
                tmpSkillsets = skills.join();
            }
            let newSkillValue = null;
            if (loc === undefined) {
                const tempNewSkill = skilllist.concat(skills);
                newSkillValue = this.arrayString(tempNewSkill);
            } else {
                skilllist[loc] = tmpSkillsets;
                newSkillValue = this.arrayString(skilllist);
            }
            validData.skillsets = newSkillValue.join(', ');
            if (!validData.end_date) {
                validData.end_date = null;
            }

            if (!validData.date_to) {
                validData.date_to = null;
            }
            if (!validData.date_from) {
                validData.date_from = null;
            }

            if (validData.id) {
                validData.resume_block_id = validData.id;
                this.props.postUserResumeBlockEdit(validData).then((response) => {
                    if (response) {
                        this.updateResponse(response.data, 'Details edited successfully!');
                    }
                });
            } else {
                // validData.external_entity_reference = 'HR Ceegees';
                // validData.external_ref_url = 'www.ceegees.in';
                validData.skills = tmpSkillsets;
                validData.status = 1;
                this.props.postUserResumeBlockCreate(validData).then((response) => {
                    if (response) {
                        this.updateResponse(response.data, 'Details added successfully!');
                    }
                });
            }
        }
    }

    handleDescription(content) {
        const { form } = this.state;
        form.description = (content == '<p><br></p>') ? '' : content;
        this.setState({ form });
    }

    handleCurrentWorking(e) {
        const { resumeDetail } = this.props;
        const { errors, form } = this.state;
        if (resumeDetail && resumeDetail.data) {
            const current = resumeDetail.data.filter((detail) => {
                if (detail.type === form.type && !detail.end_date) {
                    if (form.id) {
                        return form.id !== detail.id;
                    }
                    return detail;
                }
            });
            if (current && current.length) {
                const msg = form.type === 'Experience' ? 'experience' : 'education';
                errors.currently_work = `You cannot check more than one present ${msg}`;
                this.setState({
                    errors,
                });
                return;
            }
        }
        if (errors.end_date) {
            errors.end_date = null;
        }
        if (e.target.checked) {
            form.end_date = '';
        }

        this.setState({
            checkCurrentlyWorking: e.target.checked,
            errors,
            form,
        });
    }

    validateProjects(form) {
        const { projectError } = this.state;
        let hasErrors = false;
        if (form.projects && form.projects.length) {
            form.projects.map((item, i) => {
                projectError[i] = {};
                if (!item.title) {
                    hasErrors = true;
                    projectError[i].title = 'Please fill this field';
                }
                if (item.url) {
                    const urlRegex = /^(https?|ftp|smtp):\/\//;
                    const regex = new RegExp(urlRegex);
                    if (!item.url.match(regex)) {
                        hasErrors = true;
                        projectError[i].url = 'Enter a valid url';
                    }
                }
                if (item.date_from && new Date(item.date_from) > new Date()) {
                    hasErrors = true;
                    projectError[i].date_from = 'This date must be less than today\'s';
                }
                if (item.date_to && item.date_to !== '0000-00-00' && (item.date_from >= item.date_to)) {
                    hasErrors = true;
                    projectError[i].date_to = 'This date must be a date after date from.';
                }
                if (item.date_to && item.date_to !== '0000-00-00' && (!item.date_from || item.date_from === '0000-00-00')) {
                    hasErrors = true;
                    projectError[i].date_from = 'Please select a start date';
                }
            });
        }
        if (hasErrors) {
            this.setState({
                projectError,
            });
            return false;
        }
        return form;
    }

    addNewProject() {
        const { form } = this.state;
        const validProjects = this.validateProjects(form);
        if (validProjects) {
            form.projects.push({
                title: '',
                url: null,
                date_from: null,
                date_to: null,
                responsibilities: '',
                checkProject: false,
            });
            this.setState({
                form,
            });
        }
    }

    handleChangeProject(projects, projectError) {
        const { form } = this.state;
        form.projects = projects;
        this.setState({
            form,
            projectError,
        });
    }

    removeProject(idx) {
        const { form } = this.state;
        form.projects.splice(idx, 1);
        this.setState({
            form,
        });
    }

    render() {
        const { onModalClose, metaData } = this.props;
        const {
            form, errors, checkCurrentlyWorking, projectError, searchKey,
        } = this.state;
        const options = [];
        if (metaData) {
            const { metaData: { skills } } = this.props;
            if (skills && skills.length) {
                skills.forEach((skill) => {
                    options.push({ val: skill.term, name: skill.term });
                });
            }
        }

        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                title={form.id ? `Edit ${form.type}` : `Add ${form.type}`}
                widthCls="chp-small-dlg"
            >
                <form className="form-horizontal" noValidate onSubmit={this.handleSubmit}>
                    <InputTextField
                        label={(form.type == 'Education') ? 'Course with Specialization' : 'Designation'}
                        name="title"
                        id="title"
                        required
                        value={form.title ? form.title : ''}
                        onChange={this.handleChange}
                        errors={errors.title}
                        autoFocus
                    />

                    <InputTextField
                        label={(form.type == 'Education') ? 'Institution/University' : 'Company'}
                        name="institution"
                        id="institution"
                        required
                        value={form.institution ? form.institution : ''}
                        onChange={this.handleChange}
                        errors={errors.institution}
                    />
                    <div className="w3-panel">
                        <CheckboxMUI
                            checked={checkCurrentlyWorking}
                            onChange={this.handleCurrentWorking}
                            label={(form.type === 'Education') ? 'Pursuing' : 'I currently work here'}
                        />
                        <FormHelperText className="w3-margin-left" error={errors.currently_work}>{errors.currently_work}</FormHelperText>
                    </div>

                    <div className="w3-panel">
                        <label>Description</label>
                        <ReactQuill
                            value={form.description ? form.description : ''}
                            placeholder="Description"
                            style={{ height: '100px', marginBottom: '50px' }}
                            onChange={this.handleDescription}
                        />
                        <FormHelperText className="w3-margin-left" error={errors.description}>{errors.description}</FormHelperText>
                    </div>

                    <div className="w3-panel w3-row" style={{ display: 'flex' }}>
                        <div className="w3-col m6 h7t-margin-top-mobile h7t-padding-right-4">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    size="small"
                                    required
                                    inputVariant="outlined"
                                    label="Start Date"
                                    format="MMM do, yyyy"
                                    disableFuture={true}
                                    inputProps={{readOnly: true}}
                                    value={form.start_date ? moment(form.start_date) : null}
                                    onChange={(e, date) => this.handleDatePicker(e, date, 'start_date')}
                                    error={errors.start_date}
                                    helperText={errors.start_date}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        {!checkCurrentlyWorking
                            ? (
                                <div className="w3-col m6 h7t-margin-top-mobile h7t-padding-left-4">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            size="small"
                                            required
                                            inputVariant="outlined"
                                            label="End Date"
                                            format="MMM do, yyyy"
                                            disableFuture={true}
                                            inputProps={{readOnly: true}}
                                            value={form.end_date ? moment(form.end_date) : null}
                                            onChange={(e, date) => this.handleDatePicker(e, date, 'end_date')}
                                            error={errors.end_date}
                                            helperText={errors.end_date}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            ) : (
                                <div className="w3-col m6 w3-display-container">
                                    <div className="w3-display-middle">To Present</div>
                                </div>
                            )}
                    </div>

                    <SearchDropdown
                        required
                        multiple
                        id="skills"
                        label={(form.type == 'Education') ? 'Acquired Skills' : 'Skills'}
                        options={options}
                        value={form.skills ? form.skills : undefined}
                        errors={errors.skills}
                        onChange={(evt, val) => {
                            this.handleSkillChange(val)
                        }}
                        searchKey={searchKey}
                        onSearch={this.handleSearch}
                    />

                    <div className="w3-bar w3-panel">
                        <Button variant="contained" className="w3-margin-right h7t-margin-10 h7t-secondary-btn" onClick={onModalClose}>Close</Button>
                        <Button variant="contained" type="submit" className="h7t-margin-10 h7t-primary-btn">{form.id ? 'Update' : 'Add'}</Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentSkillList: state.currentSkillList,
        listSkills: state.listSkills,
        metaData: state.meta,
        currentUser: state.currentUser,
        resumeDetail: state.resumeDetail,
    };
}
export default connect(mapStateToProps, {
    postUserResumeBlockEdit,
    postUserResumeBlockCreate,
    setAppMessage,
    getCurrentDetailsList,
    getCurrentSkillList,
    getMetaData,
    getCurrentUser,
})(AddDetailsModal);
