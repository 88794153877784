import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactQuill from 'react-quill';
import { Button, FormHelperText } from '@material-ui/core';
import {
    setAppMessage,
    getCurrentUser,
    postEditUser,
    getMetaData,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import ConfirmationModal from '../../Common/ConfirmationModal';
import { InputTextField, RadioButtonMUI, SearchDropdown, InputSelectField } from '../../Common/MaterialUI/HelperInputs';

class UserDetailsEditModal extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        postEditUser: PropTypes.func,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getCurrentUser: PropTypes.func,
        getMetaData: PropTypes.func,
    }

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            form: {},
            initialFormData: '',
            dpOpenStatus: false,
            mediaBlock: [],
            searchKey: {
                preferred_location: '',
                current_location: '',
            },
            selectedLocations: [],
            saveData: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDPOpenStateChage = this.handleDPOpenStateChage.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChangeSelection = this.handleChangeSelection.bind(this);
        this.handleShortSummary = this.handleShortSummary.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        const { currentUser } = this.props;
        let selectedLocations = [];
        if (currentUser) {
            const userDetails = JSON.parse(JSON.stringify(currentUser));
            userDetails.user.phone_number = ((userDetails.user.phone_number && userDetails.user.phone_number.includes('NOT_UPDATED')) || !userDetails.user.phone_number) ? '' : userDetails.user.phone_number;
            let mediaBlock = [];
            if (userDetails.profile) {
                if (userDetails.profile.profile_links) {
                    mediaBlock = JSON.parse(userDetails.profile.profile_links);
                }
                if (userDetails.profile.preferred_locations) {
                    userDetails.profile.preferred_locations = JSON.parse(userDetails.profile.preferred_locations);
                    selectedLocations = userDetails.profile.preferred_locations.slice(0);
                }
                if (userDetails.profile.current_location) {
                    selectedLocations.push(userDetails.profile.current_location);
                }
            } else {
                userDetails.profile = {
                    relevant_experience: null,
                    relevant_experience_month: null,
                    current_location: null,
                    preferred_locations: null,
                    industry: null,
                    functional_area: null,
                    notice_period: null,
                    current_ctc: null,
                    expected_ctc: null,
                };
            }
            this.setState({
                mediaBlock,
                form: Object.assign(userDetails.profile, userDetails.user),
                initialFormData: Object.assign({}, userDetails.profile, userDetails.user),
                selectedLocations,
            });
        }
        const cityData = {};
        cityData.type = 'city';
        cityData.ids = selectedLocations;
        this.props.getMetaData(cityData).then(() => {
            this.forceUpdate();
        });
       
        this.props.getMetaData({ type:'category' }).then(() => {
            this.forceUpdate();
        });
    }

    onModalClose() {
        const { form, initialFormData } = this.state;
        if(!_.isEqual(form, initialFormData)) {
            this.setState({
                saveData: true,
            });
            return;
        }
        this.props.onModalClose();
    }

    handleCancel() {
        this.setState({
            saveData: false,
        });
        this.props.onModalClose();
    }

    handleDatePicker(evt, date, id) {
        const { form, errors } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');
        const selectedDate = new Date(formatedDate).getTime();
        const currentDate = new Date().getTime();

        if (!(selectedDate < currentDate)) {
            errors[id] = 'Date should not be greater than Today';
            this.setState({ errors });
            return;
        }
        if (errors[id]) {
            errors[id] = null;
            this.setState({ errors });
        }

        form[id] = formatedDate;
        this.setState({ form });
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { form, errors } = this.state;
        form[name] = value;
        if (errors[name]) {
            errors[name] = null;
        }
        this.setState({ form, errors });
    }

    validateData() {
        const { form, errors } = this.state;
        let hasErrors = false;
        const regExpEmail = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(form.email);
        if (!regExpEmail) {
            hasErrors = true;
            errors.email = 'Enter a valid email';
        }

        if (form.phone_number && !/^\d{10}$/.test(form.phone_number)) {
            hasErrors = true;
            errors.phone_number = 'Enter a valid phone number';
        }

        if (form.relevant_experience && !/^\d+$/.test(form.relevant_experience)) {
            hasErrors = true;
            errors.relevant_experience = 'Enter a valid number';
        }

        if (form.dob && new Date(form.dob) > new Date()) {
            hasErrors = true;
            errors.dob = 'Date of birth must be less than today\'s';
        } else if (form.dob === '0000-00-00') {
            hasErrors = true;
            errors.dob = 'This field is mandatory';
        }

        const ignoreList = [
            'latitude',
            'longitude',
            'profile_pic_id',
            'reset_token',
            'timezone_offset',
            'verified_at',
            'current_company_id',
            'current_office_id',
            'status',
            'bookmark_ids',
            'job_title',
            'profile_links',
            'profile_pic',
            'skillsets',
            'current_loc_name',
            'short_summary',
            'preferred_loc_name',
            'last_name',
            'access_token',
        ];
        const fileds = ['relevant_experience', 'relevant_experience_month', 'current_ctc', 'expected_ctc'];

        Object.keys(form).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                const regExp = /^[0-9]+([.][0-9]+)?$/;
                if (fileds.indexOf(key) !== -1) {
                    if (form[key] && !regExp.test(form[key])) {
                        hasErrors = true;
                        errors[key] = 'Enter a valid number';
                    } else if (form[key] === 0) {
                        return;
                    }
                }
                if (typeof (form[key]) === 'string' && ignoreList.indexOf(key) < 0) {
                    if (!form[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!form[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return form;
    }

    handleSubmit(e) {
        e.preventDefault();
        const validData = this.validateData();
        if (validData) {
            const { mediaBlock } = this.state;
            validData.profile_links = mediaBlock;
            this.props.postEditUser(validData).then((response) => {
                const resp = response && response.data;
                if (resp) {
                    if (resp.success) {
                        this.handleCancel();
                        this.props.setAppMessage('success', 'Details edited successfully!');
                        this.props.getCurrentUser();
                    } else if (resp.data) {
                        this.setState({
                            errors: resp.data,
                        });
                    }
                }
            });
        }
    }

    handleDPOpenStateChage(status) {
        this.setState({
            dpOpenStatus: status,
        });
    }

    handleSearch(evt, value) {
        if (evt && evt.type === 'change') {
            const { id } = evt.target;
            const { selectedLocations } = this.state;
            const { searchKey } = this.state;
            searchKey[id] = value;
            this.setState({ searchKey });
            const cityData = {};
            cityData.type = 'city';
            cityData.search = value;
            cityData.ids = selectedLocations;
            this.props.getMetaData(cityData).then(() => {
                this.forceUpdate();
            });
        }
    }

    handleChangeSelection(option, label) {
        const { form, errors } = this.state;
        let value = null;
        if (option && Array.isArray(option)) {
            if (option.length) {
                value = [];
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        } else if (option) {
            value = option.val;
        }
        form[label] = value;
        if (errors[label]) {
            errors[label] = null;
        }
        if (label === 'current_location' || label === 'preferred_locations') {
            let ids = [];
            if (form.preferred_locations) {
                ids = form.preferred_locations.slice(0);
            }
            if (form.current_location) {
                ids.push(form.current_location);
            }
            const cityData = {};
            cityData.type = 'city';
            cityData.ids = ids;
            this.props.getMetaData(cityData).then(() => {
                this.setState({
                    searchKey: {
                        preferred_location: '',
                        current_location: '',
                    },
                    selectedLocations: ids,
                });
            });
        }
        this.setState({
            form,
            errors,
        });
    }

    handleShortSummary(content) {
        const { form } = this.state;
        form.short_summary = (content == '<p><br></p>') ? '' : content;
        this.setState({ form });
    }

    render() {
        const {
            form, errors, dpOpenStatus, searchKey, saveData,
        } = this.state;
        const modalContent = (<div>
            <p>Changes will not be saved. Complete the profile to save changes</p>
        </div>)
        const noticePeriodOpt = [
            { val: 'Immediatly Available', name: 'Immediatly Available' },
            { val: 'Serving Notice Period', name: 'Serving Notice Period' },
            { val: '1 Month', name: '1 Month' },
            { val: '2 Months', name: '2 Months' },
            { val: '3 Months', name: '3 Months' },
            { val: 'More than 3 Months', name: 'More than 3 Months' },
        ];
    

        const functionalAreaOpt = [
            { val: 'Software Developer', name: 'Software Developer' },
            { val: 'Technical Architect', name: 'Technical Architect' },
            { val: 'Project Lead / Manager', name: 'Project Lead / Manager' },
            { val: 'QA & Testing', name: 'QA & Testing' },
            { val: 'Design / Graphics', name: 'Design / Graphics' },
        ];
        const currentLocationOptions = [];

        const relevant_month = [
            { val: '0', name: '0' },
            { val: '1', name: '1' },
            { val: '2', name: '2' },
            { val: '3', name: '3' },
            { val: '4', name: '4' },
            { val: '5', name: '5' },
            { val: '6', name: '6' },
            { val: '7', name: '7' },
            { val: '8', name: '8' },
            { val: '9', name: '9' },
            { val: '10', name: '10' },
            { val: '11', name: '11' },
        ];

        const categorySelect = [{ val:'', name:'-Select Category-' }];

        if (this.props.metaData) {
            const { metaData: { city, category } } = this.props;
            if (city && city.length) {
                city.map((cityData) => {
                    currentLocationOptions.push(
                        { val: cityData.id, name: cityData.term },
                    );
                });
            }
            if (category && category.length) {
                category.map((categoryData) => {
                    categorySelect.push({ val: categoryData.term, name: categoryData.term });
                });
            }
        }

        return (
            <Modal
                modalCls="w3-show"
                onClose={this.onModalClose}
                checkChanges={true}
                title="Edit User Details"
                handleOpen={this.handleDPOpenStateChage}
                datePickerStatus={dpOpenStatus}
            >
                <form className="form-horizontal" noValidate onSubmit={this.handleSubmit}>
                    <div className="w3-row w3-panel">
                        <ReactQuill
                            value={form.short_summary ? form.short_summary : ''}
                            placeholder="Tell us about yourself"
                            style={{height: '100px', marginBottom: '30px'}}
                            onChange={this.handleShortSummary}
                        />
                    </div>
                    <div className="w3-row">
                        <div className="w3-col m6">
                            <InputTextField
                                label="Display Name"
                                name="display_name"
                                id="display_name"
                                required
                                value={form.display_name ? form.display_name : ''}
                                onChange={this.handleChange}
                                errors={errors.display_name}
                                autoFocus
                            />

                            <InputTextField
                                label="Email"
                                name="email"
                                id="email"
                                required
                                value={form.email ? form.email : ''}
                                onChange={this.handleChange}
                                errors={errors.email}
                            />

                            <InputTextField
                                label="Phone Number"
                                name="phone_number"
                                id="phone_number"
                                required
                                value={form.phone_number ? form.phone_number: ''}
                                onChange={this.handleChange}
                                errors={errors.phone_number}
                            />

                            <div className="w3-panel">
                                <RadioButtonMUI
                                    name="gender"
                                    id="gender_male"
                                    checked={form.gender === 'Male' ? 'checked' : undefined}
                                    value="Male"
                                    onChange={this.handleChange}
                                    label="Male"
                                />

                                <RadioButtonMUI
                                    name="gender"
                                    id="gender_female"
                                    checked={form.gender === 'Female' ? 'checked' : undefined}
                                    value="Female"
                                    onChange={this.handleChange}
                                    label="Female"
                                />
                                <FormHelperText error={errors.gender} className="w3-margin-left">{errors.gender}</FormHelperText>
                            </div>

                            <div className="w3-padding">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        required
                                        inputVariant="outlined"
                                        label="Date of Birth"
                                        format="MMM do, yyyy"
                                        inputProps={{readOnly: true}}
                                        value={form.dob && form.dob !== '0000-00-00' ? moment(form.dob) : null}
                                        onChange={(e, date) => this.handleDatePicker(e, date, 'dob')}
                                        error={errors.dob}
                                        helperText={errors.dob}
                                        disableFuture={true}
                                        maxDate={moment().subtract(18, 'years')}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="w3-row">
                                <div className="w3-col m6">
                                    <InputTextField
                                        required
                                        label="Relevant Years"
                                        name="relevant_experience"
                                        id="relevant_experience"
                                        value={form.relevant_experience}
                                        onChange={(e) => this.handleChange(e)}
                                        errors={errors.relevant_experience}
                                    />
                                </div>
                                <div className="w3-col m6">
                                    <InputSelectField
                                        required
                                        label="Relevant Months"
                                        name="relevant_experience_month"
                                        id="relevant_experience_month"
                                        options={relevant_month}
                                        value={form.relevant_experience_month}
                                        onChange={(e) => this.handleChange(e)}
                                        errors={errors.relevant_experience_month}
                                    />
                                </div>
                            </div>

                            <SearchDropdown
                                required
                                label="Current Location"
                                options={currentLocationOptions}
                                value={form.current_location ? form.current_location : undefined}
                                errors={errors.current_location}
                                onChange={(evt, val) => {
                                    this.handleChangeSelection(val, 'current_location')
                                }}
                                id="current_location"
                                // searchKey={searchKey.current_location}
                                onSearch={this.handleSearch}
                            />

                        </div>
                        <div className="w3-col m6">

                            <SearchDropdown
                                required
                                multiple
                                label="Preferred Locations"
                                options={currentLocationOptions}
                                errors={errors.preferred_locations}
                                value={form.preferred_locations ? form.preferred_locations : undefined}
                                onChange={(evt, val) => {
                                    this.handleChangeSelection(val, 'preferred_locations')
                                }}
                                id="preferred_location"
                                searchKey={searchKey.preferred_location}
                                onSearch={this.handleSearch}
                                clearText="Clear All"
                            />

                            <SearchDropdown
                                required
                                label="Select Category"
                                options={categorySelect}
                                value={form.industry ? form.industry : undefined}
                                errors={errors.industry}
                                onChange={(evt, val) => {
                                    this.handleChangeSelection(val, 'industry')
                                }}
                            />

                            <SearchDropdown
                                required
                                label="Functional Area"
                                options={functionalAreaOpt}
                                value={form.functional_area ? form.functional_area : undefined}
                                errors={errors.functional_area}
                                onChange={(evt, val) => {
                                    this.handleChangeSelection(val, 'functional_area')
                                }}
                            />

                            <SearchDropdown
                                required
                                label="Notice Period"
                                options={noticePeriodOpt}
                                value={form.notice_period ? form.notice_period : undefined}
                                errors={errors.notice_period}
                                onChange={(evt, val) => {
                                    this.handleChangeSelection(val, 'notice_period')
                                }}
                            />

                            <InputTextField
                                required
                                label="Current CTC (LPA)"
                                name="current_ctc"
                                id="current_ctc"
                                value={form.current_ctc}
                                onChange={this.handleChange}
                                errors={errors.current_ctc}
                            />

                            <InputTextField
                                required
                                label="Expected CTC (LPA)"
                                name="expected_ctc"
                                id="expected_ctc"
                                value={form.expected_ctc}
                                onChange={this.handleChange}
                                errors={errors.expected_ctc}
                            />
                        </div>
                    </div>
                    <div className="w3-bar w3-panel">
                        <Button className="h7t-secondary-btn w3-margin-right" variant="contained" onClick={this.onModalClose}>Close</Button>
                        <Button type="submit" variant="contained" className="h7t-primary-btn">Save</Button>
                    </div>
                </form>
                {saveData ? 
                    <ConfirmationModal
                        title="Confirm"
                        modalContent={modalContent}
                        confirmBtn="Okay"
                        cancelBtn="Close Anyway"
                        handleCancel={this.handleCancel}
                        handleConfirm={() => this.setState({ saveData: false })}
                        onModalClose={() => this.setState({ saveData: false })}
                    />
					 : null}
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
        metaData: state.meta,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentUser,
    postEditUser,
    getMetaData,
})(UserDetailsEditModal);
