import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import {
    ErrorHelperText,
} from '../../Common/HelperComponents';
import {
    setAppMessage,
    getCurrentUser,
    getCurrentDetailsList,
    postUserResumeBlockEdit,
    postCertificateEdit,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import { InputTextField, InputSelectField } from '../../Common/MaterialUI/HelperInputs';

class CertificateModal extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getCurrentDetailsList: PropTypes.func,
        postUserResumeBlockEdit: PropTypes.func,
        postCertificateEdit: PropTypes.func,
    }

    constructor(args) {
        super(args);
        this.state = {
            error: null,
            form: [{
                certificate: '',
                year_of_expire: '',
            }],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAnotherCertificate = this.handleAnotherCertificate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateResponse = this.updateResponse.bind(this);
        this.removeCertificate = this.removeCertificate.bind(this);
        this.handleChangeCertificate = this.handleChangeCertificate.bind(this);
    }

    componentDidMount() {
        const { existingUserCertificate } = this.props;
        if (existingUserCertificate && existingUserCertificate.length) {
            this.setState({
                form: JSON.parse(JSON.stringify(existingUserCertificate)),
            });
        }
        this.props.getCurrentDetailsList().then((resp) => {
            const filterCertificate = resp.data.data.filter(item => item.type === 'Certificate');
            this.setState({
                certificateData: filterCertificate,
            });
        });
    }

    updateResponse(response, successMessage) {
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getCurrentUser();
            this.props.getCurrentDetailsList().then((resp) => {
                const filterCertificate = resp.data.data.filter(item => item.type === 'Certificate');
                this.setState({
                    certificateData: filterCertificate,
                });
            });
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                error: response.data,
            });
        }
    }

    handleAnotherCertificate() {
        const { form } = this.state;
        if (form.length) {
            const lastItem = form[form.length - 1];
            if (!lastItem.certificate || !lastItem.year_of_expire) {
                this.setState({
                    error: 'Please fill the above fields',
                });
                return;
            }
        }
        form.push({
            certificate: '',
            year_of_expire: '',
        });
        this.setState({
            form,
        });
    }

    handleChange(evt, i) {
        // console.log("EVT",evt,i);
        // const targetElement = $(evt.target).attr('id');
        const { form } = this.state;
        form[i]['year_of_expire'] = evt.target.value;
        this.setState({
            form, error: null,
        });
    }

    handleChangeCertificate(evt, i){
        const { form } = this.state;
        form[i]['certificate'] = evt.target.value;
        this.setState({
            form, error: null,
        });

    }


    removeCertificate(idx) {
        const { form } = this.state;
        if (form[idx].id) {
            form[idx].deleted = true;
        } else {
            form.splice(idx, 1);
        }
        this.setState({
            form,
            error: null,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { form } = this.state;
        if (form.length) {
            const lastItem = form[form.length - 1];
            if (!lastItem.certificate || !lastItem.year_of_expire) {
                this.setState({
                    error: 'Please fill the above fields',
                });
                return;
            }
            this.setState({
                form,
            });
        }
        if (form) {
            this.props.postCertificateEdit(form).then((response) => {
                if (response) {
                    this.updateResponse(response.data, 'Certification added successfully!');
                }
            });
        }
    }

    render() {
        const { onModalClose } = this.props;
        const { form, error } = this.state;
        const optionList = [
            { val: null, name: 'Select your option' },
            { val: 'No Expiry', name: 'No Expiry' },
            { val: '2020', name: '2020' },
            { val: '2021', name: '2021' },
            { val: '2022', name: '2022' },
            { val: '2023', name: '2023' },
            { val: '2024', name: '2024' },
            { val: '2025', name: '2025' },
            { val: '2026', name: '2026' },
            { val: '2027', name: '2027' },
            { val: '2028', name: '2028' },
            { val: '2029', name: '2029' },
            { val: '2030', name: '2030' },

        ];
        const renderElement = [];
        if (form && form.length) {
            form.map((item, idx) => {
                if (item.deleted !== true) {
                    renderElement.push(
                        <tr key={`row_${idx}`} className="w3-row margin-top">
                            <th className="w3-col m8 margin-bottom h-padding-left-20">
                                <InputTextField
                                    id={`certificate_${idx}`}
                                    label="Certificate Name"
                                    placeholder="eg: Bangalore University"
                                    required
                                    value={item.certificate ? item.certificate : ''}
                                    errors={error && error.certificate}
                                    onChange={e => this.handleChangeCertificate(e, idx)}
                                />
                            </th>
                            <th className="w3-col m3 margin-bottom">
                                <InputSelectField
                                    label="Valid upto"
                                    id="year_of_expire"
                                    required
                                    wrapClass="w3-panel"
                                    selectClass="w3-select w3-select-big"
                                    value={item.year_of_expire ? item.year_of_expire : ''}
                                    onChange={e => this.handleChange(e, idx)}
                                    options={optionList}
                                />
                            </th>
                            <th className="w3-col m1 margin-bottom h-padding-left-20" style={{ marginTop: '27px' }}>
                                <span
                                    className="ion-trash-a h7t-cursor-pointer"
                                    onClick={() => this.removeCertificate(idx)}
                                />
                            </th>
                        </tr>,
                    );
                }
            });
        }
        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                title="Certification"
            >
                <form className="form-horizontal w3-panel" onSubmit={this.handleSubmit} autocomplete="off">
                    {renderElement.length === 0 ? (
                        <h4 style={{ textAlign: 'center' }}>
                            Add certificate details to improve your resume!
                        </h4>
                    ) : (
                        <table className="w3-table h7t-table-data">
                            {renderElement}
                        </table>
                    )}
                    <div className="w3-panel">
                        <ErrorHelperText errors={error} />
                    </div>
                    <div className="w3-row">
                    {/* <button 
                            type="button" 
                            className="w3-button w3-round w3-margin-right h7t-margin-10 h7t-secondary-btn" 
                            onClick={onModalClose}
                        >
                            Close
                        </button> */}
                        <Button 
                                variant="contained"
                                 className="w3-margin-right h7t-margin-10 h7t-secondary-btn" 
                                 onClick={this.handleAnotherCertificate}>
                                 <span className="ion-plus-circled" />
                            &nbsp;{renderElement.length ? 'Add Another Certificate' : 'Add Certificate'}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            className={`${!form.length ? 'w3-disabled ' : ''} h7t-margin-10 h7t-primary-btn w3-right`}
                            disabled={!form.length}
                            >
                            Save Changes
                        </Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentUser,
    getCurrentDetailsList,
    postUserResumeBlockEdit,
    postCertificateEdit,
})(CertificateModal);
