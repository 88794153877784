import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getPackagesById,
    getCurrentPackage,
    postUpdatePackage,
    getCurrentOfficeList,
    getCurrentJobList,
    getCurrentWorkflows,
    getWorkflowState,
    getCurrentUser,
    setAppMessage,
    postPaypalPayment,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { ErrorHelperText } from '../Common/HelperComponents';

class ChangePackageModal extends Component {
    constructor(args) {
        super(args);
        this.state = {
            packageData: null,
            errors: {},
            disableMakePayment: false,
        };
        this.handleEnableDisable = this.handleEnableDisable.bind(this);
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
    }

    componentDidMount() {
        const { packageSelected } = this.props;
        this.props.getPackagesById(packageSelected).then((resp) => {
            this.setState({
                packageData: resp.data.data,
            });
        });
    }

    handleEnableDisable(val, section) {
        const { packageData } = this.state;
        const index = packageData[section].findIndex((obj => obj.id == val));
        const package_status = packageData[section][index].package_status;
        packageData[section][index].package_status = package_status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        this.setState({
            packageData,
            errors: {},
        });
    }

    validateData() {
        const { packageData, errors } = this.state;
        const officeCount = $('[name="checkOffice[]"]:checked').length;
        const workflowCount = $('[name="checkWorkflow[]"]:checked').length;
        const jobsCount = $('[name="checkJobs[]"]:checked').length;
        const usersCount = $('[name="checkUsers[]"]:checked').length;
        let hasErrors = false;

        if (officeCount && (officeCount > packageData.package.offices)) {
            errors.offices = `Office is limited to ${packageData.package.offices} for this package. Please deselect or upgrade package.`;
            hasErrors = true;
        }

        if (workflowCount && (workflowCount > packageData.package.workflows)) {
            errors.workflows = `Workflow is limited to ${packageData.package.workflows} for this package. Please deselect or upgrade package.`;
            hasErrors = true;
        }

        if (jobsCount && (jobsCount > packageData.package.jobPosting)) {
            errors.jobs = `Job posting is limited to ${packageData.package.jobPosting} for this package. Please deselect or upgrade package.`;
            hasErrors = true;
        }

        if (usersCount && (usersCount > packageData.package.colabs)) {
            errors.users = `Collaborators is limited to ${packageData.package.colabs} for this package. Please deselect or upgrade package.`;
            hasErrors = true;
        }

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }

        return packageData;
    }

    handleSaveChanges() {
        let validData = this.validateData();
        if (validData) {
            this.setState({ disableMakePayment: true });
            const { packageData } = this.state;
            if (packageData.package.price > 0) {
                validData = Object.assign(validData, {
                    company_id: COMPANY_ID
                });
                const companyData = JSON.stringify(validData);
                const data = {
                    amount: `${packageData.package.price}`,
                    data: `${companyData}`,
                };
                this.props.postPaypalPayment(data).then((resp) => {
                    if (resp && resp.data && resp.data.success) {
                        document.location.href = resp.data.data.url;
                    }
                });
            } else {
                this.props.postUpdatePackage(validData).then((resp) => {
                    if (resp && resp.data && resp.data.success) {
                        this.props.getCurrentPackage();
                        this.props.getCurrentOfficeList();
                        this.props.getCurrentJobList();
                        this.props.getCurrentWorkflows();
                        this.props.getWorkflowState();
                        this.props.getCurrentUser();
                        this.props.setAppMessage('success', 'Package added to this company successfully');
                        this.props.onModalClose();
                    }
                });
            }
        }
    }

    render() {
        const { onModalClose } = this.props;
        const { packageData, errors, disableMakePayment } = this.state;
        const officeList = [];
        let jobList = [];
        let workflowList = [];
        let usersList = [];
        let paymentBtn = [];

        if (packageData) {
            if (packageData.package && packageData.offices && packageData.offices.length) {
                packageData.offices.map((offices, idx) => {
                    const checkActive = (offices.package_status == 'ACTIVE') ? 'checked' : '';
                    officeList.push(
                        <div className="w3-padding" key={idx}>
                            <input
                                type="checkbox"
                                checked={checkActive}
                                name="checkOffice[]"
                                onChange={() => this.handleEnableDisable(offices.id, 'offices')} 
                            /> {offices.name}
                        </div>,
                    );

                    if (checkActive) {
                        packageData.workflows.map((workflows, idx) => {
                            const workflowActive = (workflows.package_status == 'ACTIVE') ? 'checked' : '';
                            if (workflows.office_id == offices.id) {
                                workflowList.push(
                                    <div className="w3-padding" key={idx}>
                                        <input
                                            type="checkbox"
                                            checked={workflowActive}
                                            name="checkWorkflow[]"
                                            onChange={() => this.handleEnableDisable(workflows.id, 'workflows')}
                                        /> {workflows.name}
                                    </div>,
                                );
                            }

                            if (workflowActive) {
                                packageData.jobs.map((jobs, idx) => {
                                    if ((jobs.office_id == offices.id) && (jobs.workflow_id == workflows.id)) {
                                        jobList.push(
                                            <div className="w3-padding" key={idx}>
                                                <input
                                                    type="checkbox"
                                                    name="checkJobs[]"
                                                    checked={(jobs.package_status == 'ACTIVE') ? 'checked' : ''} 
                                                    onChange={() => this.handleEnableDisable(jobs.id, 'jobs')}
                                                /> {jobs.job_title}
                                            </div>,
                                        );
                                    }
                                });
                            }
                        });
                        packageData.users.map((users, idx) => {
                            if (users.office_id == offices.id) {
                                usersList.push(
                                    <div className="w3-padding" key={idx}>
                                        <input
                                            type="checkbox"
                                            name="checkUsers[]"
                                            checked={(users.package_status == 'ACTIVE') ? 'checked' : ''} 
                                            onChange={() => this.handleEnableDisable(users.id, 'users')}
                                        /> {users.email}
                                    </div>,
                                );
                            }
                        });
                    }
                });
                if (workflowList && workflowList.length == 0) {
                    workflowList = <div className="w3-padding">No workflows found</div>
                }
                if (jobList && jobList.length == 0) {
                    jobList = <div className="w3-padding">No jobs found</div>
                }
                if (usersList && usersList.length == 0) {
                    usersList = <div className="w3-padding">No users found</div>
                }
            }
            paymentBtn = (
                <div className="w3-margin-top h7t-margin-bottom-40">
                    <button
                        type="button"
                        className="w3-button w3-padding-small w3-black" 
                        onClick={this.handleSaveChanges}
                        disabled={disableMakePayment}
                    >
                        {packageData.package.price > 0 ? 'Make Payment' : 'Save'}
                    </button>
                </div>
            );
        }

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title="Change Package">
                <div className="w3-container">
                    <div className="w3-border">
                        <div className="w3-light-grey w3-padding">
                            <strong>Offices</strong>
                            <ErrorHelperText errors={errors.offices} />
                        </div>
                        {officeList}
                        <div className="w3-light-grey w3-padding">
                            <strong>Workflows</strong>
                            <ErrorHelperText errors={errors.workflows} />
                        </div>
                        {workflowList}
                        <div className="w3-light-grey w3-padding">
                            <strong>Jobs</strong>
                            <ErrorHelperText errors={errors.jobs} />
                        </div>
                        {jobList}
                        <div className="w3-light-grey w3-padding">
                            <strong>Users</strong>
                            <ErrorHelperText errors={errors.users} />
                        </div>
                        {usersList}
                    </div>
                    {paymentBtn}
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
    getPackagesById,
    getCurrentPackage,
    postUpdatePackage,
    getCurrentOfficeList,
    getCurrentJobList,
    getCurrentWorkflows,
    getWorkflowState,
    getCurrentUser,
    setAppMessage,
    postPaypalPayment,
})(ChangePackageModal);
