import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getPlansPackagesList,
    getCurrentPackage,
    postUpdatePackage,
    setAppMessage,
} from '../../js/actions';
import ChangePackageModal from './ChangePackageModal';

class PlansAndPackages extends Component {
    constructor(args) {
        super(args);
        this.state = {
            packageSelected: '',
        };

        this.handlePurchase = this.handlePurchase.bind(this);
    }

    componentDidMount() {
        const { currentPackage, plansPackagesList } = this.props;
        if (!currentPackage) {
            this.props.getCurrentPackage();
        }
        if (!plansPackagesList) {
            this.props.getPlansPackagesList();
        }
    }

    handlePurchase(id) {
        this.setState({
            packageSelected: id,
        });
    }

    render() {
        const { currentPackage, plansPackagesList } = this.props;
        const { packageSelected } = this.state;
        let listPackages = null;

        if (plansPackagesList && plansPackagesList.data && plansPackagesList.data.length) {
            const morePlans = [];
            plansPackagesList.data.map((list, idx) => {
                let activePackage = null;
                let disableBuyBtn = false;
                if (currentPackage && currentPackage.data && (currentPackage.data.id == list.id)) {
                    activePackage = 'h7t-active-package';
                    disableBuyBtn = true;
                }
                morePlans.push(
                    <div key={`plans_${idx}`} className="h7t-item-container w3-col m4">
                        <div className={`h7t-pricing-item h7t-small-item w3-border ${activePackage}`}>
                            <div className="h7t-item-top">
                                <div className="h7t-item-top-bg" />
                                <div className="h7t-item-top-content">
                                    <div className="h7t-sub-title">{list.title}</div>
                                    <h3 className="h7t-title w3-margin-bottom">{list.name}</h3>
                                    <div className="h7t-price">{list.price ? `${list.currency}${list.price} per month` : 'Free'}</div>
                                </div>
                            </div>
                            <div className="h7t-item-bottom">
                                <ul>
                                    <li>{`${list.jobPosting} Job Postings`}</li>
                                    <li>{`${list.colabs} Collaborators`}</li>
                                    <li>{`${list.offices} ${list.offices > 1 || list.offices === 'Unlimited' ? 'Offices' : 'Office'}`}</li>
                                    <li>{`${list.workflows} Workflows`}</li>
                                    <li>Widget Integration to websites</li>
                                    <li>Custom Sub domain</li>
                                </ul>
                                <button
                                    type="button"
                                    className="w3-button w3-block h7t-secondary-btn"
                                    disabled={disableBuyBtn}
                                    onClick={() => this.handlePurchase(list.id)}
                                >
									Buy Now
								</button>
                            </div>
                        </div>
                    </div>,
                );
            });

            listPackages = (
                <div className="h7t-pricing-wrapper">
                    <div className="w3-row">
                        <div className="h7t-pricing-tables w3-center w3-row-padding">
                            {morePlans}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {listPackages}
                {packageSelected
                    ? (
                        <ChangePackageModal
                            onModalClose={() => this.setState({ packageSelected: '' })}
                            packageSelected={packageSelected}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentPackage: state.currentPackage,
        plansPackagesList: state.plansPackagesList,
    };
}
export default connect(mapStateToProps, {
    getPlansPackagesList,
    getCurrentPackage,
    postUpdatePackage,
    setAppMessage,
})(PlansAndPackages);
