import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Modal from '../Common/Modal';
import {
    getCurrentUser,
    postWorkflowCreate,
    getCurrentWorkflows,
    postWorkflowStateCreateTemplate,
} from '../../js/actions';
import WorkflowTemplateChart from '../JsPlumb/WorkflowTemplateChart';

const TemplateDetails = ({
    item,
    userRole,
    handleCreate,
    handlePreview,
}) => {
    return (
        <div className="w3-row w3-margin-bottom w3-padding h7t-table-data">
            {!handlePreview
                ? (
                    <div className="w3-row">
                        <WorkflowTemplateChart templateData={item} />
                    </div>
                ) : (
                    <h4 className="w3-padding-small" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        Workflow For&nbsp;
                        {item.name}
                    </h4>
                )}
            <div className="w3-col m12 w3-margin-top">
                <div className="w3-row">
                    <div className="w3-col m2 w3-padding-small">
                        States :
                    </div>
                    <div className="w3-col m10">
                        {item.state_details.map((state, index) => {
                            return (
                                <div
                                    key={`state_${index}`}
                                    className="w3-border w3-margin-bottom w3-margin-right w3-padding-small h-display-inline-block"
                                >
                                    {state.state_name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="w3-row">
                    <div className="w3-col m2 w3-padding-small">
                        Roles :
                    </div>
                    <div className="w3-col m10">
                        {userRole.data &&
                            userRole.data.map((role, idx) => {
                                return (
                                    <div
                                        key={`role_${idx}`}
                                        className="w3-border w3-padding-small w3-margin-bottom w3-margin-right h-display-inline-block"
                                    >
                                        {role.name}
                                    </div>
                                );
                            })
                        }
                        <div className="w3-right">
                            <Button
                                variant="contained"
                                onClick={() => handleCreate(item)}
                                className="w3-margin-right h7t-primary-btn"
                            >
                                Create
                            </Button>
                            {handlePreview
                                ? (
                                    <Button
                                        variant="contained"
                                        onClick={() => handlePreview(item)}
                                        className="h7t-secondary-btn"
                                    >
                                        Preview
                                    </Button>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TemplateDetails.propTypes = {
    item: PropTypes.object,
    userRole: PropTypes.object,
    handleCreate: PropTypes.func,
    handlePreview: PropTypes.func,
};

class WorkflowTemplateModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        userRole: PropTypes.object,
        handleRow: PropTypes.func,
        currentUser: PropTypes.object,
        templateList: PropTypes.object,
        onModalClose: PropTypes.func,
        getCurrentUser: PropTypes.func,
        postWorkflowCreate: PropTypes.func,
        getCurrentWorkflows: PropTypes.func,
        postWorkflowStateCreateTemplate: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            previewData: '',
            errorMessage: '',
        };
        this.handlePreview = this.handlePreview.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
    }

    componentDidMount() {
        if (!this.props.currentUser) {
            this.props.getCurrentUser();
        }
    }

    setResponseMessage(message) {
        this.setState({ errorMessage: message });
        setTimeout(() => {
            this.setState({ errorMessage: '' });
        }, 3000);
    }

    handlePreview(previewData) {
        this.setState({
            previewData,
            errorMessage: '',
        });
    }

    handleCreate(createData) {
        const { userRole } = this.props;
        const { errorMessage } = this.state;

        if (errorMessage) {
            this.setState({ errorMessage: '' });
        }

        const data = {};
        const stateData = Object.assign(data, createData);
        data.name = createData.name;
        data.description = createData.description;
        data.department = 'IT';
        const roleList = userRole.data.find((roleUser) => {
            return roleUser.permission_type == 'HR';
        });
        if (roleList) {
            stateData.actor_role_id = roleList.id;
        } else {
            this.setState({ errorMessage: 'Invite a HR to add workflow' });
            return;
        }
        this.props.postWorkflowCreate(data).then((workflowResp) => {
            const workflowResponse = workflowResp && workflowResp.data;

            if (workflowResponse.success) {
                stateData.workflow_id = workflowResponse.data.id;
                this.props.getCurrentWorkflows();

                this.props.postWorkflowStateCreateTemplate(stateData).then((result) => {
                    const stateResult = result && result.data;

                    if (stateResult.success) {
                        this.props.handleRow(workflowResponse.data);
                        $('.close-button').click();
                    } else {
                        const message = stateResult.data.state_name
                            ? stateResult.data.state_name
                            : stateResult.data.error
                                ? stateResult.data.error
                                : 'Failed to create workflow template. Try again!';
                        this.setResponseMessage(message);
                    }
                });
            } else {
                const message = (workflowResponse.data && workflowResponse.data.name) ? workflowResponse.data.name : 'Failed to create workflow template.';
                this.setState({ errorMessage: message });
                this.setResponseMessage(message);
            }
        });
    }

    render() {
        let modalContent = [];
        let messageCls = '';
        const {
            title, onModalClose, templateList, userRole,
        } = this.props;
        
        const { previewData, errorMessage } = this.state;

        if (templateList && templateList.data) {
            templateList.data.map((item, key) => {
                if (item.template_id !== 'initial_template') {
                    modalContent.push(
                        <TemplateDetails
                            item={item}
                            key={key}
                            userRole={userRole}
                            handlePreview={this.handlePreview}
                            handleCreate={this.handleCreate}
                        />,
                    );
                }
            });
        } else {
            modalContent = (
                <div className="w3-row w3-padding-64 w3-center h7t-table-data">
                    No Workflow Templates!
                </div>
            );
        }

        if (previewData) {
            messageCls = 'w3-margin-right w3-right';
            modalContent = (
                <TemplateDetails
                    item={previewData}
                    userRole={userRole}
                    handleCreate={this.handleCreate}
                />
            );
        }

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title={!previewData ? title : `Workflow for ${previewData.name}`}>
                {errorMessage
                    ? <div className={`w3-text-red w3-margin-left ${messageCls}`}>* {errorMessage}</div>
                    : null
                }
                {previewData
                    ? <a className="w3-padding h7t-cursor-pointer w3-text-blue" onClick={() => this.setState({ previewData: '', errorMessage: '' })}><span className="ion-ios-arrow-back"></span> Back</a>
                    : null}
                <div className="w3-panel h-template-modal h7t-padding-mobile-view" id="flowChartDiv">
                    {modalContent}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.user,
    };
};

export default connect(mapStateToProps, {
    getCurrentUser,
    postWorkflowCreate,
    getCurrentWorkflows,
    postWorkflowStateCreateTemplate,
})(WorkflowTemplateModal);
