/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-const */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Icon, Button, Tooltip } from '@material-ui/core';
import { Rate } from 'antd';
import moment from 'moment';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { ErrorHelperText } from '../Common/HelperComponents';
import {
    getJobApplnFeedback,
    getApplicationStatus,
    getCheckRoleForUser,
    postAssignSelf,
    getList,
    postChangeState,
    setAppMessage,
    getJobApplications,
    getApplicationTitleList,
    postAssignScheduleSelf,
    postScheduleStateProcess,
    getScheduleApplicationStatus,
    getWorkflowState,
    getScheduleWorkflowList,
    getUnreadNotifications,
    postChangeStateEdit,
} from '../../js/actions';
import ScheduleRole from './ScheduleRole';
import { InputTextField, RadioButtonMUI } from '../Common/MaterialUI/HelperInputs';

const starLevels = {
    1: 'Poor',
    2: 'Below Average',
    3: 'Average',
    4: 'Good',
    5: 'Expert',
};
class ApplicationFeedbacks extends Component {
    static propTypes = {
        getList: PropTypes.func,
        currentUser: PropTypes.object,
        scheduleWorkflowList: PropTypes.object,
        setAppMessage: PropTypes.func,
        postAssignSelf: PropTypes.func,
        postChangeState: PropTypes.func,
        application_id: PropTypes.number,
        applicationStatus: PropTypes.object,
        getJobApplications: PropTypes.func,
        applicationFeedback: PropTypes.object,
        getJobApplnFeedback: PropTypes.func,
        getCheckRoleForUser: PropTypes.func,
        getApplicationStatus: PropTypes.func,
        getWorkflowState: PropTypes.func,
        getScheduleWorkflowList: PropTypes.func,
        params: PropTypes.object,
    };

    constructor(args) {
        super(args);

        const { application_id } = this.props;
        this.state = {
            id: application_id || '',
            errors: {},
            showAssign: false,
            form: {
                feedback: '',
                feedback_file_path: '',
                target_state_id: '',
                rating: '',
                workflow_end_state: '',
                workflow_start_state: '',
            },
            workflowStatesList: '',
            workflowStates_list: '',
            application_feedback: '',
            showAssignModal: false,
            editSchedule: false,
            workflowFullList: '',
            disableSubmitBtn: false,
            editFeedbackForm: false,
            editData:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckRole = this.handleCheckRole.bind(this);
        this.handleScheduleAssign = this.handleScheduleAssign.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleschedule = this.handleschedule.bind(this);
        this.handleEditScheduling = this.handleEditScheduling.bind(this);
        this.handleEditFeedback = this.handleEditFeedback.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.loadFeedbackDetails();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.application_id && this.props.application_id && nextProps.application_id !== this.props.application_id) {
            this.setState({ id: nextProps.application_id }, () => {
                this.loadFeedbackDetails();
            });
        }
    }

    loadFeedbackDetails() {
        this.handleCheckRole();
        const { getWorkflowState, getScheduleWorkflowList, getJobApplnFeedback } = this.props;
        const { applicationStatus: { data: { appln: { job_workflow_id } } } } = this.props;
        const { applicationStatus: { data: { appln: { current_lifecycle: { application_id } } } } } = this.props;
        getWorkflowState(job_workflow_id).then((workflowStates) => {
            const workflowResp = workflowStates.data && workflowStates.data.data;
            const workflowStatesList = workflowResp.filter((states) => {
                return states.display_order > 0;
            });
            this.setState({
                workflowStatesList,
                workflowFullList : workflowResp
            });
        });
        getJobApplnFeedback(application_id).then((resp) => {
            this.setState({
                application_feedback: resp.data.data,
            });
        });
        getScheduleWorkflowList(application_id);
    }

    handleCheckRole() {
        const { applicationStatus: { data: { appln: { current_lifecycle }, schedule } } } = this.props;
        if (current_lifecycle.workflow_state_status === 'assigned_to_role') {
            let roleId = current_lifecycle.actor_role_id;
            if (schedule && schedule.schedule_role_id) {
                roleId = schedule.schedule_role_id;
            }
            this.props.getCheckRoleForUser(roleId).then((res) => {
                if (res.data.data.length > 0) {
                    this.setState({ showAssign: true });
                } else {
                    this.setState({ showAssign: false });
                }
            });
        }
    }

    resetErrors() {
        this.setState({ errors: {} });
    }

    handleChangeRating(value, field) {
        const { form, errors } = this.state;
        form.rating = starLevels[value];
        form[field] = value;
        if (errors[field]) {
            errors[field] = null;
            this.setState({
                errors,
            });
        }
        this.setState({
            form,
        });
    }

    handleChange(evt) {
        const targetElement = $(evt.target).attr('name');
        const { errors, form } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({
                errors,
            });
        }
        const formValue = Object.assign({}, form);
        formValue[targetElement] = $(evt.target).val();
        this.setState({
            form: formValue,
        });
    }

    handleFileUpload(event) {
        const { form } = this.state;
        form.feedback_file_path = event.target.files[0] ? event.target.files[0] : '';
        this.setState({
            form,
        });
    }

    validateData() {
        const { form } = this.state;
        const errors = {};
        let hasError = false;
        const ignoreList = [
            'feedback_file_path',
            'workflow_start_state',
            'workflow_end_state',
        ];

        Object.keys(form).forEach((key) => {
            if (ignoreList.indexOf(key) < 0) {
                if (typeof (form[key]) === 'string') {
                    if (!form[key].match(/\w/)) {
                        hasError = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!form[key]) {
                    hasError = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (hasError) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    handleSubmit(evt) {
        evt.preventDefault();
        const { form, id, editFeedbackForm } = this.state;
        const { applicationStatus: { data: { appln: { current_lifecycle: { application_id, workflow_state_status } } } } } = this.props;
        // this.resetErrors();
        const validData = this.validateData();
        if (workflow_state_status === 'complete' || !validData) {
            return;
        }
        this.setState({
            disableSubmitBtn: true,
        });
        const formData = new FormData();
        if (formData) {
            formData.append('application_id', application_id);
            formData.append('feedback', form.feedback);
            formData.append('rating', form.rating);
            formData.append('feedback_file_path', form.feedback_file_path);
            formData.append('target_state_id', form.target_state_id);
            formData.append('email', this.props.applicantEmail);
            formData.append('workflow_start_state', form.workflow_start_state);
        }
        let funcName = '';

        if(editFeedbackForm) {
            funcName = 'postChangeStateEdit';
        } else {
            funcName = 'postChangeState';
        }
        this.props[funcName](formData).then((res) => {
        const response = res && res.data;
        const successMessage = editFeedbackForm ? 'Feedback Edited Successfully!' : 'Feedback Submitted Successfully!' ;
        this.setState({
            disableSubmitBtn: false,
            editFeedbackForm : false,
        });
        if (response && response.success) {
            this.props.setAppMessage('success',successMessage);
            this.resetFormData();
            this.setState({ form });
            this.props.getList();
            this.props.getUnreadNotifications();
            this.props.getJobApplications({ page: 1 });
            this.props.getApplicationTitleList();
            this.props.getApplicationStatus(id).then((resp) => {
                this.props.getJobApplnFeedback(id);
                if (this.props.getFeedbackStatus) {
                    this.props.getFeedbackStatus(resp.data);
                }
                this.handleCheckRole();
            });
        } else if (response) {
            this.setState({
                errors: response.data,
            });
        }
        });
    }

    resetFormData() {
        const { form } = this.state;
        form.feedback = '';
        form.feedback_file_path = '';
        form.target_state_id = '';
        form.rating = '';
        form.workflow_start_state = '';
        form.workflow_end_state = '';
        let checkFilePath = document.getElementById('feedback_file_path');
        if(checkFilePath) {
            checkFilePath.value = '';
        } 
    }

    handleCancel() {
        const { form,editFeedbackForm } = this.state;
        this.resetFormData();
        this.setState({ form,editFeedbackForm: !editFeedbackForm });
    }

    handleScheduleAssign() {
        const { id } = this.state;
        const { applicationStatus: { data: { schedule } } } = this.props;
        this.props.postAssignScheduleSelf(schedule.id).then((res) => {
            const response = res && res.data;
            if (response && response.success) {
                this.props.setAppMessage('success', 'Application Assigned to interviewer for Action!');
                this.props.getJobApplnFeedback(id);
                this.props.getApplicationStatus(id);
            } else if (response) {
                this.setState({
                    errors: response.data,
                });
            }
        });
    }


    handleschedule(workflowStates) {
        this.setState({
            editSchedule: false,
            showAssignModal: true,
            workflowStates_list: workflowStates,
        });
    }

    handleEditScheduling(workflowStates) {
        this.setState({
            editSchedule: true,
            showAssignModal: true,
            workflowStates_list: workflowStates,
        });
    }
    
    handleEditFeedback(e, data) {
        window.scrollTo({
            top: 200,
            behavior: 'smooth'
        });
        const dataVal = {};
            dataVal.feedback = data.actor_feedback;
            dataVal.feedback_file_path = data.attachment;
            dataVal.rating = data.rating;
            dataVal.target_state_id = data.target_state_id;
            dataVal.workflow_start_state = data.workflow_start_state;
            dataVal.workflow_end_state = data.workflow_end_state;
    
        this.setState({
            form: dataVal,
            editFeedbackForm: true
        },() => {
            this.resetErrors();
            this.renderFeedbackForm();
        });
    }

    renderFeedbackForm() {
        const { errors, form, workflowFullList, disableSubmitBtn, editFeedbackForm, } = this.state;
        const {
            applicationStatus, currentUser, applicationFeedback
        } = this.props;
        const appln_Status = workflowFullList && workflowFullList.some((list_data) => {
            if ((list_data.id == applicationStatus.data.appln.current_lifecycle.workflow_end_state) && list_data.state_type === 'End') {
                return true;
            }
        });
        let feedbackFileName = '';
        if (typeof form.feedback_file_path === 'string' && editFeedbackForm) {
            const res = form.feedback_file_path.split('/');
            feedbackFileName = res[res.length - 1];
        }

        if (currentUser && (applicationStatus.data.appln.current_lifecycle.actor_user_id === currentUser.user.id) && !appln_Status || editFeedbackForm) {
            return (
                <form
                    className="w3-margin-top w3-padding w3-round h7t-table-data"
                    noValidate
                >
                    <h4 className="w3-panel">{editFeedbackForm ? 'Edit Feedback' : 'Add Feedback'}</h4>
                    <InputTextField
                        label="Feedback"
                        name="feedback"
                        id="feedback"
                        value={form.feedback ? form.feedback : ''}
                        onChange={e => this.handleChange(e)}
                        multiline
                        rows="5"
                        errors={errors.feedback}
                        autoFocus
                        required
                    />
                    <div className="w3-panel">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <input 
                                className="w3-input w3-border"
                                id="feedback_file_path" 
                                name="feedback_file_path" 
                                type="file" 
                                onChange={this.handleFileUpload} />
                                {form.feedback_file_path ? <a className="h7t-hyperlink-color"  target="_blank"  href={form.feedback_file_path}>{feedbackFileName}</a>
                                : null }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="w3-right">
                                    <strong className="w3-margin-right">Rating:</strong>
                                    <Rate
                                        style={{ marginTop: 5, fontSize: 20 }}
                                        value={form.rating ? form.rating : 0}
                                        onChange={e => this.handleChangeRating(e, 'rating')}
                                    />
                                    <ErrorHelperText errors={errors.rating} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="w3-margin-top w3-panel">
                        <strong>Select Action</strong>
                        {!editFeedbackForm ? 
                        applicationStatus.data.transitions.map(transition => (
                            <div>
                                <div>
                                    <RadioButtonMUI
                                        checked={form.target_state_id == transition.target_state_id ? 'checked' : undefined}
                                        name="target_state_id"
                                        id={`option_${transition.target_state_id}`}
                                        value={transition.target_state_id}
                                        onChange={e => this.handleChange(e)}
                                        label={transition.state_name}
                                    />
                                </div>
                            </div>
                        )) : 
                        applicationFeedback && applicationFeedback.data && applicationFeedback.data.filter(transition => transition.workflow_end_state == form.target_state_id || transition.workflow_end_state == form.workflow_end_state).map((transition) => (
                             <div>
                                <div>
                                    <RadioButtonMUI
                                        checked={form.workflow_end_state == transition.target_state_id ? 'checked' : undefined}
                                        name="target_state_id"
                                        id={`option_${transition.target_state_id}`}
                                        value={transition.target_state_id}
                                        onChange={e => this.handleChange(e)}
                                        label={transition.stateName}
                                        disabled={editFeedbackForm}
                                    />
                                </div>
                            </div>
                        ))
                        }
                        <ErrorHelperText errors={errors.target_state_id} />

                    </div>
                    <div className="w3-bar">
                        <Button
                            variant="contained"
                            className={`${disableSubmitBtn ? 'w3-disabled' : ''} w3-right h7t-primary-btn w3-margin-right`}
                            disabled={disableSubmitBtn}
                            onClick={e => this.handleSubmit(e)}
                        >
                            Submit
                        </Button>
                        {editFeedbackForm && 
                        <Button 
                            variant="contained"
                            className="w3-right h7t-secondary-btn w3-margin-right"
                            disabled={disableSubmitBtn}
                            onClick={this.handleCancel}
                        >
                            Cancel
                        </Button> }   
                    </div>
                </form>
            );
        }
        return null;
    }

    render() {
        const {
            applicationStatus, applicationFeedback, currentUser, currentUser: { companies }, scheduleWorkflowList,
        } = this.props;
        const { applicationStatus: { data: { appln: { current_lifecycle: { application_id } } } } } = this.props;
        const {
            showAssignModal, workflowStatesList, workflowStates_list,
            editSchedule, workflowFullList, editFeedbackForm
        } = this.state;
        let scheduleInterviewer = [];
        let scheduledDetails = '';
        let permissions = null;

        if (applicationStatus && applicationStatus.data) {
            if (workflowStatesList) {
                let applicationEnd = false;
                let appln_status_rejected = false;
                let candidateHired = false;


                scheduleInterviewer = workflowStatesList.map((list_item) => {
                    let feedbackResult = '';
                    if (applicationEnd) {
                        return;
                    }
                    
                    let feedbacks = [];
                    if(editFeedbackForm) {
                        feedbacks = applicationFeedback && applicationFeedback.data.filter((applnFeedback) => (list_item.id == applnFeedback.workflow_start_state));
                        if(feedbacks.length>1) {
                            feedbacks && feedbacks.map((applnFeedback) => {
                                    if (list_item.id == applnFeedback.workflow_start_state && applnFeedback.workflow_end_state == applnFeedback.target_state_id && applnFeedback.workflow_state_status == 'completed') {
                                        feedbackResult = applnFeedback;
                                        return true;
                                    }
                            });
                        } else {
                            feedbackResult = feedbacks;
                        }
                    } else {
                        feedbacks = applicationFeedback && applicationFeedback.data.some((applnFeedback) => {
                            if (list_item.id == applnFeedback.workflow_start_state) {
                                feedbackResult = applnFeedback;
                                return true;
                            }
                        });
                    }
                    if (currentUser && companies) {
                        const company = companies.find((com) => {
                            return COMPANY_ID == com.company_id;
                        });
            
                        if (company && company.office_list) {
                            const office = company.office_list.find((off) => {
                                return off.id == OFFICE_ID;
                            });
                            if (office && office.permissions) {
                                if (office.permissions.indexOf('HR') !== -1) {
                                    permissions = 'HR';
                                }
                            }
                        }
                    }
                    const scheduleList = scheduleWorkflowList && scheduleWorkflowList.some((scheduleState) => {
                        if (list_item.id == scheduleState.state_id) {
                            scheduledDetails = scheduleState;
                            return true;
                        }
                    });
                   
                    const currentLifeCycle = applicationStatus.data && applicationStatus.data.appln && applicationStatus.data.appln.current_lifecycle;
                    if (list_item.id == currentLifeCycle.workflow_start_state && currentLifeCycle.workflow_end_state) {
                        appln_status_rejected = workflowFullList && workflowFullList.some((stateList) => {
                            if (currentLifeCycle.workflow_end_state == stateList.id && stateList.display_order == 0) {
                                candidateHired = true;
                            }
                            if (currentLifeCycle.workflow_end_state == stateList.id && stateList.display_order == -1) {
                                applicationEnd = true;
                                return true;
                            }
                        });
                    }
                    
                    let appln_Schedule_Details = (
                        <div style={{ padding: '5px 0'}}>
                            <div className="w3-row" style={{ display: 'flex' }}>
                                <div>
                                    <img alt="avatar"
                                        className="h7t-feedback-img w3-circle w3-left w3-margin-right"
                                        src={scheduledDetails.profile_pic ? scheduledDetails.profile_pic : 'https://www.w3schools.com/w3css/img_avatar6.png'}
                                    />
                                </div>
                                <div style={{ flex: '1'}}>
                                    <div>
                                        <p className="h7t-font-size-18">{scheduledDetails.display_name ? scheduledDetails.display_name : null}</p>
                                    </div>
                                    <hr className="h7t-horizondal-line" />
                                    {scheduledDetails.description
                                        ? (
                                            <div className="w3-row">
                                                <p className="h7t-feedback">
                                                    {scheduledDetails.description}
                                                </p>
                                            </div>
                                        )
                                        : null}
                                    <div className="w3-row">
                                        <div className="w3-col m6">
                                            {scheduledDetails.where
                                                ? <p className="h7t-font-size-13 h7t-font-bold"><span className="h7t-light-grey">Where | </span>{scheduledDetails.where}</p>
                                                : null}
                                            {scheduledDetails.meeting_room
                                                ? <p className="h7t-font-size-13 h7t-font-bold"><span className="h7t-light-grey">Meeting Room | </span>{scheduledDetails.meeting_room}</p>
                                                : null}
                                        </div>
                                        <div className="w3-col m6">
                                            {scheduledDetails.action_date ? 
                                                <p className="h7t-font-size-13 h7t-font-bold">
                                                    <span className="h7t-light-grey">Scheduled for | </span>
                                                    {moment(scheduledDetails.action_date).format('MMM Do, YYYY')} at {moment(scheduledDetails.action_time).format('hh:mm A')} 
                                                </p> : null}
                                            {scheduledDetails.action_date && list_item.sla_days ?
                                                <p className="h7t-font-size-13 h7t-font-bold">
                                                    <span className="h7t-light-grey">To be completed by | </span>
                                                    {moment(scheduledDetails.action_date).add(list_item.sla_days, 'day').format('MMM Do, YYYY')}
                                                </p> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w3-margin-bottom">
                                {permissions == 'HR' ? (
                                        <span className="material-icons w3-right h7t-cursor-pointer w3-padding-small w3-hover-teal w3-round w3-border"
                                            style={{ fontSize: '15px', marginBottom: '8px' }}
                                            title="Edit Scheduling"
                                            onClick={() => this.handleEditScheduling(list_item)}
                                        >
                                            edit
                                        </span>
                                ) : null
                                }
                            </div>
                        </div>
                    );

                    let appln_feedback_content = (
                        <div style={{ padding: '5px 0'}}>
                            <div className="w3-row" style={{ display: 'flex' }}>
                                <div>
                                    <img alt="avatar"
                                        className="h7t-feedback-img w3-circle w3-left w3-margin-right"
                                        src={feedbackResult.profile_pic ? feedbackResult.profile_pic : 'https://www.w3schools.com/w3css/img_avatar6.png'}
                                    />
                                </div>
                                <div style={{ flex: '1'}}>
                                    <div>
                                        <p className="h7t-font-size-18">{feedbackResult.display_name ? feedbackResult.display_name : null}</p>
                                    </div>
                                    <hr className="h7t-horizondal-line" />
                                    <div className="w3-row">
                                        <p className="h7t-feedback">
                                            {feedbackResult.actor_feedback}
                                        </p>
                                    </div>
                                    <div className="w3-row w3-margin-bottom">
                                        <div className="w3-col m6">
                                            <p className="h7t-font-size-13 h7t-font-bold">
                                                <span className="h7t-light-grey">Rating | </span>
                                                    {feedbackResult.rating}
                                            </p>
                                            <p>
                                                {feedbackResult.attachment ? (
                                                    <a href={feedbackResult.attachment} className="w3-text-blue w3-small" download>Download Attachment</a>
                                                ) : null}
                                            </p>
                                        </div>
                                        <div className="w3-col m6">
                                            <p className="h7t-font-size-13 h7t-font-bold"><span className="h7t-light-grey">Interviewed on | </span>{moment(feedbackResult.completed_at).format('MMM Do, YYYY')}</p>
                                            {currentUser && (feedbackResult.actor_user_id === currentUser.user.id) && feedbackResult.actor_feedback && <p className="w3-margin-bottom">
                                              <span className="material-icons h7t-cursor-pointer w3-padding-small w3-hover-teal w3-round w3-border"
                                                    title="Edit Feedback"
                                                    style={{ fontSize: '15px', marginBottom: '8px' }}
                                                    onClick={e => this.handleEditFeedback(e,feedbackResult)}
                                                >
                                                    edit
                                                </span>
                                            </p> }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    return (
                        <div>
                            <Grid item xs={12}>
                                <Timeline lineStyle={{ top: '40px', height: '100%', width: '1px', backgroundColor: '#bdbdbd' }}>
                                    <TimelineEvent
                                        bubbleStyle={{ border: '0px solid', backgroundColor: 'white', top: '-6px' }}
                                        iconStyle={{
                                            border: '0px solid',
                                            backgroundColor: appln_status_rejected ? '#e85342'
                                                : (editFeedbackForm ? (feedbacks.length > 0 ? '#3fc198' : '#fd863c') : (!feedbacks ? '#fd863c' : '#3fc198')),
                                            width: '25px',
                                            height: '25px',
                                            left: '3px',
                                            borderRadius: '50%',
                                        }}
                                        title={(
                                            <div>
                                                {list_item.state_name}
                                                <span className="w3-border w3-round w3-tiny w3-padding-small w3-right" >
                                                    Weightage : {list_item.weight}
                                                </span>
                                            </div>
                                        )
                                        }
                                        titleStyle={{ fontSize: '17px', width: '98%' }}
                                        contentStyle={{ backgroundColor: '#f7f7f7' }}
                                        icon={(appln_status_rejected)
                                            ? <Icon className="h7t-completedFeedback" style={{ background: '#e85342' }}>clear</Icon>
                                            : (editFeedbackForm ? (feedbacks.length > 0 ? <Icon className="h7t-timeline-icon">done</Icon> : null) : feedbacks ?
                                                <Icon className="h7t-timeline-icon">done</Icon> : null)
                                        }
                                    >
                                        {feedbacks && (feedbackResult.workflow_start_state == list_item.id)
                                            ? (
                                                <div>
                                                    {appln_feedback_content}
                                                </div>
                                            )
                                            : (scheduleList && (scheduledDetails.state_id == list_item.id) ?
                                                <div>{appln_Schedule_Details}</div>
                                                : permissions == 'HR'
                                                    ? (
                                                        <p className="h7t-cursor-pointer w3-center"
                                                            style={{ color: '#2980b9', textDecoration: 'underline', marginTop: '10px' }}
                                                            onClick={() => this.handleschedule(list_item)}
                                                        >
                                                            Select Interviewer
                                                        </p>
                                                    ) : <p className="w3-padding h7t-feedback">To Be Scheduled by HR</p>
                                            )
                                        }
                                    </TimelineEvent>
                                </Timeline>
                            </Grid>
                        </div>
                    );
                });
            }
        }

        return (
            <div className="w3-margin-bottom">
                <div className="w3-row">
                    <div className="w3-col m12 s12">
                        <div className="h7t-margin-mobile-view">
                            {this.renderFeedbackForm()}
                        </div>
                    </div>
                </div>
                <div className="w3-row">
                    <div className="w3-col m12">
                        <div className="w3-margin-top w3-round">
                            {scheduleInterviewer}
                        </div>
                    </div>
                </div>
                {showAssignModal
                    ? (
                        <ScheduleRole
                            onModalClose={() => this.setState({ showAssignModal: false })}
                            officeId={OFFICE_ID}
                            workflowStates={workflowStates_list}
                            id={this.state}
                            applnId={application_id}
                            editData={editSchedule}
                        />
                    ) : null
                }
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    const scheduleWorkflowList = state.scheduleWorkflowList ? state.scheduleWorkflowList.data : state.scheduleWorkflowList;
    return {
        currentUser,
        scheduleWorkflowList,
        applicationFeedback: state.applicationFeedback,
        workflowState: state.workflowState,
    };
}
export default connect(mapStateToProps, {
    getApplicationStatus,
    getJobApplnFeedback,
    getCheckRoleForUser,
    postAssignSelf,
    postChangeState,
    setAppMessage,
    getList,
    getJobApplications,
    getApplicationTitleList,
    postAssignScheduleSelf,
    postScheduleStateProcess,
    getScheduleApplicationStatus,
    getWorkflowState,
    getScheduleWorkflowList,
    getUnreadNotifications,
    postChangeStateEdit,
})(ApplicationFeedbacks);
