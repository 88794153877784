import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import {
    setAppMessage,
    postEditProfileInfo,
    getCurrentUser,
} from '../../js/actions';
import ProfilePicUpload from '../Seeker/Profile/ProfilePicUpload';
import { ErrorHelperText } from './HelperComponents';
import ChangePassword from './ChangePassword';
import { InputTextField, RadioButtonMUI } from './MaterialUI/HelperInputs';

class ProfileSettings extends Component {
    static propTypes = {
        setAppMessage: PropTypes.func,
        postChangePassword: PropTypes.func,
        currentUser: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            profilePicModal: false,
            formData: {},
            errors: {},
            changePasswordModal: false,
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { currentUser: { user } } = this.props;
        if (user) {
            this.setState({ formData: Object.assign({}, user) });
        }
    }

    handleOpenUploadModal() {
        this.setState({
            profilePicModal: true,
        });
    }

    handleChangePassword() {
        this.setState({
            changePasswordModal: true,
        });
    }

    validateData() {
        const { formData, errors } = this.state;
        let hasErrors = false;

        const regExp = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(formData.email);
        if (!regExp) {
            hasErrors = true;
            errors.email = 'Enter a valid email';
        }

        if (formData.phone_number && !/^\d{10}$/.test(formData.phone_number)) {
            hasErrors = true;
            errors.phone_number = 'Enter a valid phone number';
        }
        if (formData.dob && new Date(formData.dob) > new Date()) {
            hasErrors = true;
            errors.dob = 'Date of birth must be less than today\'s';
        } else if (formData.dob === '0000-00-00') {
            hasErrors = true;
            errors.dob = 'This field is mandatory';
        }

        const ignoreList = [
            'last_name',
            'reset_token',
            'verified_at',
            'profile_pic_id',
            'current_company_id',
            'current_office_id',
            'timezone_offset',
            'latitude',
            'longitude',
            'access_token',
        ];

        Object.keys(formData).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (formData[key]) === 'string' && ignoreList.indexOf(key) < 0) {
                    if (!formData[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return formData;
    }

    handleSubmit() {
        const validData = this.validateData();
        if (validData) {
            this.props.postEditProfileInfo(validData).then((response) => {
                const resp = response && response.data;
                if (resp) {
                    if (resp.success) {
                        this.props.setAppMessage('success', 'Profile edited successfully!');
                        this.props.getCurrentUser();
                    } else if (resp.data) {
                        this.setState({
                            errors: resp.data,
                        });
                    }
                }
            });
        }
    }

    handleChange(evt) {
        let targetElement = $(evt.target).attr('id');
        const { formData, errors } = this.state;

        if (targetElement === 'gender_male' || targetElement === 'gender_female') {
            targetElement = 'gender';
        }

        if (errors[targetElement]) {
            errors[targetElement] = null;
        }

        formData[targetElement] = $(evt.target).val();
        this.setState({ formData, errors });
    }

    handleDatePicker(evt, date, id) {
        const { formData, errors } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');

        if (errors[id]) {
            errors[id] = null;
        }

        formData[id] = formatedDate;
        this.setState({ formData, errors });
    }

    render() {
        const { currentUser: { profile } } = this.props;
        const {
            formData, errors, profilePicModal, changePasswordModal,
        } = this.state;
        return (
            <div>
                <div className="w3-right w3-margin">
                    <Button className="h7t-primary-btn" onClick={this.handleChangePassword}>Change Password</Button>
                </div>
                <div className="w3-content w3-row" style={{ maxWidth: 500 }}>
                    <div className="w3-white w3-margin-top w3-container h7t-border-mobile-view">
                        <div className="w3-center w3-margin-top">
                            <div className="h7t-profile-pic">
                                <img className="h7t-company-logo w3-circle" style={{ border: '1px solid #e7e7e7' }} alt="" src={(profile && profile.profile_pic) ? profile.profile_pic : 'https://www.w3schools.com/w3css/img_avatar6.png'} />
                                <div className="h7t-upload-profile-pic">
                                    <button
                                        type="button"
                                        className="w3-button w3-black w3-opacity-min w3-circle h7t-padding-2-8"
                                        onClick={this.handleOpenUploadModal.bind(this)}
                                    >
                                        <i className="ion-camera w3-large" />
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div>
                            <InputTextField
                                label="Display name"
                                onChange={this.handleChange}
                                id="display_name"
                                name="display_name"
                                required
                                value={formData.display_name ? formData.display_name : ''}
                                errors={errors.display_name}
                            />
                            <InputTextField
                                label="Email"
                                onChange={this.handleChange}
                                id="email"
                                name="email"
                                required
                                value={formData.email ? formData.email : ''}
                                errors={errors.email}
                            />
                            <InputTextField
                                label="Phone Number"
                                onChange={this.handleChange}
                                id="phone_number"
                                name="phone_number"
                                required
                                value={formData.phone_number ? formData.phone_number.indexOf('NOT_UPDATED') !== -1 ? '' : formData.phone_number : ''}
                                errors={errors.phone_number}
                            />

                            <div className="w3-panel">
                                <RadioButtonMUI
                                    name="gender"
                                    id="gender_male"
                                    checked={formData.gender === 'Male' ? 'checked' : undefined}
                                    value="Male"
                                    onChange={this.handleChange}
                                    label="Male"
                                />

                                <RadioButtonMUI
                                    name="gender"
                                    id="gender_female"
                                    checked={formData.gender === 'Female' ? 'checked' : undefined}
                                    value="Female"
                                    onChange={this.handleChange}
                                    label="Female"
                                />
                                <ErrorHelperText errors={errors.gender} />
                            </div>

                            <div className="w3-padding">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        required
                                        inputVariant="outlined"
                                        label="Date of Birth"
                                        format="MMM do, yyyy"
                                        inputProps={{readOnly: true}}
                                        value={formData.dob && formData.dob !== '0000-00-00' ? moment(formData.dob) : null}
                                        onChange={(e, date) => this.handleDatePicker(e, date, 'dob')}
                                        error={errors.dob}
                                        helperText={errors.dob}
                                        disableFuture={true}
                                        maxDate={moment().subtract(18, 'years')}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="w3-center w3-margin">
                                <Button className="h7t-secondary-btn" onClick={this.handleSubmit}>Update Profile</Button>
                            </div>
                        </div>
                    </div>
                    {profilePicModal
                        ? (
                            <ProfilePicUpload
                                onModalClose={() => this.setState({ profilePicModal: false })}
                            />
                        ) : null}

                    {changePasswordModal ? (
                        <ChangePassword
                            onModalClose={() => this.setState({ changePasswordModal: false })}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    postEditProfileInfo,
    getCurrentUser,
})(ProfileSettings);
