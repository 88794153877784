import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import moment from 'moment';
import {
    getListInvite,
    postAcceptRoleUserInvite,
    postRejectRoleUserInvite,
    setAppMessage,
    getCurrentUser,
    getNotifications,
    postClearNotifications,
} from '../../js/actions';

class UserNotifications extends Component {
    static propTypes = {
        getListInvite: PropTypes.func,
        postAcceptRoleUserInvite: PropTypes.func,
        postRejectRoleUserInvite: PropTypes.func,
        getCurrentUser: PropTypes.func,
        setAppMessage: PropTypes.func,
        listInvite: PropTypes.object,
        userNotifications: PropTypes.object,
    }

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            invite_token: '',
        };
        this.handleAccept = this.handleAccept.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleRemoveNotifications = this.handleRemoveNotifications.bind(this);
    }

    componentDidMount() {
        this.props.getListInvite();
        this.props.getNotifications();
    }

    validateData(token) {
        let isValid = true;
        const { errors } = this.state;

        if (typeof (token) === 'string') {
            if (!token.match(/\w/)) {
                errors.invite_token = 'This field is mandatory';
                isValid = false;
            }
        }
        if (!token) {
            errors.invite_token = 'This field is mandatory.';
            isValid = false;
        }

        this.setState({ errors });

        return isValid;
    }

    handleAccept(invite_token) {
        if (!this.validateData(invite_token)) {
            return;
        }

        this.props.postAcceptRoleUserInvite({ invite_token }, invite_token).then((resp) => {
            const response = resp && resp.data;
            if (response && response.success) {
                this.props.setAppMessage('success', 'Role request accepted successfully!');
                this.props.getListInvite();
                this.props.getCurrentUser().then(() => {
                    if (response && response.data) {
                        const companyId = response.data.company_id;
                        const officeId = response.data.office_id;
                        if (companyId && officeId) {
                            const redirectUrl = `/e/${companyId}/${officeId}/dashboard`;
                            this.props.history.push({
                                pathname: redirectUrl,
                                state: {
                                    companyId,
                                    officeId,
                                },
                            });
                        }
                    }
                });
            } else if (response) {
                this.setState({ errors: response.data });
            }
        });
    }

    handleReject(invite_token) {
        if (!this.validateData(invite_token)) {
            return;
        }

        this.props.postRejectRoleUserInvite({ invite_token }, invite_token).then((resp) => {
            const response = resp && resp.data;
            if (response && response.success) {
                this.props.setAppMessage('success', 'Role request rejected successfully!');
                this.props.getListInvite();
            } else if (response) {
                this.setState({
                    errors: response.data,
                });
            }
        });
    }

    handleRemoveNotifications(id) {
        this.props.postClearNotifications(id).then((resp) => {
            if (resp && resp.data && resp.data.success) {
                this.props.setAppMessage('success', 'Notifications cleared successfully!');
                this.props.getNotifications();
            }
        });
    }

    render() {
        let notificationsList = [];
        let clearBtn = null;
        const {
            listInvite, userNotifications, closeDrawer,
        } = this.props;
        const self = this;
        if (listInvite && listInvite.data && listInvite.data.length > 0) {
            listInvite.data.forEach((roleRequest) => {
                notificationsList.push(
                    <div key={`role_${roleRequest.id}`} className="notification-card">
                        <div className="capitalize-first">You are invited to join hiringNXT to manage {roleRequest.role_name} role for {roleRequest.company_name} office {roleRequest.office_name}.</div>
                        <div style={{ marginTop: '10px' }}>
                            <Button
                                className="h7t-primary-btn h7t-margin-bottom-5"
                                onClick={self.handleAccept.bind(self, roleRequest.invite_token)}
                            >
                                Accept
                            </Button>
                            <Button
                                className="h7t-secondary-btn w3-margin-left h7t-margin-bottom-5"
                                onClick={self.handleReject.bind(self, roleRequest.invite_token)}
                            >
                                Reject
                            </Button>
                        </div>
                    </div>,
                );
            });
        }

        if (userNotifications && userNotifications.data && userNotifications.data.length) {
            clearBtn = (
                <div className="w3-bar-item w3-right">
                    <Button className="h7t-capitalize-text" onClick={this.handleRemoveNotifications}>Clear All</Button>
                </div>
            );
            userNotifications.data.map((notify) => {
                let gotToLink = null;
                if (notify.target) {
                    const hashData = notify.target.split('/');
                    const companyId = parseInt(hashData[2]);
                    const officeId = parseInt(hashData[3]);
                    gotToLink = (
                        <span
                            className="ion-arrow-right-c w3-large w3-display-right h7t-cursor-pointer"
                            onClick={(e) => {
                                this.props.history.push({
                                    pathname: notify.target,
                                    state: {
                                        companyId,
                                        officeId,
                                    },
                                });
                                closeDrawer(e);
                            }}
                        />
                    );
                }
                notificationsList.push(
                    <div key={`notify_${notify.id}`} className="w3-row notification-card">
                        <div className="capitalize-first">{notify.notification}</div>
                        <div className="w3-display-container">
                            <div className="notification-time">{moment(new Date(moment.utc(notify.created_at))).format('MMM Do, YYYY [at] hh:mm A')}</div>
                            {gotToLink || null}
                        </div>
                    </div>,
                );
            });
        }

        if (notificationsList && notificationsList.length === 0) {
            notificationsList = (
                <div className="h7t-table-data w3-padding" style={{ height: '100vh' }}>
                    <div className="w3-center w3-margin-top">
                        <span className="ion-ios-bell-outline" style={{ fontSize: '120px' }} />
                        <p className="w3-margin-top"><strong>You don't have any notifications to display!</strong></p>
                    </div>
                    <div className="h7t-flex-container w3-margin-top">
                        <ul className="h7t-feature-description">
                            <li>Company role invitation notifications</li>
                            <li>Job application workflow change notifications</li>
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="w3-bar w3-border-bottom">
                    <div className="w3-bar-item">
                        <h4 style={{ color: '#000', marginBottom: '0px' }}>Notifications</h4>
                    </div>
                    {clearBtn}
                </div>
                <div>
                    {notificationsList}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        listInvite: state.listInvite,
        userNotifications: state.userNotifications,
    };
}

export default withRouter(connect(mapStateToProps, {
    getListInvite,
    postAcceptRoleUserInvite,
    postRejectRoleUserInvite,
    setAppMessage,
    getCurrentUser,
    getNotifications,
    postClearNotifications,
})(UserNotifications));
