import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
    Grid, Drawer, Divider, List, Slider, Typography, Button
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { InputSelectField, SearchDropdown } from './MaterialUI/HelperInputs';
import { getMetaData } from '../../js/actions';
import { discoverFilterConfig } from '../Common/Constants';
import moment from 'moment';

class DiscoverFilters extends Component {
    static propTypes = {
        handleDiscoverFilters: PropTypes.func,
        clearFilterValues: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            right: false,
            filterOn: {
                location: '',
                yoeRange: discoverFilterConfig.yoeInitialRange,
                skills: '',
                ctc_range: '',
                channel: '',
                selectedLocationLabel: ''
            },
            skillSearchKey: '',
            marks: [],
        };
        this.triggerFilter = this.triggerFilter.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleSkillChange = this.handleSkillChange.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleSkillSearch = this.handleSkillSearch.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    componentDidMount() {
        let { marks } = this.state;
        this.props.getMetaData({
            type: 'country',
        });

        const skillsList = {};
        skillsList.type = 'skills';
        skillsList.ids = [];
        this.props.getMetaData(skillsList).then(() => {
            this.forceUpdate();
        });

        for (let i = 0; i <= discoverFilterConfig.yoeMaxLimit; i = (i + discoverFilterConfig.yoeOffset)) {
            marks.push({ value: i, label: i });
        }
        this.setState({ marks });
    }

    toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            right: open,
        });
    };

    handleClearFilter() {
        const filterOn = {};
        filterOn.location = '';
        filterOn.yoeRange = '';
        filterOn.skills = '';
        filterOn.ctc_range = '';
        filterOn.channel = '';
        filterOn.selectedLocationLabel = '';
        const filterData = Object.assign({}, filterOn);
        filterData.yoeRange = discoverFilterConfig.yoeInitialRange;
        this.setState({
            filterOn: filterData,
            right: false,
        }, () => {
            this.props.clearFilterValues(filterOn);
        });
    }


    handleSelectFilter(event, data) {
        const { name, value } = event.target;
        const { filterOn } = this.state;
        if (event) {
            if (name === 'location' && data) {
                filterOn['selectedLocationLabel'] = data.props.children.indexOf('Select') === -1 ? data.props.children : '';
            }
            filterOn[name] = value;
        } else {
            filterOn[name] = '';
        }
        this.setState({ filterOn });
    }

    getYoeLimits() {
        const { filterOn: { yoeRange } } =  this.state;
        const minValue = yoeRange[0].toString().split('.');
        const maxValue = yoeRange[1].toString().split('.');

        const minYear = minValue[0] || 0;
        const minMonth = minValue[1] || 0;

        const maxYear = maxValue[0] || 0;
        const maxMonth = maxValue[1] || 0;

        const yoeMaxTimestampValue = moment().subtract({ year: minYear, month: minMonth }).format('x');
        const yoeMinTimestampValue = moment().subtract({ year: maxYear, month: maxMonth }).format('x');
        return {
            yoeMaxTimestampValue: yoeMaxTimestampValue,
            yoeMinTimestampValue: yoeMinTimestampValue,
        };
    }

    triggerFilter() {
        const { filterOn } = this.state;
        let filterData = Object.assign({}, filterOn);
        filterData.yoeRange = this.getYoeLimits();
        if (filterData.selectedLocationLabel) {
            filterData.location = filterData.selectedLocationLabel;
            delete filterData.selectedLocationLabel;
        }
        if (filterData.skills && filterData.skills.length > 0) {
            const skillList = filterData.skills.reduce((acc, skill) => {
                acc.push(skill.toLowerCase());
                return acc;
            }, []);
            filterData.skills = skillList;
        }
        this.props.handleDiscoverFilters(filterData);
        this.setState({
            right: false,
        });
    }

    handleSkillChange(option) {
        const { filterOn } = this.state;
        const value = [];
        if (option && Array.isArray(option)) {
            if (option.length) {
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        }
        filterOn.skills = value;
        const skillsList = {};
        skillsList.type = 'skills';
        skillsList.ids = value;
        this.props.getMetaData(skillsList).then(() => {
            this.setState({
                filterOn,
                skillSearchKey: '',
            });
        });
    }

    handleSkillSearch(evt, val) {
        if (evt && evt.type === 'change') {
            const { filterOn } = this.state;
            this.setState({ skillSearchKey: val });
            const skillsList = {};
            skillsList.type = 'skills';
            skillsList.search = val;
            skillsList.ids = filterOn.skills;
            this.props.getMetaData(skillsList).then(() => {
                this.forceUpdate();
            });
        }
    }

    handleSliderChange(event, newValue) {
        const { filterOn } = this.state;
        filterOn.yoeRange = newValue;
        this.setState({
            filterOn,
        });
    }

    render() {
        const { metaData } = this.props;
        const {
            right, filterOn, skillSearchKey, marks
        } = this.state;

        const locationSelect = [{ val: '', name: '-Select Location-' }];
        const skillSelect = [];
        const ctcRangeSelect = [{ val: '', name: '-Select CTC Range-' }];
        const channelSelect = [
            { val: '', name: '-Select Channel-' },
            { val: 'stackoverflow', name: 'Stackoverflow' },
            { val: 'indeed', name: 'Indeed' },
            { val: 'monster', name: 'Monster' },
        ];

        if (metaData) {
            const { country, skills } = metaData;
            if (country && country.length) {
                country.map((countryData) => {
                    locationSelect.push({ val: countryData.id, name: countryData.term });
                });
            }
            if (skills && skills.length) {
                skills.forEach((skill) => {
                    skillSelect.push({ val: skill.term, name: skill.term });
                });
            }
        }

        const sideList = (
            <div
                className="h7t-filter-container"
                role="presentation"
            >
                <h3>Filters</h3>
                <InputSelectField
                    label="Location"
                    id="location"
                    name="location"
                    value={filterOn.location}
                    options={locationSelect}
                    onChange={(e, data) => this.handleSelectFilter(e, data)}
                />
                <Divider className="h-margin-16" />
                <SearchDropdown
                    multiple
                    id="skills"
                    label="Skills"
                    options={skillSelect}
                    value={filterOn.skills ? filterOn.skills : undefined}
                    onChange={(evt, val) => this.handleSkillChange(val)}
                    searchKey={skillSearchKey}
                    onSearch={this.handleSkillSearch}
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="CTC Range"
                    id="ctc_range"
                    name="ctc_range"
                    value={filterOn.ctc_range}
                    options={ctcRangeSelect}
                    disabled={true}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Channel"
                    id="channel"
                    name="channel"
                    value={filterOn.channel}
                    options={channelSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <div className="w3-panel">
                    <Typography id="yoe-range-slider" gutterBottom className="w3-text-gray">
                        Years of Experience
                    </Typography>
                    <Slider
                        value={filterOn.yoeRange}
                        onChange={this.handleSliderChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="yoe-range-slider"
                        marks={marks}
                        step={discoverFilterConfig.yoeStepValue}
                        min={discoverFilterConfig.yoeMinLimit}
                        max={discoverFilterConfig.yoeMaxLimit}
                    />
                </div>
                <Divider className="h-margin-16" />

                <List>
                    <Grid container justify="space-between" alignItems="center">
                        <Button
                            variant="contained"
                            className="h7t-secondary-btn"
                            size="small"
                            onClick={this.handleClearFilter}
                        >
                            Clear
                        </Button>
                        <Button
                            className="h7t-primary-btn"
                            variant="contained"
                            size="small"
                            onClick={this.triggerFilter}
                        >
                            Filter
                        </Button>
                    </Grid>
                </List>
            </div>
        );

        return (
            <div>
                <IconButton
                    className="w3-right h7t-cursor-pointer"
                    style={{ marginTop: '12px', marginRight: '20px' }}
                    onClick={this.toggleDrawer(true)}
                >
                    <FilterListIcon />
                </IconButton>
                <Drawer
                    anchor="right"
                    open={right}
                    onClose={this.toggleDrawer(false)}
                >
                    {sideList}
                </Drawer>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        metaData: state.meta,
    };
}
export default connect(mapStateToProps, {
    getMetaData,
})(DiscoverFilters);
