import React from 'react';
import { withRouter } from 'react-router-dom';
const querystring = require('querystring');
import axios from 'axios';
import { LoadingIcon } from '../Common/HelperComponents';


class WidgetLogin extends React.Component {
    componentDidMount() {
        const out = querystring.decode(this.props.location.search.replace('?',''));
        if (!out.code) {
            return;
        }

        let stateData = null;
        if (this.props.location.pathname.indexOf('google') !== -1) {
            stateData = JSON.parse(out.state);
        }
        axios.post('/v1/api/auth/connect', Object.assign({}, out, {
            provider: this.props.match.params.provider,
        })).then(resp => {
            if (resp && resp.data && resp.data.data && resp.data.data.token) {
                localStorage.setItem('accessToken', resp.data.data.token);
            }
            let redirectUrl = '/sdk.html';
            const widgetRedirectUrl = localStorage.getItem('widgetRedirectUrl')
            if (widgetRedirectUrl) {
                redirectUrl = JSON.parse(widgetRedirectUrl);
                localStorage.removeItem('widgetRedirectUrl');
            }
            document.location.href = (stateData && stateData.redirectUrl) || redirectUrl;
        });
    }
    render() {
        return <LoadingIcon />
    }
}

export default withRouter(WidgetLogin);