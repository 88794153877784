import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {
    getResumeBlockViews,
    setAppMessage,
    deleteUserResume,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import { LoadingIcon } from '../../Common/HelperComponents';
import DeleteUserResume from '../../Common/DeleteRecordModal';
import AddResumeViewBlock from './AddResumeViewBlock';
import PreviewResumeModal from '../../Common/PreviewResumeModal';

class ResumeMaker extends Component {
    static propTypes = {
        setAppMessage: PropTypes.func,
        resumeBlockViews: PropTypes.object,
        deleteUserResume: PropTypes.func,
        getResumeBlockViews: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            editResume: '',
            blockViewData: '',
            deleteId: '',
            previewResume: false,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddEditDetails = this.handleAddEditDetails.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        this.props.getResumeBlockViews().then((resp) => {
            if(location && location.state && location.state.jobId && resp.data.data.length<1) {
                this.handleAddEditDetails('Add');
            }
        });
    }

    handleAddEditDetails(item, viewList) {
        let editResume = item;
        let blockViewData = null;
        if (viewList) {
            blockViewData = viewList;
        }
        if (editResume === 'Add') {
            editResume = {
                title: '',
                covering_letter_text: '',
            };
            blockViewData = [];
        }
        this.setState({
            editResume,
            blockViewData,
        });
    }

    handleDelete(viewId) {
        if (viewId === true) {
            const { deleteId } = this.state;
            this.props.deleteUserResume(deleteId).then((response) => {
                if (response && response.data && response.data.success) {
                    this.props.getResumeBlockViews();
                    this.props.setAppMessage('success', 'Resume deleted successfully!');
                    $('.close-button').click();
                }
            });
        } else {
            this.setState({ deleteId: viewId });
        }
    }

    handlePreview(previewResume) {
        this.setState({
            previewResume,
        });
    }

    render() {
        let resumePreview = [];
        const { resumeBlockViews, location } = this.props;
        const {
            editResume, blockViewData, deleteId, previewResume,
        } = this.state;
        if (!resumeBlockViews || !resumeBlockViews.data) {
            return <LoadingIcon />;
        }
        if (resumeBlockViews.data.length) {
            resumeBlockViews.data.forEach((item) => {
                const viewBlocks = [];
                if (item.resume_view_block && item.resume_view_block.length && !item.resume_file_path) {
                    item.resume_view_block.map((block) => {
                        if (block.resume_block) {
                            viewBlocks.push(
                                <div key={block.id} className="w3-col m12">
                                    <div className="w3-row w3-border-top">
                                        <div className="w3-col m6">
                                            <p className="capitalize-first">{block.resume_block.title}, {block.resume_block.institution} </p>
                                        </div>
                                        <div className="w3-col m6 h7t-right-align-web">
                                            <p>{moment(block.resume_block.start_date).format('MMM Do, YYYY')} -  {block.resume_block.end_date ? moment(block.resume_block.end_date).format('MMM Do, YYYY') : 'Present'} </p>
                                        </div>
                                    </div>
                                </div>,
                            );
                        }
                    });
                }
                resumePreview.push(
                    <div
                        key={`resume-preview-${item.id}`}
                        className="w3-row w3-margin-top w3-margin-bottom w3-padding h7t-table-data"
                    >
                        <div className="w3-row">
                            <div className="w3-col s7 m9">
                                <h4 className="capitalize-first">{item.title}</h4>
                            </div>
                            <div className="w3-col s5 m3 w3-right-align w3-padding">
                                <Tooltip title="Edit">
                                    <button
                                        type="button"
                                        className="w3-button w3-hover-teal w3-padding-small icon-border"
                                        onClick={() => this.handleAddEditDetails(item, item.resume_view_block)}
                                    >
                                        <span className="ion-edit" aria-hidden="true" />
                                    </button>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <button
                                        type="button"
                                        className="w3-button w3-hover-red w3-padding-small icon-border"
                                        onClick={() => this.handleDelete(item.id)}
                                    >
                                        <span className="ion-trash-a" aria-hidden="true" />
                                    </button>
                                </Tooltip>
                                <Tooltip title="View Resume">
                                    <button
                                        type="button"
                                        className="w3-button w3-hover-blue w3-padding-small icon-border"
                                        onClick={() => this.handlePreview(item)}
                                    >
                                        <span className="ion-eye" aria-hidden="true" />
                                    </button>
                                </Tooltip>
                            </div>
                            <div className="w3-col s12 m12 h7t-ql-editor" dangerouslySetInnerHTML={{__html: item.covering_letter_text}} />
                            {/* <div className="w3-col s12 m12">
                            
                                <p className="h7t-cover-letter">{item.covering_letter_text}</p>
                            </div> */}
                        </div>
                        {/* {viewBlocks} */}
                    </div>,
                );
            });
        } else {
            resumePreview = (
                <div className="w3-padding h7t-table-data h7t-margin-10 ">
                    <div className="w3-center w3-margin-top"> 
                        <span className="ion-ios-paper-outline" style={{fontSize:"120px"}}/>
                        <p className="w3-margin-top"><strong>No details to display!</strong></p>
                    </div>
                    <div className="h7t-flex-container w3-margin-top">
                        <ul className="h7t-feature-description">
                            <li>Click on create new resume button to build your own resume.</li>
                            <li>Upload a resume or select details to show in your resume.</li>
                            <li>Click on the eye icon to view the preview of your resume.</li>
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <div className="content-margin">
                <div className="w3-row">
                    <div className="w3-col m6 s6 w3-margin-top h7t-margin-mobile-view">
                        <h3 className="h-margin-top-0">My Resumes</h3>
                    </div>
                    <div className="w3-col m6 s6 w3-right-align">
                        <Button
                            variant="contained"
                            className="w3-margin-top h7t-primary-btn"
                            onClick={() => this.handleAddEditDetails('Add')}
                        >
                            Create new resume
                        </Button>
                    </div>
                </div>
                <div>
                    {resumePreview}
                </div>
                {editResume
                    ? (
                        <AddResumeViewBlock
                            editResume={editResume}
                            blockViewData={blockViewData}
                            companyId={location && location.state && location.state.companyId ? location.state.companyId: '' }
                            officeId = { location && location.state && location.state.officeId ? location.state.officeId : ''}
                            jobId = { location && location.state && location.state.jobId ? location.state.jobId : '' }
                            onModalClose={() => this.setState({ editResume: '' })}
                            disableClose = {location && location.state ? true : false }
                            id = "resume_view_block"
                        />
                    ) : null}

                {deleteId
                    ? (
                        <DeleteUserResume
                            handleDelete={this.handleDelete}
                            deleteMessage="resume"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteId: '' })}
                        />
                    ) : null}

                {previewResume
                    ? (
                        <Modal modalCls="w3-show" onClose={() => this.setState({ previewResume: false })}>
                            <div className="w3-container h-margin-top-35 h7t-margin-bottom-40" style={{ padding: '0px 40px' }}>
                                <PreviewResumeModal
                                    previewData={previewResume}
                                />
                            </div>
                        </Modal>
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resumeBlockViews: state.resumeBlockViews,
    };
}
export default connect(mapStateToProps, {
    getResumeBlockViews,
    deleteUserResume,
    setAppMessage,
})(ResumeMaker);
