import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select, Rate } from 'antd';
import {
    ErrorHelperText,
} from '../../Common/HelperComponents';
import {
    setAppMessage,
    getCurrentUser,
    getMetaData,
    postAddUserSkillsRate,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import { Button } from '@material-ui/core';

const { Option } = Select;
const starLevels = {
    1: 'Beginner',
    2: 'Intermediate',
    3: 'Proficient',
    4: 'Expert',
    5: 'Advanced',
};

class SkillsRatingModal extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getMetaData: PropTypes.func,
        metaData: PropTypes.object,
        currentUser: PropTypes.object,
    }

    constructor(args) {
        super(args);
        this.state = {
            error: [],
            form: [{
                skill: '',
                star_rating: '',
                rating: '',
            }],
        };
        this.handleAnotherSkill = this.handleAnotherSkill.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeSkill = this.removeSkill.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        const { existingUserSkillsRate, metaData } = this.props;
        if (existingUserSkillsRate && existingUserSkillsRate.length) {
            this.setState({
                form: JSON.parse(JSON.stringify(existingUserSkillsRate)),
            });
        }
        if (!metaData) {
            this.fetchSkills();
        } else {
            const { metaData: { skills } } = this.props;
            if (!skills) {
                this.fetchSkills();
            }
        }
    }

    fetchSkills() {
        const skillsList = {};
        skillsList.type = 'skills';
        this.props.getMetaData(skillsList);
    }

    handleAnotherSkill() {
        const { form, error } = this.state;
        if (form.length) {
            const lastItem = form[form.length - 1];
            if (!lastItem.skill || !lastItem.star_rating || !lastItem.rating) {
                error[form.length - 1] = 'Please fill the above fields';
                this.setState({
                    error,
                });
                return;
            }
        }
        form.push({
            skill: '',
            star_rating: '',
            rating: '',
        });
        this.setState({
            form,
        });
    }

    removeSkill(i) {
        const { form, error } = this.state;
        if (form[i].id) {
            form[i].deleted = true;
        } else {
            form.splice(i, 1);
        }
        error[i] = null;
        this.setState({
            form,
            error,
        });
    }

    handleChange(value, i, field) {
        const { form, error } = this.state;
        if (field === 'star_rating') {
            form[i].rating = starLevels[value];
        }
        form[i][field] = value;
        if (error[i]) {
            error[i] = null;
        }
        this.setState({
            form,
            error,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { form, error } = this.state;
        let hasError = false;
        if (form.length) {
            form.map((item, i) => {
                if (!item.skill || !item.star_rating || !item.rating) {
                    hasError = true;
                    error[i] = 'Please fill the above fields';
                }
            });
            this.setState({
                error,
            });
        }
        if (hasError) {
            return;
        }
        this.props.postAddUserSkillsRate(form).then(() => {
            this.props.setAppMessage('success', 'Skills rated successfully');
            this.props.getCurrentUser();
            this.props.onModalClose();
        });
    }


    handleSearch(val) {
        if (val) {
            const skillsList = {};
            skillsList.type = 'skills';
            skillsList.search = val;
            this.props.getMetaData(skillsList).then(() => {
                this.forceUpdate();
            });
        }
    }

    render() {
        const { onModalClose, metaData } = this.props;
        const { form, error } = this.state;
        const skillsOptions = [];
        if (metaData) {
            const { metaData: { skills } } = this.props;
            if (skills && skills.length) {
                skills.filter(skill=> !form.find(item=>item.skill === skill.term && !item.deleted)).forEach((skill) => {
                    skillsOptions.push(<Option key={skill.id} value={skill.term}>{skill.term}</Option>);
                });
            }
        }
        const ratingBlocks = [];
        form.map((item, i) => {
            let errorMessage = null;
            if (error && error[i]) {
                errorMessage = (
                    <tr>
                        <td colSpan={3}>
                            <ErrorHelperText errors={error[i]} />
                        </td>
                    </tr>
                );
            }
            if (item.deleted !== true) {
                ratingBlocks.push(
                    <React.Fragment>
                        <tr key={`rating_${i}`}>
                            <td>
                                <Select
                                    showSearch={true}
                                    style={{ borderRadius: '0px' }}
                                    placeholder="eg. PHP"
                                    value={item && item.skill ? item.skill : undefined}
                                    onChange={e => this.handleChange(e, i, 'skill')}
                                    onSearch={this.handleSearch}
                                >
                                    {skillsOptions}
                                </Select>
                            </td>
                            <td>
                                <Rate
                                    style={{ marginTop: 5, fontSize: 20 }}
                                    value={item && item.star_rating ? item.star_rating : 0}
                                    onChange={e => this.handleChange(e, i, 'star_rating')}
                                />
                            </td>
                            <td>
                                {item && item.rating ? item.rating : undefined}
                            </td>
                            <td>
                                <span
                                    className="ion-trash-a"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.removeSkill(i)}
                                />
                            </td>
                        </tr>
                        {errorMessage}
                    </React.Fragment>
                );
            }
        });
        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                title="Skills"
            >
                <form className="form-horizontal w3-panel" onSubmit={this.handleSubmit}>
                    {ratingBlocks.length === 0 ? (
                        <h4 style={{ textAlign: 'center' }}>
                            Add Skills and rate it to improve your resume!
                        </h4>
                    ) : (
                        <table className="w3-table h7t-table-data">
                            <tr>
                                <th>Skill Name</th>
                                <th>Star Rating</th>
                                <th>Expertise Level</th>
                                <th />
                            </tr>
                            {ratingBlocks}
                        </table>
                    )}
                    <div className="w3-row w3-margin-top">
                        <Button
                            type="button"
                            variant="contained"
                            className="w3-round h7t-secondary-btn"
                            onClick={this.handleAnotherSkill}
                        >
                            <span className="ion-plus-circled" />
                            &nbsp;{ratingBlocks.length ? 'Add Another Skill' : 'Add Skill'}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            className="w3-round h7t-primary-btn w3-right"
                            disabled={!form.length}
                        >
                            Save Changes
                        </Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
        userSkillsRate: state.userSkillsRate,
        metaData: state.meta,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentUser,
    getMetaData,
    postAddUserSkillsRate,
})(SkillsRatingModal);
