import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import NewLoginSignupModal from './NewLoginSignupModal';
import Footer from '../Common/ApplicationFooter';

class HomePage extends Component {
    constructor(args) {
        super(args);
        this.state = {
            homePageView: '',
            currentSlider: {
                h7t_employer: 0,
                h7t_consultant: 0,
                h7t_candidate: 0,
            },
            autoplay: true,
        };
        this.handleShowMenu = this.handleShowMenu.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkOffset);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.checkOffset);
    }

    checkOffset() {
        if (document.getElementById('h7t_header_opacity')) {
            // const scrollbar = document.body.scrollTop - window.innerHeight;
            if (document.scrollingElement.scrollTop > 60) {
                document.getElementById('h7t_header_opacity').setAttribute('style', 'background-color: black; opacity: 0.8;');
            } else {
                document.getElementById('h7t_header_opacity').setAttribute('style', 'background-color: transparent;');
            }
        }
    }

    handleShowMenu() {
        const toggle = document.getElementById('h7t_menu_bar').style.display;
        const ele = $('#h7t_header_opacity');
        if (ele.hasClass('h7t-header-opacity')) {
            ele.removeClass('h7t-header-opacity');
        } else {
            ele.addClass('h7t-header-opacity');
        }

        document.getElementById('h7t_menu_bar').style.display = (toggle === 'none') ? 'block' : 'none';
    }

    handleShowModal(data) {
        this.setState({ homePageView: data });
    }

    goToByScroll(id) {
        $('html,body').animate({ scrollTop: $(`#${id}`).offset().top - 70 }, 'slow');
    }

    handleChangeCarousel(current, id) {
        const { currentSlider } = this.state;
        currentSlider[id] = current;
        this.setState({
            currentSlider,
        });
    }

    renderSliderFeatures() {
        const featureContent = [];
        const features = [
            {
                key: 'h7t_employer',
                id: '01',
                type: 'Employer',
                title: 'Employers',
                featuresHead: [
                    'Reachout',
                    'Role based access',
                    'Manage workflow',
                    'Manage organization',
                    'Job posting',
                ],
                sliders: [
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_reachout_banner.png',
                        title: 'Reachout',
                        description: 'Reaching out to candidate is performed by the recruiter with the intention of pre-screening the candidate against the job requirement.',

                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_rolebased_banner.png',
                        title: 'Role based access',
                        description: 'Role based access is an approach to restricting the access level of authorized users of the organization.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_workflow_banner.png',
                        title: 'Manage workflow',
                        description: 'A workflow system provides an infrastructure for the setup, performance and monitoring of a defined sequence of tasks, arranged as a workflow application.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_manage_organisation_banner.jpg',
                        title: 'Manage organization',
                        description: 'The process of organizing, planning, leading and controlling resources within an entity with the overall aim of achieving its objectives. The organizational management of a business needs to be able to make decisions and resolve issues in order to be both effective and beneficial.',

                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_jobposting_banner.jpg',
                        title: 'Job posting',
                        description: 'A job posting is an advertisement created by an employer, his administrative or human resources staff, or a recruiter that alerts current employees or the public of an immediate or future job opening within a company.',
                    },
                ],
            },
            {
                key: 'h7t_consultant',
                id: '02',
                type: 'Consultant',
                title: 'Consultants',
                featuresHead: [
                    'Reachout',
                    'Role based access',
                    'Manage workflow',
                    'Manage organization',
                    'Job posting',
                ],
                sliders: [
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_reachout_banner.png',
                        title: 'Reachout',
                        description: 'Reaching out to candidate is performed by the recruiter with the intention of pre-screening the candidate against the job requirement.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_rolebased_banner.png',
                        title: 'Role based access',
                        description: 'Role based access is an approach to restricting the access level of authorized users of the organization.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_workflow_banner.png',
                        title: 'Manage workflow',
                        description: 'A workflow system provides an infrastructure for the setup, performance and monitoring of a defined sequence of tasks, arranged as a workflow application.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_manage_organisation_banner.jpg',
                        title: 'Manage organization',
                        description: 'The process of organizing, planning, leading and controlling resources within an entity with the overall aim of achieving its objectives. The organizational management of a business needs to be able to make decisions and resolve issues in order to be both effective and beneficial.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_jobposting_banner.jpg',
                        title: 'Job posting',
                        description: 'A job posting is an advertisement created by an employer, his administrative or human resources staff, or a recruiter that alerts current employees or the public of an immediate or future job opening within a company.',
                    },
                ],
            },
            {
                key: 'h7t_candidate',
                id: '03',
                type: 'Job Seeker',
                title: 'Job Seekers',
                featuresHead: [
                    'Profile',
                    'Create resume',
                    'My applications',
                    'Recommended jobs',
                ],
                sliders: [
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_exe_banner.jpg',
                        title: 'Profile',
                        description: 'The profile is a record of your personal information that defines who you are. The record includes your name, location, education, job title and affiliation.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_pro_banner.jpg',
                        title: 'Create resume',
                        description: 'A resume is a document used by a person to present their backgrounds and skills. Hiringnxt help you to create your own resume with the details you want to show to the employer.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_comp_banner.jpg',
                        title: 'My applications',
                        description: 'My application listing all the applied jobs and their status to review by the candidate itself.',
                    },
                    {
                        src: 'https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_build_banner.jpg',
                        title: 'Recommended jobs',
                        description: 'Recommended jobs help us to filter the matching jobs from thousands of open jobs. Hiringnxt provides you to apply for a job with selected resumes from your resume library.',
                    },
                ],
            },
        ];

        const { currentSlider, autoplay } = this.state;
        features.map((section) => {
            featureContent.push(
                <div key={section.id} className="w3-content" style={{ maxWidth: 1500 }}>
                    <div id={section.key} className="w3-row-padding">
                        <div className="w3-col m3 s2">
                            <div className="h7t-section-header w3-padding-64">
                                <h3 className="h7t-section-title">{section.id}</h3>
                                <ul className="w3-hide-small">
                                    <li className={`h7t-cursor-pointer ${section.id === '01' ? 'active' : ''}`} onClick={() => this.goToByScroll('h7t_employer')}>{section.id === '01' ? 'EMPLOYERS' : '' }</li>
                                    <li className={`h7t-cursor-pointer ${section.id === '02' ? 'active' : ''}`} onClick={() => this.goToByScroll('h7t_consultant')}>{section.id === '02' ? 'CONSULTANTS' : '' }</li>
                                    <li className={`h7t-cursor-pointer ${section.id === '03' ? 'active' : ''}`} onClick={() => this.goToByScroll('h7t_candidate')}>{section.id === '03' ? 'JOB SEEKERS' : '' }</li>
                                    <li className="h7t-cursor-pointer" onClick={() => this.goToByScroll('h7t_pricing')} />
                                </ul>
                            </div>
                        </div>
                        <div className="w3-col m9 s10">
                            <div className="w3-row-padding">
                                <div className="h7t-section-header w3-padding-64">
                                    <h3 className="h7t-section-title h7t-underline">{section.title}</h3>
                                </div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m6 h7t-margin-bottom-40">
                                    <ul className="h7t-feature-list-menu">
                                        {section.featuresHead.map((list, idx) => <li key={`${list}_${idx}`} className={`${currentSlider[section.key] === idx ? 'h7t-list-underline' : ''}`}>{list}</li>)}
                                    </ul>
                                </div>
                                <div className="w3-col m6">
                                    <button type="button" className="w3-button h7t-register-button w3-margin-bottom" onClick={() => this.handleShowModal(`${section.id === '03' ? 'signup' : 'employer'}`)}>
                                        {`Register as ${section.type}`}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-row-padding">
                        <Carousel autoplay={autoplay} afterChange={e => this.handleChangeCarousel(e, section.key)}>
                            {section.sliders.map((image, key) => (
                                <div key={key} className="h7t-slider-container">
                                    <img className="w3-image" style={{ minWidth: '100%' }} src={image.src} alt="slider" />
                                    <div className="h7t-slider-text">
                                        <h2 className="h7t-underline">{image.title}</h2>
                                        <p>{image.description}</p>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>,
            );
        });
        return featureContent;
    }

    renderPricingSchemes() {
        const freeForLife = {
            title: 'Free',
            info: [
                '10 Job Postings',
                '5 Collaborators',
                '3 Office',
                '3 Worflows',
                'Widget Integration to websites',
                'Custom Sub domain',
            ],
        };

        const tierOne = {
            title: '$10 per month',
            info: [
                '20 Job Postings',
                '10 Collaborators',
                '10 offices',
                '50 Worflows',
                'Widget Integration to websites',
                'Custom Sub domain',
            ],
        };

        const tierTwo = {
            title: '$50 per month',
            info: [
                'Unlimitted Job Postings',
                'Unlimitted Collaborators',
                'Unlimitted Offices',
                'Unlimitted Worflows',
                'Widget Integration to websites',
                'Custom Sub domain',
            ],
        };

        return (
            <div className="h7t-pricing-wrapper">
                <div className="w3-content" id="h7t_pricing" style={{ maxWidth: 1500 }}>
                    <div className="w3-row w3-padding-small">
                        <div className="w3-col m3 s2">
                            <div className="h7t-section-header w3-padding-64">
                                <h3 className="h7t-section-title">04</h3>
                                <ul className="w3-hide-small">
                                    <li className="h7t-cursor-pointer" onClick={() => this.goToByScroll('h7t_employer')} />
                                    <li className="h7t-cursor-pointer" onClick={() => this.goToByScroll('h7t_consultant')} />
                                    <li className="h7t-cursor-pointer" onClick={() => this.goToByScroll('h7t_candidate')} />
                                    <li className="h7t-cursor-pointer active" onClick={() => this.goToByScroll('h7t_pricing')}>PRICING & PLANS</li>
                                </ul>
                            </div>
                        </div>
                        <div className="w3-col m9 s10">
                            <div className="w3-row-padding">
                                <div className="h7t-section-header w3-padding-64">
                                    <h3 className="h7t-section-title h7t-underline">Pricing & Plans</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-row h7t-flex-container">
                        <div className="w3-col m10">
                            <div className="h7t-pricing-tables w3-center w3-row-padding">
                                <div className="h7t-item-container w3-col m4 s12">
                                    <div className="h7t-pricing-item h7t-small-item">
                                        <div className="h7t-item-top">
                                            <div className="h7t-item-top-bg" />
                                            <div className="h7t-item-top-content">
                                                <div className="h7t-sub-title">Small Business</div>
                                                <h3 className="h7t-title w3-margin-bottom"> Simple</h3>
                                                <div className="h7t-price">{freeForLife.title}</div>
                                            </div>
                                        </div>
                                        <div className="h7t-item-bottom">
                                            <ul>
                                                {freeForLife.info.map(txt => <li key={`${txt}`}>{txt}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="h7t-item-container w3-col m4 s12">
                                    <div className="h7t-pricing-item h7t-featured-item ">
                                        <div className="h7t-item-top">
                                            <div className="h7t-item-top-bg" />
                                            <div className="h7t-item-top-content">
                                                <div className="h7t-star">
                                                    <i className="ion-android-star" />
                                                    <i className="ion-android-star" />
                                                    <i className="ion-android-star" />
                                                </div>
                                                <div className="h7t-sub-title">Medium Business</div>
                                                <h3 className="h7t-title w3-margin-bottom"> Premium</h3>
                                                <div className="h7t-price">{tierOne.title}</div>
                                            </div>
                                        </div>
                                        <div className="h7t-item-bottom">
                                            <ul>
                                                {tierOne.info.map(txt => <li key={`${txt}`}>{txt}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="h7t-item-container w3-col m4 s12">
                                    <div className="h7t-pricing-item h7t-large-item">
                                        <div className="h7t-item-top">
                                            <div className="h7t-item-top-bg" />
                                            <div className="h7t-item-top-content">
                                                <div className="h7t-sub-title">Large Business</div>
                                                <h3 className="h7t-title w3-margin-bottom"> Advanced</h3>
                                                <div className="h7t-price">{tierTwo.title}</div>
                                            </div>
                                        </div>
                                        <div className="h7t-item-bottom">
                                            <ul>
                                                {tierTwo.info.map(txt => <li key={`${txt}`}>{txt}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { homePageView } = this.state;
        return (
            <div className="w3-row h7t-homepage">
                <div className="h7t-banner">
                    <div id="h7t_header_opacity" className="w3-top h7t-header-opacity">
                        <div className="w3-bar w3-padding w3-text-white h7t-header w3-content" style={{ maxWidth: 1500 }}>
                            <div className="h7t-logo-container">
                                <a className="w3-bar-item" href="/">
                                    <img className="h7t-header-logo" src="https://assets.vegasonweb.com/hiring/logos/hiring_nxt_logo.png" alt="hiring_nxt_logo" />
                                </a>
                            </div>
                            <div className="w3-right w3-hide-small">
                                <Link className="w3-bar-item h7t-menu-style" to="/home">JOBS</Link>
                                <a className="w3-bar-item h7t-menu-style" onClick={() => this.goToByScroll('h7t_employer')}>EMPLOYERS</a>
                                <a className="w3-bar-item h7t-menu-style" onClick={() => this.goToByScroll('h7t_candidate')}>JOB SEEKERS</a>
                                <a className="w3-bar-item h7t-menu-style" onClick={() => this.handleShowModal('login')}>LOGIN</a>
                                <a className="w3-bar-item h7t-menu-style" onClick={() => this.handleShowModal('signup')}>REGISTER</a>
                            </div>
                            <a
                                className="w3-bar-item w3-right w3-hide-large w3-hide-medium"
                                onClick={this.handleShowMenu}
                                style={{ textDecoration: 'none' }}
                            >&#9776;
                            </a>
                        </div>
                        <div id="h7t_menu_bar" className="w3-bar-block w3-text-white w3-border-bottom w3-animate-left w3-hide-large w3-hide-medium">
                            <ul className="h7t-menu-bar-content">
                                <li className="h7t-menu-bar-item">
                                    <Link className="w3-bar-item w3-button" to="/home">JOBS</Link>
                                </li>
                                <li className="h7t-menu-bar-item">
                                    <a className="w3-bar-item w3-button" onClick={() => this.goToByScroll('h7t_employer')}>EMPLOYERS</a>
                                </li>
                                <li className="h7t-menu-bar-item">
                                    <a className="w3-bar-item w3-button" onClick={() => this.goToByScroll('h7t_candidate')}>JOB SEEKERS</a>
                                </li>
                                <li className="h7t-menu-bar-item">
                                    <a className="w3-bar-item w3-button" onClick={() => this.handleShowModal('login')}>LOGIN</a>
                                </li>
                                <li className="h7t-menu-bar-item">
                                    <a className="w3-bar-item w3-button" onClick={() => this.handleShowModal('signup')}>SIGN UP</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                     
                    {homePageView ? (
                        <NewLoginSignupModal
                            homePageView={homePageView}
                            onModalClose={() => this.setState({ homePageView: '' })}
                        />
                    ) : null }
                    <div id="h7t-inside" className="w3-content">
                        <div className="h7t-banner-inside">
                            <h1 className="h7t-banner-title">HIRING NXT</h1>
                            <div className="h7t-banner-sub-title">
                                a comprehensive career platform
                            </div>
                            <div className="h7t-read-more">
                                Read more
                                <span className="h7t-rightward-arrow">&#8594;</span>
                            </div>
                        </div>

                        <div className="h7t-scroll-down">
                            <img
                                className="h7t-scroll-down-icon h7t-cursor-pointer"
                                onClick={() => this.goToByScroll('h7t_employer')}
                                src="https://assets.vegasonweb.com/hiring/home_page/hiring_nxt_scrolldown.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>

                {this.renderSliderFeatures()}
                {this.renderPricingSchemes()}
                <Footer />
            </div>
        );
    }
}

export default HomePage;
