/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, FormHelperText } from '@material-ui/core';
import Modal from '../Common/Modal';
import { ErrorHelperText } from '../Common/HelperComponents';
import {
    postNotifyCandidate,
    setAppMessage,
    getScheduleWorkflowList,
    getJobApplnFeedback,
    getApplicationStatusById,
} from '../../js/actions';
import { SearchDropdown } from '../Common/MaterialUI/HelperInputs';

class NotifyCandidate extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        postNotifyCandidate: PropTypes.func,
        setAppMessage: PropTypes.func,
        scheduleWorkflowList: PropTypes.object,
        applicationFeedback: PropTypes.object,
        getScheduleWorkflowList: PropTypes.func,
        getApplicationStatusById: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            mailContent: {
                content: [],
                selected_Rounds: [],
            },
            errors: {},
            disableBtn: false,
        };

        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleSendMail = this.handleSendMail.bind(this);
        this.onSendMail = this.onSendMail.bind(this);
        this.hiredOrRejectedMessage = this.hiredOrRejectedMessage.bind(this);
    }

    componentDidMount() {
        const { application_Id } = this.props;
        getScheduleWorkflowList(application_Id);
        getJobApplnFeedback(application_Id);
        this.hiredOrRejectedMessage();
    }

    onSendMail(content) {
        const {
            postNotifyCandidate,
            setAppMessage,
            onModalClose,
            application_Id,
            getScheduleWorkflowList,
            getApplicationStatusById,
            applicant_Hired,
            applicant_Rejected,
        } = this.props;
        const { mailContent } = this.state;

        content.application_id = application_Id;
        content.notifiedStates = mailContent.selected_Rounds;
        if (applicant_Hired || applicant_Rejected) {
            content.hiredOrRejected = true;
        }
        postNotifyCandidate(content).then(() => {
            getScheduleWorkflowList(application_Id);
            getApplicationStatusById(application_Id);
            onModalClose();
            setAppMessage('success', 'Mail send successfully!');
        });
    }

    hiredOrRejectedMessage() {
        const {
            applicant_Hired,
            applicant_Rejected,
            candidate_Name,
            hr_Name,
            applied_Post,
            office_Name,
        } = this.props;
        const { mailContent } = this.state;
        if (applicant_Hired) {
            mailContent.content += `<p>Hi ${candidate_Name},</p><br /><p>Congratulations on being hired for ${applied_Post} at ${office_Name}. 
                We are delighted for your success and we wish you the very best in new your new role. 
                We will be in touch with you soon to move forward with the hiring formalities.</p><br /><p>Regards</p><p>${hr_Name}</p>`;
        }
        if (applicant_Rejected) {
            mailContent.content += `<p>Hi ${candidate_Name},</p><br /><p>Thank you for your recent application for the position of ${applied_Post}.<p/>
                <br /><p>Unfortunately I regret to inform you that we will not be able to advance your application forward any further at this time. 
                However we would like to keep your resume for any further openings with your permission.</p>
                <p>If there are other roles you have applied for at ${office_Name}, we will get in touch with you.</p><br />
                <p>Regards</p><p>${hr_Name}</p>`
        }
        this.setState({
            mailContent,
        });
    }

    handleChangeContent(content) {
        const { mailContent, errors } = this.state;
        mailContent.content = (content == '<p><br></p>') ? '' : content;
        if (errors.content) {
            errors.content = null;
        }
        this.setState({ mailContent, errors });
    }

    handleSendMail() {
        const { mailContent, errors } = this.state;
        const { applicant_Hired, applicant_Rejected } = this.props;
        let hasError = false;
        if (mailContent.content.length === 0) {
            errors.content = 'This field is mandatory';
            hasError = true;
        }
        if (mailContent.selected_Rounds && mailContent.selected_Rounds.length === 0) {
            if (applicant_Hired || applicant_Rejected) {
                hasError = false;
            } else {
                errors.selected_Rounds = 'This field is mandatory';
                hasError = true;
            }
        }
        if (!hasError) {
            this.setState({
                disableBtn: true,
            });
            this.onSendMail(mailContent);
        } else {
            this.setState({
                errors,
            });
        }
    }

    handleChangeSelection(option, label) {
        const { mailContent, errors } = this.state;
        const { candidate_Name, hr_Name, scheduleWorkflowList } = this.props;
        let value = null;
        if (option && Array.isArray(option)) {
            if (option.length) {
                value = [];
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        } else if (option) {
            value = option.val;
        }
        mailContent[label] = value;
        if (errors[label]) {
            errors[label] = null;
        }
        if (label === 'selected_Rounds' && mailContent.selected_Rounds) {
            mailContent.selected_Rounds.slice(0);
        }

        mailContent.content = `Hi ${candidate_Name},<br />`;
        if (mailContent.selected_Rounds && mailContent.selected_Rounds.length > 0) {
            scheduleWorkflowList.data.map((item) => {
                if (mailContent.selected_Rounds.indexOf(item.id) !== -1) {
                    mailContent.content += `<br /><p>${item.schedule_Round_Name} is scheduled on ${moment(item.action_date).format('MMM Do YYYY')} at ${moment(item.action_time).format('hh:mm')} for the post ${item.job_Name} from ${item.office_Name}</p>`;
                }
            });
        }
        mailContent.content += '<br /><p>Regards</p>';
        mailContent.content += `<p>${hr_Name}</p>`;

        this.setState({
            mailContent,
            errors,
        });
    }

    render() {
        const {
            onModalClose,
            scheduleWorkflowList,
            applicationFeedback,
            applicant_Hired,
            applicant_Rejected
        } = this.props;
        const {
            mailContent,
            errors,
            disableBtn,
        } = this.state;
        const schedList = scheduleWorkflowList.data.filter(schedule => !applicationFeedback.data.find(item => item.workflow_start_state === schedule.state_id));
        const scheduleRoundOptions = [];
        if (schedList) {
            schedList.map((list_items) => {
                if (list_items.display_order > 1) {
                    scheduleRoundOptions.push(
                        { val: list_items.id, name: list_items.schedule_Round_Name, notified: list_items.notified_at },
                    );
                }
            });
        }
        const condition = applicant_Hired || applicant_Rejected;
        return (
            <div>
                <Modal modalCls="w3-show" onClose={onModalClose} widthCls="chp-small-dlg">
                    <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">Notify Candidate</h4>
                    <div>
                        {!condition
                            ? (
                                <SearchDropdown
                                    required
                                    multiple
                                    label="Select rounds"
                                    options={scheduleRoundOptions}
                                    errors={errors.selected_Rounds}
                                    disableActive
                                    value={mailContent.selected_Rounds ? mailContent.selected_Rounds : undefined}
                                    onChange={(evt, val) => {
                                        this.handleChangeSelection(val, 'selected_Rounds');
                                    }}
                                    id="selected_Round"
                                />
                            ) : null}
                        <div className="w3-margin-top w3-panel">
                            <ReactQuill
                                defaultValue={mailContent.content ? mailContent.content : ''}
                                placeholder="Enter the content here..."
                                onChange={this.handleChangeContent}
                                style={{ height: '100px', marginBottom: '50px' }}
                                value={mailContent.content ? mailContent.content : ''}
                            />
                            <FormHelperText error={errors.content} className="w3-margin-left">{errors.content}</FormHelperText>
                            <p><i className="ion-information-circled ion-icon " /> This will send an e-mail to candidate.</p>
                            <Button
                                variant="contained"
                                disabled={disableBtn}
                                className={`${disableBtn ? 'w3-disabled' : ''} h7t-primary-btn`}
                                onClick={this.handleSendMail}
                            >
                                {disableBtn ? 'Sending...' : 'Send'}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const scheduleWorkflowList = state.scheduleWorkflowList;
    const applicationFeedback = state.applicationFeedback;
    return {
        scheduleWorkflowList,
        applicationFeedback,
    };
}
export default connect(mapStateToProps, {
    postNotifyCandidate,
    setAppMessage,
    getScheduleWorkflowList,
    getJobApplnFeedback,
    getApplicationStatusById,
})(NotifyCandidate);
