import React, {Fragment} from 'react';
import Footer from './ApplicationFooter';
import Header from './Header';

class TermsOfService extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="w3-top">
                    <Header />
                </div>
            <div  className="w3-row h7t-homepage">
                <h5 className="w3-container w3-content margin-top-100 margin-bottom-100">
                    <b>Terms Of Service</b><br></br><br></br>
                    The site is a public site with free access for candidates and Hiring NXT assumes no liability for the quality
                    and
                    genuineness of responses. Hiring NXT cannot monitor the responses that a person may receive in response to
                    information he/she has displayed on the site. The individual/company would have to conduct their own background
                    checks on the bonafide nature of all response(s).<br></br><br></br>
                    Hiring NXT will not be liable on account of any inaccuracy of information on this web site. It is the
                    responsibility
                    of the visitor to further research the information on the site. Any breach of privacy or of the information
                    provided
                    by the consumer to Hiring NXT to be placed on the website by technical or any other means is not the
                    responsibility
                    of Hiring NXT.<br></br><br></br>
                    Hiring NXT does not share personally identifiable data of any individual with other companies / entities without
                    obtaining permission except with those acting as our agents. Hiring NXT shall share all such information that it
                    has
                    in its possession in response to legal process, such as a court order or subpoena. The user shall not utilize
                    the
                    services offered by hiringnxt.com in any manner so as to impair the interests and functioning of hiringnxt.com.
                    The
                    user undertakes not to duplicate, download publish, modify and distribute material on hiringnxt.com unless
                    specifically authorized by Hiring NXT in this regard.<br></br><br></br>
                    The user undertakes to use hiringnxt.com for his/her own purposes. Using content from hiringnxt.com for
                    derivative
                    works with a commercial motive without prior written consent from Hiring NXT is strictly
                    prohibited.<br></br><br></br>
                    hiringnxt.com uses technological means to exclude Robots etc from crawling the website and scraping content. The
                    user undertakes not to circumvent these methods.<br></br><br></br>
                    Users undertake that the services offered by hiringnxt.com shall not be utilized to upload, post, email,
                    transmit or
                    otherwise make available either directly or indirectly, any unsolicited bulk e-mail or unsolicited commercial
                    e-mail. Hiring NXT reserves the right to filter and monitor and block the emails sent by you/user using the
                    servers
                    maintained by Hiring NXT to relay emails. All attempts shall be made by Hiring NXT and the user to abide by
                    International Best Practices in containing and eliminating Spam.<br></br><br></br>
                    Users shall not spam the database maintained by hiringnxt.com or indiscriminately and repeatedly forward mail
                    that
                    may be considered spam etc. etc. Any conduct of the user in violation of this clause shall entitle Hiring NXT to
                    forthwith terminate all services to the user without notice and to forfeit any amounts paid by
                    him.<br></br><br></br>
                    The user shall not upload, post, transmit, publish, or distribute any material or information that is unlawful,
                    or
                    which may potentially be perceived as being harmful, threatening, abusive, harassing, defamatory, libelous,
                    vulgar,
                    obscene, or racially, ethnically, or otherwise objectionable.<br></br><br></br>
                    The user expressly states that the resume/insertion or information/ data being fed into the network of Hiring
                    NXT by
                    the user is correct and complete in all respects and does not contain any false, distorted, manipulated,
                    fraudulent
                    or misleading facts or averments. Hiring NXT expressly disclaims any liability arising out of the said resume
                    insertion/information/ data so fed into the network of Hiring NXT by the user. Further, the user agrees to
                    indemnify
                    Hiring NXT for all losses incurred by Hiring NXT due to any false, distorted, manipulated, defamatory, libelous,
                    vulgar, obscene, fraudulent or misleading facts or otherwise objectionable averments made by the user on the
                    network
                    of Hiring NXT.<br></br><br></br>
                    The User is solely responsible for maintaining confidentiality of the User password and user identification and
                    all
                    activities and transmission performed by the User through his user identification and shall be solely
                    responsible
                    for carrying out any online or off-line transaction involving credit cards / debit cards or such other forms of
                    instruments or documents for making such transactions and Hiring NXT assumes no responsibility or liability for
                    their improper use of information relating to such usage of credit cards / debit cards used by the subscriber
                    online
                    / off-line.<br></br><br></br>
                    The User/Subscriber/Visitor to hiringnxt.com and/or its affiliated websites does hereby specifically agree that
                    he/she shall, at all times, comply with the requirements of the Information Technology Act, 2000 as also rules,
                    regulations, guidelines, bye laws and notifications made thereunder, while assessing or feeding any resume/
                    insertion or information/data into the computers, computer systems or computer network of Hiring NXT. The said
                    User/
                    subscriber/ visitor to hiringnxt.com and/or its affiliated websites does further unequivocally declare that in
                    case
                    he violates any provisions of the Information Technology Act, 2000 and/or rules, regulations, guidelines,
                    byelaws
                    and notifications made thereunder, he shall alone be responsible for all his acts, deeds and things and that he
                    alone shall be liable for civil and criminal liability there under or under any other law for the time being in
                    force.<br></br><br></br>
                    The User is solely responsible for obtaining, at his own cost, all licenses, permits, consents, approvals and
                    intellectual property or other rights as may be required by the user for using the Service.<br></br><br></br>
                    The User / subscriber / visitor to hiringnxt.com or affiliated site(s) are prohibited from uploading, posting,
                    transmitting, updating or sharing: any information that:<br></br><br></br>
                    belongs to another person and to which the user does not have any right to;<br></br><br></br>
                    is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of
                    another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner whatever; harm minors in any way;<br></br><br></br>
                    infringes any patent, trademark, copyright or other proprietary rights;<br></br><br></br>
                    violates any law for the time being in force;<br></br><br></br>
                    deceives or misleads the addressee about the origin of such messages or communicates any information which is
                    grossly offensive or menacing in nature; impersonate another person;<br></br><br></br>
                    contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit
                    the
                    functionality of any computer resource; threatens the unity, integrity, defense, security or sovereignty of
                    India,
                    friendly relations with foreign states, or or public order or causes incitement to the commission of any
                    cognizable
                    offence or prevents investigation of any offence or is insulting any other nation.<br></br><br></br>
                    The user shall not infringe on any intellectual property rights of any person / entity or retain information /
                    download any information from any computer system or otherwise with an intention to do so.<br></br><br></br>
                    Hiring NXT will make best efforts to do so but does not warrant that any of the web sites or any affiliate
                    site(s)
                    or network system linked to it is free of any operational errors nor does it warrant that it will be free of any
                    virus, computer contaminant, worm, or other harmful components. The subscription of a user shall be subject to
                    Quotas as applicable and as advised. E-Mails provided as part of contact details are expected to be genuine and
                    access to such email accounts is available to authorized personnel only.<br></br><br></br>
                    Hiring NXT shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or
                    otherwise) of any information concerning the user's account and / or information relating to or regarding online
                    transactions using credit cards / debit cards and / or their verification process and particulars nor for any
                    error,
                    omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance of a
                    legal
                    process or otherwise.<br></br><br></br>
                    Payments for the services offered by hiringnxt.com shall be on a 100% advance basis. Refund if any will be at
                    the
                    sole discretion of Hiring NXT. Hiring NXT offers no guarantees whatsoever for the accuracy or timeliness of the
                    refunds reaching the Customers card/bank accounts. Hiring NXT gives no guarantees of server uptime or
                    applications
                    working properly. All is on a best effort basis and liability is limited to refund of amount only. Hiring NXT
                    undertakes no liability for free services. Hiring NXT reserves its right to amend / alter or change all or any
                    disclaimers or terms of agreements at any time without any prior notice. All terms / disclaimers whether
                    specifically mentioned or not shall be deemed to be included if any reference is made to them.<br></br><br></br>
                    Hiring NXT further reserves its right to post the data on the website hiringnxt.com or on such other affiliated
                    sites and publications as Hiring NXT may deem fit and proper at no extra cost to the subscriber /
                    user.<br></br><br></br>
                    The subscription / agreement between Hiring NXT and the subscriber / user is not a "non-poach agreement" nor can
                    the
                    same be termed or used as an alternative to "non-poach agreement" in as much as Hiring NXT / hiringnxt.com is a
                    public site and all information posted by Hiring NXT goes to the public domain except information / data which
                    is
                    specifically assigned a non-public / private character.<br></br><br></br>
                    Any agreement for a subscription / usage entered into Hiring NXT does not confer exclusivity of service on any
                    subscriber / user.<br></br><br></br>
                    Hiring NXT will not be party to any legal proceedings between a user (e.g. a subscriber) and a party contracted
                    through the site. In case Hiring NXT is implicated in any legal proceedings, costs will be recovered from the
                    party
                    that names Hiring NXT. Hiring NXT however will abide with any court order served on it through due
                    process.<br></br><br></br>
                    In case a person using the world wide web/internet receives a spam or virus which includes a link to
                    hiringnxt.com
                    or to any other site maintained, operated or owned by Hiring NXT, it should not be held responsible for the
                    same.
                    Hiring NXT assumes no responsibility for such mails.<br></br><br></br>
                    The services provided by the websites maintained, operated or owned by Hiring NXT do not extend to acting as an
                    agent (express or implied) on behalf of any subscriber or user.
                    Hiring NXT has no agents and does not operate through any agents save for those specifically mentioned on the
                    home
                    page of the website.<br></br><br></br>
                    The Terms and conditions mentioned above regulate the usage of hiringnxt.com. Any person using hiringnxt.com in
                    violation of the stipulations contained in the Terms and Conditions of hiringnxt.com shall render
                    himself/herself
                    liable to appropriate action in a court of law both civil and criminal.<br></br><br></br>
                    The user of hiringnxt.com is subject to the <a className="h7t-name-hover" href="/privacy-policy">Privacy Policy</a> available through this link.<br></br><br></br>
                    In case of non compliance of these Terms and Conditions or Privacy Policy, Hiring NXT may terminate usage rights
                    and
                    take down any offending information that might have been uploaded by such subscriber/user<br></br><br></br>
                    "We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or
                    indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded
                    the
                    preset limit mutually agreed by us with our acquiring bank from time to time."<br></br><br></br>
            
            
            
                    Privacy
            
                </h5>  
                <Footer />
            </div>
            </Fragment>
        );
    }
}

export default TermsOfService;