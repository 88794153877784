import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormHelperText, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Modal from '../../Common/Modal';
import {
    postUserProjectCreate, postUserProjectEdit, getCurrentDetailsList, getProjectList, getCurrentUser, setAppMessage,
} from '../../../js/actions';
import { InputTextField, InputSelectField, CheckboxMUI } from '../../Common/MaterialUI/HelperInputs';

class SeekerProjects extends Component {
    static propTypes = {
        projects: PropTypes.array,
        projectError: PropTypes.object,
        removeProject: PropTypes.func,
        getCurrentDetailsList: PropTypes.func,
        type: PropTypes.string,
        postUserProjectEdit: PropTypes.func,
        postUserProjectCreate: PropTypes.func,
    }

    constructor(args) {
        super(args);
        this.state = {
            compList: [],
            checkProject: false,
            project: args.project,
            projectError: {},
        };
        this.handleChangeProject = this.handleChangeProject.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.handleCurrentWorking = this.handleCurrentWorking.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProject = this.handleProject.bind(this);
    }

    componentDidMount() {
        this.props.getCurrentDetailsList().then((resp) => {
            const options = [
                { val: 'freelance', name: 'Freelance' },
                { val: 'personal', name: 'Personal' },
                { val: 'other', name: 'Other' },
            ];
            if (resp && resp.data && resp.data.data) {
                const filterCompany = resp.data.data.filter(item => item.type === 'Experience');
                filterCompany.map((item) => {
                    options.push({ val: item.institution, name: item.institution });
                });
            }
            this.setState({
                compList: options,
            });
        });
    }

    handleProject(evt) {
        const { value } = evt.target;
        const { project, projectError } = this.state;
        project.company = value;

        if (projectError.compList) {
            projectError.compList = null;
        }
        this.setState({
            project,
            projectError,
        });
    }

    handleChangeProject(evt) {
        const targetElement = $(evt.target).attr('id');
        const { project, projectError } = this.state;

        if (projectError[targetElement]) {
            projectError[targetElement] = null;
        }

        project[targetElement] = $(evt.target).val();
        // this.props.handleChangeProject(project, projectError);
        this.setState({
            project, projectError,
        });
    }

    handleDatePicker(evt, date, id) {
        const { project, projectError } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');
        const selectedDate = new Date(formatedDate).getTime();
        const currentDate = new Date().getTime();

        if (projectError[id]) {
            projectError[id] = null;
        }
        if (!(selectedDate < currentDate)) {
            projectError[id] = 'Date should not be greater than Today';
            this.setState({ projectError });
            return;
        }
        project[id] = formatedDate;
        this.setState({ project, projectError });
    }

    updateResponse(response, successMessage) {
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getCurrentDetailsList();
            this.props.getProjectList();
            this.props.getCurrentUser();
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                errors: response.data,
            });
        }
    }

    handleResponsibilities(content) {
        const { project } = this.state;
        project.responsibilities = (content == '<p><br></p>') ? '' : content;
        // this.props.handleChangeProject(project, projectError);
    }

    handleCurrentWorking(e) {
        const { projectError, project } = this.state;
        project.date_to = null;
        project.checkProject = e.target.checked;
        if (projectError.date_to) {
            projectError.date_to = null;
        }
        if (e.target.checked) {
            project.date_to = '';
        }

        this.setState({
            projectError,
            project,
        });
    }

    validateAddProjects() {
        const { project, errors, checkProject } = this.state;
        let hasErrors = false;
        const ignoreList = [
            'date_to',
            'date_from',
            'company',
            'url',
            'responsibilities',
        ];
        if (checkProject) {
            ignoreList.push('date_to');
        }

        Object.keys(project).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (project[key]) === 'string') {
                    if (!project[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!project[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });

        const validProjects = this.validateProjects(project);
        if (hasErrors || !validProjects) {
            this.setState({ errors });
            return false;
        }
        return project;
    }

    validateProjects() {
        const { project } = this.state;
        let hasErrors = false;
        const projectError = {};
        if (Object.keys(project).length) {
            if (!project.title) {
                hasErrors = true;
                projectError.title = 'Please fill this field';
            }
            if (project.url) {
                const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                const regex = new RegExp(urlRegex);
                if (!project.url.match(regex)) {
                    hasErrors = true;
                    projectError.url = 'Url with http://, https:// and www is accepted';
                }
            }
            if (project.date_from && new Date(project.date_from) > new Date()) {
                hasErrors = true;
                projectError.date_from = 'This date must be less than today\'s';
            }
            if (project.date_to && project.date_to !== '0000-00-00' && (project.date_from >= project.date_to)) {
                hasErrors = true;
                projectError.date_to = 'This date must be a date after date from.';
            }
            if (project.date_to && project.date_to !== '0000-00-00' && (!project.date_from || project.date_from === '0000-00-00')) {
                hasErrors = true;
                projectError.date_from = 'Please select a start date';
            }
        }
        if (hasErrors) {
            this.setState({
                projectError,
                project,
            });
            return false;
        }
        return project;
    }

    handleSubmit(e) {
        e.preventDefault();
        const validData = this.validateProjects();
        if (!validData) {
            return;
        }
        if (!validData.date_to) {
            validData.date_to = null;
        }
        if (!validData.date_from) {
            validData.date_from = null;
        }

        if (validData.id) {
            this.props.postUserProjectEdit(validData).then((response) => {
                if (response) {
                    this.updateResponse(response.data, 'Project edited successfully!');
                }
            });
        } else {
            this.props.postUserProjectCreate(validData).then((response) => {
                if (response) {
                    this.updateResponse(response.data, 'Project added successfully!');
                }
            });
        }
    }

    render() {
        const { onModalClose } = this.props;
        const {
            project, projectError, compList,
        } = this.state;

        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                title={project.id ? `Edit Project` : `Add Project`}
                widthCls="chp-small-dlg" 
            >
                <form className="form-horizontal" noValidate onSubmit={this.handleSubmit}>
                    <div>
                        <div className="w3-row">
                            <div className="w3-col m6">
                                <InputTextField
                                    label="Title"
                                    id="title"
                                    required
                                    value={project.title ? project.title : ''}
                                    errors={projectError && projectError.title}
                                    onChange={e => this.handleChangeProject(e)}
                                    autoFocus
                                />
                            </div>
                            <div className="w3-col m6">
                                <InputTextField
                                    label="URL"
                                    id="url"
                                    placeholder="eg: http://example.com / www.example.com"
                                    value={project.url ? project.url : ''}
                                    errors={projectError && projectError.url}
                                    onChange={e => this.handleChangeProject(e)}
                                />
                            </div>

                            <div className="w3-col">
                                <InputSelectField
                                    label="Company"
                                    name="company"
                                    id="company"
                                    value={project.company ? project.company : ''}
                                    onChange={this.handleProject}
                                    options={compList}
                                />
                            </div>
                        </div>
                        <div className="w3-panel">
                            <CheckboxMUI
                                checked={project.checkProject}
                                onChange={this.handleCurrentWorking}
                                label="Currently working on this project"
                            />
                            <FormHelperText className="w3-margin-left" error={projectError && projectError.currently_work}>{projectError && projectError.currently_work}</FormHelperText>
                        </div>

                        <div className="w3-row w3-panel">
                            <div className="w3-col m6 h7t-padding-right-4">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        inputVariant="outlined"
                                        label="Date From"
                                        format="MMM do, yyyy"
                                        disableFuture={true}
                                        inputProps={{readOnly: true}}
                                        value={project.date_from && project.date_from !== '0000-00-00' ? moment(project.date_from) : null}
                                        onChange={(e, date) => this.handleDatePicker(e, date, 'date_from')}
                                        error={projectError && projectError.date_from}
                                        helperText={projectError && projectError.date_from}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            {!project.checkProject ? (
                                <div className="w3-col m6 h7t-padding-left-4">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            size="small"
                                            inputVariant="outlined"
                                            label="Date To"
                                            format="MMM do, yyyy"
                                            disableFuture={true}
                                            inputProps={{readOnly: true}}
                                            value={project.date_to && project.date_to !== '0000-00-00' ? moment(project.date_to) : null}
                                            onChange={(e, date) => this.handleDatePicker(e, date, 'date_to')}
                                            error={projectError && projectError.date_to}
                                            helperText={projectError && projectError.date_to}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            ) : (
                                <div className="w3-col m6 w3-display-container h7t-margin-20">
                                    <div className="w3-display-middle">To Present</div>
                                </div>
                            )}
                        </div>
                        <div className="w3-panel">
                            <label>Responsibilities</label>
                            <ReactQuill
                                placeholder="Responsibilities, technologies or brief of your project"
                                style={{ height: '100px', marginBottom: '50px' }}
                                value={project.responsibilities ? project.responsibilities : ''}
                                onChange={e => this.handleResponsibilities(e)}
                            />
                        </div>
                    </div>
                    <div className="w3-bar w3-panel w3-left-align">
                        <Button className="w3-margin-right h7t-margin-10 h7t-secondary-btn" onClick={onModalClose}>Close</Button>
                        <Button type="submit" className="h7t-margin-10 h7t-primary-btn">{project.id ? 'Update' : 'Save'}</Button>
                    </div>
                </form>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    return {
        compList: state.compList,
    };
}
export default connect(mapStateToProps, {
    postUserProjectCreate,
    postUserProjectEdit,
    getCurrentDetailsList,
    getProjectList,
    getCurrentUser,
    setAppMessage,
})(SeekerProjects);
