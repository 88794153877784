import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingIcon } from '../../Common/HelperComponents';
import moment from 'moment';
import { Select } from 'antd';
import { Grid, Paper, Button } from '@material-ui/core';
const { Option } = Select;
import {
    postAddBookmark,
    postApplyJob,
    getResumeBlockViews,
    setAppMessage,
    getCurrentUser,
    getRecommendedJobList,
    postSearchJobs,
    getLatestThisWeek,
    postJobViews,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import PreviewResumeModal from '../../Common/PreviewResumeModal';
import JobDescriptionModal from '../../Dashboard/JobDescriptionModal';

const ResumeSelectTemplate = ({
    jobId,
    resumeList,
    onModalClose,
    onModalSuccess,
    selectedJobDetails,
    previewData,
    handleSelectResume,
}) => {
    let temp = null;

    return (
        <Modal modalCls="w3-show" onClose={onModalClose} title="Select Resume to Apply with">
            <div>
                {resumeList.map((resume) => {
                    temp = (
                        <label key={`resume-list-${resume.id}`} htmlFor={`resume-${resume.id}`}>
                            <input
                                className="w3-margin-right w3-margin-left"
                                id={`resume-${resume.id}`}
                                checked={previewData.id === resume.id ? true : false}
                                onClick={() => handleSelectResume(resume)}
                                type="radio"
                                value={resume.id}
                                name="user-resumes"
                            />
                            <span>{resume.title}</span>
                        </label>
                    );
                    return temp;
                })}
                <div className="w3-bar w3-margin-top w3-margin-bottom">
                    <Button
                        variant="contained"
                        className="w3-margin-left h7t-secondary-btn"
                        onClick={onModalClose}
                    >
                        Cancel  
                    </Button>
                    <Button
                        variant="contained"
                        className="h7t-primary-btn w3-margin-right w3-right"
                        onClick={() => onModalSuccess(jobId, previewData.id, selectedJobDetails.company_id, selectedJobDetails.office_id)}
                    >
                        Apply for Job   
                    </Button>
                </div>
            </div>
            <h5 className="w3-margin-left">Selected Resume Preview</h5>
            <div className="w3-row w3-margin w3-padding-large h7t-table-data">
                <PreviewResumeModal previewData={previewData} />
            </div>
        </Modal>
    );
};

ResumeSelectTemplate.propTypes = {
    jobId: PropTypes.string,
    resumeList: PropTypes.object,
    onModalClose: PropTypes.func,
    onModalSuccess: PropTypes.func,
    selectedJobDetails: PropTypes.object,
    previewData: PropTypes.object,
    handleSelectResume: PropTypes.func,
};

class RecommendedJobs extends Component {
    static propTypes = {
        jobs: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            selectedJobId: 0,
            selectedJobDetails: null,
            filterOn: {
                show_bookmark: '',
                locations: [],
                skills: [],
                experience: '',
                show_applied: '',
                show_recommended: '',
                search: '',
                show_all_jobs:'',
            },

            previewData: null,
            jobDescription: '',
            bookmarkIds: [],
        };

        this.handleJobApply = this.handleJobApply.bind(this);
        this.triggerResumeSelect = this.triggerResumeSelect.bind(this);
        this.handleSelectResume = this.handleSelectResume.bind(this);
        this.handleBookmarkJob = this.handleBookmarkJob.bind(this);
        this.handleJDModal = this.handleJDModal.bind(this);
    }

    componentDidMount() {
        this.props.getResumeBlockViews();
        this.props.getCurrentUser().then((resp) => {
            const response = resp.data.data;
            if (response && response.profile && response.profile.bookmark_ids) {
                const bookmarkIds = response.profile.bookmark_ids.split(',')
                    .map((x) => {
                        return parseInt(x)
                    });
                this.setState({
                    bookmarkIds,
                });
            }
        });
    }

    triggerResumeSelect(selectedJob) {
        const { resumeBlockViews } = this.props;

        if (!resumeBlockViews || !resumeBlockViews.data || !resumeBlockViews.data.length) {
            this.props.setAppMessage('fail', 'Please add profile & resume to apply.');
        } else {
            this.setState({
                selectedJobId: selectedJob.id,
                selectedJobDetails: selectedJob,
                previewData: resumeBlockViews.data[0],
            });
        }
    }
    handleJDModal(selectedJD) {
        const { postJobViews } = this.props;
        this.setState({ jobDescription: selectedJD });
        postJobViews(selectedJD);
    }

    handleJobApply(jobDetailId, resumeViewId, companyId, officeId) {
        if (jobDetailId && resumeViewId) {
            this.props.postApplyJob(jobDetailId, resumeViewId, companyId, officeId).then((resp) => {
                if (resp && resp.data && resp.data.success) {
                    this.setState({ selectedJobId: 0, selectedJobDetails: null });
                    const { filterOn } = this.state;
                    const data = JSON.parse(JSON.stringify(filterOn));
                    this.props.getLatestThisWeek();
                    this.props.postSearchJobs(data);
                    this.props.setAppMessage('success', 'Applied for job successfully.');
                }
            });
        }
    }

    handleSelectResume(resume) {
        this.setState({
            previewData: resume,
        });
    }

    handleBookmarkJob(jobSelected) {
        let bookmarkIds = [];
        this.props.getCurrentUser().then((resp) => {
            const loginUser = resp.data.data;
            if (loginUser && loginUser.user) {
                if (loginUser.profile && loginUser.profile.bookmark_ids) {
                    bookmarkIds = loginUser.profile.bookmark_ids.split(',')
                        .map((x) => {
                            return parseInt(x)
                        });
                }
                if (bookmarkIds.includes(jobSelected.id)) {
                    this.props.setAppMessage('fail', 'You have already saved this job...!');

                    return;
                }
                bookmarkIds.push(jobSelected.id);
                const bookmark_ids = bookmarkIds.join();
                this.props.postAddBookmark(bookmark_ids).then((response) => {
                    if (response && response.data && response.data.success) {
                        this.setState({
                            bookmarkIds,
                        });
                        const { filterOn } = this.state;
                        const data = JSON.parse(JSON.stringify(filterOn));
                        this.props.postSearchJobs(data);
                        this.props.setAppMessage('success', 'Job saved to your profile...!');

                    }
                });
            } else {
                this.setState({
                    jobSelected,
                });
            }
        });
    }


    render() {
        const {
            jobs,
            resumeBlockViews,
        } = this.props;
        const {
            selectedJobId, selectedJobDetails, previewData, jobDescription, bookmarkIds,
        } = this.state;

        let listJobs = [];

        if (!jobs) {
            return <LoadingIcon />;
        }

        if (jobs && jobs.data.length > 0) {
            jobs.data.forEach((item) => {
                listJobs.push(
                    <Grid item xs={12} sm={4} md={4} key={`job-${item.id}`}>
                        <Paper className="card-bg center-align">
                            <div className="card-content white-text h7t-cursor-pointer" onClick={() => this.handleJDModal(item)}>
                                 <div className="w3-col s12">
                                    <p className="white-text w3-right h7t-font-size-13">{moment(item.created_at).format('MMM Do, YYYY')}</p>
                                </div> 
                                <div className="w3-center w3-margin-top">
                                    <div className="h7t-profile-pic">
                                        <img style={{ width: '100px', height: '100px', objectFit: 'contain', border: '1px solid #e7e7e7' }} alt="" className="w3-circle w3-white" src={item.logoUrl ? item.logoUrl : "https://www.w3schools.com/w3css/img_avatar6.png"} />
                                    </div>
                                </div>
                                <h5 className="white-text mb-1 bold-header h7t-ellipsis" style={{ minHeight: '38px' }}>
                                    {item.job_title}
                                </h5>
                                <p className="m-0 h7t-font-size-13">{item.companyName}</p>
                                <p className="mt-8 h7t-ellipsis h7t-font-size-13" style={{ minHeight: '24px' }}>{item.skills_required.split(',').join(', ')}</p>
                                <div className="w3-row w3-margin-top w3-margin-bottom w3-center" style={{ overflow: 'visible' }}>
                                    <button
                                        type="button"
                                        className="w3-button gradient-45deg-deep-orange-orange waves-effect card-btn border-round z-depth-4 btn-space h7t-padding-0-20"
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.triggerResumeSelect(item);
                                        }}
                                        disabled={item.applicationStatus}
                                        
                                    >
                                    {item.applicationStatus ? 'Applied' : 'Apply'}
                                    </button>
                                    <button
                                        type="button"
                                        className="w3-button gradient-45deg-deep-orange-orange waves-effect card-btn border-round z-depth-4 btn-space h7t-padding-0-20"
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.handleBookmarkJob(item);
                                        }}
                                        disabled={bookmarkIds.includes(item.id) ? true : ''}
                                    >
                                    {bookmarkIds.includes(item.id) ? 'Saved' : 'Save'}
                                    </button>
                                </div>
                                <hr/>
                                <div className="w3-row" style={{ marginTop: 5 }}>
                                    <div className="w3-col s6 m6">
                                        <p 
                                            className={`white-text h7t-font-size-13 ${item && item.current_office && item.current_office.city_info && item.current_office.city_info.term ? 'card-bottom-text':''}`} >{
                                            item && item.current_office && item.current_office.city_info && item.current_office.city_info.term}</p>
                                    </div>
                                    <div className="w3-col s6 m6">
                                        <p className="white-text h7t-font-size-13">
                                            {`${item.min_experience_required} - ${item.max_experience_required} Yrs`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                );
            });
        } else {
            listJobs = (
                <div className="w3-padding h7t-table-data w3-margin-top">
                    <div className="w3-center w3-margin-top">
                        <span className="ion-ios-briefcase-outline" style={{ fontSize: "120px" }} />
                        <p className="w3-margin-top"><strong>No job matches found !</strong></p>
                    </div>
                    <div className="h7t-flex-container w3-margin-top">
                        <ul className="h7t-feature-description">
                            <li>View available jobs that matching your skills.</li>
                            <li>Apply for a job.</li>
                            <li>Filter jobs based on the skills and locations.</li>
                            <li>Save a job</li>
                            <li>View saved jobs.</li>
                        </ul>
                    </div>
                </div>
            );
        }

        return (
            <div className="w3-row w3-margin-top">
                <div className="w3-white">
                    {listJobs.length ? (
                        <Grid container spacing={2}>
                            {listJobs}
                        </Grid>
                    ) : listJobs}
                </div>
                {(selectedJobId && resumeBlockViews && resumeBlockViews.data && resumeBlockViews.data.length)
                    ? (
                        <ResumeSelectTemplate
                            jobId={selectedJobId}
                            selectedJobDetails={selectedJobDetails}
                            resumeList={resumeBlockViews.data}
                            onModalClose={() => this.setState({ selectedJobId: 0, selectedJobDetails: null })}
                            onModalSuccess={this.handleJobApply}
                            previewData={previewData}
                            handleSelectResume={this.handleSelectResume}
                        />
                    ) : null}

                {jobDescription
                    ? (
                        <JobDescriptionModal
                            bookmarkIds={bookmarkIds}
                            jobDescription={jobDescription}
                            onModalClose={() => this.setState({ jobDescription: '' })}
                            triggerResumeSelect={this.triggerResumeSelect}
                            handleBookmarkJob={this.handleBookmarkJob}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        resumeBlockViews: state.resumeBlockViews,
        currentUser: state.currentUser,
    };
}

export default connect(mapStateToProps, {
    getRecommendedJobList,
    postApplyJob,
    getResumeBlockViews,
    setAppMessage,
    postAddBookmark,
    getCurrentUser,
    postSearchJobs,
    getLatestThisWeek,
    postJobViews,
})(RecommendedJobs);