import Footer from './ApplicationFooter';
import React, { Fragment } from 'react';
import Header from './Header';


class PrivacyPolicy extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="w3-top">
                    <Header />
                </div>
            <div  className="w3-row h7t-homepage">
                <h5 className="w3-container w3-content margin-top-100 margin-bottom-100">

                    <b>Privacy Policy</b><br></br><br></br>
                    <b>Introduction</b><br></br><br></br>
                        We, at "Hiring NXT" are committed to respecting your online privacy and recognize your need for appropriate
                        protection and management of any personally identifiable information ("Personal Information") you share with us.<br></br><br></br>
                        "Personal Information" means any information that may be used to identify an individual, including, but not limited
                        to, a first and last name, a home or other physical address and an email address or other contact information,
                        whether at work or at home. In general, you can visit Hiring NXT's web pages without telling us who you are or
                        revealing any Personal Information about yourself.<br></br><br></br>
                                            <b>Cookies and Other Tracking Technologies</b><br></br><br></br>
                        Some of our Web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text file that may be
                        used, for example, to collect information about Web site activity. Some cookies and other technologies may serve to
                        recall Personal Information previously indicated by a Web user. Most browsers allow you to control cookies,
                        including whether or not to accept them and how to remove them.<br></br><br></br>
                        You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your
                        browser, but please note that if you choose to erase or block your cookies, you will need to re-enter your original
                        user ID and password to gain access to certain parts of the Web site.<br></br><br></br>
                        Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP)
                        addresses; browser software and operating system types; clickstream patterns; and dates and times that our site is
                        accessed. Our use of cookies and other tracking technologies allows us to improve our Web site and your Web
                        experience. We may also analyze information that does not contain Personal Information for trends and statistics.<br></br><br></br>
                                            <b>Third Party Services</b><br></br><br></br>
                        "Hiring NXT" does not intend to transfer Personal Information without your consent to third parties who are not
                        bound to act on Hiring NXT's behalf unless such transfer is legally required. Similarly, it is against Hiring NXT's
                        policy to sell Personal Information collected online without consent.<br></br><br></br>
                                            <b>Your Consent</b><br></br><br></br>
                        By using this Web site, you consent to the terms of our Online Privacy Policy and to Hiring NXT's processing of
                        Personal Information for the purposes given above as well as those explained where "Hiring NXT" collects Personal
                        Information on the Web.<br></br><br></br>
                        Information security<br></br><br></br>
                        We take appropriate security measures to protect against unauthorized access to or unauthorized alteration,
                        disclosure or destruction of data.<br></br><br></br>
                        We restrict access to your personally identifying information to employees who need to know that information in
                        order to operate, develop or improve our services.<br></br><br></br>
                        Updating your information<br></br><br></br>
                        We provide mechanisms for updating and correcting your personally identifying information for many of our services.
                        For more information, please see the help pages for each service.<br></br><br></br>
                                            <b>Children</b><br></br><br></br>
                        "Hiring NXT" will not contact children under age 13 about special offers or for marketing purposes without a
                        parent's permission.<br></br><br></br>
                                            <b>Information Sharing and Disclosure</b><br></br><br></br>
                        "Hiring NXT" does not rent, sell, or share personal information about you with other people (save with your consent)
                        or non-affiliated companies except to provide products or services you've requested, when we have your permission,
                        or under the following circumstances<br></br><br></br>
                        We provide the information to trusted partners who work on behalf of or with "Hiring NXT" under confidentiality
                        agreements. These companies may use your personal information to help "Hiring NXT" communicate with you about offers
                        from "Hiring NXT" and our marketing partners. However, these companies do not have any independent right to share
                        this information.<br></br><br></br>
                        We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend
                        against legal claims;<br></br><br></br>
                        We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal
                        activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations
                        of Hiring NXT's terms of use, or as otherwise required by law.<br></br><br></br>
                        We transfer information about you if "Hiring NXT" is acquired by or merged with another company. In this event,
                        "Hiring NXT" will notify you before information about you is transferred and becomes subject to a different privacy
                        policy.<br></br><br></br>
                        "Hiring NXT" does not provide any personal information to the advertiser when you interact with or view a targeted
                        ad. However, by interacting with or viewing an ad you are consenting to the possibility that the advertiser will
                        make the assumption that you meet the targeting criteria used to display the ad.<br></br><br></br>
                        "Hiring NXT" works with vendors, partners, advertisers, and other service providers in different industries and
                        categories of business. For more information regarding providers of products or services that you've requested
                        please read our detailed reference links.<br></br><br></br>
                                            <b>Confidentiality and Security</b><br></br><br></br>
                        We limit access to personal information about you to employees who we believe reasonably need to come into contact
                        with that information to provide products or services to you or in order to do their jobs.<br></br><br></br>
                        "We have physical, electronic, and procedural safeguards that comply with the laws prevalent in India to protect
                        personal information about you. We seek to ensure compliance with the requirements of the Information Technology
                        Act, 2000 and Rules made thereunder to ensure the protection and preservation of your privacy.<br></br><br></br>
                                            <b>Changes to this Privacy Policy</b><br></br><br></br>
                        "Hiring NXT" reserves the right to update, change or modify this policy at any time. The policy shall come to effect
                        from the date of such update, change or modification.<br></br><br></br>
                                            <b>Disclaimer</b><br></br><br></br>
                        Hiring NXT does not store or keep credit card data in a location that is accessible via the Internet. Once a credit
                        card transaction has been completed, all credit card data is moved off-line only to ensure that the data/credit card
                        information received is not accessible to anyone after completion of the on-line transaction and to ensure the
                        maximum security. Hiring NXT uses the maximum care as is possible to ensure that all or any data / information in
                        respect of electronic transfer of money does not fall in the wrong hands.<br></br><br></br>
                        Hiring NXT shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or
                        otherwise) of any information concerning the user's account and / or information relating to or regarding online
                        transactions using credit cards / debit cards and / or their verification process and particulars nor for any error,
                        omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance of a legal
                        process or otherwise.<br></br><br></br>
                        Contact Information<br></br><br></br>
                        "Hiring NXT" welcomes your comments regarding this privacy statement at the contact address given at the website.
                        Should there be any concerns about contravention of this Privacy Policy, "Hiring NXT" will employ all commercially
                        reasonable efforts to address the same.<br></br><br></br>
                        Note: The terms in this agreement may be changed by Hiring NXT at any time. Hiring NXT is free to offer its services
                        to any client/prospective client without restriction<br></br><br></br>



                </h5>
                <Footer />
            </div>
            </Fragment>
        );
    }
}

export default PrivacyPolicy;