import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Modal from '../Common/Modal';
import {
    getListByRole,
    postEditRoleUser,
    setAppMessage,
} from '../../js/actions';
import { InputSelectField } from '../Common/MaterialUI/HelperInputs';

class UserWithRoleEdit extends Component {
    static propTypes = {
        role_edit: PropTypes.object,
        userRole: PropTypes.object,
        onModalClose: PropTypes.func,
        postEditRoleUser: PropTypes.func,
        getListByRole: PropTypes.func,
        setAppMessage: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {},
            selectedRow: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { role_edit } = this.props;
        if (role_edit) {
            this.setState({
                formData: Object.assign({}, role_edit),
                selectedRow: role_edit.role_id,
            });
        }
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { errors, formData } = this.state;

        if (errors[name]) {
            errors[name] = null;
            this.setState({
                errors,
            });
        }
        formData[name] = value;
        this.setState({
            formData,
        });
    }

    handleSubmit(evt) {
        evt.preventDefault();
        const { formData, selectedRow } = this.state;
        formData.role_user_id = formData.id;
        this.props.postEditRoleUser(formData).then((resp) => {
            const response = resp && resp.data;
            if (response && response.success) {
                this.props.setAppMessage('success', 'Role user edited successfully!');
                this.props.getListByRole(selectedRow);
                $('.close-button').click();
            } else if (response && response.data) {
                this.setState({
                    errors: response.data,
                });
            }
        });
    }

    render() {
        let roleSelect = [];
        roleSelect = [{ val: '', name: '-Select Role-' }];
        const { errors, formData } = this.state;
        const { userRole, onModalClose, permission } = this.props;

        if (userRole && userRole.data) {
            userRole.data.forEach((role) => {
                let push = false;
                if (permission === 'Management') {
                    push = true;
                } else if (permission === 'HR') {
                    if (role.permission_type === 'HR' || role.permission_type === 'Panel Member') {
                        push = true;
                    }
                } else if (role.permission_type === 'Panel Member') {
                    push = true;
                }
                if (push) {
                    roleSelect.push({ val: role.id, name: role.name });
                }
            });
        }

        return (
            <div>
                <Modal modalCls="w3-show" onClose={onModalClose} widthCls="chp-small-dlg">
                    <h4 className="w3-margin">Edit Role User</h4>
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>

                        <InputSelectField
                            label="Role"
                            name="role_id"
                            id="role_id"
                            value={formData.role_id}
                            onChange={this.handleChange}
                            options={roleSelect}
                            errors={errors.role_id}
                        />
                        <div className="w3-bar w3-margin-top w3-panel">
                            <Button
                                variant="contained"
                                className="w3-margin-right h7t-secondary-btn"
                                onClick={onModalClose}
                            >
                                Close
                            </Button>
                            <Button variant="contained" type="submit" className="h7t-primary-btn" onClick={this.handleSubmit}>Update</Button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userRole: state.userRole,
    };
}
export default connect(mapStateToProps, {
    getListByRole,
    postEditRoleUser,
    setAppMessage,
})(UserWithRoleEdit);
