import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Grid, Button }  from '@material-ui/core';
import { Select, Checkbox } from 'antd';
import {
    getLatestThisWeek, 
    postSearchJobs,
    setAppMessage,
    getMetaData, 
} from '../../js/actions';
import RecommendedJobs from '../Seeker/RecommendedJobs/RecommendedJobs';
import Pagination from '../Common/Pagination';
import { LoadingIcon } from '../Common/HelperComponents';
import SearchInputBase from '../Common/MaterialUI/SearchInputBase';

const { Option } = Select;
class NewSeekerJobDashboard extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        latestThisWeek: PropTypes.object,
        paginationDetails: PropTypes.object,
        setAppMessage: PropTypes.func,
        metaData: PropTypes.object,
        getMetaData: PropTypes.func,
        searchJobs: PropTypes.object,
        getLatestThisWeek: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            filterOn: {
                show_bookmark: '',
                locations: [],
                skills: [],
                experience: '',
                show_applied: '',
                show_recommended: '',
                search: '',
                show_all_jobs: '',
            },
            openSelect: {},
            filterBySearch: '',
            filterByCount: '',
        };
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchJobs = this.handleSearchJobs.bind(this);
        this.handleChangeFilterOption = this.handleChangeFilterOption.bind(this);
        this.handleOpenSelect = this.handleOpenSelect.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
    }

    componentDidMount() {
        this.props.getLatestThisWeek();
        const { metaData } = this.props;
        if (!metaData) {
            this.fetchSkills();
        } else {
            const { metaData: { skills } } = this.props;
            if (!skills) {
                this.fetchSkills();
            }
        }
        this.props.postSearchJobs();
    }


    fetchSkills() {
        const skillsList = {};
        skillsList.type = 'skills';
        this.props.getMetaData(skillsList);
    }


    handleChangeFilterOption(value, label) {
        const { filterOn } = this.state;
        if (label === 'locations' || label === 'skills') {
            const idx = filterOn[label].indexOf(value);
            if (idx !== -1) {
                filterOn[label].splice(idx, 1);
            } else {
                filterOn[label].push(value);
                
            }
        } else {
            filterOn[label] = value;
        }
        this.setState({ filterOn });
    }

    handlePagination(page = 1) {
        const { filterOn, filterBySearch } = this.state;
        const data = JSON.parse(JSON.stringify(filterOn));
        if (filterBySearch.length > 2) {
            data.search = filterBySearch;
        }
        this.props.postSearchJobs(data, page);
    }

    handleOpenSelect(value, label) {
        const { openSelect } = this.state;
        openSelect[label] = value;
        this.setState({ openSelect });
    }

    searchFilter(event) {
        const { value } = event.target;
        if (!value) {
            const { filterOn } = this.state;
            const data = JSON.parse(JSON.stringify(filterOn));
            this.props.postSearchJobs(data);
        }
        this.setState({
            filterBySearch: value,
        });
    }

    handleSearchJobs(event) {
        event.preventDefault();
        const { filterOn, filterBySearch } = this.state;
        const data = JSON.parse(JSON.stringify(filterOn));
        if (filterBySearch.length > 1) {
            data.search = filterBySearch;
            this.setState({ filterOn });
            this.props.postSearchJobs(data);
        } else {
            this.props.setAppMessage('fail', 'Insufficient data. Please enter minimum 2 characters !');
        }
    }

    handleSearch(val) {
        if (val) {
            const skillsList = {};
            skillsList.type = 'skills';
            skillsList.search = val;
            this.props.getMetaData(skillsList).then(() => {
                this.forceUpdate();
            });
        }
    }

    handleFilters(evt) {
        const { filterOn,filterByCount } = this.state;
        this.setState({ filterByCount: evt });
        let newfilterOn = Object.assign({}, filterOn);
        newfilterOn.show_applied = '';
        newfilterOn.show_bookmark = '';
        newfilterOn.show_recommended = '';
        newfilterOn.show_not_interested = '';
        newfilterOn.show_all_jobs = '';
        if (evt) {
            newfilterOn[evt] = 'filter';
        } else {
            newfilterOn[evt] = '';
        }
        this.setState({ filterOn: newfilterOn, filterByCount: evt }, () => {
            this.handlePagination();
        });
    }
    
    render() {
        const {
            currentUser: { user }, latestThisWeek, paginationDetails, searchJobs, metaData,
        } = this.props;
        const {
            filterOn, openSelect, filterByCount, filterBySearch,
        } = this.state;
        const locationOptions = [];
        const experienceOptions = [
            <Option key="any" value="">Any Exp. Level</Option>,
            <Option key="0,2">0-2 yrs</Option>,
            <Option key="3,6">3-6 yrs</Option>,
            <Option key="7,10">7-10 yrs</Option>,
            <Option key="10+">10+ yrs</Option>,
        ];

        const JobTypeFilter = [
            <Option value="show_all_jobs">All Jobs</Option>,
            <Option value="show_recommended">Recommended Jobs</Option>,
            <Option value="show_applied">Applied Jobs</Option>,
            <Option value="show_bookmark">Saved Jobs</Option>,
            <Option value="show_not_interested" disabled>Not Interested</Option>,
        ]; 

        let recommendedCount = 0;
        let appliedCount = 0;
        let savedCount = 0;
       
        if (latestThisWeek && latestThisWeek.data) {
            recommendedCount = latestThisWeek.data.recommendedJobs;
            appliedCount = latestThisWeek.data.appliedJobs;
        }

        if (searchJobs) {
            savedCount = searchJobs.savedJobs;
        }

        if (!searchJobs) {
            return <LoadingIcon />;
        }
        const skillsOptions = [];
        if (metaData) {
            const { metaData: { skills } } = this.props;
            if (skills && skills.length) {
                skills.forEach((skill) => {
                    skillsOptions.push(
                        <Option key={skill.term} term={skill.term}>
                            <Checkbox
                                checked={filterOn['skills'].indexOf(skill.term.toString()) !== -1 ? true : false}
                            />
                            {` ${skill.term}`}
                        </Option>,
                    );
                });
            }
        }
        if (searchJobs && searchJobs.locations) {
            searchJobs.locations.forEach((item) => {
                locationOptions.push(
                    <Option key={item.id} term={item.term}>
                        <Checkbox
                            checked={filterOn['locations'].indexOf(item.id.toString()) !== -1 ? true : false}
                        />
                        {` ${item.term}`}
                    </Option>,
                );
            });
        }

        const filter = filterByCount? filterByCount: 'All Jobs';
        return (
            <div className="content-margin w3-margin-top h7t-margin-bottom-40">
                <div className="w3-row">
                    <div className="w3-row">
                        <strong className="w3-left h7t-modal-title">{`Hi ${user.display_name}!`}</strong>
                    </div>
                    <div className="w3-row">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                                <Paper className="h7t-Paper overview-graph seeker-job-overview-graph-height h7t-table-data h7t-cursor-pointer dashed-border" onClick={() => this.handleFilters('show_recommended')}>
                                    <p><i className="ion ion-briefcase w3-xlarge" /></p>
                                    <p className="title">Recommended Jobs</p>
                                    <p className="total-count">{recommendedCount}</p>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Paper className="h7t-Paper overview-graph seeker-job-overview-graph-height h7t-table-data h7t-cursor-pointer dashed-border" onClick={() => this.handleFilters('show_applied')}>
                                <p><i className="ion ion-android-people w3-xlarge" /></p>
                                    <p className="title">Applied Jobs</p>
                                    <p className="total-count ">{appliedCount}</p>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Paper className="h7t-Paper overview-graph seeker-job-overview-graph-height h7t-table-data h7t-cursor-pointer dashed-border" onClick={() => this.handleFilters('show_bookmark')}>
                                <p><i className="ion ion-briefcase w3-xlarge" /></p>
                                    <p className="title">Saved Jobs</p>
                                    <p className="total-count">{savedCount}</p>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div>
                    <div className="w3-row w3-padding-16">
                        <div className="w3-row">   
                            <form onSubmit={this.handleSearchJobs}>
                                <SearchInputBase
                                    placeholder="Search for job title, description, company"
                                    customClass="w3-margin-top"
                                    onChange={e => this.searchFilter(e)}
                                    value={filterBySearch}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="w3-row w3-padding-16" style={{ padding: '8px' }}>
                        <div className="w3-col m10">
                            <div className="w3-col m3" style={{ paddingRight: '5px' }}>
                                <Select
                                    defaultValue="All Jobs"
                                    style={{ borderRadius: '0px', width: '100%', marginTop: 2 }}
                                    onChange={e => this.handleFilters(e)}
                                    id="JobView"
                                    value={filter}
                                    options={JobTypeFilter}
                                >
                                    {JobTypeFilter}
                                </Select> 
                            </div>
                            <div className="w3-col m3" style={{ paddingRight: '5px' }}>
                                <Select
                                    open={openSelect['locations']}
                                    optionFilterProp="term"
                                    style={{ borderRadius: '0px', width: '100%' }}
                                    placeholder="Locations"
                                    value={filterOn['locations'].length ? `${filterOn['locations'].length} selected` : undefined}
                                    onFocus={() => this.handleOpenSelect(true, 'locations')}
                                    onBlur={() => this.handleOpenSelect(false, 'locations')}
                                    onChange={e => this.handleChangeFilterOption(e, 'locations')}
                                >
                                    {locationOptions}
                                </Select>
                            </div>
                            <div className="w3-col m3" style={{ paddingRight: '5px' }}>
                                 <Select
                                    open={openSelect['skills']}
                                    optionFilterProp="term"
                                    style={{ borderRadius: '0px', width: '100%' }}
                                    placeholder="Skills"
                                    value={filterOn['skills'].length ? `${filterOn['skills'].length} selected` : undefined}
                                    onFocus={() => this.handleOpenSelect(true, 'skills')}
                                    onBlur={() => this.handleOpenSelect(false, 'skills')}
                                    onChange={e => this.handleChangeFilterOption(e, 'skills')}
                                    onSearch={this.handleSearch}
                                    showSearch={true}
                                >
                                    {skillsOptions}
                                </Select>
                            </div>
                            <div className="w3-col m3" style={{ paddingRight: '5px' }}>
                                <Select
                                    style={{ borderRadius: '0px', width: '100%' }}
                                    placeholder="Experience"
                                    onChange={e => this.handleChangeFilterOption(e, 'experience')}
                                >
                                    {experienceOptions}
                                </Select>
                            </div>
                            </div>
                            <div className="w3-col m2">
                                <Button
                                    variant="contained"
                                    className="h7t-secondary-btn w3-padding-small w3-block w3-round"
                                    onClick={() => this.handlePagination()}
                                >
                                Go
                                </Button>
                            </div>
                    </div>
                </div> 
                {searchJobs && searchJobs.openJobs && searchJobs.openJobs.data ? (
                    <div>
                        <RecommendedJobs jobs={searchJobs.openJobs} />
                        {paginationDetails && paginationDetails.openJobs && paginationDetails.openJobs.last_page > 1
                            ? (
                                <Pagination
                                    paginationDetails={paginationDetails.openJobs}
                                    handlePagination={this.handlePagination}
                                />
                            ) : null}
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    const searchJobs = state.searchJobs && state.searchJobs.data;
    return {
        currentUser,
        latestThisWeek: state.latestThisWeek,
        searchJobs,
        paginationDetails: searchJobs,
        metaData: state.meta,
    };
};

export default connect(mapStateToProps, {
    getLatestThisWeek,
    setAppMessage,
    postSearchJobs,
    getMetaData,
})(NewSeekerJobDashboard);
