import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import ApplicationSite from '../components/ApplicationSite';
import Application from '../components/Application';
import { getCurrentUser } from './actions';
import { LoadingIcon } from '../components/Common/HelperComponents';
import ShareJob from '../components/ShareJob';

class AppRouter extends React.Component {
    static propTypes = {
        hideSideBarMenu: PropTypes.bool,
    };

    constructor(arg) {
        super(arg);
        this.state = {
            userData: null,
        };
    }

    componentDidMount() {
        const { currentUser } = this.props;
        if (!currentUser) {
            this.props.getCurrentUser().then((resp) => {
                const res = resp && resp.data;
                if (res && res.data) {
                    this.setState({ userData: res.data });
                }
            });
        }
    }

    getDefaultCompanyDetails() {
        const { currentUser } = this.props;
        const comp = currentUser.companies[0];
        const off = comp.office_list[0];
        window.COMPANY_ID = comp.company_id;
        window.OFFICE_ID = off.id;
        return {
            companyId: comp.company_id,
            officeId: off.id,
        };
    }

    render() {
        const { currentUser ,hideSideBarMenu} = this.props;
        const { userData } = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        const jobId = urlParams.get('jobId');
        const pathUrl = window.location.pathname;
        let routes = [
            <Route key="route_e_application" path="/e" component={Application} />,
            <Route key="route_s_application" path="/s" component={Application} />,
        ];
        if (!userData) {
            return <LoadingIcon />;
        }
        if (userData && !userData.user) {
            routes = [
                <Route key="route_application_site" path="/" component={ApplicationSite} />
            ];
        }
        if (jobId) {
            routes = [
                <Route key="route_share_job" path="/" component={ShareJob} />
            ];
        } 
        else if (pathUrl.includes("/about-us") || pathUrl.includes("/privacy-policy") || pathUrl.includes("/terms-of-service")) {
            routes = [
                <Route key="route_static_page" path="/" component={ApplicationSite} />
            ];
        }
        else if (currentUser.companies && currentUser.companies.length > 0) {
            const { location: { state }, hideSideBarMenu } = this.props;
            const companyId = state ? state.companyId : this.getDefaultCompanyDetails().companyId;
            const officeId = state ? state.officeId : this.getDefaultCompanyDetails().officeId;

            const location = {
                pathname: hideSideBarMenu === false ? `/e/${companyId}/${officeId}/dashboard` : `/e/${companyId}/${officeId}/manage-company` ,
                state: {
                    companyId,
                    officeId,
                },
            };
            routes.push(<Redirect key="route_employer" from="/" to={location} />);
        } else {
            routes.push(<Redirect key="route_seeker" from="/" to="/s/job-dashboard" />);
        }

        return (
            <div>
                <Switch>
                    {routes}
                </Switch>
            </div>
        );
    }
}

const ms2p = (state) => {
    const currentUser = state.currentUser && state.currentUser.data ? state.currentUser.data : state.currentUser;
    const hideSideBarMenu = state && state.hideSideBarMenu;
    return {
        currentUser,
        hideSideBarMenu,
    };
};

export default withRouter(connect(ms2p, {
    getCurrentUser
})(AppRouter));
