import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../Common/Modal';
import {
    getMetaData,
    setAppMessage,
    getCurrentUser,
    getCurrentJobList,
    postAddJobPosition,
    getCurrentWorkflows,
    getAllCurrentJobList,
    getActiveJobs,
} from '../../js/actions';
import { Button } from '@material-ui/core';
import { InputSelectField, InputTextField } from '../Common/MaterialUI/HelperInputs';

class JobTemplateModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        metaData: PropTypes.object,
        getMetaData: PropTypes.func,
        currentUser: PropTypes.object,
        templateList: PropTypes.object,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getCurrentUser: PropTypes.func,
        currentWorkflows: PropTypes.object,
        getCurrentJobList: PropTypes.func,
        postAddJobPosition: PropTypes.func,
        getCurrentWorkflows: PropTypes.func,
        getAllCurrentJobList: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            formData: {},
            errors: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    componentDidMount() {
        const { currentWorkflows, currentUser } = this.props;
        const skillList = {};
        skillList.type = 'skills';
        this.props.getMetaData(skillList);
        if (!currentWorkflows) {
            this.props.getCurrentWorkflows();
        }
        if (!currentUser) {
            this.props.getCurrentUser();
        }
    }

    handleChange(e) {
        const { formData, errors } = this.state;
        const { name, value } = e.target;
        formData[name] = value;
        if (errors[name]) {
            errors[name] = null;
        }
        this.setState({ formData, errors });
    }

    validateData(createData) {
        let isValid = true;
        const { formData, errors } = this.state;
        const data = Object.assign({}, createData);
        data.workflow_id = formData['workflow_id'.concat('_', createData.id)];
        data.skills_required = createData.skills_required.replace(/\s*,\s*/g, ',');
        data.level_of_hiring = formData['level_of_hiring'.concat('_', createData.id)];
        data.salary_min = formData['salary_min'.concat('_', createData.id)];
        data.salary_max = formData['salary_max'.concat('_', createData.id)];

        if (parseFloat(data.salary_max, 10) <= parseFloat(data.salary_min, 10)) {
            errors[`salary_max_${data.id}`] = 'CTC Max must be greater than CTC Min';
            isValid = false;
        }
        const fields = ['salary_min', 'salary_max'];
        Object.keys(data).forEach((key) => {
            const regExprsn = /^-?\d*[.]?\d*$/;
            if (!data[key]) {
                errors[`${key}_${data.id}`] = 'This field is mandatory';
                isValid = false;
            }
            if (fields.indexOf(key) !== -1) {
                if (data[key] && !regExprsn.test(data[key])) {
                    isValid = false;
                    errors[`${key}_${data.id}`] = 'Enter a valid number';
                } else if (data[key] === 0) {
                    return;
                }
            }

        });

        if (!isValid) {
            this.setState({ errors });
            return false;
        }
        return data;
    }

    handleCreate(createData) {
        const { currentUser } = this.props;
        const validData = this.validateData(createData);
        if (!validData) {
            return false;
        }
        this.props.postAddJobPosition(validData).then((resp) => {
            const response = resp && resp.data;
            if (response) {
                if (response.success) {
                    this.props.setAppMessage('success', 'Job Template Created Successfully!');
                    this.props.getCurrentJobList();
                    this.props.getCurrentWorkflows();
                    this.props.getAllCurrentJobList('all');
                    this.props.getActiveJobs();
                    $('.close-button').click();
                    this.setState({
                        formData: {},
                        errors: {},
                    });
                } else if (response && response.data) {
                    const errorObj = Object.assign({}, errors);
                    Object.keys(response.data).map((item) => {
                        const inputField = item.concat('_', createData.id);
                        errorObj[inputField] = response.data[item];
                    });

                    this.setState({
                        errors: errorObj,
                    });
                }
            }
        });
    }

    handleSkillChange(name, val) {
        const skillsSelected = [];
        const { formData, errors } = this.state;
        if (val.length > 0) {
            for (let i = 0; i < val.length; i++) {
                skillsSelected.push(val[i].value);
            }
        }
        formData[name] = skillsSelected.join();
        errors[name] = null;
        this.setState({ formData, errors });
    }

    render() {
        const {
            title, onModalClose, templateList, currentWorkflows, metaData,
        } = this.props;
        const { formData, errors } = this.state;
        const templateListBlock = [];

        const workflowSelect = [{ val: '', name: 'Select' }];
        const levelOptions = [
            { val: null, name: 'Select' },
            { val: 'Junior', name: 'Junior' },
            { val: 'Mid', name: 'Mid' },
            { val: 'Senior', name: 'Senior' },
        ];

        if (currentWorkflows && currentWorkflows.data) {
            currentWorkflows.data.forEach((item) => {
                workflowSelect.push({ val: item.id, name: item.name });
            });
        }

        const optionSkills = [];

        if (metaData && metaData.skills && metaData.skills.length > 0) {
            metaData.skills.map((skill) => {
                optionSkills.push({ value: skill.term, label: skill.term });
            });
        }

        if (templateList && templateList.data) {
            templateList.data.map((item, key) => {
                templateListBlock.push(
                    <div key={key} className="w3-row w3-margin-bottom w3-padding h7t-table-data">
                        <h4 className="w3-padding-small h7t-modal-subtitle">{`Template For ${item.job_title}`}</h4>
                        <div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Title :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small">{item.job_title}</div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Description :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small">{item.description}</div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Skills Required :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small">
                                    {/*
                                        // Disabling loading skills from meta data.

                                    <Select
                                        multi={true}
                                        name={`skills_required_${item.id}`}
                                        value={formData[`skills_required_${item.id}`] ? formData[`skills_required_${item.id}`] : ''}
                                        options={optionSkills}
                                        onChange={this.handleSkillChange.bind(this, `skills_required_${item.id}`)}
                                    />
                                    <ErrorHelperText errors={errors[`skills_required_${item.id}`]} /> */}
                                    {item.skills_required}
                                </div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Min/Max Experience :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small">{item.min_experience_required}/{item.max_experience_required}</div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Positions Open :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small">{item.positions_open}</div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Workflow <span className="w3-text-red">*</span> :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small job-template-select-fields">
                                    <InputSelectField
                                        label="Select Workflow"
                                        name={`workflow_id_${item.id}`}
                                        id={`workflow_id_${item.id}`}
                                        value={formData[`workflow_id_${item.id}`] ? formData[`workflow_id_${item.id}`] : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        errors={errors[`workflow_id_${item.id}`]}
                                        options={workflowSelect}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                        Level of hiring <span className="w3-text-red">*</span> :
                                </div>
                                <div className="w3-col m8 s8 w3-padding-small job-template-select-fields">
                                    <InputSelectField
                                        label="Level of hiring"
                                        name={`level_of_hiring_${item.id}`}
                                        id={`level_of_hiring_${item.id}`}
                                        value={formData[`level_of_hiring_${item.id}`] ? formData[`level_of_hiring_${item.id}`] : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        errors={errors[`level_of_hiring_${item.id}`]}
                                        options={levelOptions}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="w3-row">
                                <div className="w3-col m4 s4 w3-padding-small h7t-light-grey">
                                    Salary CTC <span className="w3-text-red">*</span> :
                                </div>
                                <div className="w3-col m4 s4 w3-padding-small job-template-select-fields">
                                    <InputTextField
                                        label="Min (LPA)"
                                        id={`salary_min_${item.id}`}
                                        name={`salary_min_${item.id}`}
                                        value={formData[`salary_min_${item.id}`] ? formData[`salary_min_${item.id}`] : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        errors={errors[`salary_min_${item.id}`]}
                                        required
                                        fullwidth
                                    />
                                </div>
                                <div className="w3-col m4 s4 w3-padding-small" style={{ marginTop: '-20px', marginBottom: '-10px' }}>
                                    <InputTextField
                                        label="Max (LPA)"
                                        id={`salary_max_${item.id}`}
                                        name={`salary_max_${item.id}`}
                                        value={formData[`salary_max_${item.id}`] ? formData[`salary_max_${item.id}`] : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        errors={errors[`salary_max_${item.id}`]}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                className="w3-margin-bottom w3-margin-top h7t-primary-btn"
                                style={{ marginLeft: '8px' }}
                                onClick={() => this.handleCreate(item)}
                            >
                                Create
                            </Button>
                        </div>
                    </div>,
                );
            });
        } else {
            templateListBlock.push(
                <div className="w3-row w3-padding-64 w3-border w3-center">
                    No Job Templates!
                </div>,
            );
        }

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title="Job Templates">
                <div className="w3-panel h-template-modal h7t-padding-mobile-view">
                    {templateListBlock}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    const currentWorkflows = state.currentWorkflows && state.currentWorkflows.data;
    return {
        currentWorkflows,
        currentUser: state.currentUser.user,
        metaData: state.meta,
    };
};

export default connect(mapStateToProps, {
    getAllCurrentJobList,
    getCurrentWorkflows,
    postAddJobPosition,
    getCurrentJobList,
    getCurrentUser,
    setAppMessage,
    getMetaData,
    getActiveJobs,
})(JobTemplateModal);
