import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import { Button, FormHelperText } from '@material-ui/core';
import {
    getCurrentDetailsList,
    getResumeBlockViews,
    setAppMessage,
    postUserResumeViewEdit,
    postUserResumeViewCreate,
    postApplyJob,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import { InputTextField, CheckboxMUI } from '../../Common/MaterialUI/HelperInputs';

class ResumeViewBlock extends Component {
    static propTypes = {
        editResume: PropTypes.object,
        resumeDetail: PropTypes.object,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        blockViewData: PropTypes.array,
        getResumeBlockViews: PropTypes.func,
        getCurrentDetailsList: PropTypes.func,
        postUserResumeViewEdit: PropTypes.func,
        postUserResumeViewCreate: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            form: {
                title: '',
                covering_letter_text: '',
                resume_file_path: '',
            },
            errors: {},
            resumeBlock: [],
            modalCloseDisable: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleCoverLetter = this.handleCoverLetter.bind(this);
    }

    componentDidMount() {
        const { resumeDetail, editResume, currentUser: { profile }, 
        disableClose 
        } = this.props;
        if(disableClose){
            const { modalCloseDisable } = this.state;
            this.setState({
                modalCloseDisable:disableClose,
            });
        }
        if (!resumeDetail) {
            this.props.getCurrentDetailsList();
        }
        if (editResume && editResume.id) {
            const blocks = [];
            if (this.props.blockViewData) {
                this.props.blockViewData.map((blockView) => {
                    if (blockView.resume_block) {
                        blocks.push(blockView.resume_block.id);
                    }
                });
            }
            if (!editResume.resume_file_path) {
                editResume.resume_file_path = '';
            }

            this.setState({
                form: Object.assign({}, editResume),
                errors: {},
                resumeBlock: blocks,
            });
        } else if (profile && profile.short_summary) {
            const { form } = this.state;
            form.covering_letter_text = profile.short_summary;
            this.setState({
                form,
            });
        }
    }

    handleChange(evt) {
        const targetElement = $(evt.target).attr('id');
        const { errors, form } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({ errors });
        }

        form[targetElement] = $(evt.target).val();
        this.setState({ form });
    }

    handleCoverLetter(content) {
        const { errors, form } = this.state;
        if (errors.covering_letter_text) {
            errors.covering_letter_text = null;
        }

        form.covering_letter_text = (content == '<p><br></p>') ? '' : content;
        this.setState({ form, errors });
    }

    handleResumeBlocks(idx) {
        const { resumeBlock, errors } = this.state;
        errors.resume_block_ids = null;
        const eleId = idx;
        if (document.getElementById(eleId).checked) {
            // resumeBlock = [];
            resumeBlock.push(idx);
        } else if (!document.getElementById(eleId).checked) {
            const index = resumeBlock.indexOf(idx);
            if (index > -1) {
                resumeBlock.splice(index, 1);
            }
        }
        this.setState({ resumeBlock, errors });
    }

    handleFileUpload(event) {
        const { form, errors } = this.state;
        if (event === 'remove') {
            form.resume_file_path = '';
        } else {
            form.resume_file_path = event.target.files[0] ? event.target.files[0] : '';
        }
        errors.resume_file_path = null;
        errors.resume_block_ids = null;
        this.setState({
            form,
            errors,
        });
    }

    updateResponse(response, successMessage) {
        if (response && response.success) {
            const { modalCloseDisable } = this.state;
            this.props.setAppMessage('success', successMessage);
            this.props.getResumeBlockViews();
            
            if(this.props.disableClose) {
                const {companyId, officeId,jobId} =this.props;
                this.props.postApplyJob(jobId, response.data.id, companyId, officeId).then((resp) => {
                    if (resp && resp.data && resp.data.success) {
                        this.props.setAppMessage('success', 'Applied for job successfully.'); 
                    }
                }); 
            }
            this.setState({
                modalCloseDisable:false,
            });
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                errors: response.data,
            });
        }
    }

    validateData() {
        const { form, errors, resumeBlock } = this.state;
        let hasErrors = false;

        if (form.resume_file_path && form.resume_file_path.size > 2097152) {
            hasErrors = true;
            errors.resume_file_path = 'File size is larger than 2MB';
        }

        if (!form.resume_file_path && resumeBlock.length == 0) {
            hasErrors = true;
            errors.resume_block_ids = 'Upload your resume or select profile to submit the form';
        }

        Object.keys(form).forEach((key) => {
            if (key !== 'deleted_at' && key !== 'resume_view_block' && key !== 'resume_file_path' && key !== 'covering_letter_text') {
                if (typeof (form[key]) === 'string') {
                    if (!form[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!form[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return form;
    }

    handleSubmit(e) {
        e.preventDefault();
        const validData = this.validateData();
        const { resumeBlock } = this.state;
        if (validData) {
            validData['resume_block_ids'] = resumeBlock;
            validData['display_order'] = 1;

            const sendFormData = new FormData();

            if (sendFormData) {
                sendFormData.append('title', validData.title);
                sendFormData.append('covering_letter_text', validData.covering_letter_text || '');
                sendFormData.append('resume_block_ids', validData.resume_block_ids);
                sendFormData.append('display_order', validData.display_order);
                sendFormData.append('resume_file_path', validData.resume_file_path);

                if (validData.id) {
                    sendFormData.append('id', validData.id);
                    this.props.postUserResumeViewEdit(sendFormData).then((response) => {
                        if (response) {
                            this.updateResponse(response.data, 'Details edited successfully!');
                        }
                    });
                } else {
                    this.props.postUserResumeViewCreate(sendFormData).then((response) => {
                        if (response) {
                            this.updateResponse(response.data, 'Details added successfully!');
                        }
                    });
                }
            }
        }
    }

    render() {
        const dList = [];
        let viewResume = '';
        const self = this;
        const { resumeDetail, onModalClose, disableClose,id } = this.props;
        const { resumeBlock, form, errors, modalCloseDisable } = this.state;
        const Education = [];
        const Work = [];
        const certify = [];

        if (form.resume_file_path && !form.resume_file_path.name) {
            viewResume = (
                <div className="w3-col m6 s12">
                    <a className="h7t-view-resume-link" href={form.resume_file_path} target="_blank">View Resume</a>
                    <a className="w3-button w3-padding-small w3-hover-white" onClick={() => this.handleFileUpload('remove')} >
                        <span className="icon ion-close-circled"></span>
                    </a>
                </div>
            );
        }
        if (resumeDetail && resumeDetail.data && resumeDetail.data.length > 0) {
            dList.push(<p key="or" className="w3-center">OR</p>);
            resumeDetail.data.map((data) => {
                const idx = data.id;
                data.selectBlock = '';
                resumeBlock.forEach((selected) => {
                    if (selected == idx && !data.selectBlock) {
                        data.selectBlock = 'checked';
                    }
                });
                const block = (
                    <div key={idx} className="h7t-padding-list">
                        <CheckboxMUI
                            name="work"
                            checked={data.selectBlock === 'checked'}
                            id={data.id}
                            value={data.id}
                            label={data.type === 'Certificate' ? data.certificate : `${data.title}, ${data.institution}`}
                            onChange={self.handleResumeBlocks.bind(self, idx)}
                        />
                    </div>
                );
                data.type === 'Experience' ? Work.push(block): null;
                data.type === 'Certificate' ? certify.push(block): null;
                data.type === 'Education' ? Education.push(block) : null;
            });
        }

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} disableClose={modalCloseDisable} id={id} title="Resume Maker" widthCls="chp-small-dlg">
                <form id="resume_form" encType="multipart/form-data" className="form-horizontal">
                    <InputTextField
                        label="Title"
                        name="title"
                        id="title"
                        required
                        value={form.title ? form.title : ''}
                        onChange={this.handleChange}
                        errors={errors.title}
                        autoFocus
                    />

                    <div className="w3-padding">
                        <label>Covering Letter</label>
                        <ReactQuill
                            value={form.covering_letter_text ? form.covering_letter_text : ''}
                            placeholder="Covering Letter"
                            style={{height: '100px', marginBottom: '50px'}}
                            onChange={this.handleCoverLetter} 
                            className="w3-text-black"
                        />
                        <FormHelperText error={errors.covering_letter_text}>{errors.covering_letter_text}</FormHelperText>
                    </div>

                    <div className="w3-panel">
                        <div className="w3-border h7t-margin-top-mobile">
                            <div className="w3-bar w3-row w3-light-gray">
                                <div className="w3-bar-item  w3-quarter">
                                    <strong>Choose your resume</strong>
                                </div>
                            </div>
                            <div className="w3-row w3-padding w3-center">
                                <div className="w3-col m6 s12">
                                    <input id="myfile" name="myfile" type="file" onChange={this.handleFileUpload} />
                                </div>
                                {errors.resume_file_path
                                    ? (
                                        <div className="w3-col s12 w3-left-align">
                                            <FormHelperText error={errors.resume_file_path}>{errors.resume_file_path}</FormHelperText>
                                        </div>
                                    ) : null}
                                {viewResume}
                            </div>
                            {dList}
                            {Work.length ? (
                                <div className="w3-margin-bottom">
                                    <strong className="w3-margin">Experience</strong>
                                    {Work}
                                </div>
                            ) : null}
                            {Education.length ? (
                                <div className="w3-margin-bottom">
                                    <strong className="w3-margin">Education</strong>
                                    {Education}
                                </div>
                            ) : null}
                            {certify.length ? (
                                <div className="w3-margin-bottom">
                                    <strong className="w3-margin">Certification</strong>
                                    {certify}
                                </div>
                            ) : null}
                            <div className="w3-margin-left w3-margin-bottom">
                                <FormHelperText error={errors.resume_block_ids}>{errors.resume_block_ids}</FormHelperText>
                            </div>
                        </div>
                    </div>
                    <div className="w3-bar w3-panel">
                        <Button className={`w3-margin-right h7t-secondary-btn ${modalCloseDisable ? 'w3-disabled': ''}`} disabled={modalCloseDisable} onClick={onModalClose}>Close</Button>
                        <Button type="submit" className="h7t-primary-btn" onClick={this.handleSubmit}>Save changes</Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        resumeDetail: state.resumeDetail,
        currentUser,
    };
}
export default connect(mapStateToProps, {
    getCurrentDetailsList,
    setAppMessage,
    getResumeBlockViews,
    postUserResumeViewEdit,
    postUserResumeViewCreate,
    postApplyJob,
})(ResumeViewBlock);
