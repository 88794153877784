import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import {
    setAppMessage,
    postChangePassword,
} from '../../js/actions';
import Modal from './Modal';
import { InputTextField } from './MaterialUI/HelperInputs';

class ChangePassword extends Component {
    static propTypes = {
        setAppMessage: PropTypes.func,
        postChangePassword: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            formData: {
                // password: '',
                new_password: '',
                confirm_password: '',
            },
            errors: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(evt) {
        const targetElement = $(evt.target).attr('id');
        const { errors, formData } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({
                errors,
            });
        }

        formData[targetElement] = $(evt.target).val();
        this.setState({
            formData,
        });
    }

    validateData() {
        const { formData, errors } = this.state;
        let hasErrors = false;

        if (formData.new_password !== formData.confirm_password) {
            hasErrors = true;
            errors.confirm_password = 'The confirm password and new password must match.';
        }

        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                hasErrors = true;
                errors[key] = 'This field is mandatory';
            }
        });

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return formData;
    }

    handleSubmit(e) {
        e.preventDefault();
        const ValidData = this.validateData();
        if (ValidData) {
            this.props.postChangePassword(ValidData).then((response) => {
                if (response.data.success) {
                    this.props.setAppMessage('success', 'Password changed successfully!');
                    this.setState({ formData: {}, errors: {} }, () => {
                        this.props.onModalClose();
                    });
                } else {
                    this.setState({
                        errors: response.data.data,
                    });
                }
            });
        }
    }

    render() {
        const { errors, formData } = this.state;
        const { onModalClose } = this.props;
        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                title="Change Password"
                widthCls="chp-small-dlg"
            >
                <div>
                    <form className="form-horizontal" noValidate onSubmit={this.handleSubmit}>
                        {/* <InputTextField
                            label="Current Password"
                            onChange={this.handleChange}
                            type="password"
                            id="password"
                            name="password"
                            required
                            value={formData.password ? formData.password : ''}
                            errors={errors.password}
                        /> */}
                        <InputTextField
                            label="New Password"
                            onChange={this.handleChange}
                            type="password"
                            id="new_password"
                            name="new_password"
                            required
                            value={formData.new_password ? formData.new_password : ''}
                            errors={errors.new_password}
                        />
                        <InputTextField
                            label="Confirm Password"
                            onChange={this.handleChange}
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            required
                            value={formData.confirm_password ? formData.confirm_password : ''}
                            errors={errors.confirm_password}
                        />
                        <div className="w3-margin-bottom">
                            <Button className="h7t-primary-btn w3-margin-left" type="submit">Change Password</Button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
    setAppMessage,
    postChangePassword,
})(ChangePassword);
