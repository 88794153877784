import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import { Tabs } from 'antd';
import {
    Tooltip, Button, Breadcrumbs, Link, Typography, Paper, Tabs, Tab, Box,
} from '@material-ui/core';
import {
    getWorkflowState,
    postWorkflowStateDelete,
    getCurrentWorkflows,
    setAppMessage,
    postWorkflowStateEdit,
    getManage,
} from '../../js/actions';

import FlowStateEditModal from './ModalEditFlowState';
import { LoadingIcon } from '../Common/HelperComponents';
import WorkflowDeleteModal from '../Common/DeleteRecordModal';
import WorkflowChart from '../JsPlumb/WorkflowChart';
import WorkflowListView from './WorkflowListView'; 

// const { TabPane } = Tabs;
const TabPanel = ({
    handleChange,
    value,
    index,
    children,
}) => {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        // {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
class PreviewWorkflow extends Component {
    static propTypes = {
        workflowState: PropTypes.object,
        params: PropTypes.object,
        currentWorkflows: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            processFlow: '',
            deleteId: '',
            value: 0,
        };
        this.handleAddEditState = this.handleAddEditState.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { match: { params }, currentWorkflows } = this.props;
        if (!currentWorkflows) {
            this.props.getCurrentWorkflows();
        }
        this.props.getWorkflowState(params.workflowId);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.match.params.workflowId !== this.props.match.params.workflowId) {
            this.props.getWorkflowState(newProps.match.params.workflowId);
        }
    }

    handleAddEditState(processFlow) {
        let data = processFlow;
        const { match:{params} } = this.props;
        if (data === 'Add') {
            data = {
                workflow_id: params.workflowId,
                state_name: '',
                state_action_name: '',
                state_type: 'In process',
                action_type: '',
                weight: '',
                actor_role_id: '',
                target_states: [],
                scheduledTargets: {},
            };
        }
        this.setState({ processFlow: data });
    }

    updateResponse(response) {
        if (response.success) {
            this.props.setAppMessage('success', 'Record deleted successfully!');
            $('.close-button').click();
        }
    }

    handleDelete(data) {
        if (data === true) {
            const { deleteId } = this.state;
            const { workflowState } = this.props;
            if (deleteId.workflow_id && deleteId.state_name) {
                deleteId.workflow_state_id = deleteId.id;
                this.props.postWorkflowStateDelete(deleteId).then((response) => {
                    // this.updateResponse(response.data);
                    const res = response && response.data;
                    if (res && res.success) {
                        this.props.getManage();
                        if (workflowState && workflowState.data) {
                            const index = workflowState.data.findIndex(state => state.id == deleteId.id);
                            const updateState = Object.assign({}, workflowState.data[index - 1]);
                            updateState.target_states = deleteId.target_states;
                            updateState.workflow_state_id = updateState.id;
                            this.props.postWorkflowStateEdit(updateState).then(() => {
                                this.props.getWorkflowState(deleteId.workflow_id);
                            });
                        }
                        this.props.setAppMessage('success', 'Step deleted successfully!');
                        $('.close-button').click();
                    }
                    // this.props.getWorkflowState(deleteId.workflow_id);
                });
            }
        } else {
            this.setState({ deleteId: data });
        }
    }
    handleChange(event, newValue){
        this.setState({
            value: newValue,
        });
    }

    render() {
        const { workflowState, match: {params}, currentWorkflows } = this.props;
        const { processFlow, deleteId, value } = this.state;
        let workflowStatesList = [];
        let targetStateList;
        let checkWorkflow = null;
        let acceptedStep = null;

        if (!currentWorkflows || !currentWorkflows.data) {
            return <LoadingIcon />;
        }

        if (currentWorkflows.data.length) {
            checkWorkflow = currentWorkflows.data.find((data) => {
                return data.id == params.workflowId;
            });
            if (!checkWorkflow) {
                return (
                    <div className="w3-row w3-center w3-padding-24">
                        <h4>Workflow not found</h4>
                    </div>
                );
            }
            if (workflowState && workflowState.data) {
                if (workflowState.data.length > 0) {
                    workflowState.data.forEach((item, idx) => {
                        if (item.display_order === -1) {
                            return;
                        }
                        const steps = (
                            <tr key={`item-${idx}`}>
                                <td className="w3-left-align">
                                    <span>{item.state_name}</span>
                                    <div className="w3-text-grey">
                                        <span className="w3-tiny">Assigned to </span>
                                        {item.actorName}
                                    </div>
                                    {item.weight && item.display_order > 0
                                        ? <span className="w3-text-gray w3-tiny">Weightage : {item.weight}</span>
                                        : null}
                                </td>
                                <td className="w3-left-align">
                                    <ol className="h-padding-left-20">
                                        {item.target_states.map((target) => {
                                            targetStateList = _.where(workflowState.data, { id: target });
                                            return (<li key={target}>{targetStateList.length && targetStateList[0].state_name}</li>);
                                        })}
                                    </ol>
                                </td>
                                <td className="w3-left-align">
                                    <div className="w3-bar">
                                        {(item.state_type !== 'End' && item.state_type !== 'end') 
                                            ? (
                                                <Tooltip title="Edit">
                                                    <button
                                                        type="button"
                                                        className="w3-button w3-padding-small w3-hover-teal icon-border"
                                                        onClick={() => this.handleAddEditState(item)}
                                                    >
                                                        <span className="ion-edit" />
                                                    </button>
                                                </Tooltip>
                                            ) : null}
                                        {((item.state_type !== 'End' && item.state_type !== 'end') && (item.state_type !== 'Start' && item.state_type !== 'start'))
                                            ? (
                                                <Tooltip title="Delete">
                                                    <button
                                                        type="button"
                                                        disabled={checkWorkflow && checkWorkflow.count_jobs_used}
                                                        className="w3-button w3-padding-small w3-hover-red icon-border"
                                                        onClick={() => this.handleDelete(item)}
                                                    >
                                                        <span className="ion-trash-a" />
                                                    </button>
                                                </Tooltip>
                                            ) : null}
                                    </div>
                                </td>
                            </tr>
                        );
                        if (item.display_order === 0) {
                            acceptedStep = steps;
                        } else {
                            workflowStatesList.push(steps);
                        }
                    });
                } else {
                    workflowStatesList = <tr><td colSpan="3" className="w3-center w3-light-grey">There are no workflow states to display!</td></tr>;
                }
            }
        }

        let addStateBtn = null;
        if (params.workflowId) {
            addStateBtn = (
                <Button
                    variant="contained"
                    className={`${checkWorkflow && checkWorkflow.count_jobs_used ? 'w3-disabled' : ''} h7t-secondary-btn`}
                    disabled={checkWorkflow && checkWorkflow.count_jobs_used}
                    onClick={() => this.handleAddEditState('Add')}
                >
                    Add Step
                </Button>
            );
        }

        return (
            <div className="w3-row content-margin">
                <div>
                    <div className="w3-row w3-margin-top w3-display-container">
                        <h6>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link
                                    href="/"
                                    color="inherit"
                                    underline="none"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push({
                                            pathname: `/e/${params.companyId}/${params.officeId}/hiring-workflow/`,
                                            state: {
                                                companyId: params.companyId,
                                                officeId: params.officeId,
                                            }
                                        })
                                    }}
                                >
                                    Hiring Workflow
                                </Link>
                                <Typography color="primary">
                                    {`Hiring Workflow Steps ${checkWorkflow && checkWorkflow.name ? `(${checkWorkflow.name})` : null}`}
                                </Typography>
                            </Breadcrumbs>
                        </h6>
                    </div>
                    <Paper>
                        <div style={{ marginTop: '8px' }} className="w3-right w3-margin-right w3-margin-left">{addStateBtn}</div>
                        <Tabs 
                            value={value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="workflow States"
                            variant="fullWidth"
                            className="tab-border"
                        >
                            <Tab label="List View" className="h7t-capitalize-text" />
                            <Tab label="Text View" className="h7t-capitalize-text" />
                            <Tab label="Graph View" className="h7t-capitalize-text" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            {workflowState
                                ? (
                                    <WorkflowListView
                                        workflowState={workflowState}
                                        onEdit={this.handleAddEditState}
                                        onDelete={this.handleDelete}
                                        params={params}
                                        currentWorkflowsList={currentWorkflows}
                                    />
                                ) : null}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className="w3-responsive">
                                <table className="w3-table w3-striped" style={{ float: "left" }}>
                                    <thead className="w3-border-bottom">
                                        <tr>
                                            <th className="w3-left-align">Step Name</th>
                                            <th className="w3-left-align">Target Steps</th>
                                            <th className="w3-left-align">Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {workflowStatesList}
                                        {acceptedStep}
                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {workflowState
                                ? (
                                    <WorkflowChart
                                        workflowState={workflowState}
                                        checkWorkflow={checkWorkflow}
                                        onEdit={this.handleAddEditState}
                                        onDelete={this.handleDelete}
                                        key={new Date().getTime()}
                                    />
                                ) : null}
                        </TabPanel>
                    </Paper>
                </div>
                {processFlow
                    ? (
                        <FlowStateEditModal
                            processFlow={processFlow}
                            onModalClose={() => this.setState({ processFlow: '' })}
                        />
                    ) : null}
                {deleteId
                    ? (
                        <WorkflowDeleteModal
                            handleDelete={this.handleDelete}
                            deleteMessage="step"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteId: '' })}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentWorkflows = state.currentWorkflows && state.currentWorkflows.data;
    return {
        workflowState: state.workflowState,
        currentWorkflows,
        userRole: state.userRole,
    };
}
export default withRouter(connect(mapStateToProps, {
    getWorkflowState,
    postWorkflowStateDelete,
    getCurrentWorkflows,
    setAppMessage,
    postWorkflowStateEdit,
    getManage,
})(PreviewWorkflow));
