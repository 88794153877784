import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { jsPlumb } from 'jsplumb';
import {
    postWorkflowStateEdit,
    getWorkflowState,
    setAppMessage,
} from '../../js/actions';

class WorkflowChart extends Component {
    static propTypes = {
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        setAppMessage: PropTypes.func,
        workflowState: PropTypes.object,
        getWorkflowState: PropTypes.func,
        postWorkflowStateEdit: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            workflowState: [],
        };
    }

    componentDidMount() {
        const { workflowState } = this.props;
        this.setState({
            workflowState,
        }, () => {
            this.handleConnectNodes();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.workflowState !== this.state.workflowState) {
            this.setState({
                workflowState: nextProps.workflowState,
            }, () => {
                this.handleConnectNodes();
            });
        }
    }

    onEdit(item) {
        this.props.onEdit(item);
    }

    onDelete(item) {
        this.props.onDelete(item);
    }

    handleConnectNodes() {
        const connections = [];
        const states = [];
        const { workflowState } = this.state;
        const { postWorkflowStateEdit, setAppMessage, getWorkflowState } = this.props;
        if (workflowState && workflowState.data && workflowState.data.length) {
            workflowState.data.map((item) => {
                states.push(item.id.toString());
                if (item.target_states && item.target_states.length > 0) {
                    item.target_states.map((target) => {
                        connections.push({
                            source: `${item.id}`,
                            target: `${target}`,
                            hoverPaintStyle: { stroke: 'black' },
                            overlays: [['Arrow', { width: 8, length: 10 }]],
                        });
                    });
                }
            });
        }

        jsPlumb.ready(() => {
            const firstInstance = jsPlumb.getInstance({
                Connector: ['Flowchart', { stub: 0 }],
                Endpoint: ['Dot', { radius: 3 }],
                EndpointStyle: { fill: '#a2a2a2' },
                Anchor: 'Continuous',
                Container: 'h7t-workflow-container',
                PaintStyle: { stroke: '#a2a2a2' },
            });

            jsPlumb.importDefaults({
                // ConnectionsDetachable:true,
                // ReattachConnections:true
            });

            // firstInstance.draggable(firstInstance.getSelector(".workflow-chart .window"), { grid: [20, 20] });

            const editConnection = (item) => {
                item.workflow_state_id = item.id;
                postWorkflowStateEdit(item).then((resp) => {
                    const response = resp && resp.data;
                    if (response && response.success) {
                        setAppMessage('success', 'Connection updated successfully!');
                        getWorkflowState(item.workflow_id);
                        return true;
                    }
                    setAppMessage('fail', response.data.error);
                    getWorkflowState(item.workflow_id);
                    return false;
                });
            };

            firstInstance.bind('connectionMoved', (con) => {
                workflowState.data.map((item) => {
                    if (item.id == con.originalSourceId) {
                        item.target_states.map((target, index) => {
                            if (target == con.originalTargetId) {
                                item.target_states.splice(index, 1);
                                editConnection(item);
                            }
                        });
                    }
                    if (item.id == con.newSourceId) {
                        item.target_states.push(con.newTargetId);
                        editConnection(item);
                    }
                });
            });

            firstInstance.bind('connectionDetached', (con) => {
                workflowState.data.map((item) => {
                    if (item.id == con.sourceId) {
                        item.target_states.map((target, index) => {
                            if (target == con.targetId) {
                                item.target_states.splice(index, 1);
                                editConnection(item);
                            }
                        });
                    }
                });
            });

            const initNode = (el) => {
                firstInstance.draggable(el);

                firstInstance.makeSource(el, {
                    filter: '.h7t-ep',
                    anchor: 'Continuous',
                    connectorStyle: {
                        stroke: '#a2a2a2', strokeWidth: 2, outlineStroke: 'transparent', outlineWidth: 4,
                    },
                    connectionType: 'basic',
                    // maxConnections: 5,
                    // onMaxConnections: function (info, e) {
                    //     alert("Maximum connections (" + info.maxConnections + ") reached");
                    // }
                });

                firstInstance.makeTarget(el, {
                    // dropOptions: { hoverClass: "dragHover" },
                    beforeDrop: (params) => {
                        workflowState.data.map((item) => {
                            if (item.id == params.sourceId) {
                                item.target_states.push(params.targetId);
                                editConnection(item);
                            }
                        });
                    },
                    anchor: 'Continuous',
                });

                firstInstance.fire('jsPlumbDemoNodeAdded', el);
            };

            jsPlumb.batch(() => {
                firstInstance.deleteEveryConnection();

                for (let i = 0; i < states.length; i++) {
                    initNode(states[i], true);
                }

                connections.map((nodes) => {
                    firstInstance.connect(nodes);
                });
                // firstInstance.connect({ source:"node-1", target:"node-4", overlays:[[ "Arrow", { width:10, height:10 }]] });
            });
        });
    }

    render() {
        const { workflowState } = this.state;
        const { key } = this.props;
        const nodes = [];
        let codeflowDiagram = null;
        if (workflowState && workflowState.data && workflowState.data.length) {
            let top = 0;
            let left = 0;
            let start = 0;
            let inProcess = 1;
            let end = 2;
            const totalWidth = $('#flowChartDiv').width();
            workflowState.data.map((item) => {
                if (item.state_type === 'Start') {
                    top = start * 80;
                    start += 1;
                    left = 20;
                }
                if (item.state_type === 'In process') {
                    top = inProcess * 120;
                    inProcess += 1;
                    left = (totalWidth / 4);
                }
                if (item.state_type === 'End') {
                    top = end * 120;
                    end += 1;
                    left = ((totalWidth / 4) * 3) + 50;
                }

                const nodePosition = { top, left, bottom: 20 };
                nodes.push(
                    <div
                        key={item.id}
                        style={nodePosition}
                        className="window jtk-node"
                        id={`${item.id}`}
                    >
                        <div className="w3-row">
                            {item.state_name}
                        </div>
                        <div className="w3-row">
                            <div className="w3-col m4 s4" action="newconnection">
                                <div className="h7t-ep" />
                            </div>
                            <div className="w3-col m4 s4">
                                {(item.state_type !== 'End')
                                    ? (
                                        <button
                                            type="button"
                                            className="w3-button w3-padding-small"
                                            onClick={() => this.onEdit(item)}
                                        >
                                            <span className="ion-edit" />
                                        </button>
                                    )
                                    : null}
                            </div>
                            <div className="w3-col m4 s4">
                                {((item.state_type !== 'End' && item.state_type !== 'end') && (item.state_type !== 'Start' && item.state_type !== 'start'))
                                    ? (
                                        <button
                                            type="button"
                                            disabled={this.props.checkWorkflow}
                                            className="w3-button w3-padding-small"
                                            onClick={() => this.onDelete(item)}
                                        >
                                            <span className="ion-trash-a" />
                                        </button>
                                    ) : null}
                            </div>
                        </div>
                    </div>,
                );
            });
            codeflowDiagram = (
                <div className="h7t-workflow-container">
                    <div className="h7t-workflow-chart" id="h7t-workflow-container" key={key}>
                        {nodes}
                    </div>
                </div>
            );
        }

        return (
            <div className={codeflowDiagram ? 'w3-margin-bottom' : ''} id="flowChartDiv">
                {codeflowDiagram}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}
export default connect(mapStateToProps, {
    postWorkflowStateEdit,
    getWorkflowState,
    setAppMessage,
})(WorkflowChart);
