import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getList,
    setAppMessage,
    postProfileEdit,
    postProfileCreate,
    getUnreadNotifications,
    getApplicationStatusList,
    getAllJobApplications,
    getApplicationTitleList,
    getActiveJobs,
    postChangeCandidateProfileStatus,
    getApplicationStatus,
    getApplicantsProfileStatus,
    getProfileScheduleApplicationStatus,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { InputTextField, InputSelectField } from '../Common/MaterialUI/HelperInputs';
import { FormHelperText, Button } from '@material-ui/core';

const availableChannels = [
    'Indeed',
    'Monster',
    'Naukri',
    'LinkedIn',
    'IIMJobs',
    'Facebook',
    'Email',
    'Other',
];

class ProfileAddEditModal extends Component {
    static propTypes = {
        getList: PropTypes.func,
        currentPage: PropTypes.number,
        onModalClose: PropTypes.func,
        profileEditData: PropTypes.object,
        postProfileEdit: PropTypes.func,
        setAppMessage: PropTypes.func,
        postProfileCreate: PropTypes.func,
        getAllCurrentJobList: PropTypes.func,
        currentUser: PropTypes.object,
        postChangeCandidateProfileStatus: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {
                resume_file_path: '',
            },
            resumeFile: null,
            disableBtn: false,
            showOtherChannel: false,
            disableInput: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleAddbacktoJob = this.handleAddbacktoJob.bind(this);
    }

    componentDidMount() {
        const { profileEditData, activeJobs } = this.props;
        const { disableInput } = this.state;
        if(!activeJobs) {
            this.props.getActiveJobs();
        }
        if (profileEditData) {
            let showOtherChannel = false;
            if(profileEditData.profile_id) {
                profileEditData.other_text_channel = profileEditData.channel;
                this.props.getProfileScheduleApplicationStatus(profileEditData.profile_id).then((response) =>{
                    const resp = response && response.data && response.data.data;
                    if(resp.length>0 ) {
                        this.setState({ disableInput : true });
                    }
                });
            }
            if (profileEditData.channel && profileEditData.channel == 'Other' || availableChannels.indexOf(profileEditData.channel) === -1) {
                profileEditData.channel = 'Other';
                showOtherChannel = true;
            }
            this.setState({ formData: Object.assign({}, profileEditData), showOtherChannel });
        }
    }

    resetError(targetElement) {
        const { errors } = this.state;
        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({
                errors,
            });
        }
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { formData, errors } = this.state;
        let { showOtherChannel } = this.state;
        formData[name] = value;
        this.resetError(name);
        if (name === 'channel' && value === 'Other') {
            formData.other_text_channel = 'HiringnXt';
            errors.other_text_channel = null;
            showOtherChannel = true;
        }
        if (name === 'channel' && value !== 'Other' && showOtherChannel) {
            showOtherChannel = false;
        }
        if (name == 'other_text_channel') {
            formData.other_text_channel = value;
        }
        this.setState({
            formData,
            showOtherChannel,
            errors,
        });
    }

    handleFileUpload(event) {
        const { formData } = this.state;
        const targetElement = $(event.target).attr('id');
        formData.resume_file_path = event.target.files[0] ? event.target.files[0] : '';
        this.setState({
            formData,
        }, () => {
            this.resetError(targetElement);
        });
    }

    updateResponse(response, successMessage, status) {
        let { currentPage, profileStatus, profileEditData, getApplicantsProfileStatus } = this.props;
        if (!status) {
            currentPage = 1;
        }
        if (response.success) {
            if(this.props.changeProfileStatus == 'AddBacktoJob') {
                // if(profileEditData.currentApplnStatus == true) {
                    this.handleAddbacktoJob(profileEditData.applicationId);
                // }
                getApplicantsProfileStatus(profileEditData.applicant_profile_id ?
                    profileEditData.applicant_profile_id : profileEditData.applicant_user_id);
                successMessage = 'Profile added back to job successfully!';
                this.props.history.push(`/e/${profileEditData.company_id}/${profileEditData.office_id}/on-going`);
            }
            this.props.setAppMessage('success', successMessage);
            this.props.getAllJobApplications({ 
                page: currentPage,
                profileStatus: profileStatus,
            });
            this.props.getApplicationTitleList();
            this.props.getApplicationStatusList();
            $('.close-button').click();
            this.setState({
                formData: {},
                errors: {},
                disableBtn: false,
            });
        } else if (response.data) {
            this.setState({
                errors: response.data,
                disableBtn: false,
            });
        } else {
            this.props.setAppMessage('fail', response.message);
            this.setState({
                disableBtn: false,
            });
        }
    }

    handleAddbacktoJob(applnId) {
        let {
            postChangeCandidateProfileStatus,
            getApplicationStatus,
        } = this.props;
        const data = {
            application_id: applnId,
            profile_status: 'On-going',
        }
        postChangeCandidateProfileStatus(data).then((response) => {
            if (response.data.success) {
                getApplicationStatus(applnId);
            }
        });
    }

    validateData() {
        const { formData, errors } = this.state;
        let hasErrors = false;
        
        const ignoreList = [
            'app_user_id',
            'end_status',
            'status',
            'type',
            'reffered_by',
            'resume_text',
            'resume_view_id',
            'applicant_user_id',
            'notified_at',
            'category_title',
            'min_experience_required',
            'positions_filled',
            'universities',
            'certifications',
            'remote_Jobs',
            'visible_to_Managment',
            'views',
            'address_line_2',
            'address_line_3',
            'latitude',
            'longitude',
            'access_token',
            'applicant_profile_id',
            'current_company_id',
            'current_office_id',
            'profile_pic_id',
            'reset_token',
            'timezone_offset',
            'verified_at',
            'currentApplnStatus',
            'offer_letter_path',
            'reason',
            'domain',
            'education',
            'project_description',
            
        ];
        if(formData.channel !== 'Other') {
            ignoreList.push('other_text_channel');
        }
        if (!/^\d{10}$/.test(formData.phone_number)) {
            hasErrors = true;
            errors.phone_number = 'Enter a valid phone number';
        }
        if (!/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(formData.email)) {
            hasErrors = true;
            errors.email = 'Enter a valid email';
        }

        if (formData.reffered_by && !/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(formData.reffered_by)) {
            hasErrors = true;
            errors.reffered_by = 'Enter a valid email';
        }

        if (formData.resume_file_path && formData.resume_file_path.size > 2097152) {
            hasErrors = true;
            errors.resume_file_path = 'File size is larger than 2MB';
        }

        if (parseFloat(formData.expected_ctc_min, 10) <= parseFloat(formData.current_ctc, 10)) {
            hasErrors = true;
            errors.expected_ctc_min = 'Expected CTC must be greater than Current CTC ';
        }
        const fields = ['relevant_experience', 'relevant_experience_month'];
        const salaryFields = [ 'current_ctc','expected_ctc_min'];
        Object.keys(formData).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (formData[key]) === 'string') {
                    if (!formData[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                const regExp = /^\d+$/;
                if (fields.indexOf(key) !== -1) {
                    if (formData[key] && !regExp.test(formData[key])) {
                        hasErrors = true;
                        errors[key] = 'Enter a valid number';
                    } 
                    else if (formData[key] === 0) {
                        return;
                    }
                }

                const salaryRegExp = /^\d+[.]?\d*$/;
                if (salaryFields.indexOf(key) !== -1) {
                    if (formData[key] && !salaryRegExp.test(formData[key])) {
                        hasErrors = true;
                        errors[key] = 'Enter a valid number';
                    }
                }

                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return formData;
    }

    handleFormSubmit(evt, status) {
        // evt.preventDefault();
        const { profileStatus } = this.props;
        const validData = this.validateData();
        if (validData) {
            const sendFormData = new FormData();
            if (sendFormData) {
                sendFormData.append('display_name', validData.display_name);
                sendFormData.append('email', validData.email);
                sendFormData.append('phone_number', validData.phone_number);
                sendFormData.append('relevant_experience', validData.relevant_experience);
                sendFormData.append('relevant_experience_month', validData.relevant_experience_month);
                sendFormData.append('current_ctc', validData.current_ctc);
                sendFormData.append('expected_ctc_min', validData.expected_ctc_min);
                sendFormData.append('resume_text', validData.resume_text);
                sendFormData.append('profile_info', validData.profile_info);
                sendFormData.append('job_in_consideration', validData.job_in_consideration);
                sendFormData.append('visibility', validData.visibility);
                sendFormData.append('resume_file_path', validData.resume_file_path);
                sendFormData.append('channel', validData.channel == 'Other' ? validData.other_text_channel : validData.channel);
                sendFormData.append('reffered_by', validData.reffered_by || '');
                sendFormData.append('status', 'active');
                sendFormData.append('profile_status', status == 'archive' ? 'Archived' : validData.profile_status);
                this.setState({
                    disableBtn: true,
                }, () => {
                    if (validData.profile_id) {
                        sendFormData.append('profile_id', validData.profile_id);
                        this.props.postProfileEdit(sendFormData).then((response) => {
                            this.updateResponse(response.data, 'Profile edited successfully', true);
                        });
                    } else {
                        this.props.postProfileCreate(sendFormData).then((response) => {
                            this.props.getUnreadNotifications();
                            this.updateResponse(response.data, profileStatus == 'archived' || status == 'archive' ? 'Profile archived successfully!' : 'Profile added successfully!', false);
                        });
                    }
                });
            }
        }
        return false;
    }

    render() {
        let resumeFileName = '';
        const { onModalClose, activeJobs, profileStatus } = this.props;
        const { errors, formData, disableBtn, showOtherChannel ,disableInput } = this.state;
        if (typeof formData.resume_file_path === 'string') {
            const res = formData.resume_file_path.split('/');
            resumeFileName = res[res.length - 1];
        }
        const visibilitySelect = [
            { val: '', name: 'Select Visibility' },
            { val: 'creator_only', name: 'Creator Only' },
            { val: 'visible', name: 'Visible to Everyone' },
        ];
        const channel = [
            { val: '', name: 'Select Channel' },
        ];
        const relevant_month = [
            { val: '0', name: '0'},
            { val: '1', name: '1'},
            { val: '2', name: '2'},
            { val: '3', name: '3'},
            { val: '4', name: '4'},
            { val: '5', name: '5'},
            { val: '6', name: '6'},
            { val: '7', name: '7'},
            { val: '8', name: '8'},
            { val: '9', name: '9'},
            { val: '10', name: '10'},
            { val: '11', name: '11'},
        ];

        availableChannels.map((medium) => {
            channel.push({ val: medium, name: medium });
        });


        const jobSelect = [{ val: '', name: 'Select Job Considered' }];
        if (activeJobs && activeJobs.data) {
            activeJobs.data.forEach((item) => {
                jobSelect.push({ val: item.id, name: item.job_title });
            });
        }

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title={formData.profile_id ? 'Edit Profile' : 'Create Profile'} >
                <form encType="multipart/form-data">
                    <div className="w3-col m6 h7t-padding-mobile-view">
                        <InputTextField
                            autoFocus
                            label="Candidate Name"
                            name="display_name"
                            id="display_name"
                            value={(formData.display_name) ? formData.display_name : ''}
                            onChange={e => this.handleChange(e)}
                            errors={errors.display_name}
                            required
                        />
                        <InputTextField
                            label="Email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={e => this.handleChange(e)}
                            errors={errors.email}
                            required
                        />
                        <InputTextField
                            label="Phone Number"
                            name="phone_number"
                            id="phone_number"
                            value={(formData.phone_number) ? formData.phone_number : ''}
                            onChange={e => this.handleChange(e)}
                            errors={errors.phone_number}
                            required
                        />
                        <div style={{ marginTop: '-16px' }}>
                            <div className="w3-col m6">
                                <InputTextField
                                    required
                                    label="Experience in Years"
                                    name="relevant_experience"
                                    id="relevant_experience"
                                    value={formData.relevant_experience}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.relevant_experience}
                                />
                            </div>
                            <div className="w3-col m6">
                                <InputSelectField
                                    required
                                    label="Experience in Months"
                                    name="relevant_experience_month"
                                    id="relevant_experience_month"
                                    options={relevant_month}
                                    value={formData.relevant_experience_month}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.relevant_experience_month}
                                />
                            </div>
                        </div>

                        <InputTextField
                            label="Current CTC (LPA)"
                            name="current_ctc"
                            id="current_ctc"
                            value={formData.current_ctc}
                            onChange={e => this.handleChange(e)}
                            errors={errors.current_ctc}
                            required
                        />

                        <InputTextField
                            label="Expected CTC (LPA)"
                            name="expected_ctc_min"
                            id="expected_ctc_min"
                            value={formData.expected_ctc_min}
                            onChange={e => this.handleChange(e)}
                            errors={errors.expected_ctc_min}
                            required
                        />

                        <div className="w3-panel">
                            <FormHelperText>Resume*</FormHelperText>
                            <input
                                className="w3-input w3-border"
                                type="file"
                                id="resume_file_path"
                                name="resume_file_path"
                                onChange={e => this.handleFileUpload(e)}
                            />
                            <FormHelperText errors={errors.resume_file_path} className="w3-margin-left w3-text-red">
                                {errors.resume_file_path}
                            </FormHelperText>
                            {formData.profile_id || formData.applicant_profile_id || formData.applicant_user_id
                                ? <a className="h7t-hyperlink-color"  target="_blank"  href={formData.resume_file_path}>{resumeFileName}</a>
                                : null}
                        </div>

                    </div>
                    <div className="w3-col m6 h7t-padding-mobile-view">
                        <InputTextField
                            label="Profile Info"
                            name="profile_info"
                            id="profile_info"
                            multiline
                            rows="3"
                            onChange={e => this.handleChange(e)}
                            value={(formData.profile_info) ? formData.profile_info : ''}
                            errors={errors.profile_info}
                            required
                        />
                        <InputTextField
                            label="Notes by Recruiter"
                            name="resume_text"
                            id="resume_text"
                            multiline
                            rows="3"
                            onChange={e => this.handleChange(e)}
                            value={(formData.resume_text) ? formData.resume_text : ''}
                            errors={errors.resume_text}
                        />
                        <InputSelectField
                            label="Channel"
                            name="channel"
                            id="channel"
                            value={(availableChannels.indexOf(formData.channel) !== -1) ? formData.channel : formData.channel ? 'Other' : ''}
                            onChange={e => this.handleChange(e)}
                            errors={errors.channel}
                            options={channel}
                            required
                        />

                        {showOtherChannel ? (
                            <InputTextField
                                label="Enter Other Channel"
                                name="other_text_channel"
                                id="other_text_channel"
                                value={formData.other_text_channel ? formData.other_text_channel : ''}
                                onChange={e => this.handleChange(e)}
                                errors={errors.other_text_channel}
                                required
                            />
                        ) : null}
                        <InputTextField
                            label="Referred By"
                            name="reffered_by"
                            id="reffered_by"
                            placeholder="Enter email"
                            value={(formData.reffered_by) ? formData.reffered_by : ''}
                            onChange={e => this.handleChange(e)}
                            errors={errors.reffered_by}
                        />
                        <InputSelectField
                            label="Job Considered"
                            name="job_in_consideration"
                            id="job_in_consideration"
                            value={(formData.job_in_consideration) ? formData.job_in_consideration : ''}
                            onChange={e => this.handleChange(e)}
                            errors={errors.job_in_consideration}
                            options={jobSelect}
                            disabled={disableInput}
                            required
                        />
                        <InputSelectField
                            label="Visibility"
                            name="visibility"
                            id="visibility"
                            value={(formData.visibility) ? formData.visibility : ''}
                            onChange={e => this.handleChange(e)}
                            errors={errors.visibility}
                            options={visibilitySelect}
                            required
                        />
                    </div>
                    <div className="w3-bar w3-padding-16 w3-panel">
                        <Button 
                            onClick={(e) => this.handleFormSubmit(e, 'on-going')}
                            className={`h7t-primary-btn ${disableBtn ? 'w3-disabled' : ''}`}
                            disabled={disableBtn}
                        >
                            {formData.profile_id ? 'Update' : 
                                profileStatus == 'archived' ? 'Archive Profile' : 'Publish Profile'}
                        </Button>
                        {profileStatus == 'on-going' && this.props.changeProfileStatus !== 'AddBacktoJob' &&  !formData.profile_id ?
                            (<Button 
                                onClick={(e) => this.handleFormSubmit(e, 'archive')}
                                className={`h7t-secondary-btn w3-margin-left ${disableBtn ? 'w3-disabled' : ''}`}
                                disabled={disableBtn}
                            >
                                Archive Profile
                            </Button> 
                            ) : null}
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = (state && state.currentUser && state.currentUser.data) ? state.currentUser.data : (state && state.currentUser) ? state.currentUser : '';
    return {
        currentUser,
        activeJobs: state && state.activeJobs,
    };
}
export default withRouter(connect(mapStateToProps, {
    getList,
    postProfileEdit,
    postProfileCreate,
    setAppMessage,
    getUnreadNotifications,
    getApplicationStatusList,
    getAllJobApplications,
    getApplicationTitleList,
    getActiveJobs,
    postChangeCandidateProfileStatus,
    getApplicationStatus,
    getApplicantsProfileStatus,
    getProfileScheduleApplicationStatus,
})(ProfileAddEditModal));
