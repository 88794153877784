import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper
} from '@material-ui/core';

export default class TwoTableComponent extends Component {
    static propTypes = {
        data: PropTypes.array,
        heading: PropTypes.string,
    };

    render() {
        const { data, heading, keys } = this.props;
        return (
            <Fragment>
            <Paper style={{ height: '340px' }} className="w3-padding h7t-table-data">
            <h6 className="w3-text-grey h7t-margin-0">{heading}</h6>
                <TableContainer>
                    <Table className="table-striped">
                        <TableHead>
                            <TableRow>
                            {keys.map(key => {
                                        return(<TableCell align="center">
                                            {key}
                                                </TableCell>)
                                    })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, idx) => (<TableRow key={`item_${idx}`}>
                                <TableCell align="center">
                                    {item.name}
                                </TableCell>
                                <TableCell align="center">
                                    {(item.value) ? item.value : 0}
                                </TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            </Fragment>
        )
    }
}