import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { jsPlumb } from 'jsplumb';
import { connect } from 'react-redux';

class WorkflowTemplateChart extends Component {
    static propTypes = {
        templateData: PropTypes.object,
    };

    componentDidMount() {
        const connections = [];
        const { templateData } = this.props;
        if (templateData && templateData.state_details) {
            templateData.state_details.map((states) => {
                if (states.target_states && states.target_states.length > 0) {
                    states.target_states.map((nodes) => {
                        connections.push({
                            source: `${states.ref_id}`,
                            target: `${nodes}`,
                            hoverPaintStyle: { stroke: 'black' },
                            overlays: [['Arrow', { width: 8, length: 10 }]],
                        });
                    });
                }
            });
        }
        jsPlumb.ready(() => {
            const templateInstance = jsPlumb.getInstance({
                Connector: ['Flowchart', { stub: 0 }],
                Endpoint: ['Dot', { radius: 3 }],
                EndpointStyle: { fill: '#a2a2a2' },
                Anchor: 'Continuous',
                Container: 'workflowTemplate',
                PaintStyle: { stroke: '#a2a2a2' },
            });

            jsPlumb.importDefaults({
                ConnectionsDetachable: true,
                ReattachConnections: true,
            });

            templateInstance.draggable(templateInstance.getSelector('.h7t-workflow-template .window'), { grid: [20, 20] });

            jsPlumb.batch(() => {
                connections.map((nodes) => {
                    templateInstance.connect(nodes);
                });
            });
        });
    }

    render() {
        const { templateData } = this.props;
        const states = [];
        if (templateData && templateData.state_details) {
            let top = 0;
            let left = 0;
            let start = 0;
            let inProcess = 1;
            let end = 2;
            const totalWidth = $('#flowChartDiv').width();
            templateData.state_details.map((temp, idx) => {
                if (temp.state_type === 'Start') {
                    top = start * 60;
                    start += 1;
                    left = 10;
                }
                if (temp.state_type === 'In process') {
                    top = inProcess * 60;
                    inProcess += 1;
                    left = totalWidth / 3;
                }
                if (temp.state_type === 'End') {
                    top = end * 60;
                    end += 1;
                    left = (totalWidth / 3) * 2;
                }

                const nodePosition = { top, left };
                states.push(<div key={idx} style={nodePosition} className="window w3-padding jtk-node" id={temp.ref_id}>{temp.state_name}</div>);
            });
        }
        return (
            <div className="w3-container">
                <div className="h7t-workflow-template" id="workflowTemplate">
                    {states}
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {

})(WorkflowTemplateChart);
