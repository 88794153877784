import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    setAppMessage,
    postResetNewPassword,
    postLoginUser,
} from '../../js/actions';
import { Button } from '@material-ui/core';
import { FormTextField } from './HelperComponents';

class ResetPassword extends Component {
    static propTypes = {
        postResetNewPassword: PropTypes.func,
        setAppMessage: PropTypes.func,
        postLoginUser: PropTypes.func,
        params: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            formData: {
                password: '',
                confirm_password: '',
            },
            errors: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(evt) {
        const targetElement = $(evt.target).attr('id');
        const { errors, formData } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({
                errors,
            });
        }

        formData[targetElement] = $(evt.target).val();
        this.setState({
            formData,
        });
    }

    validateData(formData) {
        let isValid = true;
        const { errors } = this.state;

        if (formData.password !== formData.confirm_password) {
            errors.confirm_password = 'The password and confirm password must match.';
        }

        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                errors[key] = 'This field is mandatory.';
            }
            if (errors[key]) {
                isValid = false;
            }
        });

        if (Object.keys(errors).length > 0 && !isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        const { formData, errors } = this.state;
        const { match: { params: { token } } } = this.props;
        if (Object.keys(errors).length > 0) {
            this.setState({ errors: {} });
        }

        if (!this.validateData(formData)) {
            return;
        }

        formData.token = token;
        this.props.postResetNewPassword(formData).then((result) => {
            if (result.data.success) {
                this.props.setAppMessage('success', 'Password changed successfully!');
                formData.email = result.data.data.email;
                this.props.postLoginUser(formData).then((resp) => {
                    const response = resp && resp.data;
                    const companyId = response.data.current_company_id;
                    const officeId = response.data.current_office_id;
                    let redirectUrl = '/s/job-dashboard';
                    if (companyId && officeId) {
                        redirectUrl = {
                            pathname: `/e/${companyId}/${officeId}/dashboard`,
                            state: {
                                companyId,
                                officeId
                            }
                        };
                    }
                    this.props.history.push(redirectUrl);
                });
            } else {
                this.setState({
                    errors: result.data.data,
                });
            }
        });
    }

    render() {
        const { errors } = this.state;
        if (errors.error) {
            this.props.setAppMessage('fail', errors.error);
        }
        return (
            <div className="w3-content w3-margin-top w3-padding-64" style={{ maxWidth: 500 }}>
                <div className="w3-border w3-center">
                    <div className="w3-black w3-padding" style={{ opacity: '0.8' }}>
                        <img className="h7t-header-logo" src="https://assets.vegasonweb.com/hiring/logos/hiring_nxt_logo.png" alt="" />
                    </div>
                    <h3 className="w3-margin-top">Reset Password</h3>
                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <FormTextField
                            onChange={this.handleChange}
                            type="password"
                            id="password"
                            name="password"
                            placeholder="New Password"
                            inputClass="w3-input w3-border"
                            errors={errors.password}
                        />
                        <FormTextField
                            onChange={this.handleChange}
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            inputClass="w3-input w3-border"
                            errors={errors.confirm_password}
                        />
                        <Button
                            variant="contained"
                            className="w3-margin-bottom h7t-btn-background-blue w3-margin-left"
                            type="submit"
                        >
                            Reset Password
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {};
}
export default withRouter(connect(mapStateToProps, {
    setAppMessage,
    postResetNewPassword,
    postLoginUser,
})(ResetPassword));
