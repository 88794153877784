/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import UserRoleAddEditModal from './UserRoleAddEditModal';
import UserRoleDeleteModal from '../Common/DeleteRecordModal';
import UserWithRoleEditModal from './UserWithRoleEditModal';
import UserInviteModal from './UserInviteModal';
import { LoadingIcon } from '../Common/HelperComponents';
import {
    getManage,
    getListByRole,
    getCurrentUser,
    postDeleteRoleUser,
    setAppMessage,
    postDeleteRole,
    postInviteRoleUser,
} from '../../js/actions';
import ViewPermissions from './ViewPermissions';

const Role = ({
    item,
    disabled,
    isSelected,
    childTable,
    handleSelect,
    handleEdit,
    handleDelete,
    handleInviteUser,
    currentUser,
    permissions,
}) => {
    let showInvite = false;
    
    if (permissions && permissions.length) {
        if (permissions === 'Management') {
            showInvite = true;
        } else if (permissions === 'HR') {
            if (item.permission_type === 'HR' || item.permission_type === 'Panel Member') {
                showInvite = true;
            }
        } else if (permissions === 'Panel Member') {
            if (item.permission_type === 'Panel Member') {
                showInvite = true;
            }
        }
    }
    const buttons = [];
    if ((currentUser && currentUser.user && item.creator_user_id === currentUser.user.id) || permissions === 'Management') {
        buttons.push(
            <Tooltip key="tooltip_btn" title="Edit">
                <button
                    disabled={disabled}
                    type="button"
                    className="w3-button w3-hover-teal icon-border w3-padding-small"
                    onClick={() => handleEdit(item)}
                >
                    <span className="ion-edit" />
                </button>
            </Tooltip>,
        );

        buttons.push(
            <Tooltip key="tooltip_dlt" title="Delete">
                <button
                    disabled={disabled || item.actorRoleId != null}
                    type="button"
                    className="w3-button w3-hover-red w3-padding-small icon-border"
                    onClick={() => handleDelete(item)}
                >
                    <span className="ion-trash-a" />
                </button>
            </Tooltip>,
        );
    }

    if (showInvite) {
        buttons.push(
            <Tooltip key="tooltip_invite" title="Invite User">
                <button
                    type="button"
                    className="w3-button w3-padding-small icon-border"
                    onClick={e => handleInviteUser(e, item.id)}
                >
                    <span className="ion-person-add" />
                </button>
            </Tooltip>,
        );
    }

    return (
        <React.Fragment>
            <tr className={isSelected ? 'info h7t-cursor-pointer' : 'h7t-cursor-pointer'} onClick={() => handleSelect(item)}>
                <td>
                    {!isSelected
                        ? <i className="material-icons">expand_more</i>
                        : <i className="material-icons">expand_less</i>}
                </td>
                <td className="w3-left-align">{item.name}</td>
                <td className="w3-left-align">{item.permission_type}</td>
                <td className="w3-left-align">
                    <div className="w3-bar">
                        {buttons}
                    </div>
                </td>
                <td className="w3-left-align">{item.createdBy || <span>-</span>}</td>
            </tr>
            {(childTable && isSelected) ? <tr><td colSpan="6">{childTable}</td></tr> : null}
        </React.Fragment>
    );
};

Role.propTypes = {
    handleSelect: PropTypes.func,
    isSelected: PropTypes.bool,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func,
    handleInviteUser: PropTypes.func,
    handleviewPermissions: PropTypes.func,
    handleResendInvite: PropTypes.func,
    item: PropTypes.object,
    disabled: PropTypes.bool,
    currentUser: PropTypes.object,
    permissions: PropTypes.array,
};

// const ROLE_MANAGEMENT = 'Management';
// const ROLE_HR = 'HR';
// const ROLE_PANEL_MEMBER = 'Panel Member';

// const RoleAccessMap = {}
// RoleAccessMap[Management] = [Management,ROLE_HR,ROLE_PANEL_MEMBER],
// RoleAccessMap[ROLE_HR] = [ROLE_HR,ROLE_PANEL_MEMBER],
// RoleAccessMap[ROLE_PANEL_MEMBER] = [ROLE_PANEL_MEMBER]

class UserRole extends Component {
    static propTypes = {
        params: PropTypes.object,
        getManage: PropTypes.func,
        getListByRole: PropTypes.func,
        getCurrentUser: PropTypes.func,
        postDeleteRoleUser: PropTypes.func,
        postDeleteRole: PropTypes.func,
        setAppMessage: PropTypes.func,
        userRole: PropTypes.object,
        userRoleList: PropTypes.object,
        currentUser: PropTypes.object,
        onModalClose: PropTypes.func,
    }

    constructor(args) {
        super(args);

        this.state = {
            roleId: null,
            roleName: '',
            roleEdit: '',
            roleLoading: true,
            roleUserEdit: '',
            deleteId: '',
            userInvite: '',
            viewPermission: false,
            formData: {
                email: '',
            },
            disableInviteBtn: false,
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInviteUser = this.handleInviteUser.bind(this);
        this.handleEditUserRole = this.handleEditUserRole.bind(this);
        this.handleviewPermissions = this.handleviewPermissions.bind(this);
        this.handleResendInvite = this.handleResendInvite.bind(this);
    }

    componentDidMount() {
        const { userRole, currentUser } = this.props;
        if (!userRole) {
            this.props.getManage('getAllRoles');
        }
        if (!currentUser) {
            this.props.getCurrentUser();
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.userRole && nextProps.userRole.data && nextProps.userRole.data.length) {
    //         if (!this.state.roleId) {
    //             this.setState({
    //                 roleId: nextProps.userRole.data[0].id,
    //                 roleName: nextProps.userRole.data[0].name,
    //             });
    //             this.props.getListByRole(nextProps.userRole.data[0].id).then(()=> {
    //                 this.setState({
    //                     roleLoading: false,
    //                 });
    //             });
    //         }
    //     }
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     console.log('prevProps',prevProps);
    //     console.log('currentProps',this.props.match.params);
    //     if (prevProps.match.params.officeId !== this.props.match.params.officeId) {
    //         this.props.getManage().then((resp) => {
    //             this.setState({
    //                 roleId: resp.data.data[0].id,
    //                 roleName: resp.data.data[0].name,
    //             });
    //             this.props.getListByRole(resp.data.data[0].id).then(()=> {
    //                 this.setState({
    //                     roleLoading: false,
    //                 });
    //             });
    //         });
    //         this.props.getCurrentUser();
    //     }
    // }

    handleEdit(data) {
        this.setState({ roleUserEdit: data });
    }

    handleDelete(data) {
        if (data === true) {
            const { deleteId, roleId } = this.state;
            const { userRole } = this.props;
            if (deleteId.role_id && deleteId.role_name) {
                this.props.postDeleteRoleUser(deleteId.id).then((response) => {
                    if (response && response.data && response.data.success) {
                        this.props.getListByRole(roleId);
                        this.props.setAppMessage('success', 'Role user deleted successfully!');
                        $('.close-button').click();
                    } else if (response) {
                        const msg = (response.data.data.id) ? response.data.data.id : 'Failed to delete Role user!';
                        this.props.setAppMessage('fail', msg);
                    }
                });
            } else {
                deleteId.role_id = deleteId.id;
                this.props.postDeleteRole(deleteId).then((response) => {
                    if (response && response.data && response.data.success) {
                        this.props.getManage('getAllRoles');
                        this.setState({
                            roleId: userRole.data[0].id,
                            roleName: userRole.data[0].name,
                        });
                        this.props.getListByRole(userRole.data[0].id);
                        this.props.setAppMessage('success', 'Role deleted successfully!');
                        $('.close-button').click();
                    } else if (response && response.data && response.data.data) {
                        this.props.setAppMessage('fail', response.data.data);
                    }
                });
            }
        } else {
            this.setState({ deleteId: data });
        }
    }

    handleSelect(role, hasResponse) {
        const { roleId } = this.state;
        if (roleId === role.id && !hasResponse) {
            this.setState({
                roleId: null,
            });
            return;
        }
        this.setState({
            roleId: role.id,
            roleLoading: true,
            roleName: role.name,
        }, () => {
            this.props.getListByRole(role.id).then(() => {
                this.setState({
                    roleLoading: false,
                });
            });
        });
    }

    handleInviteUser(e, roleId) {
        e.stopPropagation();
        this.setState({
            roleId,
            userInvite: 'show',
        }, () => {
            this.props.getListByRole(roleId).then(() => {
                this.setState({
                    roleLoading: false,
                });
            });
        });
    }
    
    handleResendInvite(e, roleUser) {
        e.preventDefault();
        const { formData } = this.state;
        this.setState({
            disableInviteBtn: true,
        });
        formData.role_id = roleUser.role_id;
        formData.email = roleUser.email;
        formData.resendInvite = true;
        this.props.postInviteRoleUser(formData).then((response) => {
            this.setState({
                disableInviteBtn: false,
            });
            const resp = response && response.data;
            if (resp && resp.success) {
                this.props.setAppMessage('success', 'User invited successfully!');
            } else if (resp && resp.data) {
                this.props.setAppMessage('Error', 'Something went wrong');
            }
        });
    }

    handleEditUserRole(data) {
        this.setState({
            roleEdit: data,
        });
    }

    handleviewPermissions() {
        this.setState({
            viewPermission: true,
        });
    }

    render() {
        let tableRow = [];
        const {
            userRole, userRoleList, currentUser, currentUser: { user, companies },
        } = this.props;
        const {
            roleEdit, roleId, roleUserEdit, deleteId, userInvite, viewPermission, disableInviteBtn
        } = this.state;
        let permissions = null;
        if (!userRole) {
            return <LoadingIcon />;
        }

        if (currentUser && companies) {
            const company = companies.find((com) => {
                return COMPANY_ID == com.company_id;
            });

            if (company && company.office_list) {
                const office = company.office_list.find((off) => {
                    return off.id == OFFICE_ID;
                });
                if (office && office.permissions) {
                    if (office.permissions.indexOf('Management') !== -1) {
                        permissions = 'Management';
                    } else if (office.permissions.indexOf('HR') !== -1) {
                        permissions = 'HR';
                    } else if (office.permissions.indexOf('Panel Member') !== -1) {
                        permissions = 'Panel Member';
                    }
                }
            }
        }

        let roleUserList = [];
        if (userRoleList && userRoleList.data && userRoleList.data.length) {
            userRoleList.data.forEach((roleUser) => {
                const accept = (user && user.email === roleUser.email && roleUser.status === 'pending') ? 'w3-show' : 'w3-hide';
                // const disabled = ((user && user.email === roleUser.email) || roleUser.creator_user_id !== user.id || roleUser.role_name === 'Management');
                // const disableDeleteBtn = (user && user.email === roleUser.email) || roleUser.role_name === 'Management';
                const disabled = roleUser.creator_user_id !== user.id || roleUser.role_name === 'Management' || (user && user.email === roleUser.email);
                const disableEditBtn = disabled || roleUser.status === 'active';
                roleUserList.push(
                    <Grid item xs={12} sm={6} md={6} key={`role_${roleUser.id}`} className="w3-margin-top w3-margin-bottom">
                        <Paper>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm container>
                                    <Grid item xs container direction="column" spacing={2} className="w3-padding">
                                        <Grid item xs className="w3-left-align">
                                            <Typography variant="body2" gutterBottom>
                                                {roleUser.email}                                 
                                            </Typography>
                                            <Typography variant="body2" gutterBottom style={{ textTransform: 'capitalize' }}>
                                                Status : {roleUser.status}                                  
                                            </Typography>
                                            {/* <Typography variant="body2" gutterBottom>
                                                <div className={`${accept} w3-text-blue`}>
                                                    <Link to="e/notifications">Accept Role</Link>
                                                </div>
                                            </Typography> */}
                                            <Typography variant="body2" color="textSecondary">
                                                Joined On : &nbsp;
                                                {roleUser.joined_at !== null
                                                ? moment(roleUser.joined_at).format('MMM Do, YYYY') : '---'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" className="h7t-cursor-pointer w3-left-align">
                                                <div>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            variant="outlined"
                                                            className="w3-margin-right"
                                                            disabled={disableEditBtn}
                                                            onClick={() => this.handleEditUserRole(roleUser)}>
                                                            <span className="ion-edit" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            size="small"
                                                            color="secondary"
                                                            variant="outlined"
                                                            className="w3-margin-right"
                                                            disabled={disabled}
                                                            onClick={() => this.handleDelete(roleUser)}
                                                        >
                                                            <span className="ion-trash-a"/>
                                                        </Button>
                                                    </Tooltip>
                                                    {user.id == roleUser.creator_user_id && user.id !== roleUser.user_id && roleUser.status !== 'active' ? (
                                                        <Tooltip title="Resend Invite">
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                variant="outlined"
                                                                disabled={disabled || disableInviteBtn}
                                                                className={`${disabled || disableInviteBtn ? 'w3-disabled' : ''}`}
                                                                onClick={e => this.handleResendInvite(e, roleUser)}>
                                                                <span className="ion-refresh" />
                                                            </Button>
                                                        </Tooltip>
                                                        ) : null}
                                                </div>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>,
                );
            });
        } else {
            roleUserList = (
                <Grid item xs={12} sm={12} md={12} className="w3-margin-top w3-margin-bottom">
                    <Paper className="w3-padding-24">
                        <div className="w3-large w3-margin-left">
                            Invite user to this role!
                        </div>
                    </Paper>
                </Grid>
            );
        }

        let roleListUI = (
            <Grid container spacing={3}>
                {roleUserList}
            </Grid>
        );
        if (this.state.roleLoading) {
            roleListUI = <LoadingIcon />;
        }

        if (userRole && userRole.data && userRole.data.length) {
            userRole.data.forEach((item, idx) => {
                const disabled = (idx === 0 && item.permission_type === 'Management');
                tableRow.push(<Role
                    key={`idx_${idx}`}
                    item={item}
                    disabled={disabled}
                    childTable={roleListUI}
                    isSelected={item.id === roleId}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    handleSelect={this.handleSelect}
                    handleInviteUser={this.handleInviteUser}
                    currentUser={currentUser}
                    permissions={permissions}
                />);
            });
        } else {
            tableRow = <tr><td colSpan="4" className="w3-center h7t-table-data">No roles to display!</td></tr>;
        }

        return (
            <div className="w3-row">
                <div className="w3-row w3-margin-top w3-margin-bottom">
                    <div className="w3-col">
                        {(permissions && permissions.indexOf('Management') !== -1) || (permissions && permissions.indexOf('HR') !== -1) ? (
                            <Button
                                variant="contained"
                                className="w3-right h7t-primary-btn"
                                onClick={() => this.handleEdit({})}
                            >
                                Add New Role
                            </Button>
                        ) : null}
                        <div className="w3-right w3-margin-right">
                            <p
                                className="h7t-cursor-pointer"
                                style={{ color: 'blue', marginTop: '4px' }}
                                onClick={() => this.handleviewPermissions()}
                            >
                                View Permissions
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w3-row w3-card">
                    <table className="w3-table w3-striped">
                        <thead className="h7t-table-head w3-border-bottom">
                            <tr>
                                <td />
                                <th className="w3-left-align">Role Name</th>
                                <th className="w3-left-align">Permission Type</th>
                                <th className="w3-left-align">Actions</th>
                                <th className="w3-left-align">Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow}
                        </tbody>
                    </table>
                </div>

                {userInvite
                    ? (
                        <UserInviteModal
                            roleId={roleId}
                            onModalClose={() => this.setState({ userInvite: '' })}
                        />
                    ) : null }
                {roleUserEdit
                    ? (
                        <UserRoleAddEditModal
                            roleUserEdit={roleUserEdit}
                            handleSelect={this.handleSelect}
                            onModalClose={() => this.setState({ roleUserEdit: '' })}
                            permissions={permissions}
                        />
                    ) : null}
                {deleteId
                    ? (
                        <UserRoleDeleteModal
                            handleDelete={this.handleDelete}
                            deleteMessage="role"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteId: '' })}
                        />
                    ) : null }
                {roleEdit
                    ? (
                        <UserWithRoleEditModal
                            permission={permissions}
                            role_edit={roleEdit}
                            onModalClose={() => this.setState({ roleEdit: '' })}
                        />
                    ) : null }
                {viewPermission
                    ? <ViewPermissions onModalClose={() => this.setState({ viewPermission: false })} />
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        userRole: state.userRole,
        userRoleList: state.userRoleList,
        currentUser,
    };
};

export default connect(mapStateToProps, {
    getManage,
    getListByRole,
    getCurrentUser,
    postDeleteRoleUser,
    setAppMessage,
    postDeleteRole,
    postInviteRoleUser,
})(UserRole);
