import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { Button, FormHelperText } from '@material-ui/core';
import Modal from '../Common/Modal';
import {
    setAppMessage,
    postApplicantOfferStatus,
    getApplicationStatus,
    getCurrentJobList,
} from '../../js/actions';


class OfferModal extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        jobStatus: PropTypes.string,
        applicationId: PropTypes.number,
        getApplicationStatus: PropTypes.func,
        applicationEditData: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {
                reasonToDecline:'',
            },
            form: {
                offer_letter_path: '',
            },
            
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleUploadOffer = this.handleUploadOffer.bind(this);
    }

    componentDidMount() {
        const { applicationEditData,applicationId } = this.props;
        let { formData } = this.state;
        let offerEditData;
        if(applicationEditData && applicationEditData.id) {
        const data = {
            application_id: applicationId,
            reasonToDecline: applicationEditData.reason,
        }
        this.setState({ formData : Object.assign({}, data) });
        }
    }


    handleChangeContent(content) {
        const { formData, errors } = this.state;
        formData.reasonToDecline = (content == '<p><br></p>') ? '' : content;
        errors.reasonToDecline = '';
        this.setState({ formData, errors });
    }

    handleUploadOffer(event) {
        const { form, errors } = this.state;
        form.offer_letter_path = event.target.files[0] ? event.target.files[0] : '';
        errors.offer_letter_path = null;
        this.setState({
            form,
            errors
        });
    }

    validateData(data) {
        let isValid = true;
        const { errors } = this.state;
        const formData = Object.assign({}, data);
        let hasErrors = false;

        if (formData.offer_letter_path && formData.offer_letter_path.size > 2097152) {
            hasErrors = true;
            errors.offer_letter_path = 'File size is larger than 2MB';
        }

        Object.keys(formData).forEach((key) => {
            if (formData[key] === null || formData[key] == '') {
                errors[key] = 'This field is mandatory';
            }
            if (errors[key]) {
                isValid = false;
            }
        });
        if (Object.keys(errors).length > 0 && !isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    handleFormSubmit(evt, status) {
        evt.preventDefault();
        const { applicationId,applicationEditData } = this.props;
        const { formData, errors, form } = this.state;
        if(status==='job_declined'){
            const validData = this.validateData(formData);
            if(validData==true) {
                const data = {
                    application_id: applicationId,
                    reason: formData.reasonToDecline,
                    offerStatus: 'Job-Declined',
                }
                this.props.postApplicantOfferStatus(data).then((resp) => {
                    const response = resp && resp.data;
                    if(response && response.success) {
                        let successMessage = applicationEditData && applicationEditData.reason ? 'Reason Updated Successfully' : 'Reason Added Successfully';
                        this.props.setAppMessage('success',successMessage);
                        this.props.getApplicationStatus(applicationId).then((resp) => {
                            $('.close-button').click();
                        });
                        this.props.getCurrentJobList();
                    }
                    else if(response && response.data) {
                        this.setState({
                            errors: response.data
                        });
                    }
                })
            }
        } else {
            let hasErrors = false;
            if (form.offer_letter_path && form.offer_letter_path.size > 2097152) {
                hasErrors = true;
                errors.offer_letter_path = 'File size is larger than 2MB';
            }
            if(hasErrors) {
                this.setState({ errors });
            } else  {
                const sendFormData = new FormData();
                if(sendFormData) {
                    sendFormData.append('offerLetter',form.offer_letter_path);
                    sendFormData.append('application_id',applicationId);
                    sendFormData.append('offerStatus','Job-Accepted');
                }
                this.props.postApplicantOfferStatus(sendFormData).then((resp) => {
                    const response = resp && resp.data;
                    if(response && response.success) {
                        let successMessage = response.data.offer_letter_path ? 'Offer Letter Uploaded Successfully' : 'Status Updated Successfully';
                        this.props.setAppMessage('success', successMessage);
                        this.props.getApplicationStatus(applicationId).then((resp) => {
                            $('.close-button').click();
                        });
                        this.props.getCurrentJobList();
                    }
                });
            }
        }
    }

    render() {
        const { onModalClose,jobStatus } = this.props;
        const { formData, errors, form } = this.state;

        return (
                <Modal modalCls="w3-show" onClose={onModalClose} widthCls="chp-small-dlg">
                    <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">{jobStatus=='job_declined'?'Job Offer-Declined':'Job Offer-Accepted'}</h4>
                    {jobStatus=='job_declined' 
                    ? (
                        <form className="form-horizontal">
                            <div className="w3-margin-top w3-panel">
                                <ReactQuill
                                    name="reason"
                                    id="reason"
                                    isMandatory="true"
                                    value={formData.reasonToDecline?formData.reasonToDecline:''}
                                    placeholder="Enter the reason here..."
                                    onChange={this.handleChangeContent}
                                    style={{ height: '100px', marginBottom: '50px' }}
                                    errors={errors.reasonToDecline}
                                    className="w3-text-black"
                                />
                                <div>
                                    <FormHelperText error={errors.reasonToDecline}>{errors.reasonToDecline}</FormHelperText>
                                </div>
                                <Button
                                    variant="contained"
                                    className="h7t-primary-btn"
                                    onClick={evt => this.handleFormSubmit(evt,'job_declined')}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )
                    : (
                        <form id="offer_form" encType="multipart/form-data" className="form-horizontal">
                            <div className="w3-panel">
                                <div className="w3-border h7t-margin-top-mobile">
                                    <div className="w3-bar w3-row w3-light-gray">
                                        <div className="w3-bar-item  w3-quarter">
                                            <strong>Upload offer letter</strong>
                                        </div>
                                    </div>
                                    <div className="w3-row w3-padding w3-center">
                                        <div className="w3-col m6 s12">
                                            <input 
                                                id="myfile" 
                                                name="myfile" 
                                                type="file" 
                                                onChange={this.handleUploadOffer} 
                                            />
                                        </div>
                                        {errors.offer_letter_path
                                            ? (
                                                <div className="w3-col s12 w3-left-align">
                                                    <FormHelperText error={errors.offer_letter_path}>{errors.offer_letter_path}</FormHelperText>
                                                </div>
                                            ) : null}
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="h7t-primary-btn w3-margin-top" 
                                    onClick={evt => this.handleFormSubmit(evt,'job_accepted')}
                                >Save</Button>
                            </div>
                        </form>
                      ) }      
                </Modal>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
export default connect(mapStateToProps, {
    setAppMessage,
    postApplicantOfferStatus,
    getApplicationStatus,
    getCurrentJobList
})(OfferModal);
