import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import {
    getManage,
    getWorkflowState,
    getCurrentWorkflows,
    postWorkflowStateEdit,
    postWorkflowStateCreate,
    setAppMessage,
    getCurrentUser,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { InputTextField, InputSelectField } from '../Common/MaterialUI/HelperInputs';

class FlowStateEditModal extends Component {
    static propTypes = {
        userRole: PropTypes.object,
        getManage: PropTypes.func,
        workflow_id: PropTypes.number,
        currentUser: PropTypes.object,
        processFlow: PropTypes.object,
        onModalClose: PropTypes.func,
        workflowState: PropTypes.object,
        setAppMessage: PropTypes.func,
        getCurrentUser: PropTypes.func,
        getWorkflowState: PropTypes.func,
        currentWorkflows: PropTypes.object,
        getCurrentWorkflows: PropTypes.func,
        postWorkflowStateEdit: PropTypes.func,
        postWorkflowStateCreate: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            form: {},
            disabled: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const {
            userRole, currentUser, currentWorkflows, processFlow,
        } = this.props;
        if (!userRole) {
            this.props.getManage();
        }
        if (!currentUser) {
            this.props.getCurrentUser();
        }
        if (!currentWorkflows) {
            this.props.getCurrentWorkflows();
        }
        if (processFlow) {
            this.setState({
                form: processFlow,
                errors: {},
            });
            this.props.getWorkflowState(processFlow.workflow_id);
        }
        if (processFlow.state_name && currentWorkflows && currentWorkflows.data && currentWorkflows.data.length) {
            currentWorkflows.data.forEach((workflow) => {
                if (processFlow.workflow_id === workflow.id && workflow.count_jobs_used) {
                    this.setState({ disabled: true });
                }
            });
        }
    }

    resetErrors(targetElement) {
        const { errors } = this.state;
        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({ errors });
        }
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { form } = this.state;
        this.resetErrors(name);
        form[name] = value;
        this.setState({ form });
    }

    updateResponse(response, successMessage) {
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getWorkflowState(this.props.processFlow.workflow_id);
            this.props.getManage();
            $('.close-button').click();
        }
        if (response && response.data) {
            this.setState({
                errors: response.data,
            });
        }
    }

    validateData() {
        const { form, errors } = this.state;
        let hasErrors = false;
        const ignoreList = [
            'transition_count',
            'display_order',
            'state_action_name',
            'schedule',
            'schedule_role_id',
            'sla_days',
        ];

        Object.keys(form).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (form[key]) === 'string') {
                    if (!form[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!form[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return form;
    }

    handleSubmit(e) {
        e.preventDefault();
        const validData = this.validateData();
        if (validData) {
            validData.state_name = this.titleCase(validData.state_name);
            validData.state_action_name = validData.state_name;
            if (validData.id) {
                validData.workflow_state_id = validData.id;
                this.props.postWorkflowStateEdit(validData).then((resp) => {
                    const response = resp && resp.data;
                    if (response) {
                        this.updateResponse(response, 'Workflow Step Edited successfully!');
                    }
                });
            } else {
                const { workflowState } = this.props;
                let progressStateOrder = 2;
                if (workflowState && workflowState.data) {
                    workflowState.data.map((target) => {
                        if ((target.state_type === 'End') || (target.state_type === 'end')) {
                            if(validData.target_states.indexOf(target.id) === -1){
                                validData.target_states.push(target.id);
                            }
                        } else if (target.state_type === 'In process') {
                            progressStateOrder = target.display_order + 1;
                        }
                    });
                }
                validData.display_order = progressStateOrder;
                this.props.postWorkflowStateCreate(validData).then((resp) => {
                    const response = resp && resp.data;
                    if (response) {
                        this.updateResponse(response, 'Workflow Step Added successfully!');
                    }
                });
            }
        }
    }

    render() {
        const actionTypeSelect = [
            { val: '', name: '-Select Action Type-' },
            { val: 'Normal', name: 'Normal Priority' },
            { val: 'High', name: 'High Priority' },
        ];
        const SLADaysOption = [
            { val: '', name: '-Select SLA Days-' },
            { val: '1', name: '1' },
            { val: '2', name: '2' },
            { val: '3', name: '3' },
            { val: '4', name: '4' },
            { val: '5', name: '5' },
            { val: '6', name: '6' },
            { val: '7', name: '7' },
            { val: '8', name: '8' },
            { val: '9', name: '9' },
            { val: '10', name: '10' },
        ];
        const weightOption = [
            { val: null, name: 'Select the weight' },
            { val: '1', name: '1' },
            { val: '2', name: '2' },
            { val: '3', name: '3' },
            { val: '4', name: '4' },
            { val: '5', name: '5' },
            { val: '6', name: '6' },
            { val: '7', name: '7' },
            { val: '8', name: '8' },
            { val: '9', name: '9' },
            { val: '10', name: '10' },
        ];

        let roleSelect = [];
        const { userRole, workflowState, onModalClose } = this.props;
        const {
            form, errors, disabled,
        } = this.state;
        if (userRole) {
            roleSelect = [{ val: '', name: '-Select Role-' }];
            userRole.data.map((roles_List) => {
                roleSelect.push(
                    { val: roles_List.id, name: roles_List.name },
                );
            });
        }

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title={form.id ? 'Edit Workflow Step' : 'Add Workflow Step'} widthCls="chp-small-dlg">
                <form className="form-horizontal" noValidate onSubmit={this.handleSubmit}>
                    <InputTextField
                        autoFocus
                        label="Step Name"
                        name="state_name"
                        id="state_name"
                        required
                        value={(form.state_name) ? form.state_name : ''}
                        onChange={this.handleChange}
                        errors={errors.state_name}
                    />

                    <InputSelectField
                        label="Action Type  "
                        name="action_type"
                        id="action_type"
                        required
                        value={(form.action_type) ? form.action_type : ''}
                        onChange={this.handleChange}
                        options={actionTypeSelect}
                        errors={errors.action_type}
                    />

                    <InputSelectField
                        label="Actor Role"
                        name="actor_role_id"
                        id="actor_role_id"
                        required
                        value={(form.actor_role_id) ? form.actor_role_id : ''}
                        onChange={this.handleChange}
                        //disabled={disabled}
                        options={roleSelect}
                        errors={errors.actor_role_id}
                    />

                    <InputSelectField
                        label="Weightage"
                        name="weight"
                        id="weight"
                        value={(form.weight) ? form.weight : ''}
                        onChange={this.handleChange}
                        disabled={disabled}
                        options={weightOption}
                        errors={errors.weight}
                        required
                    />

                    <InputSelectField
                        label="SLA in days"
                        name="sla_days"
                        id="sla_days"
                        value={(form.sla_days) ? form.sla_days : ''}
                        onChange={this.handleChange}
                        options={SLADaysOption}
                        errors={errors.sla_days}
                    />
                    <div className="w3-clear w3-margin-top w3-panel">
                        <Button variant="contained" className="w3-margin-right h7t-secondary-btn" onClick={onModalClose}>Close</Button>
                        <Button variant="contained" type="submit" className="h7t-primary-btn">{form.id ? 'Update' : 'Add Step'}</Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentWorkflows = state.currentWorkflows && state.currentWorkflows.data;
    return {
        userRole: state.userRole,
        workflowState: state.workflowState,
        currentUser: state.currentUser.user,
        currentWorkflows,
    };
}
export default connect(mapStateToProps, {
    getManage,
    getWorkflowState,
    getCurrentUser,
    getCurrentWorkflows,
    postWorkflowStateCreate,
    postWorkflowStateEdit,
    setAppMessage,
})(FlowStateEditModal);
