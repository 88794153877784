import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    postLoginUser,
    postRegisterUser,
    postResetPassword,
    setAppMessage,
    registerCompanyAndOffice,
    getMetaData,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { InputTextField, InputSelectField, RadioButtonMUI, SearchDropdown } from '../Common/MaterialUI/HelperInputs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { FormHelperText, Button, Grid } from '@material-ui/core';

class NewLoginSignupModal extends Component {
    static propTypes = {
        widgetJobId: PropTypes.number,
        onModalClose: PropTypes.func,
        homePageView: PropTypes.string,
        postLoginUser: PropTypes.func,
        setAppMessage: PropTypes.func,
        handleOnSuccess: PropTypes.func,
        postRegisterUser: PropTypes.func,
        postResetPassword: PropTypes.func,
        getMetaData: PropTypes.func,
    }

    constructor(args) {
        super(args);
        this.state = {
            errorMessage: {},
            formData: {},
            isDisabled: false,
            homePageView: '',
            activeClass: '',
            dpOpenStatus: false,
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.registerEmployer = this.registerEmployer.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.handleDPOpenStateChange = this.handleDPOpenStateChange.bind(this);
        this.handleChangeSelection = this.handleChangeSelection.bind(this);
    }

    componentDidMount() {
        const { homePageView } = this.props;
        if (homePageView) {
            this.handleViewChange(homePageView);
        }
        
        this.props.getMetaData({ type:'category' });
    }

    updateErrors(errorMessage) {
        if (errorMessage.error) {
            this.props.setAppMessage('fail', errorMessage.error);
        }
        this.setState({
            errorMessage,
        });
    }

    handleViewChange(homePageView) {
        const { errorMessage } = this.state;
        if (Object.keys(errorMessage).length > 0) {
            this.updateErrors({});
        }
        this.setState({ activeClass: homePageView });
        this.handleHomePageView(homePageView);
    }

    handleHomePageView(homePageView) {
        let { formData } = this.state;
        if (homePageView === 'login') {
            formData = {
                email: '',
                password: '',
            };
        } else if (homePageView === 'signup') {
            formData = {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                gender: '',
                dob: '',
                password: '',
                confirm_password: '',
            };
        } else if (homePageView === 'forgot_password') {
            formData = {
                email: '',
            };
        } else if (homePageView === 'employer') {
            formData = {
                first_name: '',
                name: '',
                email: '',
                phone_number: '',
                size: '',
                category: '',
                user_type: 'COMPANY',
                password: '',
                confirm_password: '',
            };
        }
        this.setState({ formData, homePageView });
    }

    validateData(type) {
        const { formData, errorMessage } = this.state;
        let hasErrors = false;

        if (!/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(formData.email)) {
            hasErrors = true;
            errorMessage.email = 'Enter a valid email';
        }
        if (type === 'register') {
            if (formData.phone_number && !/^\d{10}$/.test(formData.phone_number)) {
                hasErrors = true;
                errorMessage.phone_number = 'Enter a valid phone number';
            }
            if (formData.dob && new Date(formData.dob) > new Date()) {
                hasErrors = true;
                errorMessage.dob = 'Date must be less than today\'s';
            }
            if (formData.confirm_password && formData.password !== formData.confirm_password) {
                hasErrors = true;
                errorMessage.confirm_password = 'The password and confirm password must match.';
            }
        }

        Object.keys(formData).forEach((key) => {
            if (typeof (formData[key]) === 'string' && key !== 'password' && key !== 'confirm_password') {
                if (!formData[key].match(/\w/)) {
                    hasErrors = true;
                    errorMessage[key] = 'This field is mandatory';
                }
            }
            if (!formData[key]) {
                hasErrors = true;
                errorMessage[key] = 'This field is mandatory';
            }
        });
        if (hasErrors) {
            this.setState({ errorMessage });
            return false;
        }
        return formData;
    }

    handleLogin(e) {
        e.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('login');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postLoginUser(validData).then((resp) => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && !response.success) {
                    this.updateErrors(response.data);
                } else if (response) {
                    if (!response.data.token) {
                        return;
                    } 
                    localStorage.setItem('accessToken', response.data.token);
                       
                    if (this.props.widgetJobId || this.props.handleOnSuccess) {
                        this.props.handleOnSuccess();
                     } else if(this.props.shareJobId || this.props.handleShareOnSuccess) {
                        this.props.handleShareOnSuccess();
                    } else {
                        const companyId = response.data.current_company_id;
                        const officeId = response.data.current_office_id;
                        const pathUrl = window.location.pathname;
                        let redirectUrl = '/s/job-dashboard';
                        if (response.data.type === 'COMPANY') {
                            redirectUrl = '/e/dashboard';
                        }
                        if (pathUrl.indexOf('/s/profile') !== -1) {
                            redirectUrl = pathUrl;
                        } else if (pathUrl.indexOf('?redirectUrl=') !== -1) {
                            redirectUrl = pathUrl.replace('?redirectUrl=', '').replace('#/', '');
                        } else if (companyId && officeId) {
                            redirectUrl = {
                                pathname: `/e/${companyId}/${officeId}/dashboard`,
                                state: {
                                    companyId,
                                    officeId,
                                }
                            };
                        }
                        this.props.history.push(redirectUrl);
                        window.location.reload();
                    }
                }
            });
        }
    }

    handleRegister(evt) {
        evt.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('register');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postRegisterUser(validData).then((resp) => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && !response.success) {
                    this.updateErrors(response.data);
                } else if (response) {
                    this.props.postLoginUser(validData).then(() => {
                        if (!response.data.token) {
                            return;
                        } 
                        localStorage.setItem('accessToken', response.data.token);
                        if (this.props.widgetJobId || this.props.handleOnSuccess) {
                            this.props.handleOnSuccess();
                        } else if (this.props.shareJobId || this.props.handleShareOnSuccess) {
                            this.props.handleShareOnSuccess();
                        } else {
                            const pathUrl = window.location.pathname;
                            const redirectUrl = pathUrl.indexOf('/s/profile') !== -1 ? pathUrl : '/s/job-dashboard';
                            this.props.history.push(redirectUrl);
                            window.location.reload();
                        }
                    });
                }
            });
        }
    }

    handleForgotPassword(evt) {
        evt.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('forgotPassword');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postResetPassword(validData).then((resp) => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && response.success) {
                    if (!this.props.widgetJobId || !this.props.handleOnSuccess) {
                        this.props.setAppMessage('success', 'A reset password link has been mailed to your email!');
                        $('.close-button').click();
                    }
                } else if (response) {
                    this.updateErrors(response.data);
                }
            });
        }
    }

    registerEmployer(e) {
        e.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('register');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postCreateCompanyAndOffice(validData).then(resp => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && response.success) {
                    this.props.postLoginUser(validData).then((lResp) => {
                        const loginResp = lResp && lResp.data;
                        if (!loginResp.data.token) {
                            return;
                        } 
                        localStorage.setItem('accessToken', loginResp.data.token);
                        const companyId = loginResp.data.current_company_id;
                        const officeId = loginResp.data.current_office_id;
                        const pathUrl = window.location.pathname;
                        let redirectUrl = '/e/dashboard';
                        if (pathUrl.indexOf('?redirectUrl=') !== -1) {
                            redirectUrl = pathUrl.replace('?redirectUrl=', '').replace('#/', '');
                        } else if (companyId && officeId) {
                            redirectUrl = {
                                pathname: `/e/${companyId}/${officeId}/manage-company`,
                                state: {
                                    companyId,
                                    officeId,
                                }
                            };
                        }
                        this.props.history.push(redirectUrl);
                        window.location.reload();
                    });
                } else if (response) {
                    const { errorMessage } = this.state;
                    Object.keys(response.data).forEach((key) => {
                        errorMessage[key] = response.data[key][0];
                    });
                    this.setState({ errorMessage });
                }
            });
        } else {
            console.log('Errors need to be fixed.');
        }
    }

    handleChangeSelection(option, label) {
        const { formData, errorMessage } = this.state;
        let value = null;
        if (option && Array.isArray(option)) {
            if (option.length) {
                value = [];
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        } else if (option) {
            value = option.val;
        }
        formData[label] = value;
        if (errorMessage[label]) {
            errorMessage[label] = null;
            this.updateErrors(errorMessage);
        }
        this.setState({
            formData,
            errorMessage,
        });
    }


    handleChange(evt) {
        const { name, value } = evt.target;
        const { formData, errorMessage } = this.state;

        if(errorMessage[name]){
            errorMessage[name] = '';
            this.updateErrors(errorMessage);
        }

        formData[name] = value;
        this.setState({ formData });
    }

    handleDatePicker(evt, date, id) {
        const { formData, errorMessage } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');
        const selectedDate = new Date(formatedDate).getTime();
        const currentDate = new Date().getTime();

        if (!(selectedDate < currentDate)) {
            errorMessage[id] = 'Date should not be greater than Today';
            this.setState({ errorMessage });
            return;
        }

        if (errorMessage[id]) {
            errorMessage[id] = null;
            this.setState({ errorMessage });
        }

        formData[id] = formatedDate;
        this.setState({ formData });
    }

    handleDPOpenStateChange(status) {
        this.setState({
            dpOpenStatus: status,
        });
    }

    render() {
        const {
            errorMessage, isDisabled, homePageView, activeClass, dpOpenStatus, formData
        } = this.state;
        const {
            onModalClose, widgetJobId, handleOnSuccess, actionSelected, id, disableClose, location
        } = this.props;
        let loginRedirect = window.location.href;

        const sizeOptions = [
            { val: '', name: '-Select Size-' },
            { val: '0-100', name: '0-100' },
            { val: '100-500', name: '100-500' },
            { val: '500-1000', name: '500-1000' },
            { val: 'more than 1000', name: 'more than 1000' },
        ];

        const categorySelect = [{ val:'', name:'-Select Category-' }];

        if(this.props.metaData) {
            const {
                metaData: {
                  category
                },
            } = this.props;

            if (category && category.length) {
                category.map((categoryData) => {
                    categorySelect.push({ val: categoryData.term, name: categoryData.term });
                });
            }
        }

        if (widgetJobId || handleOnSuccess) {
            const url = window.location.href;
            if (url.indexOf('?') == -1) {
                loginRedirect = `${url}?actionSelected=${actionSelected}&jobId=${widgetJobId}`;
            } else {
                loginRedirect = `${url}&actionSelected=${actionSelected}&jobId=${widgetJobId}`;
            }
        }
        let leftModal = null;
        let modalHeader = '';
        const toggleRegister = (
            <div className="w3-center">
                <div className="h7t-toggle-register">
                    <span
                        id="signup"
                        className={activeClass === 'signup' ? 'active' : ''}
                        onClick={() => this.handleViewChange('signup')}
                    >Job Seeker</span>
                    <span
                        id="employer"
                        className={activeClass === 'employer' ? 'active' : ''}
                        onClick={() => this.handleViewChange('employer')}
                    >Employer</span>
                </div>
            </div>
        );
        let socialLogins = (
            <div>
                <h6 className="w3-margin w3-center"><span style={{ color: '#949494'}}>With</span></h6>
                <Grid container spacing={3} className="w3-panel">
                    <Grid item xs={12} md={6} className="w3-margin-bottom">
                        <a
                            href={`/v1/api/auth/facebook-connect?redirectUrl=${loginRedirect}`}
                            className="w3-button w3-block h7t-facebook-bg-color h7t-social-btn-align w3-text-white"
                            onClick={() => localStorage.setItem('loginRedirectUrl', loginRedirect)}
                        >
                            <i className="ion-social-facebook h7t-social-icon" />
                            &nbsp;facebook
                        </a>
                    </Grid>
                    <Grid item xs={12} md={6} className="w3-margin-bottom">
                        <a
                            // href={`/v1/api/auth/linkedin?redirectUrl=${loginRedirect}`} // ** disabled due to permission issue
                            className="w3-button w3-block h7t-social-btn-align h7t-linkedin-bg-color w3-text-white"
                            onClick={() => {
                                return; // ** disabled due to permission issue
                                localStorage.setItem('loginRedirectUrl', loginRedirect)
                            }}
                        >
                            <i className="ion-social-linkedin h7t-social-icon" />
                            &nbsp;linkedIn
                        </a>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="w3-panel">
                    <Grid item xs={12} md={6} className="w3-margin-bottom">
                        <a
                            href={`/v1/api/auth/google-connect?redirectUrl=${loginRedirect}`}
                            className="w3-button w3-block h7t-social-btn-align h7t-google-bg-color w3-text-white"
                            onClick={() => localStorage.setItem('loginRedirectUrl', loginRedirect)}
                        >
                            <i className="ion-social-googleplus h7t-social-icon" />
                            &nbsp;googleplus
                        </a>
                    </Grid>
                    <Grid item xs={12} md={6} className="w3-margin-bottom">
                        <a
                            href={`/v1/api/auth/twitter-connect?redirectUrl=${loginRedirect}`}
                            className="w3-button w3-block h7t-social-btn-align h7t-twitter-bg-color w3-text-white"
                            onClick={() => localStorage.setItem('loginRedirectUrl', loginRedirect)}
                        >
                            <i className="ion-social-twitter h7t-social-icon" />
                            &nbsp;twitter
                        </a>
                    </Grid>
                </Grid>
                <div className="w3-center w3-panel">
                    <h6 className="h7t-text-between-lines"><span>Or with email</span></h6>
                </div>
            </div>
        );
        if (homePageView === 'login') {
            modalHeader = 'Login';
            leftModal = (
                <div key="leftLoginModal">
                    <div key="rightmodal" className="w3-margin-bottom">
                        {socialLogins}

                        {/* {!widgetJobId ?
                                (<div className="w3-center h-margin-top-20">
                                    <button className="w3-margin-top w3-button w3-dark-grey" onClick={() => this.handleViewChange('employer')}> Singup as a consultant or an employer </button>
                                </div>) : null} */}
                    </div>
                    <form className="form-signin form-horizontal" noValidate onSubmit={this.handleLogin}>
                        <InputTextField
                            label="Email"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            errors={errorMessage.email}
                            required
                        />
                        <InputTextField
                            label="Password" 
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handleChange}
                            errors={errorMessage.password}
                            required
                        />
                        <div className="w3-panel w3-right-align">
                            <Button
                                variant="contained"
                                type="submit"
                                className="h7t-btn-background-blue w3-block h7t-capitalize-text"
                                disabled={isDisabled}
                            >
                            Log In
                            </Button>
                            <Grid container spacing={3} className="w3-margin-top">
                                <Grid item>
                                    <a className="h7t-hyperlink-color h7t-cursor-pointer" onClick={() => this.handleViewChange('signup')}>Register</a><br />
                                </Grid>
                                <Grid item>
                                    <a className="h7t-cursor-pointer h7t-hyperlink-color" onClick={() => this.handleViewChange('forgot_password')}>Forgot Password?</a>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            );
        } else if (homePageView === 'signup') {
            modalHeader = 'Register';
            leftModal = (
                <div key="leftmodal">
                    {toggleRegister}
                    <div className="w3-margin-top">
                        {socialLogins}
                    </div>
                    <form className="form-horizontal" noValidate onSubmit={this.handleRegister}>
                        <InputTextField
                            label="First Name"
                            name="first_name"
                            id="first_name"
                            onChange={this.handleChange}
                            errors={errorMessage.first_name}
                            required
                        />

                        <InputTextField
                            label="Last Name"
                            name="last_name"
                            id="last_name"
                            onChange={this.handleChange}
                            errors={errorMessage.last_name}
                            required
                        />

                        <InputTextField
                            label="Email"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            errors={errorMessage.email}
                            required
                        />

                        <InputTextField
                            label="Phone Number"
                            name="phone_number"
                            id="phone_number"
                            onChange={this.handleChange}
                            errors={errorMessage.phone_number}
                            required
                        />
                         <div className="w3-padding">
                                <RadioButtonMUI
                                    name="gender"
                                    id="gender_male"
                                    checked={formData.gender === 'Male' ? 'checked' : undefined}
                                    value="Male"
                                    onChange={this.handleChange}
                                    label="Male"
                                />

                                <RadioButtonMUI
                                    name="gender"
                                    id="gender_female"
                                    checked={formData.gender === 'Female' ? 'checked' : undefined}
                                    value="Female"
                                    onChange={this.handleChange}
                                    label="Female"
                                />
                                <FormHelperText error={errorMessage.gender} className="w3-margin-left">{errorMessage.gender}</FormHelperText>
                            </div>

                        <div className="w3-padding">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    size="small"
                                    required
                                    inputVariant="outlined"
                                    label="Date of Birth"
                                    format="MMM do, yyyy"
                                    inputProps={{readOnly: true}}
                                    value={formData.dob && formData.dob !== '0000-00-00' ? moment(formData.dob) : null}
                                    onChange={(e, date) => this.handleDatePicker(e, date, 'dob')}
                                    error={errorMessage.dob}
                                    helperText={errorMessage.dob}
                                    disableFuture={true}
                                    maxDate={moment().subtract(18, 'years')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <InputTextField
                            label="Password"
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handleChange}
                            errors={errorMessage.password}
                            required
                        />

                        <InputTextField
                            label="Confirm Password"
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            onChange={this.handleChange}
                            errors={errorMessage.confirm_password}
                            required
                        />
                        <div className="w3-panel">
                            <Button
                                variant="contained"
                                type="submit"
                                className="h7t-btn-background-blue w3-block h7t-capitalize-text"
                                disabled={isDisabled}
                                >
                                Create Account
                                </Button>
                            <div className="w3-center w3-margin-top">
                                <a onClick={() => this.handleViewChange('login')} className="h7t-cursor-pointer h7t-hyperlink-color">Login</a>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else if (homePageView === 'forgot_password') {
            modalHeader = 'Forgot Password';
            leftModal = (
                <div key="leftmodal">
                    <form className="form-horizontal" noValidate onSubmit={this.handleForgotPassword}>
                        <p className="w3-margin">Enter your email address and we will send you a link to reset your password.</p>
                        <InputTextField
                            label="Email Address"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            errors={errorMessage.email}
                            required
                        />

                        <div className="w3-panel">
                        <Button
                            variant="contained"
                            type="submit"
                            className="h7t-btn-background-blue w3-block h7t-capitalize-text"
                            disabled={isDisabled}
                            >
                           Send password reset email
                            </Button>
                            <div className="w3-center w3-margin-top">
                                <a onClick={() => this.handleViewChange('login')} className="h7t-cursor-pointer h7t-hyperlink-color">Login</a>&nbsp;&nbsp;&nbsp;
                                <a onClick={() => this.handleViewChange('signup')} className="h7t-cursor-pointer h7t-hyperlink-color">Register</a>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else if (homePageView === 'employer') {
            modalHeader = 'Register';
            leftModal = (
                <div key="leftmodal">
                    {toggleRegister}
                    <form className="form-horizontal" noValidate onSubmit={this.registerEmployer}>
                        <InputTextField
                            label="Person Name"
                            onChange={this.handleChange}
                            id="first_name"
                            name="first_name"
                            errors={errorMessage.first_name}
                            required
                        />

                        <InputTextField
                            label="Company Name"
                            onChange={this.handleChange}
                            id="name"
                            name="name"
                            errors={errorMessage.name}
                            required
                        />

                        <InputTextField
                            label="Company Email"
                            onChange={this.handleChange}
                            id="email"
                            name="email"
                            errors={errorMessage.email}
                            required
                        />

                        <InputTextField
                            label="Phone Number"
                            onChange={this.handleChange}
                            id="phone_number"
                            name="phone_number"
                            errors={errorMessage.phone_number}
                            required
                        />

                        <InputSelectField
                            label="Company Size"
                            id="size"
                            name="size"
                            value={formData.size}
                            options={sizeOptions}
                            errors={errorMessage.size}
                            onChange={this.handleChange}
                            required
                        />

                        <SearchDropdown
                            required
                            label="Select Category"
                            options={categorySelect}
                            value={formData.category}
                            errors={errorMessage.category}
                            onChange={(evt, val) => {
                                this.handleChangeSelection(val, 'category')
                            }}
                        />

                        <InputTextField
                            label="Password"
                            onChange={this.handleChange}
                            type="password"
                            id="password"
                            name="password"
                            errors={errorMessage.password}
                            required
                        />

                        <InputTextField
                            label="Confirm Password"
                            onChange={this.handleChange}
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            errors={errorMessage.confirm_password}
                            required
                        />

                        <div className="w3-panel">
                        <Button
                            variant="contained"
                            type="submit"
                            className="h7t-btn-background-blue w3-block h7t-capitalize-text"
                            disabled={isDisabled}
                            >
                           Create Account
                            </Button>
                            <div className="w3-center w3-margin-top">
                                <a onClick={() => this.handleViewChange('login')} className="h7t-cursor-pointer h7t-hyperlink-color">Login</a>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }

        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                widthCls="chp-small-dlg"
                className="w3-animate-top h7t-login-signup-modal w3-margin-bottom"
                handleOpen={this.handleDPOpenStateChange}
                datePickerStatus={dpOpenStatus}
                id={id}
                disableClose={disableClose}
            >
                <div className="w3-text-black">
                    <div className="modal-header">
                        <p className="modal-title h7t-modal-title w3-center">{modalHeader}</p>
                    </div>
                    {leftModal}
                </div>
            </Modal>

        );
    }
}

function mapStateToProps(state) {
    return {
        metaData: state.meta,
    };
}
export default withRouter(connect(mapStateToProps, {
    postLoginUser,
    postRegisterUser,
    postResetPassword,
    setAppMessage,
    postCreateCompanyAndOffice: registerCompanyAndOffice,
    getMetaData,
})(NewLoginSignupModal));
