import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar'

export default class BarComponent extends Component {
    static propTypes = {
        data: PropTypes.array,
        heading: PropTypes.string,
        keys: PropTypes.array,
    };

    render() {
        const { data, heading, keys } = this.props;
        let tickvalue = 1;
        let tickLine = 36;
        const dataLables = [
            "Closed","Hired","Open","Rejected","InProgress","Resumes"
        ];
        data.map(value => {
            dataLables.map(item =>{
                if (value[item] && value[item] > 1) {
                    tickvalue = 2;
                    tickLine = 12;
                }
            })
        })
        

        return (
            <Fragment>
                <h6 className="h7t-modal-title h7t-margin-0">{heading}</h6>
                <Paper style={{ height: '320px' }} className="w3-padding h7t-table-data">
                    <ResponsiveBar
                        data={data}
                        keys={keys}
                        indexBy="month"
                        margin={{ top: 50, right: 100, bottom: 50, left: 60 }}
                        padding={0.3}
                        groupMode="grouped"
                        layout="vertical"
                        colors={{ scheme: 'nivo' }}
                        labelSkipHeight={tickLine}
                        isInteractive={false}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'fries'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'In-Progress'
                                },
                                id: 'lines'
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        // axisBottom={{
                        //     tickSize: 5,
                        //     tickPadding: 5,
                        //     tickRotation: 0,
                        //     legend: 'Data',
                        //     legendPosition: 'middle',
                        //     legendOffset: 32
                        // }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            // legend: 'Status',
                            legendPosition: 'middle',
                            legendOffset: -40,
                            tickValues: tickvalue
                        }}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </Paper>
            </Fragment>
        )
    }
}