import React from 'react';

class NotFound extends React.Component {
    render() {
        return(
            <div className="w3-container w3-margin w3-padding-64 w3-center w3-large">
                {this.props.children || <div>Not Found :(</div>}
            </div>
        );
    }
}

export default NotFound;