import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button
} from '@material-ui/core';
import Modal from '../Common/Modal';
import { withRouter } from 'react-router-dom';

class JobDescriptionModal extends Component {
    static propTypes = {
        jobDescription: PropTypes.object,
        onModalClose: PropTypes.func,
        employerJdView: PropTypes.bool,
    }

    constructor(args) {
        super(args);
        this.state = {
        };
    }
    
    showResume() {
        const { jobDescription, triggerResumeSelect, onModalClose } = this.props;
        triggerResumeSelect(jobDescription);
        onModalClose();
    }

    showBookmark() {
        const { jobDescription, handleBookmarkJob, onModalClose } = this.props;
        handleBookmarkJob(jobDescription);
        onModalClose();
    }

    render() {
        const {
            onModalClose, jobDescription, employerJdView, bookmarkIds, disableClose, id } = this.props;
        const skills = typeof(jobDescription.skills_required)==='string'? jobDescription.skills_required.split(',').join(', '):jobDescription.skills_required.join(', ');
        const minExp = jobDescription.min_experience_required;
        const maxExp = jobDescription.max_experience_required;
        const expText = `${minExp} - ${maxExp} Yrs`;
        const salary_min = jobDescription.salary_min;
        const salary_max = jobDescription.salary_max;
        const salText = `${salary_min} - ${salary_max} LPA`;
        return (
            <Modal modalCls="w3-show" onClose={onModalClose} disableClose={disableClose} id={id} >
                <div className="w3-row w3-padding w3-margin">
                    <div className="w3-row w3-padding">
                         <div className="w3-col s2 m2">
                            {(jobDescription && jobDescription.logoUrl) 
                            ? (
                                <div className="h7t-profile-pic w3-center">
                                    <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" src={(jobDescription && jobDescription.logoUrl) ? jobDescription.logoUrl : ''} />
                                </div>
                            ) : null }
                        </div>
                        <div className="w3-col s8 m8">
                            <span className="w3-text-deep-purple" style={{ fontSize: 'large' }}><strong>{jobDescription.job_title}</strong></span>
                            {jobDescription.status == "saved" && jobDescription.permission_type == "Hiring Manager" ? <p className="w3-right">Created By:<span className="w3-text">{jobDescription.display_name}</span></p> :''}
                            <div className="w3-row">
                                <div className="w3-col m6">
                                    <span className="w3-text">{jobDescription.companyName}</span> 
                                    <br />
                                    <div className="w3-row">
                                        <div className="w3-col m4">
                                            {jobDescription && jobDescription.current_office && jobDescription.current_office.city_info && jobDescription.current_office.city_info.term ?
                                                <span className="w3-text">{jobDescription.current_office.city_info.term} <br /></span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-row w3-padding w3-margin-top">
                        <span className="w3-text-deep-purple">Job Description</span>
                        <div className="w3-col m12 margin-top-10 h7t-ql-editor" dangerouslySetInnerHTML={{ __html: jobDescription.description }} />
                    </div>
                    {jobDescription.project_description 
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Project Description</span>
                                <div className="w3-col m12 margin-top-10 h7t-ql-editor" dangerouslySetInnerHTML={{ __html: jobDescription.project_description }} />
                            </div>
                        ) : null }
                    <div className="w3-row w3-padding w3-margin-top">
                        <span className="w3-text-deep-purple">Skills</span> 
                        <div className="w3-text margin-top-10">{skills} </div> 
                    </div> 
                    {jobDescription.education
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Education</span> 
                                <div className="w3-text margin-top-10">{jobDescription.education} </div>  
                            </div>
                        ) : null }
                    {jobDescription.universities
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Universities</span> 
                                <div className="w3-text margin-top-10">{jobDescription.universities} </div>  
                            </div>
                        ) : null }
                    {jobDescription.domain 
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Domain</span> 
                                <div className="w3-text margin-top-10">
                                    {jobDescription.domain}
                                </div>
                            </div>
                        ) : null }
                    {jobDescription.certifications
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Certifications</span> 
                                <div className="w3-text margin-top-10">
                                    <ul>
                                        <li>{jobDescription.certifications}</li>
                                    </ul> 
                                </div>
                            </div>
                        ) : null }
                    <div className={`w3-row w3-padding ${jobDescription.certifications?'':'w3-margin-top'}`}>
                        <span className="w3-text-deep-purple">Details</span>
                        <br />
                        <div className="w3-col m12 margin-top-10">
                            Years of Experience : <span className="companyInfo">{expText}</span> <br />
                            Level of Hiring : <span className="w3-text">{jobDescription.level_of_hiring}</span> <br />
                            CTC : <span className="w3-text">{salText} </span> <br />
                            {jobDescription && jobDescription.current_office && jobDescription.current_office.name 
                            ? (
                                <div>
                                    Office : <span className="w3-text">{jobDescription.current_office.name}</span> <br />
                                </div>
                            ) : null } 
                            Positions Open : <span className="w3-text">{jobDescription.positions_open} </span>
                            <br />
                            Remote Job : <span className="w3-text">{(jobDescription.remote_Jobs === null || jobDescription.remote_Jobs === false)?'No':'Yes'}</span> <br />
                            Posted on : <span className="w3-text">{moment(jobDescription.created_at).format('MMM Do, YYYY')} </span> 
                        </div>
                    </div>
                    {!employerJdView 
                    ? (
                        <div className="w3-bar w3-panel">
                            <Button
                                onClick={() => this.showResume()}
                                disabled={jobDescription.applicationStatus}
                                className="w3-margin-right h7t-margin-10 h7t-secondary-btn"
                            >
                                {jobDescription.applicationStatus ? 'Applied' : 'Apply'}
                            </Button>
                            <Button
                                className="w3-margin-right h7t-margin-10 h7t-secondary-btn"
                                onClick={() => this.showBookmark()}
                                disabled={bookmarkIds.includes(jobDescription.id) ? true : ''}
                            >
                                {bookmarkIds.includes(jobDescription.id) ? 'Saved' : 'Save'}
                            </Button>
                            {jobDescription.applicationStatus && bookmarkIds.includes(jobDescription.id)  && disableClose ?
                                <Button
                                    variant="contained"
                                    className="h7t-margin-10 h7t-secondary-btn"
                                    onClick={() => this.props.history.push('/s/job-dashboard')}
                                >
                                    Go to Dashboard  
                                </Button> : ''}
                            <Button
                                variant="contained"
                                disabled
                                className="w3-right h7t-margin-10 h7t-primary-btn w3-disabled"
                            >
                                Not Interested  
                            </Button>
                        </div>
                    ) : null }
                    <div className="w3-row w3-padding w3-margin-top">
                        <span className="w3-text-deep-purple">About {jobDescription.companyName}</span>
                        <br />
                        <div className="margin-top-10 w3-margin-bottom h7t-ql-editor" dangerouslySetInnerHTML={{__html: jobDescription.companyAbout }} />
                            <TableContainer component={Paper}>
                                <Table className="table-striped">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Website
                                            </TableCell>
                                            <TableCell>
                                                Team Size
                                            </TableCell>
                                            <TableCell>
                                                Type
                                            </TableCell>
                                            <TableCell>
                                                Industry
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><a target="_blank" href={jobDescription.companyWebsite}>{jobDescription.companyWebsite}</a></TableCell>
                                            <TableCell>{jobDescription.companySize}</TableCell>
                                            <TableCell>{jobDescription.companyType}</TableCell>
                                            <TableCell>{jobDescription.companyCategory}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </div>
                </div>
            </Modal>
        );
    }
}
export default withRouter(JobDescriptionModal);
