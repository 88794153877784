import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import {
    postAddCompany,
    postUpdateCompany,
    getMetaData,
    setAppMessage,
    getCurrentUser,
    getCurrentOfficeList,
    getCurrentCompany,
    setData,
} from '../../js/actions';
import ProfilePicUpload from '../Seeker/Profile/ProfilePicUpload';
import { InputTextField, InputSelectField, SearchDropdown } from '../Common/MaterialUI/HelperInputs';
import ReactQuill from 'react-quill';
import { FormHelperText } from '@material-ui/core';
import Modal from '../Common/Modal';
import OfficeListEditModal from './OfficeListEditModal';

class CompanyForm extends Component {
    static propTypes = {
        postAddCompany: PropTypes.func,
        postUpdateCompany: PropTypes.func,
        setAppMessage: PropTypes.func,
        companyData: PropTypes.object,
        company_errors: PropTypes.object,
        company_data: PropTypes.object,
        onFailure: PropTypes.func,
        createCompany: PropTypes.func,
        buttonVal: PropTypes.string,
        getMetaData: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            data: {
                name: '',
                type: '',
                website: '',
                logo_url: '',
                sub_domain: '',
                allowed_domains: '',
                size: '',
                category: '',
                about: '',
            },
            errors: {
                name: '',
                type: '',
                website: '',
                logo_url: '',
                sub_domain: '',
                allowed_domains: '',
                size: '',
                category: '',
                about: '',
            },
            upload_logo: false,
            about_company_active_tab: 0,
            officeEditData:'',
        };

        this.handleValChange = this.handleValChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateData = this.validateData.bind(this);
        this.handleOpenUploadModal = this.handleOpenUploadModal.bind(this);
        this.handleChangeLogo = this.handleChangeLogo.bind(this);
        this.handleChangeAboutCompany = this.handleChangeAboutCompany.bind(this);
        this.handleChangeSelection = this.handleChangeSelection.bind(this);
    }

    componentDidMount() {
        if (this.props.companyData) {
            this.setState({
                data: this.props.companyData.data,
            });
        }

        if (this.props.company_data && this.props.company_errors != null) {
            this.setState({
                data: this.props.company_data,
                errors: this.props.company_errors,
            });
        }
        this.props.getMetaData({ type:'category' });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.companyData) {
            this.setState({
                data: newProps.companyData.data,
            });
        }
    }

    validateData() {
        const { data, errors } = this.state;
        let hasErrors = false;
        const ignoreList = [
           'about',
        ];

        if (!/^[a-zA-Z0-9\-_]{0,40}$/.test(data.sub_domain)) {
            hasErrors = true;
            errors.sub_domain = 'Enter a valid sub domain';
        }

        if (data.allowed_domains) {
            const domainsSelected = data.allowed_domains.split(', ');
            domainsSelected.map((url) => {
                if (!/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(url)) {
                    hasErrors = true;
                    errors.allowed_domains = 'Enter a valid url';
                }
            });
        }

        Object.keys(data).forEach((key) => {
            if(ignoreList.indexOf(key)<0) {
                if (key !== 'deleted_at' && key !== 'logo_media_id') {
                    if (typeof (data[key]) === 'string') {
                        if (!data[key].match(/\w/)) {
                            hasErrors = true;
                            errors[key] = 'This field is mandatory';
                        }
                    }
                    if (!data[key] || data[key] === ' ') {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    } else if (key === 'website') {
                        if (!/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(data[key])) {
                            hasErrors = true;
                            errors[key] = 'Enter a valid url';
                        }
                    }
                }
            }
        });

        if (hasErrors) {
            this.setState({ errors });

            if (this.props.onFailure) {
                this.props.onFailure(errors);
            }

            return false;
        }

        return data;
    }

    handleSubmit(e) {
        e.preventDefault();
        const validData = this.validateData();

        if (validData) {
            if (this.props.createCompany) {
                this.props.createCompany(this.state.data);
                return;
            }
            let funcName = '';
            if (validData.id) {
                validData.company_id = validData.id;
                funcName = 'postUpdateCompany';
            }

            this.props[funcName](validData).then((resp) => {
                const response = resp && resp.data;
                if (response && response.success) {
                    this.props.getCurrentOfficeList().then((resp) => {
                        if (resp && resp.data) {
                            const officeData = resp.data;
                            if(this.props.hideSideBarMenu === true){
                                officeData.data.some((ofc) => {
                                    if(ofc.city===null) {
                                        this.setState({
                                            officeEditData: ofc,
                                        });
                                        //return true;
                                    } else if(ofc.city!==null) {
                                        this.props.getCurrentCompany().then((resp) =>{
                                            const companyData = resp && resp.data;
                                            if(companyData && companyData.data && companyData.data.allowed_domains !== null) {
                                                this.props.setData('hideSideBarMenu', false);
                                            }
                                        })
                                    }
                                    //return false;
                                });
                            } 
                        }
                    });
                    this.props.getCurrentUser();
                    this.props.setAppMessage('success', 'Company updated successfully!');
                } else if (response) {
                    if (response.data && response.data.permission) {
                        this.props.setAppMessage('fail', response.data.permission);
                    }
                    this.setState({ errors: response.data });
                }
            });
        }
    }

    handleChangeSelection(option, label) {
        const { data, errors } = this.state;
        let value = null;
        if (option && Array.isArray(option)) {
            if (option.length) {
                value = [];
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        } else if (option) {
            value = option.val;
        }
        data[label] = value;
        if (errors[label]) {
            errors[label] = null;
        }
        this.setState({
            data,
            errors,
        });
    }

    handleValChange(e) {
        const { name, value } = e.target;
        const { data, errors } = this.state;

        data[name] = value;
        errors[name] = '';
        this.setState({ data, errors });
    }

    handleChangeLogo(logo) {
        const { data, errors } = this.state;
        if (errors.logo_url) {
            errors.logo_url = '';
        }
        data.logo_url = logo;
        this.setState({ data, errors });
    }

    handleOpenUploadModal() {
        const { errors } = this.state;
        if (errors.logo_url) {
            errors.logo_url = '';
        }
        this.setState({
            errors,
            upload_logo: true,
        });
    }

    handleChangeAboutCompany(content) {
        const { data, errors } = this.state;
        data.about = (content == '<p><br></p>') ? null : content;
        errors.about = '';
        this.setState({ data, errors });
    }

    render() {
        const { data, errors, upload_logo, officeEditData } = this.state;
        const { buttonVal } = this.props;
        const typeSelect = [
            { val: '', name: '-Select Type-' },
            { val: 'Private Ltd Company', name: 'Private Ltd Company' },
            { val: 'Public Ltd Company', name: 'Public Ltd Company' },
            { val: 'Unlimited Company', name: 'Unlimited Company' },
            { val: 'Sole proprietorship', name: 'Sole proprietorship' },
            { val: 'Partnership', name: 'Partnership' },
            { val: 'Cooperatives', name: 'Cooperatives' },
            { val: 'Limited Liability Partnership(LLP)', name: 'Limited Liability Partnership(LLP)' },
        ];

        const sizeOptions = [
            { val: '', name: '-Select Size-' },
            { val: '0-100', name: '0-100' },
            { val: '100-500', name: '100-500' },
            { val: '500-1000', name: '500-1000' },
            { val: 'more than 1000', name: 'more than 1000' },
        ];

        const categorySelect = [{ val:'', name:'-Select Category-' }];

        if(this.props.metaData) {
            const {
                metaData: {
                  category
                },
            } = this.props;

            if (category && category.length) {
                category.map((categoryData) => {
                    categorySelect.push({ val: categoryData.term, name: categoryData.term });
                });
            }
        }

        const exampleText = <span>eg : <strong>abcd</strong>.domain.com</span>;
        return (
            <form className="h-margin-bottom-0" noValidate onSubmit={this.handleSubmit}>
                <InputTextField
                    label="Name"
                    value={data.name}
                    name="name"
                    onChange={this.handleValChange}
                    required
                    errors={errors.name}
                />

                <InputSelectField
                    label="Type"
                    name="type"
                    value={data.type}
                    options={typeSelect}
                    errors={errors.type}
                    onChange={this.handleValChange}
                    required
                />

                <SearchDropdown
                    required
                    label="Select Category"
                    options={categorySelect}
                    value={data.category}
                    errors={errors.category}
                    customClass="w3-white"
                    onChange={(evt, val) => {
                        this.handleChangeSelection(val, 'category')
                    }}
                />

                <InputTextField
                    label="Website"
                    value={data.website ? data.website : ''}
                    name="website"
                    onChange={this.handleValChange}
                    required
                    errors={errors.website}
                    placeholder="http://abcd.com"
                />

                <InputSelectField
                    label="Company Size"
                    name="size"
                    value={data.size}
                    options={sizeOptions}
                    errors={errors.size}
                    onChange={this.handleValChange}
                    required
                />

                <div className="w3-col m4  h7t-order-1" style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 16 }}>
                    <label className="w3-row w3-text-grey">Logo*</label>
                    <div className="h7t-profile-pic w3-center w3-row">
                        <img
                            className="h7t-company-logo w3-white"
                            alt="upload"
                            src={(data && data.logo_url) ? data.logo_url.preview ? data.logo_url.preview : data.logo_url : 'https://www.w3schools.com/w3css/img_avatar6.png'}
                        />
                        <div className="h7t-upload-profile-pic">
                            <button
                                type="button"
                                className="w3-button w3-black w3-opacity-min w3-circle h7t-padding-2-8"
                                onClick={this.handleOpenUploadModal}
                            >
                                <i className="ion-camera w3-large" />
                            </button>
                        </div>
                    </div>
                    <div style={{ width: 160 }}>
                        {errors.logo_url
                            ? <span className="w3-text-red">*{errors.logo_url} </span>
                            : ''}
                    </div>
                </div>

                <InputTextField
                    label="Sub Domain"
                    value={data.sub_domain ? data.sub_domain : ''}
                    name="sub_domain"
                    onChange={this.handleValChange}
                    required
                    errors={errors.sub_domain}
                    placeholder="abcd"
                    helperText={exampleText}
                />

                <InputTextField
                    label="Allowed Domains"
                    value={data.allowed_domains ? data.allowed_domains : ''}
                    name="allowed_domains"
                    onChange={this.handleValChange}
                    required
                    errors={errors.allowed_domains}
                    placeholder="www.example.com, www.abcd.com"
                />
                <div className="w3-row w3-panel">
                    <label className="control-label w3-text-grey" style={{ fontSize: 'small' }}>About Company</label>
                    <ReactQuill
                        name="about"
                        id="about"
                        readOnly={this.state.about_company_active_tab === 0 ? false : true}
                        isMandatory="true"
                        value={data.about ? data.about : ''}
                        style={{ height: '100px', marginBottom: '10px' }}
                        onChange={this.handleChangeAboutCompany}
                        errors={errors.about}
                    />
                    <div style={{ paddingTop: '30px' }}>
                        <FormHelperText error={errors.about} className="w3-margin-left">{errors.about}</FormHelperText>
                    </div>
                </div>

                <Button
                    variant="contained"
                    type="submit"
                    className="w3-margin h7t-secondary-btn"
                >
                    {buttonVal != null ? buttonVal : 'Update'}
                </Button>

                {upload_logo
                    ? (
                        <ProfilePicUpload
                            logo
                            handleChangeLogo={this.handleChangeLogo}
                            currentData={data}
                            onModalClose={() => {
                                this.setState({
                                    upload_logo: false
                                })
                            }}
                        />
                    ) : null}
                {officeEditData
                    ? (
                        <Modal modalCls="w3-show" onClose={() => this.setState({ officeEditData: '' })}>
                            <OfficeListEditModal
                                officeEditData={officeEditData}
                                onModalClose={() => this.setState({ officeEditData: '' })}
                            />
                        </Modal>
                    ) : null}
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentCompany: state.currentCompany,
        metaData: state.meta,
        hideSideBarMenu: state && state.hideSideBarMenu,
    };
}

export default connect(mapStateToProps, {
    postAddCompany,
    postUpdateCompany,
    getMetaData,
    setAppMessage,
    getCurrentUser,
    getCurrentOfficeList,
    getCurrentCompany,
    setData
})(CompanyForm);
