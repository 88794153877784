import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    FormTextField,
    FormSelectField,
    ErrorHelperText,
} from '../Common/HelperComponents';

import {
    postRegisterUser,
    postLoginUser,
} from '../../js/actions';

class Employer extends Component {
    static propTypes = {
        postLoginUser: PropTypes.func,
        postRegisterUser: PropTypes.func,
        userRegisterData: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            data: {
                first_name: '',
                last_name: '',
                gender: '',
                dob: '',
                email: '',
                phone_number: '',
                user_type: '',
                password: '',
                confirm_password: '',
            },
            errors: {
                first_name: '',
                last_name: '',
                gender: '',
                dob: '',
                email: '',
                phone_number: '',
                user_type: '',
                password: '',
                confirm_password: '',
            },
        };

        this.onValChange = this.onValChange.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.registerEmployer = this.registerEmployer.bind(this);
    }

    onValChange(e) {
        const { name, value } = e.target;
        const { errors, data } = this.state;

        if (errors[name] !== '') {
            errors[name] = '';
            this.setState({ errors });
        }

        data[name] = value;
        this.setState({ data });
    }

    validateInput(cb) {
        const { data, errors } = this.state;
        let isValid = true;

        if (!/^\d{10}$/.test(data.phone_number)) {
            isValid = false;
            errors.phone_number = 'Enter a valid phone number';
        }
        if (!/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(data.email)) {
            isValid = false;
            errors.email = 'Enter a valid email';
        }
        if (data.password !== data.confirm_password) {
            isValid = false;
            errors.confirm_password = 'The password and confirm password must match.';
        }
        if (new Date(data.dob) > new Date()) {
            isValid = false;
            errors.dob = 'Enter a valid date';
        }

        Object.keys(data).forEach((key) => {
            if (typeof (data[key]) === 'string') {
                if (!data[key].match(/\w/)) {
                    isValid = false;
                    errors[key] = 'This field is mandatory';
                }
            }
            if (!data[key]) {
                isValid = false;
                errors[key] = 'This field is mandatory';
            }
        });

        this.setState({
            errors,
        }, () => {
            cb(isValid ? data : isValid);
        });
    }

    registerEmployer(e) {
        e.preventDefault();
        this.validateInput((validData) => {
            if (validData) {
                this.props.postRegisterUser(validData).then((resp) => {
                    const response = resp && resp.data;
                    if (response && response.success) {
                        this.props.postLoginUser(validData).then(() => {
                            const pathUrl = window.location.pathname;
                            let redirectUrl = '/e/dashboard';
                            if (pathUrl.indexOf('?redirectUrl=') !== -1) {
                                redirectUrl = pathUrl.replace('?redirectUrl=', '').replace('#/', '');
                            }
                            this.props.history.push(redirectUrl);
                        });
                    } else if (response) {
                        const { errors } = this.state;
                        Object.keys(response.data).forEach((key) => {
                            errors[key] = response.data[key][0];
                        });
                        this.setState({ errors });
                    }
                });
            } else {
                console.log('Errors need to be fixed.');
            }
        });
    }

    renderPricingSchemes() {
        const freeForLife = {
            title: 'Free for life',
            info: [
                'Unlimitted Job Posting (3 per day)',
                'Add upto three collaborators',
                'Setup one office',
                '10 Worflows',
                'Widget Intgration to websites',
                'Custom Sub domain',
            ],
        };

        const tierOne = {
            title: '10$ per month',
            info: [
                'Unlimitted Job Posting',
                'Unlimitted collaborators',
                'Setup up to 5 offices',
                '50 Worflows',
                'Widget Intgration to websites',
                'Custom Sub domain',
            ],
        };

        const tierTwo = {
            title: '50$ per month',
            info: [
                'Unlimitted Job Posting',
                'Unlimitted collaborators',
                'Unlimitted offices',
                'Unlimitted Worflows',
                'Widget Intgration to websites',
                'Custom Sub domain',
            ],
        };

        return (
            <div className="w3-panel w3-margin-bottom h-margin-bottom-50">
                <div className="w3-light-grey"><h3 className="w3-padding">Plans and pricing</h3></div>
                <div className="w3-row">
                    <div className="w3-col s4">
                        <div className="pricing-block" >
                            <ul className="w3-ul">
                                <li className="list-group-item"><h4>{freeForLife.title}</h4></li>
                                {
                                    freeForLife.info.map(txt => <li key={`${txt}`} className="list-group-item">{txt}</li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="w3-col s4">
                        <div className="pricing-block " >
                            <ul className="w3-ul">
                                <li className="list-group-item"><h4>{tierOne.title}</h4></li>
                                {
                                    tierOne.info.map(txt => <li key={`${txt}`} className="list-group-item">{txt}</li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="w3-col s4">
                        <div className="pricing-block " >
                            <ul className="w3-ul">
                                <li className="list-group-item"><h4>{tierTwo.title}</h4></li>
                                {
                                    tierTwo.info.map(txt => <li key={`${txt}`} className="list-group-item">{txt}</li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { errors, data } = this.state;
        return (
            <div className="w3-margin-top">
                <div className="w3-container">
                    <div className="w3-row">
                        <div className="w3-col m7">
                            <div className="bg-white">
                                <h3>Your hiring made easy!</h3>
                                <div>
                                    <ul>
                                        <li><h4>Better Co-ordination</h4></li>
                                        <li><h4>Easy Workflow Management</h4></li>
                                        <li><h4>Keep track of history</h4></li>
                                        <li><h4>Get operational in no time</h4></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="w3-col m5 w3-card w3-padding">
                            <div className="employer-register-form-bg">
                                <form className="form-horizontal" onSubmit={this.registerEmployer}>
                                    <FormTextField
                                        onChange={this.onValChange}
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        placeholder="First Name"
                                        inputClass="w3-input w3-border"
                                        errors={errors.first_name}
                                    />

                                    <FormTextField
                                        onChange={this.onValChange}
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        placeholder="Last Name"
                                        inputClass="w3-input w3-border"
                                        errors={errors.last_name}
                                    />

                                    <div className="w3-panel">
                                        <label htmlFor="gender_male" className="h7t-cursor-pointer w3-margin-right">
                                            <input
                                                selected={data.gender === 'male'}
                                                type="radio"
                                                name="gender"
                                                id="gender_male"
                                                value="male"
                                                onClick={this.onValChange}
                                            />
                                                &nbsp;Male
                                        </label>
                                        <label htmlFor="gender_female" className="h7t-cursor-pointer">
                                            <input
                                                selected={data.gender === 'female'}
                                                type="radio"
                                                name="gender"
                                                id="gender_female"
                                                value="female"
                                                onClick={this.onValChange}
                                            />
                                                &nbsp;Female
                                        </label>
                                        {errors.gender && <ErrorHelperText errors={errors.gender} />}
                                    </div>

                                    <FormTextField
                                        label="Date of Birth"
                                        type="date"
                                        placeholder="DOB"
                                        name="dob"
                                        id="dob"
                                        inputClass="w3-input w3-border"
                                        onChange={this.onValChange}
                                        errors={errors.dob}
                                    />

                                    <FormTextField
                                        onChange={this.onValChange}
                                        type="text"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        inputClass="w3-input w3-border"
                                        errors={errors.email}
                                    />

                                    <FormTextField
                                        onChange={this.onValChange}
                                        id="phone_number"
                                        type="number"
                                        name="phone_number"
                                        placeholder="Phone Number"
                                        inputClass="w3-input w3-border"
                                        errors={errors.phone_number}
                                    />

                                    <FormSelectField
                                        label="I am a"
                                        wrapClass="w3-panel"
                                        name="user_type"
                                        onChange={this.onValChange}
                                        selectClass="w3-select w3-select-big w3-border"
                                        defaultValue=""
                                        errors={errors.user_type}
                                    >
                                        <option value="" disabled>Select Type</option>
                                        <option value="CONSULTANT">Consultant</option>
                                        <option value="COMPANY">Company</option>
                                    </FormSelectField>

                                    <FormTextField
                                        onChange={this.onValChange}
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Password"
                                        inputClass="w3-input w3-border"
                                        errors={errors.password}
                                    />

                                    <FormTextField
                                        onChange={this.onValChange}
                                        type="password"
                                        id="confirm_password"
                                        name="confirm_password"
                                        placeholder="Confirm Password"
                                        inputClass="w3-input w3-border"
                                        errors={errors.confirm_password}
                                    />

                                    <button className="w3-btn w3-teal w3-block" type="submit">Create account</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w3-container vspaced-section">
                    <div className="w3-row">
                        <div className="w3-col m12">
                            <div className="feature_section info-box ">
                                <h3>Create Job Postings from Available templates</h3>
                                <h3>Create Worflows for handling a Job Application </h3>
                                <h3>Create Roles and users for managing Job Applicaiton</h3>
                                <h3>Analyse from our dashboards, the bottlenecks in your hiring process</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderPricingSchemes()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userRegisterData: state.registerUser,
    };
}

export default withRouter(connect(mapStateToProps, {
    postRegisterUser,
    postLoginUser,
})(Employer));
