import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';

export default class BlocksComponent extends Component {
    static propTypes = {
        data: PropTypes.array,
    };

    render() {
        const { data } = this.props;
        return (
            <Grid container sm={12} md={12} lg={12} spacing={1}>
                {data.map((item, idx) => (
                    <Grid item sm={12} md={6} lg={6} key={`item_${idx}`}>
                        <Paper style={{ width: '100%', height: '165px',overflow: 'auto' }} className="w3-padding h7t-table-data">
                            <h6 className="w3-text-grey">{item.title}</h6>
                            <h1 className="center-align">{(item.count) ? item.count : 0}</h1>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        )
    }
}
