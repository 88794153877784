import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import {
    setAppMessage,
    getCurrentUser,
    getCurrentCompany,
    postChangeProfilePic,
    postDeleteProfilePic,
    postUpdateCompanyLogo,
    postDeleteCompanyLogo,
} from '../../../js/actions';
import Modal from '../../Common/Modal';
import ProfilePicDeleteModal from '../../Common/DeleteRecordModal';
import { Button } from '@material-ui/core';

class ProfilePicUpload extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getCurrentUser: PropTypes.func,
        postDeleteProfilePic: PropTypes.func,
        postChangeProfilePic: PropTypes.func,
        postUpdateCompanyLogo: PropTypes.func,
        postDeleteCompanyLogo: PropTypes.func,
        currentData: PropTypes.object,
        logo: PropTypes.bool,
        handleChangeLogo: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            progressCount: 0,
            disabled: false,
            saveDisabled: true,
            currentPic: null,
            deletePic: '',
        };
        this.handleChangeProfilePic = this.handleChangeProfilePic.bind(this);
        this.handleRemoveProfile = this.handleRemoveProfile.bind(this);
        this.handleSavePicture = this.handleSavePicture.bind(this);
    }

    componentDidMount() {
        const { currentData, currentUser: { profile }, logo } = this.props;
        if (logo && currentData && currentData.logo_url) {
            this.setState({
                currentPic: {
                    preview: currentData.logo_url,
                },
                disabled: true,
            });
        } else if (!logo && profile && profile.profile_pic) {
            this.setState({
                currentPic: {
                    preview: profile.profile_pic,
                },
                disabled: true,
            });
        }
    }

    onProgress() {
        let { progressCount } = this.state;
        if (progressCount >= 100) {
            clearInterval(this);
        } else {
            progressCount++;
            this.setState({ progressCount });
        }
    }

    handleChangeProfilePic(image) {
        const profilePicSelected = image[0] ? image[0] : '';
        if (!profilePicSelected) {
            this.props.setAppMessage('fail', 'Un-supported file. Please try with png, jpg or jpeg file.');
        } else if (profilePicSelected.size > 2097152) {
            this.props.setAppMessage('fail', 'File size is larger than 2MB');
        } else {
            this.setState({
                disabled: true,
                currentPic: profilePicSelected,
                saveDisabled: false,
            });
        }
    }

    handleSavePicture() {
        const { logo,
            currentData,
            handleChangeLogo,
            postUpdateCompanyLogo,
            setAppMessage,
            getCurrentUser,
            postChangeProfilePic,
        } = this.props;
        const { currentPic } = this.state;
        this.setState({
            saveDisabled: true,
            progressCount: 0,
        });
        const id = setInterval(this.onProgress.bind(this), 20);
        const formData = new FormData();
        formData.append('myfile', currentPic);
        if (logo) {
            if (currentData && currentData.id) {
                formData.append('company_id', COMPANY_ID);
                postUpdateCompanyLogo(formData).then((response) => {
                    const resp = response && response.data;
                    if (resp && resp.success) {
                        // this.props.getCurrentCompany();
                        currentData.logo_url = resp.data.logo_url;
                        setAppMessage('success', 'Logo updated successfully');
                        getCurrentUser().then(() => {
                            clearInterval(id);
                            this.setState({ progressCount: 100 });
                            $('.close-button').click();
                        });
                    } else {
                        this.props.setAppMessage('fail', 'Logo updation failed');
                        clearInterval(id);
                        this.setState({
                            progressCount: 0,
                            currentPic: null,
                            disabled: false,
                        });
                    }
                });
            } else if (handleChangeLogo) {
                handleChangeLogo(currentPic);
                clearInterval(id);
                this.setState({ progressCount: 100 });
                $('#h7t-upload-modal-close').click();
            }
        } else {
            postChangeProfilePic(formData).then((response) => {
                const resp = response && response.data;
                if (resp && resp.success) {
                    setAppMessage('success', 'Profile picture updated successfully');
                    getCurrentUser().then(() => {
                        clearInterval(id);
                        this.setState({ progressCount: 100 });
                        $('.close-button').click();
                    });
                } else {
                    setAppMessage('fail', 'Profile picture updation failed');
                    clearInterval(id);
                    this.setState({
                        progressCount: 0,
                        currentPic: null,
                        disabled: false,
                    });
                }
            });
        }
    }

    handleRemoveProfile(evt) {
        const { logo,
            currentData,
            postDeleteCompanyLogo,
            getCurrentUser,
            setAppMessage,
            postDeleteProfilePic
        } = this.props;
        if (evt === true) {
            const { currentPic } = this.state;
            const res = currentPic.preview.split('/');
            let data = { profile_pic: res[res.length - 1] };
            if (logo) {
                data = {
                    logo_url: res[res.length - 1],
                    company_id: COMPANY_ID,
                };
                postDeleteCompanyLogo(data).then((resp) => {
                    const response = resp && resp.data;
                    if (response && response.success) {
                        currentData.logo_url = null;
                        getCurrentUser().then(() => {
                            $('.close-button').click();
                        });
                        this.setState({
                            currentPic: null,
                            deletePic: '',
                            progressCount: 0,
                            disabled: false,
                        });
                        setAppMessage('success', 'Company Logo deleted successfully');
                    }
                });
            } else {
                postDeleteProfilePic(data).then((resp) => {
                    const response = resp && resp.data;
                    if (response && response.success) {
                        getCurrentUser();
                        this.setState({
                            currentPic: null,
                            deletePic: '',
                            progressCount: 0,
                            disabled: false,
                        });
                        setAppMessage('success', 'Profile picture deleted successfully');
                    }
                });
            }
        } else {
            evt.preventDefault();
            evt.stopPropagation();
            const { currentUser: { profile } } = this.props;
            if (logo && currentData && currentData.logo_url) {
                this.setState({ deletePic: true });
            } else if (!logo && profile && profile.profile_pic) {
                this.setState({ deletePic: true });
            } else {
                this.setState({
                    currentPic: null,
                    disabled: false,
                });
            }
        }
    }


    render() {
        const { onModalClose, logo } = this.props;
        const {
            progressCount, disabled, saveDisabled, currentPic, deletePic,
        } = this.state;

        return (
            <Modal
                modalCls="w3-show w3-center"
                id="h7t-upload-modal-close"
                onClose={onModalClose}
                title={logo ? "Upload Company Logo" : "Upload Profile Picture"}
                widthCls="chp-small-dlg"
            >
                <Dropzone
                    accept="image/jpeg, image/png, image/jpg"
                    onDrop={this.handleChangeProfilePic}
                    disabled={disabled}
                    className="h7t-dropzone-div w3-center"
                >
                    {progressCount
                        ? (
                            <div className="w3-light-grey" style={{ width: '100%' }}>
                                <div id="myBar" className="w3-container w3-green w3-center w3-round-large" style={{ width: `${progressCount}%` }}>{`${progressCount}%`}</div>
                            </div>
                        ) : (currentPic)
                            ? (
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span className="w3-badge w3-small h7t-profile-remove-icon" onClick={this.handleRemoveProfile}>x</span>
                                    <img src={currentPic.preview} alt="" style={{ height: 'auto', maxWidth: '100%', objectFit: 'cover', maxHeight: '100%' }} />
                                </div>
                            ) : <p className="p-3">Select an image to upload or drag & drop an image</p>}
                </Dropzone>

                <div className="w3-row h7t-button-group">
                    <div className="w3-col m6 s6">
                        <Button
                            type="button"
                            variant="contained"
                            className="w3-left w3-round h7t-margin-10 h7t-secondary-btn"
                            style={{ width: '90%' }}
                            onClick={onModalClose}
                        >
                            Close
                        </Button>
                    </div>
                    <div className="w3-col m6 s6">
                        <Button
                            type="button"
                            variant="contained"
                            className={`w3-right w3-round h7t-margin-10 h7t-primary-btn ${saveDisabled ? 'w3-disabled' : ''}`}
                            style={{ width: '90%' }}
                            disabled={saveDisabled}
                            onClick={this.handleSavePicture}
                        >
                            Save
                        </Button>
                    </div>
                </div>

                {deletePic
                    ? (
                        <ProfilePicDeleteModal
                            handleDelete={this.handleRemoveProfile}
                            thisMsg={true}
                            deleteMessage={logo ? "company logo" : "profile picture"}
                            onModalClose={() => this.setState({ deletePic: '' })}
                        />
                    ) : null}
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentUser,
    getCurrentCompany,
    postChangeProfilePic,
    postDeleteProfilePic,
    postUpdateCompanyLogo,
    postDeleteCompanyLogo,
})(ProfilePicUpload);
