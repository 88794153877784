import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserAccess } from '../../js/actions';
import { Grid, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { sidebarMenu, getScreenName } from './Constants';

class NewSidebarMenu extends Component {
    static propTypes = {
        userAccess: PropTypes.object,
        currentUser: PropTypes.object,
        getUserAccess: PropTypes.func,
        hideSideBarMenu: PropTypes.bool,
    };

    constructor(args) {
        super(args);
        this.state = {
            showSubMenu: false,
        };
        this.getMenuList = this.getMenuList.bind(this);
        this.getIdFromUrl = this.getIdFromUrl.bind(this);
        this.handleSideBarClose = this.handleSideBarClose.bind(this);
        this.handleSubMenuList = this.handleSubMenuList.bind(this);
        this.getSidebarData = this.getSidebarData.bind(this);
    }

    componentDidMount() {
        if (this.props.userAccess === null && this.props.currentUser.user) {
            this.props.getUserAccess();
        }
    }

    handleSubMenuList() {
        const { showSubMenu } = this.state;
        this.setState({
            showSubMenu: !showSubMenu,
        });
    }

    getSidebarData(list) {
        let listName = '';
        listName = list.label.replace(/-/g, ' ').toUpperCaseWords();
        const { showSubMenu } = this.state;
        return(
            <div className="w3-row">
                <Grid container >
                    <Grid item xs={2} sm={3} md={2}>
                        <span className="material-icons w3-margin-right" style={{fontSize: '22px'}}>{list.icon}</span>
                    </Grid>
                    <Grid item xs={10} sm={9} md={10}>
                        <span>
                            {listName === 'Manage Company' ? 'Settings' : listName}
                            {list.items ? 
                                showSubMenu ?
                                    <i className="material-icons w3-right">expand_more</i>
                                    : <i className="material-icons w3-right">chevron_right</i>
                                : null}
                        </span>
                    </Grid>
                </Grid>
            </div>
        );
    }
    
    getMenuList(data) {
        const { currentUser: { user } } = this.props;
        const {
            companyId, officeId, permission, refId,
        } = data;
        const { showSubMenu } = this.state;
        const sidebarMenuList = [];
        const sidebarSubMenuList = [];
        let sidebarContent = [];
        if (companyId) {
            if (permission === 'Management') {
                sidebarContent = sidebarMenu.managementMenu;
            } else if (permission === 'HR') {
                sidebarContent = sidebarMenu.hrMenu;
            } else if (permission === 'Panel Member') {
                sidebarContent = sidebarMenu.panelMemberMenu;
            } else if (permission === 'Hiring Manager') {
                sidebarContent = sidebarMenu.hiringManagerMenu;
            } else {
                sidebarContent = sidebarMenu.defaultMenu;
            }
        } else {
            sidebarContent = sidebarMenu.seekerMenu;
        }

        String.prototype.toUpperCaseWords = function () {
            return this.replace(/\w+/g, (a) => {
                return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase();
            });
        };

        sidebarContent.forEach((list) => {
            const { hideSideBarMenu } = this.props;
            let toLink = '';
            toLink = companyId ? {
                pathname: `/e/${companyId}/${officeId}/${list.label}`,
                state: {
                    companyId,
                    officeId,
                }
            } : `/s/${list.label}`;

            let setActive = false;
            const activateSettings = hideSideBarMenu === true && list.label !== 'manage-company';
            if (list.label === 'profile') {
                const screenName = getScreenName(user);
                toLink = `/s/${list.label}/${screenName}`;
            }
            
            if(list.items) {
                list.items.map((sublist) => {
                    const toSubmenuLink = companyId ? {
                        pathname: `/e/${companyId}/${officeId}/${sublist.label}`,
                        state: {
                            companyId,
                            officeId,
                        }
                    } : `/s/${sublist.label}`;
                    if(window.location.pathname == toSubmenuLink.pathname) {
                        setActive = true;
                    }
                    sidebarSubMenuList.push(
                        <Collapse in={showSubMenu} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <NavLink
                                    key={sublist.label}
                                    className={`w3-bar-item w3-button h7t-sidebar-menu-options ${activateSettings ? 'w3-disabled' : '' }`}
                                    to={toSubmenuLink}
                                    disabled={activateSettings}
                                >
                                    <div className="w3-row w3-margin-left">
                                        <Grid container>
                                            <Grid item xs={2} sm={3} md={2}>
                                                <span className="material-icons w3-margin-left w3-margin-right" style={{fontSize: '12px'}}>{sublist.icon}</span>
                                            </Grid>
                                            <Grid item xs={10} sm={9} md={10}>
                                                <span>{sublist.label.replace(/-/g, ' ').toUpperCaseWords()}</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </NavLink>
                            </List>
                        </Collapse>
                    );
                });
            }
            sidebarMenuList.push(
                <List disablePadding>
                    {list.items ? 
                        <div
                            key={list.label}
                            className={`w3-bar-item w3-button h7t-sidebar-menu-options h7t-font-size-16 ${location.pathname === toLink.pathname ? '' : ''} ${activateSettings ? 'w3-disabled' : '' }`}
                            style={{color: 'rgba(0, 0, 0, 0.87)'}}
                            disabled={activateSettings}
                            onClick={() => { list.items && this.handleSubMenuList() }}
                        >
                            {this.getSidebarData(list)}
                        </div> : 
                        <NavLink
                            key={list.label}
                            className={`w3-bar-item w3-button h7t-sidebar-menu-options ${location.pathname === toLink.pathname ? '' : ''} ${activateSettings ? 'w3-disabled' : '' }`}
                            to={toLink}
                            disabled={activateSettings}
                            onClick={() => { list.items && this.handleSubMenuList() }}
                        >
                            {this.getSidebarData(list)}
                        </NavLink>
                    }
                    
                    {list.items || setActive ?
                        <div>
                            {sidebarSubMenuList}
                        </div>
                    : null}
                </List>
            );
        });
    
        return (
            <div id={refId}>
                {sidebarMenuList}
            </div>
        );
    }

    getIdFromUrl(pathUrl) {
        const hashData = pathUrl.split('/');
        return {
            cId: parseInt(hashData[2]),
            oId: parseInt(hashData[3]),
        };
    }

    handleSideBarClose() {
        document.getElementById('side-bar-menu').style.display = 'none';
        document.getElementById('page-overlay').style.display = 'none';
        document.getElementById('main').style.marginLeft = '0px';
        document.getElementById('main').style.marginRight = '0px';
    }

    render() {
        const { currentUser, currentUser: { companies } } = this.props;
        const companyList = [];
        let seekerMenu = null;
        let companyListBlock = null;
        const pathUrl = window.location.pathname;
        const companyDetails = this.getIdFromUrl(pathUrl);

        if (pathUrl.indexOf('/e/')  ===  0) {
            if (companies && companies.length > 0) {
                const routeIds = (pathUrl.indexOf('/e/') !== -1) ? companyDetails : null;
                companies.filter(company => company.company_id == COMPANY_ID).map((company) => {
                    const companyMenuList = [];
                    const companyRefId = `company_${company.company_id}`;
                    let permission = null;
                    if (company.office_list.length > 0) {
                        const office = company.office_list.find((off) => {
                            if (off.id == OFFICE_ID) {
                                return off;
                            }
                            if (currentUser && currentUser.user && currentUser.user.current_office_id) {
                                if (off.id == currentUser.user.current_office_id) {
                                    return off;
                                }
                            }
                        });
                        if (office) {
                            const officeRefId = `office_${office.id}`;
                            if (office.permissions.indexOf('Management') !== -1) {
                                permission = 'Management';
                            } else if (office.permissions.indexOf('HR') !== -1) {
                                permission = 'HR';
                            } else if (office.permissions.indexOf('Panel Member') !== -1) {
                                permission = 'Panel Member';
                            } else if (office.permissions.indexOf('Hiring Manager') !== -1) {
                                permission = 'Hiring Manager';
                            }
                            const data = {
                                companyId: company.company_id,
                                companyName: company.company_name,
                                officeId: office.id,
                                permission,
                                refId: officeRefId,
                                routeIds,
                            };
                            companyMenuList.push(
                                <div key={officeRefId} className={office.package_status == 'INACTIVE' ? 'w3-disabled' : ''}>
                                    {this.getMenuList(data)}
                                </div>,
                            );
                        }
                    }

                    const userStatus = (company.user_status == 'INACTIVE') ? 'w3-disabled' : '';
                    
                    companyList.push(
                        <div key={companyRefId} className={`${userStatus}`}>
                            <div id={companyRefId}>
                                {companyMenuList}
                            </div>
                        </div>,
                    );
                });
            }

            companyListBlock = (
                <div>
                    {companyList} 
                </div>
            );
        } else if (pathUrl.indexOf('/s') === 0) {
            const data = { refId: 'seekerMenu' };
            seekerMenu = (
                <div> 
                    {this.getMenuList(data)}
                </div>
            );
        }

        return (
            <div className="w3-bar-block h7t-margin-mobile-view">
                <div className="w3-bar-item w3-right-align w3-large w3-hide-medium w3-hide-large">
                    <span onClick={this.handleSideBarClose}>&times;</span>
                </div>
                {seekerMenu}
                {companyListBlock}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = (state && state.currentUser && state.currentUser.data) ? state.currentUser.data : (state && state.currentUser) ? state.currentUser : '';
    const hideSideBarMenu = state && state.hideSideBarMenu;
    return {
        currentUser,
        userAccess: state.userAccess,
        hideSideBarMenu,
    };
}

export default withRouter(connect(mapStateToProps, {
    getUserAccess,
})(NewSidebarMenu));
