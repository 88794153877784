import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../Common/Modal';

class ViewPermissions extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
        };
    }

    render() {
        const { onModalClose } = this.props;

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title="View Permissions" widthCls="chp-small-dlg">
                <div className="w3-row w3-padding">
                    <p className="h7t-modal-subtitle">Management</p>
                    <ul>
                        <li>Create a Job/ Remove a Job</li>
                        <li>Add/Remove roles</li>
                        <li>Add/Remove members </li>
                        <li>View all applicants</li>
                        <li>View feedback</li>
                        <li>Comment on Feedback</li>
                    </ul>
                    <p className="h7t-modal-subtitle">HR</p>
                    <ul>
                        <li>Create a Job/ Remove a Job</li>
                        <li>Add/Remove roles</li>
                        <li>Add/Remove members</li>
                        <li>View all applicants</li>
                        <li>Create/Edit/View/Remove Workflow</li>
                        <li>Schedule interviews</li>
                        <li>View Feedback</li>

                    </ul>
                    <p className="h7t-modal-subtitle">Panel Members</p>
                    <ul>
                        <li>View applicants only assigned to them</li>
                        <li>Give Feedback to applicants assigned to them</li>
                        <li>View their Calendar schedules</li>
                    </ul>
                    <p className="h7t-modal-subtitle">Hiring Manager</p>
                    <ul>
                        <li>Draft a Job</li>
                        <li>Edit/Remove drafted Job</li>
                        <li>Copy Job Description/Job Url </li>
                        <li>View all jobs</li>
                    </ul>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
})(ViewPermissions);
