import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Modal from './Modal';

class DeleteRecordModal extends Component {
    static propTypes = {
        handleDelete: PropTypes.func,
        onModalClose: PropTypes.func,
        deleteMessage: PropTypes.string,
        thisMsg: PropTypes.bool, 
    };

    constructor(args) {
        super(args);

        this.state = {
            message: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.callback = this.callback.bind(this);
    }

    handleSubmit() {
        if (this.props.handleDelete) {
            this.props.handleDelete(true, this.callback);
        }
    }

    callback(resp) {
        if (resp && !resp.success) {
            this.setState({ message: resp.data.error });
        }
    }

    render() {
        const { onModalClose, deleteMessage, thisMsg } = this.props;
        const { message } = this.state;

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title={`Delete ${deleteMessage}`} widthCls="chp-small-dlg">
                <div className="w3-container">
                    {message ? <p className="w3-text-red">* {message}</p> : null}
                    <p>Are you sure you want to delete {thisMsg ? 'this' : 'these'} {deleteMessage}?</p>
                </div>
                <div className="w3-bar w3-panel">
                    <Button
                        variant="contained"
                        className="w3-margin-right w3-margin-bottom h7t-secondary-btn"
                        onClick={onModalClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        className="w3-margin-bottom h7t-primary-btn"
                        onClick={this.handleSubmit}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
})(DeleteRecordModal);
