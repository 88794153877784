import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import Modal from '../Common/Modal';
import { FormTextField, ErrorHelperText } from '../Common/HelperComponents';
import { InputTextField } from '../Common/MaterialUI/HelperInputs';
import { FormHelperText , Button } from '@material-ui/core';

class SendConfigMail extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        onSendMail: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            mailContent: {},
            errors: {},
            disableBtn: false,
        };

        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleSendMail = this.handleSendMail.bind(this);
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
    }

    handleChangeSubject(e) {
        const { mailContent, errors } = this.state;
        mailContent[e.target.name] = e.target.value;
        if (errors[e.target.name]) {
            errors[e.target.name] = null;
        }
        this.setState({
            mailContent,
            errors,
        });
    }

    handleChangeContent(content) {
        const { mailContent, errors } = this.state;
        if (errors.content) {
            errors.content = null;
        }
        mailContent.content = (content == '<p><br></p>') ? '' : content;
        this.setState({ mailContent, errors });
    }

    handleSendMail() {
        const { mailContent, errors } = this.state;
        let hasError = false;
        if (!mailContent.subject) {
            errors.subject = 'This field is mandatory';
            hasError = true;
        }
        if (!mailContent.content) {
            errors.content = 'This field is mandatory';
            hasError = true;
        }
        if (!hasError) {
            this.setState({
                disableBtn: true,
            });
            this.props.onSendMail(mailContent);
        } else {
            this.setState({
                errors,
            });
        }
    }

    render() {
        const { onModalClose } = this.props;
        const { mailContent, errors, disableBtn } = this.state;

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} widthCls="chp-small-dlg">
                <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">Send Mail</h4>
                <div className="w3-row">
                    <InputTextField
                        autoFocus
                        name="subject"
                        id="subject"
                        label="Subject"
                        inputClass="w3-input w3-border"
                        defaultValue={mailContent.subject}
                        onChange={this.handleChangeSubject}
                        errors={errors.subject}
                        placeholder="Enter your subject"
                    />
                    <div className="w3-panel">
                        <ReactQuill
                            defaultValue={mailContent.content}
                            placeholder="Enter the content here..."
                            onChange={this.handleChangeContent}
                            style={{ height: '100px', marginBottom: '50px'}}
                            className="w3-text-black"
                        />
                        <FormHelperText errors={errors.content} className="w3-margin-left w3-text-red">{errors.content}</FormHelperText>
                        <p><i className="ion-information-circled ion-icon " /> This will send an e-mail to all the applicants of this job.</p>
                        <Button
                            variant="contained"
                            disabled={disableBtn}
                            className="h7t-primary-btn"
                            onClick={this.handleSendMail}
                        >
                            {disableBtn ? 'Sending...' : 'Send'}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default SendConfigMail;
