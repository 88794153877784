import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
    Grid, Drawer, Divider, List, FormControl, Select, Button, MenuItem, InputLabel,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { InputSelectField } from '../Common/MaterialUI/HelperInputs';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';


class DashboardFilter extends Component {
    static propTypes = {
        handleJobFilters: PropTypes.func,
        clearFilterValues: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            right: false,
            form: {
            },
            errors:[],
        };
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.triggerFilter = this.triggerFilter.bind(this);
        this.handleDatePicker = this.handleDatePicker(this);
    }

    toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            right: open,
        });
    };

    handleClearFilter() {
        const form = {};
        this.setState({
            form,
            right: false,
        });
        this.props.clearFilterValues(form);
    }


    handleSelectFilter(event) {
        const { name, value } = event.target;
        const { form } = this.state;
        if (event) {
            form[name] = value;
        } else {
            form[name] = '';
        }
        this.setState({ form });
    }

    triggerFilter() {
        const { form } = this.state;
        this.props.handleJobFilters(form);
        this.setState({
            right: false,
        });
    }

    handleDatePicker(evt, date, id) {
        const { form, errors } = this.state;
        const formatedDate = moment(evt).format('YYYY/MM/DD');

        // if (errors[id]) {
        //     errors[id] = null;
        // }

        form[id] = formatedDate;
        this.setState({ form, errors });
    }

    render() {
        const {
        } = this.props;
        const {
            right, form,
        } = this.state;

        const sideList = (
            <div
                style={{ padding: '20px', width: '450px', paddingTop: '50px' }}
                role="presentation"
            >
                <h3>Filters</h3>
                <div className="w3-panel w3-row" style={{ display: 'flex' }}>
                    <div className="w3-col m6 h7t-margin-top-mobile h7t-padding-right-4">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                size="small"
                                required
                                inputVariant="outlined"
                                label="From"
                                format="MMM do, yyyy"
                                inputProps={{readOnly: true}}
                                disabled={true}
                                // value={form.from_date ? moment(form.from_date) : null}
                                onChange={(e, date) => this.handleDatePicker(e, date, 'from_date')}
                                // error={errors.from_date}
                                // helperText={errors.from_date}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="w3-col m6 h7t-margin-top-mobile h7t-padding-left-4">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                size="small"
                                required
                                inputVariant="outlined"
                                label="To"
                                format="MMM do, yyyy"
                                inputProps={{readOnly: true}}
                                disabled={true}
                                // value={form.to_date ? moment(form.to_date) : null}
                                onChange={(e, date) => this.handleDatePicker(e, date, 'to_date')}
                                // error={errors.to_date}
                                // helperText={errors.to_date}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>


                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Location"
                    id="notice_period"
                    name="notice_period"
                    value={form.notice_period}
                    // options={noticePeriodSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Position Name"
                    id="step"
                    name="step"
                    value={form.step}
                    // options={stepSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <List>
                    <Grid container justify="space-between" alignItems="center">
                        <Button
                            variant="contained"
                            size="small"
                            className="h7t-secondary-btn"
                            onClick={this.handleClearFilter}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={this.triggerFilter}
                            className="w3-center h7t-primary-btn"
                        >
                            Filter
                        </Button>
                    </Grid>
                </List>
            </div>
        );

        return (
            <div>
                <IconButton
                    className="h7t-cursor-pointer"
                    onClick={this.toggleDrawer(true)}
                >
                    <FilterListIcon />
                </IconButton>
                <Drawer
                    anchor="right"
                    open={right}
                    onClose={this.toggleDrawer(false)}
                    style={{width:"100px"}}
                >
                    {sideList}
                </Drawer>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
})(DashboardFilter);
