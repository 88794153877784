import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/ApplicationFooter';
import NewSidebarMenu from './Common/NewSidebarMenu';
import StickyInfoBar from './Common/StickyInfoBar';
import {
    postUpdateCompanyOffice,
    resetState,
    getCurrentUser,
    getListInvite,
    getUnreadNotifications,
    getCurrentOfficeList,
    getCurrentCompany,
    setData,
} from '../js/actions';

import Dashboard from './Dashboard/Dashboard';
import JobApplications from './JobApplication/JobApplications';
import Jobs from './Jobs/JobList';
import UserRole from './Role/UserRole';
import ManageWorkflow from './ManageWorkflow/ManageWorkflow';
import PreviewWorkflow from './ManageWorkflow/PreviewWorkflow';
import ManageCompany from './ManageCompany/ManageCompany';
import ViewJobApplications from './JobApplication/ViewApplication';
import ArchieveJobApplications from './JobApplication/ArchieveJobApplications';

import SeekerProfile from './Seeker/Profile/Profile';
import ResumeMaker from './Seeker/Resume/ResumeMaker';
import Settings from './Common/Settings';
import Discover from './Common/Discover';
import ViewResume from './Common/ViewResume';
import NewSeekerJobDashboard from './Dashboard/NewSeekerJobDashboard'; 
import MyCalendar from './MyCalendar/MyCalendar';
import NotFound from './Common/NotFound';
import AboutUs from './Common/AboutUs';
import TermsOfService from './Common/TermsOfService';
import PrivacyPolicy from './Common/PrivacyPolicy';

class Application extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        appMessage: PropTypes.object,
        postUpdateCompanyOffice: PropTypes.func,
        resetState: PropTypes.func,
        getCurrentUser: PropTypes.func,
        hideSideBarMenu: PropTypes.bool,
    };

    constructor(args) {
        super(args);
        this.handleOverlayClick = this.handleOverlayClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkOffset);
        const pathUrl = window.location.pathname;
        if (pathUrl.indexOf('/e/') !== -1) {
            this.props.getCurrentCompany().then((response) => {
                if(response && response.data) {
                    const companyData = response.data;
                    if(this.props.hideSideBarMenu === false) {
                        this.checkOffice(companyData,null);
                    }
                }
            });
            this.props.getCurrentOfficeList().then((resp) => {
                if (resp && resp.data) {
                    const officeData = resp.data;
                    if(this.props.hideSideBarMenu === false) {
                        this.checkOffice(null,officeData);
                    }
                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (document.body.scrollTop > 0) {
            document.body.scrollTop = 0;
        }
        console.log('Next props : ', nextProps);
        if (this.props.location.state && nextProps.location.state && nextProps.location.state.companyId) {
            if ((this.props.location.state.companyId !== nextProps.location.state.companyId)
                || (this.props.location.state.officeId !== nextProps.location.state.officeId)) {
                this.props.resetState();
                const data = {
                    company_id: nextProps.location.state.companyId,
                    office_id: nextProps.location.state.officeId,
                };

                if(nextProps.currentCompany && nextProps.currentCompany.data) {
                    this.checkOffice(nextProps.currentCompany,null);
                }
                if(nextProps.currentOfficeList && nextProps.currentOfficeList.data) {
                    this.checkOffice(null,nextProps.currentOfficeList);
                }

                this.props.postUpdateCompanyOffice(data).then((resp) => {
                    if (resp && resp.data && resp.data.success) {
                        this.props.getCurrentUser();
                        this.props.getListInvite();
                        this.props.getUnreadNotifications();
                    } else {
                        document.location.reload();
                    }
                });

                // this.props.postUpdateCompanyOffice(data).then((resp) => {
                //     if (resp && resp.data && resp.data.success) {
                //         this.props.getCurrentUser();
                //         let currHashUrl = window.location.hash;
                //         currHashUrl = currHashUrl.split('/');
                //         currHashUrl.splice(0, 4);
                //         this.props.history.push(`/${data.company_id}/${data.office_id}/${currHashUrl.join('/')}`);
                //         document.location.reload();
                //     } else {
                //         document.location.reload();
                //     }
                // });
            }
        }
        this.checkOffset();
    }

    handleOverlayClick() {
        document.getElementById('side-bar-menu').style.display = 'none';
        document.getElementById('page-overlay').style.display = 'none';
        document.getElementById('main').style.marginLeft = '0px';
        document.getElementById('main').style.marginRight = '0px';
    }

    checkOffice(companyData,officeData) {
        if(companyData !== null && companyData.data.allowed_domains === null) {
            this.props.setData('hideSideBarMenu', true);
        }
        if(officeData !== null) {
            const result = officeData.data.some(ofc => ofc.city === null);
            this.props.setData('hideSideBarMenu', result);
        }
        const pathUrl = window.location.pathname;
        if(this.props.hideSideBarMenu === true && pathUrl.indexOf('/e/') !== -1) {
            let compId = null;
            let ofcId = null;
            const { currentUser } = this.props;
            compId = pathUrl.split('/')[2];
            ofcId = pathUrl.split('/')[3];
            const companyId = currentUser &&  currentUser.user && currentUser.user.current_company_id;
            const officeId = currentUser && currentUser.user && currentUser.user.current_office_id;
            let redirectUrl = null;
            if(companyId == compId && officeId == ofcId) {
                redirectUrl = {
                    pathname: `/e/${companyId}/${officeId}/manage-company`,
                    state: {
                        companyId,
                        officeId,
                    }
                };
            }
            this.props.history.push(redirectUrl); 
        }
    }

    checkOffset() {
        if (window.innerWidth > 600) {
            // const scrollTop = document.body.scrollTop + window.innerHeight;
            const scrollTop = document.scrollingElement.scrollTop + window.innerHeight;
            const footerHeight = document.getElementById('footerHeight').offsetTop;
            if (scrollTop > footerHeight) {
                document.getElementById('side-bar-menu').setAttribute('style', `bottom: ${scrollTop - footerHeight}px`);
            } else {
                document.getElementById('side-bar-menu').setAttribute('style', 'bottom: 0');
            }
        }
    }

    render() {
        const { appMessage } = this.props;

        let companyId = null;
        let officeId = null;

        const pathUrl = window.location.pathname;

        if (pathUrl.indexOf('/e/') !== -1) {
            const { location: { state } } = this.props;
            if (state) {
                companyId = state.companyId;
                officeId = state.officeId;
            }
        }
        
        return (
            <div>
                <div className="w3-top w3-hide-small" style={{ 'zIndex': 2 }}>
                    <Header key="desktop" companyId={companyId} officeId={officeId} />
                </div>
                <div className="h7t-container">
                    <div
                        id="side-bar-menu"
                        className="h7t-sidebar-section paddingBottom100 w3-bar-block sidebarMenu w3-card-2 w3-animate-left side-bar-menu"
                    >
                        <NewSidebarMenu />
                    </div>
                    <div className="w3-overlay" id="page-overlay" onClick={this.handleOverlayClick} />
                    <div className="main-container" id="main">
                        <div className="w3-hide-medium w3-hide-large">
                            <Header key="mobile" companyId={companyId} officeId={officeId} />
                        </div>
                        <div>
                            {appMessage.messageText && <StickyInfoBar msgType={appMessage.messageType} msgText={appMessage.messageText} />}
                            <Switch>
                                <Route path="/s/job-dashboard" component={NewSeekerJobDashboard} />
                                <Route path="/s/profile/:screenName" component={SeekerProfile} />
                                <Route path="/s/my-resume" component={ResumeMaker} />
                                <Route path="/s/settings" component={Settings} />

                                <Route path="/e/dashboard" component={Dashboard} />
                                <Route path="/e/:companyId/:officeId/discover-candidates" component={Discover} />
                                <Route path="/e/settings" component={Settings} />
                                <Route path="/e/view-resume/:id" component={ViewResume} />

                                <Route path="/e/:companyId/:officeId/my-calendar" component={MyCalendar} />
                                <Route path="/e/:companyId/:officeId/manage-company" component={ManageCompany} />
                                <Route path="/e/:companyId/:officeId/dashboard" component={Dashboard} />
                                <Route path="/e/:companyId/:officeId/on-going/:applnId" component={ViewJobApplications} />
                                <Route path="/e/:companyId/:officeId/archived/:applnId" component={ViewJobApplications} />
                                <Route path="/e/:companyId/:officeId/on-going" component={JobApplications} />
                                <Route path="/e/:companyId/:officeId/archived" component={ArchieveJobApplications} />
                                <Route path="/e/:companyId/:officeId/jobs" component={Jobs} />
                                <Route path="/e/:companyId/:officeId/user-roles" component={UserRole} />
                                <Route path="/e/:companyId/:officeId/hiring-workflow/:workflowId" component={PreviewWorkflow} />
                                <Route path="/e/:companyId/:officeId/hiring-workflow" component={ManageWorkflow} />
                                <Route path="/e/:companyId/:officeId/" component={Dashboard} />
                                <Route path="/about-us" component={AboutUs} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/terms-of-service" component={TermsOfService} />
                                <Route path="/:not-found" component={NotFound} />
                            </Switch>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}


function mapStateToProps(state) {
    const currentUser = (state && state.currentUser && state.currentUser.data) ? state.currentUser.data : (state && state.currentUser) ? state.currentUser : '';
    const hideSideBarMenu = state && state.hideSideBarMenu;
    const currentOfficeList = state.currentOfficeList;
    const currentCompany = state.currentCompany;
    return {
        currentUser,
        appMessage: state.appMessage,
        hideSideBarMenu: state.hideSideBarMenu,
        currentOfficeList,
        currentCompany,
    };
}

export default withRouter(connect(mapStateToProps, {
    postUpdateCompanyOffice,
    resetState,
    getCurrentUser,
    getListInvite,
    getUnreadNotifications,
    getCurrentOfficeList,
    getCurrentCompany,
    setData,
})(Application));
