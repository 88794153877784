import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import {
    getCurrentUser,
    getApplicationStatus,
} from '../../js/actions';


class CalendarEventsModal extends Component {
    static propTypes = {
        CalendarData: PropTypes.object,
        currentUser: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
        };
        this.handleRedirectUrl = this.handleRedirectUrl.bind(this);
    }

    handleRedirectUrl() {
        const { CalendarData, currentUser: { user }, getApplicationStatus } = this.props;
        const { current_company_id, current_office_id } = user;
        getApplicationStatus(CalendarData.eventData.application_id).then((resp) => {
            const url = {
                pathname: `/e/${current_company_id}/${current_office_id}/${resp.data.data.appln.profile_status == 'Archived' ? 'archived' : 'on-going'}/${CalendarData.eventData.application_id}`,
                state: {
                    companyId: current_company_id,
                    officeId: current_office_id,
                }
            }
            this.props.history.push(url);
        });
    }

    render() {
        const { CalendarData } = this.props;
        return (
            <div>
                <div>
                    {(CalendarData && CalendarData.eventData)
                        ? (
                            <div className="w3-margin-top" style={{ marginBottom: 30 }}>
                                <ul style={{ paddingLeft: '0px' }}>
                                    <div>
                                        <div className="w3-row h7t-margin-bottom-5">
                                            <div className="w3-col m1 h7t-margin-right">
                                                <StopRoundedIcon />
                                            </div>
                                            <div className="w3-col m8 w3-text-grey">
                                            {`${CalendarData.eventData.state_name ? CalendarData.eventData.state_name : ''}`}
                                            </div>
                                            <div className="w3-col align-items-padding h7t-font-size-16"><strong>{`${moment(CalendarData.eventData.action_date).format('MMM Do, YYYY')}`} | {` ${moment(CalendarData.eventData.action_time).format('LT')}`}</strong></div>
                                        </div>

                                        <div className="w3-row h7t-margin-bottom-5">
                                            <div className="w3-col m1 h7t-margin-right">
                                                <LocationOnRoundedIcon />
                                            </div>
                                            <div className="w3-col m8 w3-text-grey">
                                                Location
                                            </div>
                                            <div className="w3-col align-items-padding h7t-font-size-16"><strong>{`${CalendarData.eventData.where ? CalendarData.eventData.where : ''}`}{`${CalendarData.eventData.meeting_room ? ', '+CalendarData.eventData.meeting_room : ''}`}</strong></div>
                                        </div>

                                        <div className="w3-row h7t-margin-bottom-5">
                                            <div className="w3-col m1 h7t-margin-right">
                                                <PersonRoundedIcon />
                                            </div>
                                            <div className="w3-col m8 w3-text-grey">
                                                Candidate Name
                                            </div>
                                            <div className="w3-col align-items-padding h7t-name-hover h7t-font-size-16" onClick={this.handleRedirectUrl}><strong>{`${CalendarData.eventData.applicant_display_name || CalendarData.eventData.profile_display_name}`}</strong></div>
                                        </div>

                                        <div className="w3-row h7t-margin-bottom-5">
                                            <div className="w3-col m1 h7t-margin-right">
                                                <PeopleRoundedIcon />
                                            </div>
                                            <div className="w3-col m8 w3-text-grey">
                                                Invited By
                                            </div>
                                            <div className="w3-col align-items-padding h7t-font-size-16"><strong>{`${CalendarData.eventData.staff_name ? CalendarData.eventData.staff_name : ''}`}</strong></div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        ) : null}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
        applicationStatus: state.applicationStatus,
    };
}
export default withRouter(connect(mapStateToProps, {
    getCurrentUser,
    getApplicationStatus,
})(CalendarEventsModal));
