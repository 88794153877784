import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, Grid, Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import {
    getAllJobApplications,
    setAppMessage,
    getScheduleWorkflowCalendarList,
    getCurrentUser,
    postProfileDelete,
    getUserResumeViews,
    getUserDetails,
    getApplicationTitleList,
    getApplicationStatusList,
    getActiveJobs,
} from '../../js/actions';
import Modal from '../Common/Modal';
import PreviewResumeModal from '../Common/PreviewResumeModal';
import ProfileAddEditModal from '../Reachout/ProfileAddEditModal';
import ProfileDeleteModal from '../Common/DeleteRecordModal';
import ApplicantFilters from './ApplicantFilters';
import { LoadingIcon } from '../Common/HelperComponents';
import SearchInputBase from '../Common/MaterialUI/SearchInputBase';

const JobApplicationRow = ({
    item,
    handleViewApplication,
    handleViewResume,
    handleEdit,
    handleDelete,
    permission,
}) => {
    return (
        <TableRow onClick={() => handleViewApplication(item)} style={{ cursor: 'pointer' }}>
            <TableCell>{moment(item.applied_at).format('MMM Do, YYYY')}</TableCell>
            <TableCell>{item.display_name || item.profile_display_name}</TableCell>
            <TableCell>{item.job_title}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                {item.relevant_experience || item.relevant_experience_month
                    ? `${item.relevant_experience !== 0 //Reachout Candidates
                            ? `${item.relevant_experience}y` : ''} 
                        ${item.relevant_experience_month !== 0
                            ? `${item.relevant_experience_month}m` : ''}` 
                    : item.experience_years || item.experience_months
                        ? `${item.experience_years !== 0
                                ? `${item.experience_years}y` : ''} 
                            ${item.experience_months !== 0
                                ? `${item.experience_months}m` : ''}`
                        : <span className="w3-text-grey"> - </span>
                }
            </TableCell>
            <TableCell>{item.notice_period || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>
                {item.status == 'Rejected' ? `${item.status} (${item.current_status})` : 'New'  || <span className="w3-text-grey"> - </span>}
            </TableCell>
            <TableCell>{item.current_ctc || item.profile_current_ctc || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.expected_ctc || item.expected_ctc_min || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.owner || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                {item.profile_resume_file ? (
                    <Tooltip title="Download">
                        <a href={item.profile_resume_file} target="_new" onClick={e => e.stopPropagation()} download>  
                            <button
                                type="button"
                                className="w3-button icon-border w3-hover-blue w3-padding-small"
                                onClick={e => e.stopPropagation()}
                            >
                                <span className="ion-android-download" />
                            </button>
                        </a>
                    </Tooltip>
                ) : (
                    <Tooltip title="View Resume">
                        <button
                            type="button"
                            className="w3-button icon-border w3-hover-blue w3-padding-small"
                            onClick={e => handleViewResume(e, item.resume_view_id)}
                        >
                            <span className="ion-eye" />
                        </button>
                    </Tooltip>
                )}
                {item.applicant_profile_id && permission !== 'Panel Member' ? (
                    <React.Fragment>
                        <Tooltip title="Edit">
                            <button
                                type="button"
                                className="w3-button icon-border w3-hover-teal w3-padding-small"
                                onClick={e => handleEdit(e, item)}
                            >
                                <span className="ion-edit" />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <button
                                type="button"
                                className="w3-button icon-border w3-hover-red w3-padding-small"
                                onClick={e => handleDelete(item, e)}
                            >
                                <span className="ion-trash-a" />
                            </button>
                        </Tooltip>
                    </React.Fragment>
                ) : ''}
            </TableCell>
        </TableRow>
    );
};

class ArchiveJobApplications extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        activeJobs: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            filterBySearch: '',
            previewResume: '',
            deleteId: '',
            profileEditData: '',
            CalendarData: [],
            filterOn: {
                job_id: '',
                notice_period: '',
                step: '',
                rating: '',
                owner: '',
                search: '',
                status: 'all',
            },
        };
        this.handleViewResume = this.handleViewResume.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleJobFilters = this.handleJobFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    componentDidMount() {
        const {
            getAllJobApplications, getScheduleWorkflowCalendarList, getApplicationTitleList, getApplicationStatusList, getActiveJobs, activeJobs
        } = this.props;
        getAllJobApplications({ page: 1, profileStatus: 'archived' });
        getApplicationTitleList();
        getApplicationStatusList();
        getScheduleWorkflowCalendarList().then((resp) => {
            const calendarDetails = resp.data.data;
            this.setState({
                CalendarData: calendarDetails,
            });
        });
        if(!activeJobs) {
            getActiveJobs();
        }
    }

    handleEdit(e, data) {
        e.stopPropagation();
        const dataVal = {
            display_name: '',
            relevant_experience: '',
            relevant_experience_month: '',
            current_ctc: '',
            expected_ctc_min: '',
            resume_file_path: '',
            resume_text: '',
            profile_info: '',
            email: '',
            channel: 'Other',
            other_text_channel: 'HiringnXt',
            job_in_consideration: '',
            phone_number: '',
            visibility: '',
            profile_status: 'Archived',
        };
        
        if (data !== 'Archived') {
            dataVal.display_name = data.profile_display_name;
            dataVal.relevant_experience = data.relevant_experience;
            dataVal.relevant_experience_month = data.relevant_experience_month;
            dataVal.current_ctc = data.profile_current_ctc;
            dataVal.expected_ctc_min = data.expected_ctc_min;
            dataVal.resume_file_path = data.profile_resume_file;
            dataVal.resume_text = data.resume_text;
            dataVal.profile_info = data.profile_info;
            dataVal.email = data.email;
            dataVal.job_in_consideration = data.job_in_consideration;
            dataVal.phone_number = data.phone_number;
            dataVal.visibility = data.visibility;
            dataVal.channel = data.channel;
            dataVal.reffered_by = data.reffered_by;
            dataVal.profile_id = data.profile_id;
        }
        this.setState({ profileEditData: dataVal });
    }

    handleDelete(data, e) {
        const {
            paginationDetails, postProfileDelete, setAppMessage, getApplicationStatusList,
        } = this.props;
        let currentPage = paginationDetails.current_page;
        if (data === true) {
            const { deleteId } = this.state;
            postProfileDelete(deleteId).then((response) => {
                if (response.data.success) {
                    if (paginationDetails
                        && paginationDetails.data
                        && paginationDetails.data.length === 1
                        && paginationDetails.current_page > 1) {
                        currentPage = paginationDetails.current_page - 1;
                    }
                    this.handlePagination('', currentPage - 1);
                    getApplicationStatusList();
                    setAppMessage('success', 'Profile deleted successfully!');
                    $('.close-button').click();
                }
            });
        } else {
            e.stopPropagation();
            this.setState({ deleteId: data });
        }
    }

    searchFilter(event) {
        const { value } = event.target;
        if (!value) {
            this.handlePagination();
        }
        this.setState({
            filterBySearch: value,
        });
    }

    clearFilters(filterOn) {
        this.setState({
            filterOn,
            filterBySearch: '',
        }, () => {
            this.handlePagination();
        });
    }

    handleJobFilters(filterOn) {
        this.setState({ filterOn }, () => {
            this.handlePagination();
        });
    }

    handlePagination(e, page = 0) {
        const {
            filterBySearch, filterOn,
        } = this.state;
        const data = {};
        data.page = page + 1;
        data.jobId = filterOn.job_id;
        data.notice_period = filterOn.notice_period;
        data.step = filterOn.step;
        data.ratingRange = filterOn.ratingRange;
        data.owner = filterOn.owner;
        data.status = filterOn.status;
        data.profileStatus = 'archived';
        if (filterBySearch) {
            data.search = filterBySearch;
        }
        this.props.getAllJobApplications(data);
    }

    handleViewApplication(item) {
        const { company_id, office_id, id } = item;
        const url = `/e/${company_id}/${office_id}/archived/${id}`;
        this.props.history.push({
            pathname: url,
            state: {
                companyId: company_id,
                officeId: office_id,
            }
        });
    }

    handleViewResume(e, resumeViewId) {
        e.stopPropagation();
        const { currentUser, currentUser: { user } } = this.props;
        if (resumeViewId) {
            this.props.getUserResumeViews(resumeViewId).then((res) => {
                const previewResume = res.data && res.data.data;
                let userData = currentUser;
                if (userData.id != previewResume.user_id) {
                    this.props.getUserDetails({ user_id: previewResume.user_id }).then((userResp) => {
                        userData = userResp.data && userResp.data.data;
                        this.setState({
                            userData,
                        });
                    });
                }
                this.setState({ userData, previewResume });
            });
        }
    }

    render() {
        const {
            allJobApplications, paginationDetails, currentUser, applicationStatusList, activeJobs
        } = this.props;
        const {
            filterBySearch, filterOn, previewResume, profileEditData, deleteId,
        } = this.state;
        const tableRow = [];
        let noData = null;
        let disableAddProfile = false;

        if (!allJobApplications) {
            return <LoadingIcon />;
        }

        let permission = null;
        if (currentUser && currentUser.companies) {
            const comapny = currentUser.companies.find((com) => {
                return COMPANY_ID == com.company_id;
            });

            if (comapny && comapny.office_list) {
                const office = comapny.office_list.find((off) => {
                    return off.id == OFFICE_ID;
                });
                if (office && office.permissions) {
                    if (office.permissions.indexOf('Management') !== -1) {
                        permission = 'Management';
                    } else if (office.permissions.indexOf('HR') !== -1) {
                        permission = 'HR';
                    } else if (office.permissions.indexOf('Panel Member') !== -1) {
                        permission = 'Panel Member';
                    }
                }
            }
        }
        const self = this;
        if (allJobApplications && allJobApplications.data && allJobApplications.data.length) {
            allJobApplications.data.map((item, idx) => {
                tableRow.push(<JobApplicationRow
                    key={`id_${idx}`}
                    item={item}
                    handleEdit={self.handleEdit.bind(self)}
                    handleDelete={self.handleDelete.bind(self)}
                    handleViewApplication={self.handleViewApplication.bind(self)}
                    handleViewResume={this.handleViewResume}
                    permission={permission}
                />)
            });
        } else {
            noData = (
                <div className="h7t-table-data">
                    <div className="w3-center w3-margin-top">
                        <span className="ion-ios-paper-outline" style={{ fontSize: '120px' }} />
                        <p className="w3-margin-top"><strong>No job applications under this office to display!</strong></p>
                    </div>
                    <div className="h7t-flex-container w3-margin-top">
                        <ul className="h7t-feature-description">
                            <li>View job applications and their current status.</li>
                            <li>Add profiles to a job post to reach out to candidates.</li>
                            <li>Click on the eye icon to view feedbacks of each application.</li>
                            <li>Assign to yourself to perform any action on the application.</li>
                            <li>Add feedback and choose next level of the application process from the list.</li>
                        </ul>
                    </div>
                </div>
            );
        }

        if(activeJobs && activeJobs.data && activeJobs.data.length <=0) {
            disableAddProfile = true;
        }
        return (
            <div className="pt20 content-margin job-apps">
                <div className="w3-row">
                    <h3>Archived Applicants</h3>
                </div>
                <div className="w3-row">
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item xs={12} sm={3} md={3} onClick={e => this.handleEdit(e, 'Archived')} className={`${disableAddProfile ? 'w3-disabled' : ''}`}>
                            <Paper className={`${permission == 'Panel Member' ? 'w3-disabled' : ''} h7t-Paper overview-graph card-height h7t-table-data dashed-border h7t-cursor-pointer w3-margin-bottom`} style={{ background: 'white' }}>
                                <div className="w3-row">
                                    <AddIcon style={{ marginBottom: '0.2em' }} fontSize="large"/>
                                </div>
                                <p className="title">Add to Archive</p>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                <div className="w3-row">
                                    <p><i className="material-icons">new_releases</i></p>
                                </div>
                                <p className="title">New Pool</p>
                                <p className="total-count">{applicationStatusList && applicationStatusList.new_pool}</p>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                <p><i className="material-icons">assignment_turned_in</i></p>
                                <p className="title">Existing Pool</p>
                                <p className="total-count">{applicationStatusList && applicationStatusList.existing_pool}</p>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                    
                <div className="w3-row w3-margin-top">
                    <div className="w3-col m10 s10 w3-margin-bottom">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.handlePagination();
                        }}
                        >
                            <SearchInputBase
                                placeholder="Search for job title or applicant name"
                                customClass="w3-margin-top"
                                onChange={e => this.searchFilter(e)}
                                value={filterBySearch}
                            />
                        </form>
                    </div>
                    <div className="w3-col m2 s2 w3-right">
                        <ApplicantFilters
                            filterStatus='archive'
                            filterOn={filterOn}
                            clearFilterValues={this.clearFilters}
                            handleJobFilters={this.handleJobFilters}
                        />
                    </div>
                </div>
                {noData || (
                    <Paper>
                        <TableContainer>
                            <Table className="table-striped">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Job Role</TableCell>
                                        <TableCell>YOE</TableCell>
                                        <TableCell>Notice Period</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Current CTC (LPA)</TableCell>
                                        <TableCell>Expected CTC (LPA)</TableCell>
                                        <TableCell>Owner</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRow}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={paginationDetails.total}
                            rowsPerPage={paginationDetails.per_page}
                            page={paginationDetails.current_page - 1}
                            onChangePage={this.handlePagination}
                        />
                    </Paper>
                )}

                {previewResume
                    ? (
                        <Modal modalCls="w3-show" onClose={() => this.setState({ previewResume: false })}>
                            <div className="w3-container h-margin-top-35 h7t-margin-bottom-40" style={{ padding: '0px 40px' }}>
                                <PreviewResumeModal
                                    employer
                                    previewData={previewResume}
                                />
                            </div>
                        </Modal>
                    ) : null}

                {profileEditData && !disableAddProfile && permission !== 'Panel Member'
                    ? (
                        <ProfileAddEditModal
                            profileStatus="archived"
                            profileEditData={profileEditData}
                            currentPage={paginationDetails.current_page}
                            onModalClose={() => this.setState({ profileEditData: '' })}
                        />
                    ) : null}

                {deleteId
                    ? (
                        <ProfileDeleteModal
                            handleDelete={this.handleDelete}
                            deleteMessage="profile"
                            onModalClose={() => this.setState({ deleteId: '' })}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    const allJobApplications = state.allJobApplications && state.allJobApplications.data;
    const applicationStatusList = state.applicationStatusList && state.applicationStatusList.data;
    const activeJobs = state && state.activeJobs;
    return {
        allJobApplications,
        paginationDetails: allJobApplications,
        currentUser,
        applicationStatusList,
        activeJobs,
    };
}
export default withRouter(connect(mapStateToProps, {
    getAllJobApplications,
    setAppMessage,
    getScheduleWorkflowCalendarList,
    getCurrentUser,
    postProfileDelete,
    getUserResumeViews,
    getUserDetails,
    getApplicationTitleList,
    getApplicationStatusList,
    getActiveJobs,
})(ArchiveJobApplications));