import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper
} from '@material-ui/core';

export default class TableComponent extends Component {
    static propTypes = {
        data: PropTypes.array,
        heading: PropTypes.string,
    };

    render() {
        const { data, heading ,keys} = this.props;
        return (
            <Fragment>
                <h6 className="h7t-modal-title h7t-margin-0">{heading}</h6>
                <Paper style={{ height: '320px', overflow: 'auto' }} className="w3-padding h7t-table-data">
                    <TableContainer>
                        <Table className="table-striped">
                            <TableHead>
                                <TableRow>
                                    {keys.map(key => {
                                        return(<TableCell align="center">
                                            {key.label}
                                                </TableCell>)
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.map((item, idx) => (<TableRow key={`item_${idx}`}>
                            {keys.map(key => {
                                        return(<TableCell align="center">
                                            {item[key.value]}
                                                </TableCell>)
                                    })}
                            </TableRow>))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Fragment>
        )
    }
}