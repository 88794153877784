import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getResumeById } from '../../js/actions';

class Discover extends Component {
    constructor(args) {
        super(args);
        this.state = {

        };
    }

    componentDidMount() {
        const { params: { id } } = this.props;
        this.props.getResumeById(id);
    }

    render() {
        const { resumeById } = this.props;
        let previewResume = [];
        const workExperience = [];
        const education = [];
        let skillsList = null;

        if (resumeById && resumeById.data) {
            const item = resumeById.data._source;
            item.experience.map((work, idx) => {
                workExperience.push(
                    <div key={idx} className="w3-margin-bottom">
                        <strong>{work.title}</strong>
                        <div>{work.company}</div>
                        <div className="w3-text-grey">{work.dateRange.displayDateRange}</div>
                    </div>,
                );
            });
            item.education.map((edu, idx) => {
                education.push(
                    <div key={idx} className="w3-margin-bottom">
                        <strong>{edu.degree}</strong>
                        <div>{edu.school}</div>
                        <div className="w3-text-grey">{edu.dateRange.displayDateRange}</div>
                    </div>,
                );
            });
            skillsList = item.skills.map((elem) => {
                return elem.text;
            }).join(', ');
            previewResume = (
                <div className="w3-border w3-margin w3-padding">
                    <div className="w3-padding-16 w3-margin-bottom">
                        <h3>{item.name}</h3>
                        <div className="w3-large">{item.title}</div>
                        <div className="w3-text-grey">{skillsList}</div>
                    </div>
                    {(workExperience && workExperience.length > 0)
                        ? (
                            <div>
                                <div className="w3-padding-16 w3-margin-bottom w3-border-bottom w3-border-top"><strong>Work Experience</strong></div>
                                {workExperience}
                            </div>
                        ) : null}
                    {(education && education.length > 0)
                        ? (
                            <div>
                                <div className="w3-padding-16 w3-margin-bottom w3-border-bottom w3-border-top"><strong>Educational Details</strong></div>
                                {education}
                            </div>
                        ) : null}
                </div>
            );
        }

        return (
            <div className="w3-container w3-margin">
                {previewResume}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resumeById: state.resumeById,
    };
}

export default connect(mapStateToProps, {
    getResumeById,
})(Discover);
