import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { Grid, Button, Icon, Breadcrumbs, Typography, Drawer, Stepper, Step, StepLabel, Tooltip } from '@material-ui/core';
import {
    getUserResumeViews,
    getUserDetails,
    getJobApplnFeedback,
    getScheduleApplicationStatus,
    postChangeCandidateProfileStatus,
    getApplicationStatusById,
    getApplicationStatus,
    getWorkflowState,
    getScheduleWorkflowList,
    postUpdateUserRating,
    setAppMessage,
    getApplicantsProfileStatus,
} from '../../js/actions';
import PreviewResumeModal from '../Common/PreviewResumeModal';
import ApplicationFeedbacks from './ApplicationFeedbacks';
import NotifyCandidate from './NotifyCandidate';
import { InputSelectField } from '../Common/MaterialUI/HelperInputs';
import OfferModal from './OfferModal';
import ProfileAddEditModal from '../Reachout/ProfileAddEditModal';
import ConfirmationModal from '../Common/ConfirmationModal';
import Modal from '../Common/Modal';
import JobAddEditModal from '../Jobs/JobAddEditModal';

class ViewJobApplications extends Component {
    static propTypes = {
        currentUser: PropTypes.shape({
            user: PropTypes.object,
            profile: PropTypes.object,
        }),
        getUserResumeViews : PropTypes.func,
        setAppMessage: PropTypes.func,
        getUserDetails: PropTypes.func,
        getJobApplnFeedback: PropTypes.func,
        getScheduleApplicationStatus: PropTypes.func,
        postChangeCandidateProfileStatus: PropTypes.func,
        getApplicantsProfileStatus: PropTypes.func,
        applicationFeedback: PropTypes.object,
        applicantsProfileStatus: PropTypes.object,
        getApplicationStatusById: PropTypes.func,
        getApplicationStatus: PropTypes.func,
        getWorkflowState: PropTypes.func,
        params: PropTypes.object,
        workflowState: PropTypes.object,
        scheduleWorkflowList: PropTypes.object,
        getScheduleWorkflowList: PropTypes.func,
        applicationStatus: PropTypes.object,
        postUpdateUserRating: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.userRating = 0;
        this.state = {
            previewResume: '',
            showFeedback: false,
            application: '',
            userInfo: '',
            right: false,
            workflowStatesList: '',
            userData: '',
            showNotifyCandidate: false,
            stepperDatas: {},
            jobStatus: '',
            showModal: false,
            addBacktoJob: false,
            confirmModal: false,
            positionsModal: false,
            jobCreateData: '',
        };
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.sideList = this.sideList.bind(this);
        this.feedbackResult = this.feedbackResult.bind(this);
        this.calculateTotalRating = this.calculateTotalRating.bind(this);
        this.showNotifyCandidateModal = this.showNotifyCandidateModal.bind(this);
        this.setRejectedStepIcon = this.setRejectedStepIcon.bind(this);
        this.setActiveStepper = this.setActiveStepper.bind(this);
        this.handleSelectStatus = this.handleSelectStatus.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.handleArchiveProfile = this.handleArchiveProfile.bind(this);
        this.handleAddBacktoJob = this.handleAddBacktoJob.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCopyJob = this.handleCopyJob.bind(this);
        this.positionsModalClose = this.positionsModalClose.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
    }

    componentDidMount() {
        const { match: { params: { applnId } } } = this.props;
        this.loadApplicationDetails(applnId);
    }

    componentWillReceiveProps(nextProps) {
        const { postUpdateUserRating } = this.props;
        const { match: { params } } = nextProps;
        if (params && this.props.match.params && params.applnId !== this.props.match.params.applnId) {
            this.loadApplicationDetails(params.applnId);
        }
        if (nextProps.applicationFeedback && nextProps.applicationFeedback.data
            && this.props.applicationFeedback && this.props.applicationFeedback.data
            && nextProps.applicationFeedback.data !== this.props.applicationFeedback.data) {
            this.calculateTotalRating(nextProps.applicationFeedback.data);
            this.setActiveStepper(nextProps.applicationFeedback.data, nextProps.applicationStatus.data);
            const data = {
                application_id: params.applnId,
                userRating: this.userRating,
            }
            postUpdateUserRating(data);
        }
    }

    setRejectedStepIcon() {
        return (
            <div style={{ background: '#e85342', borderRadius: '50%', color: '#fff', height: '21px' }}>
                <Icon style={{ fontSize: '1.4rem' }}>clear</Icon>
            </div>
        );
    }

    setActiveStepper(applicationFeedback, currentLifeCycleData) {
        const {
            workflowState,
        } = this.props;
        const { stepperDatas } = this.state;
        let feedbackResult = '';
        let count = 0;
        let candidateHired = false;
        let candidateRejected = false;

        workflowState && workflowState.map((list_item) => {
            applicationFeedback.some((applnFeedback) => {
                if (list_item.id == applnFeedback.workflow_start_state) {
                    feedbackResult = applnFeedback;
                    return true;
                }
            });
            if (list_item.id == feedbackResult.workflow_start_state) {
                count++;
            }

            const currentLifeCycle = currentLifeCycleData.appln && currentLifeCycleData.appln.current_lifecycle;
            if (list_item.id == currentLifeCycle.workflow_start_state && currentLifeCycle.workflow_end_state) {
                workflowState.some((stateList) => {
                    if (currentLifeCycle.workflow_end_state == stateList.id && stateList.display_order == 0) {
                        candidateHired = true;
                    }
                    if (currentLifeCycle.workflow_end_state == stateList.id && stateList.display_order == -1) {
                        candidateRejected = true;
                    }
                });
            }
        });
        stepperDatas.candidateHired = candidateHired;
        stepperDatas.candidateRejected = candidateRejected;
        stepperDatas.count = count;
        this.setState({ stepperDatas });
    }

    toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            right: open,
        });
    };

    sideList = () => (
        <div
            style={{ width: '600px' }}
            role="presentation"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
        >
            {this.state.previewResume ? (
                <div className="w3-container h-margin-top-35" style={{ padding: '0px 40px' }}>
                    <PreviewResumeModal
                        employer
                        previewData={this.state.previewResume}
                    />
                </div>
            ) : null}
        </div>
    );

    loadApplicationDetails(applnId) {
        const {
            currentUser, currentUser: { user },
            getApplicationStatusById, 
            getUserResumeViews, 
            getWorkflowState, 
            getJobApplnFeedback, 
            getUserDetails,
            getScheduleApplicationStatus,
            getApplicationStatus,
            getApplicantsProfileStatus,
        } = this.props;

        getApplicationStatusById(applnId).then((resp) => {
            const userInfo = resp && resp.data && resp.data.data;
            this.setState({
                userInfo,
            });
            if(userInfo.applicant_profile_id) {
                getApplicantsProfileStatus(userInfo.applicant_profile_id);
            } else{
                getApplicantsProfileStatus(userInfo.applicant_user_id);
            }
            if (userInfo.applicant_user_id) {
                getUserResumeViews(userInfo.resume_view_id).then((res) => {
                    const previewResume = res.data && res.data.data;
                    let userData = currentUser;
                    if (user.id != previewResume.user_id) {
                        getUserDetails({ user_id: previewResume.user_id }).then((userResp) => {
                            userData = userResp.data && userResp.data.data;
                            this.setState({ userData });
                        });
                    }
                    this.setState({ previewResume });
                });
            }

            getWorkflowState(userInfo.job_workflow_id).then((workflowStates) => {
                const workflowResp = workflowStates && workflowStates.data && workflowStates.data.data;
                const workflowStatesList = workflowResp.filter((states) => {
                    return states.display_order > 0;
                });
                this.setState({
                    workflowStatesList,
                });
            });
            getJobApplnFeedback(applnId);
        });
        getScheduleApplicationStatus(applnId);
        getApplicationStatus(applnId).then((status) => {
            const application = status.data;
            this.setState({
                application,
                showFeedback: true,
            });
            
            this.updateStatus();
        });
        getScheduleWorkflowList(applnId);
    }

    calculateTotalRating(applicationFeedback) {
        const { workflowState } = this.props;
        let totalWeightage;
        let weightage = 0;
        let rating = 0;
        let totalRating = 0;
        let ratingPercentage = 0;
        
        workflowState && workflowState.map((list_item) => {
            applicationFeedback && applicationFeedback.map((feedback) => {
                if ((list_item.id == feedback.workflow_start_state)) {
                    weightage += parseInt(list_item.weight, 10);
                    rating = parseInt(list_item.weight, 10) * parseInt(feedback.rating, 10);
                    totalRating += rating;
                }
            });
        });
        
        totalWeightage = weightage * 5;
        ratingPercentage = Math.floor((totalRating / totalWeightage) * 100);
        this.userRating = (ratingPercentage * 5) / 100;
    }

    feedbackResult(data) {
        this.setState({
            application: data,
        });
    }

    showNotifyCandidateModal() {
        this.setState({
            showNotifyCandidate: true,
        });
    }

    handleSelectStatus(event) {
        const { name, value } = event.target;
        const { jobStatus } = this.state;
        const { applicationById } = this.props;

        if(value && jobStatus != '' && value!=jobStatus) {
            this.setState({ confirmModal: true, jobStatus: value })
        } else if(value=='job_accepted' && applicationById.positions_open <= applicationById.positions_filled) {
            this.setState({ positionsModal: true });
        } else {
            this.setState({ jobStatus: value, showModal: true });
        }
    }

    handleCreate() {
        this.setState({positionsModal: false },() => {
            this.handleCopyJob();
        });
    }

    handleCopyJob() {
        const { userInfo } = this.state;
        const selectedJob = {};
        selectedJob.id = userInfo.job_id;
        selectedJob.job_title = userInfo.job_title;
        selectedJob.description = userInfo.description;
        selectedJob.skills_required =userInfo.skills_required;
        selectedJob.universities = userInfo.universities;
        selectedJob.certifications = userInfo.certifications;
        selectedJob.office_id = userInfo.office_id;
        selectedJob.company_id = userInfo.company_id;
        selectedJob.min_experience_required = userInfo.min_experience_required;
        selectedJob.max_experience_required = userInfo.max_experience_required;
        selectedJob.positions_open = userInfo.positions_open;
        selectedJob.level_of_hiring = userInfo.level_of_hiring;
        selectedJob.salary_max = userInfo.salary_max;
        selectedJob.salary_min = userInfo.salary_min;
        selectedJob.workflow_id = userInfo.workflow_id;
        selectedJob.remote_Jobs = userInfo.remote_Jobs;
        selectedJob.companyName = userInfo.companyName;
        selectedJob.companyWebsite = userInfo.companyWebsite;
        selectedJob.companyType = userInfo.companyType;
        selectedJob.companyCategory = userInfo.companyCategory;
        selectedJob.companySize = userInfo.companySize;
        selectedJob.companyAbout = userInfo.companyAbout;
        this.setState({ jobCreateData: selectedJob });
    }
   
    updateStatus() {
        const { applicationStatus } = this.props;
        const { jobStatus } = this.state;
        let appStatus = '';
        const offerStatus = applicationStatus && applicationStatus.data && applicationStatus.data.appln && applicationStatus.data.appln.status;
        if (offerStatus === 'Job-Accepted') {
            appStatus = 'job_accepted'
        } else if (offerStatus === 'Job-Declined') {
            appStatus = 'job_declined'
        }
         this.setState({ jobStatus: appStatus });
    }

    handleArchiveProfile(applnId, status) {
        const { postChangeCandidateProfileStatus, setAppMessage, getApplicantsProfileStatus } = this.props;
        const { userInfo } = this.state;
        const data = {
            applicant_user_id: userInfo.applicant_user_id,
            application_id: applnId,
            profile_status: status,
        }
        postChangeCandidateProfileStatus(data).then((response) => {
            if (response.data.success) {
                setAppMessage('success', 'Profile archived successfully!');
                getApplicantsProfileStatus(userInfo.applicant_user_id);
            }
        });
    }

    handleAddBacktoJob() {
        const { userData:{ user }, userData:{ profile }, userInfo, stepperDatas } = this.state;
        const { match: { params: { applnId } } } = this.props;
        if(user) {
            userInfo.relevant_experience = profile && profile.relevant_experience ? profile.relevant_experience : '';
            userInfo.relevant_experience_month = profile && profile.relevant_experience_month ? profile.relevant_experience_month : '';
            userInfo.current_ctc  = profile && profile.current_ctc ? profile.current_ctc : '';
            userInfo.expected_ctc_min = profile && profile.expected_ctc ? profile.expected_ctc : '';
            userInfo.profile_info = profile && profile.short_summary ? profile.short_summary.replace( /(<([^>]+)>)/ig, '') : '';
            userInfo.resume_file_path = this.state.previewResume.resume_file_path;
        }
        userInfo.profile_status = 'On-going';
        userInfo.other_text_channel = 'HiringnXt';
        userInfo.job_in_consideration = '';
        userInfo.visibility = '';
        userInfo.applicationId = applnId;
        userInfo.currentApplnStatus = stepperDatas && stepperDatas.candidateRejected;
        console.log("User Info : ",userInfo)
        this.setState({
            addBacktoJob: true,
            userInfo,
        });
    }

    handleCancel() {
        this.setState({ confirmModal: false }, () => {
            this.updateStatus();
        });
    }

    positionsModalClose() {
        this.setState({ positionsModal: false}, () => {
            this.updateStatus();
        });
    }

    render() {
        const {
            showFeedback, application, userInfo, right, workflowStatesList, userData, showNotifyCandidate, stepperDatas, jobStatus, showModal, addBacktoJob, confirmModal, positionsModal, jobCreateData,
        } = this.state;
        const {
            applicationFeedback,
            applicationStatus,
            scheduleApplicationStatus,
            currentUser,
            applicationById,
            currentUser: { user, companies },
            match: { params: { applnId, companyId, officeId } },
            scheduleWorkflowList,
            applicantsProfileStatus,
        } = this.props;
        let permissions = null;
        let disableNotifyCandidate = true;
        if (currentUser && companies) {
            const company = companies.find((com) => {
                return COMPANY_ID == com.company_id;
            });

            if (company && company.office_list) {
                const office = company.office_list.find((off) => {
                    return off.id == OFFICE_ID;
                });
                if (office && office.permissions) {
                    if (office.permissions.indexOf('Management') !== -1) {
                        permissions = 'Management';
                    } else if (office.permissions.indexOf('HR') !== -1) {
                        permissions = 'HR';
                    } else if (office.permissions.indexOf('Panel Member') !== -1) {
                        permissions = 'Panel Member';
                    }
                }
            }
        }
        if (scheduleWorkflowList && scheduleWorkflowList.length > 0) {
            scheduleWorkflowList && scheduleWorkflowList.some((schedule_list) => {
                if (schedule_list.display_order > 1) {
                    disableNotifyCandidate = false;
                    return true;
                }
            });
        }
        const jobStatusSelect = [
            { val: 'job_accepted', name: 'Job Accepted' },
            { val: 'job_declined', name: 'Job Declined' },
        ];
        let view = '';
        const modalContent = (<div>
            <p>Are you sure you want to change the Job Offer Status</p>
        </div>)

        return (
            <div>
                <div>
                    <Drawer
                        anchor="right"
                        open={right}
                        onClose={this.toggleDrawer(false)}
                    >
                        {this.sideList('right')}
                    </Drawer>
                </div>
                {showFeedback ? (
                    <div className="content-margin h-feedback-modal-container">
                        <div className="h7t-modal-title w3-margin-top w3-margin-bottom">
                            Applicant Feedback
                            {permissions !== 'Panel Member'  && 
                                applicationStatus && applicationStatus.data && applicationStatus.data.appln && applicationStatus.data.appln.status == 'Rejected' &&
                                applicationStatus.data.appln.profile_status == 'On-going'
                                    ? 
                                    applicantsProfileStatus && applicantsProfileStatus.data.length > 0
                                        ? <p className="w3-text-blue w3-medium w3-right">Archived</p> : 
                                        (
                                            <Button
                                                variant="contained"
                                                className="w3-blue w3-right"
                                                style={{ 'textTransform': 'none'}}
                                                onClick={()=> this.handleArchiveProfile(applnId, 'Archived')}
                                            >
                                                Archive Profile
                                            </Button>
                                        )
                                    :
                                    permissions !== 'Panel Member' && (applicationStatus.data.appln.profile_status == 'Archived') && (applicationStatus.data.appln.status == 'Rejected' || 'In Progress')
                                        ? (
                                            <Button
                                                variant="contained"
                                                className="w3-blue w3-right"
                                                style={{ 'textTransform': 'none'}}
                                                onClick={()=> this.handleAddBacktoJob()}
                                            >
                                                Add Back to Job
                                            </Button>
                                        ) : null
                            }
                        </div>
                        <div className="w3-margin-bottom">
                            <Breadcrumbs separator={<i className="material-icons">chevron_right</i>} aria-label="breadcrumb">
                                <span color="inherit">Applicants</span>
                                <Typography
                                    className="h7t-cursor-pointer w3-hover-text-indigo"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    {applicationStatus.data.appln.profile_status == 'Archived' ? 'Archived' : 'On-Going'}
                                </Typography>
                                <Typography color="primary">Applicant Feedback</Typography>
                            </Breadcrumbs>
                        </div>
                        <div className="w3-container w3-white w3-padding-16 w3-round w3-border">
                            <div>
                                <div style={{ fontSize: '18px', fontWeight: 'bold' }} className="w3-left">{userInfo.display_name || ''}</div>
                                <div className="w3-right">
                                    {userInfo.applicant_user_id 
                                        ? (
                                            <Button
                                                variant="contained"
                                                className="w3-right h7t-secondary-btn"
                                                onClick={this.toggleDrawer(true)}
                                            >
                                                View Resume
                                            </Button>
                                        ) : (
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    className="w3-right h7t-secondary-btn"
                                                >
                                                    <a href={userInfo.resume_file_path} target="_new" className="w3-text-white" download>Download Resume</a>
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            </div>
                            <Grid container>
                                <Grid item xs>
                                    <span className="h7t-light-grey">Job ID : </span>{userInfo.job_id}
                                    <br />
                                    <span className="h7t-light-grey">Applied on : </span>{userInfo.applied_at !== '0000-00-00 00:00:00' ? moment(userInfo.applied_at).format('MMM Do, YYYY') : '---'}
                                </Grid>
                                <Grid item xs>
                                    <span className="h7t-light-grey">Application for Job Role : </span>{userInfo.job_title}
                                    <br />
                                    {userInfo && userInfo.applicant_user_id
                                        ? userData && userData.profile && (userData.profile.relevant_experience !== 0 || userData.profile.relevant_experience_month !== 0)
                                            ? (<div>
                                                <span className="h7t-light-grey">Years of Experience : </span>
                                                {`${userData.profile.relevant_experience 
                                                    ? `${userData.profile.relevant_experience}y` : ''}
                                                    ${userData.profile.relevant_experience_month 
                                                    ? `${userData.profile.relevant_experience_month}m` : ''}`
                                                }
                                            </div>)
                                            : null
                                        : userInfo.relevant_experience !== 0 || userInfo.relevant_experience_month !== 0 
                                            ? (<div>
                                                <span className="h7t-light-grey">Years of Experience : </span>
                                                {`${userInfo.relevant_experience 
                                                    ? `${userInfo.relevant_experience}y` : ''}
                                                    ${userInfo.relevant_experience_month 
                                                    ? `${userInfo.relevant_experience_month}m` : ''}`
                                                }
                                            </div>)
                                            : null
                                    }
                                </Grid>
                            </Grid>
                            <div>
                                {workflowStatesList && workflowStatesList.length > 0 ? (
                                    <div>
                                        <Stepper
                                            activeStep={stepperDatas.count}
                                            alternativeLabel
                                        >
                                            {workflowStatesList.map(label => (
                                                <Step key={label}>
                                                    <StepLabel>{label.state_action_name}</StepLabel>
                                                </Step>
                                            ))}
                                            {stepperDatas && stepperDatas.candidateRejected ? (
                                                <Step>
                                                    <StepLabel
                                                        completed
                                                        StepIconComponent={this.setRejectedStepIcon}
                                                    >
                                                            Rejected
                                                    </StepLabel>
                                                </Step>
                                            ) : null}
                                            {stepperDatas && stepperDatas.candidateHired ? (
                                                <Step>
                                                    <StepLabel completed StepIconProps={{ classes: { root: 'hired-step' } }}>Hired</StepLabel>
                                                </Step>
                                            ) : null}
                                        </Stepper>
                                    </div>
                                ) : null}
                                <div className="w3-row">
                                    <div className="w3-col m6 s12">
                                        <p><strong>{this.userRating ? `Rating : ${this.userRating} / 5` : null}</strong></p>
                                    </div>
                                    <div className="w3-col m6 s12">
                                        {permissions == 'HR' && applicationById && applicationById.notified_at == null
                                            ? (
                                                <Button
                                                    variant="contained"
                                                    disabled={disableNotifyCandidate}
                                                    className={`${disableNotifyCandidate ? 'w3-disabled' : ''} w3-right h7t-primary-btn`}
                                                    onClick={this.showNotifyCandidateModal}
                                                >
                                                    Notify Candidate
                                                </Button>
                                            )
                                            : (permissions == 'HR' && applicationById && applicationById.notified_at)
                                                ? <span className="w3-right">Notified At - {moment(applicationById.notified_at).format('MMM Do YYYY')}</span>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {permissions == 'HR' && applicationStatus && applicationStatus.data && applicationStatus.data.appln && applicationStatus.data.appln.profile_status != 'Archived' ?
                        <div className="w3-row">
                            <div className="w3-col m3 s6">
                                <InputSelectField
                                    label="Job Offer"
                                    id="job_offer"
                                    name="job_offer"
                                    value={jobStatus}
                                    options={jobStatusSelect}
                                    onChange={e => this.handleSelectStatus(e)}
                                    fullWidth
                                    className={`${stepperDatas && stepperDatas.candidateHired ? '' : 'w3-disabled'}`}
                                    disabled={stepperDatas && stepperDatas.candidateHired ? false : true}
                                    wrapperClass="h7t-padding-left-0"
                                />
                            </div>
                            {jobStatus=='job_declined' && applicationStatus && applicationStatus.data && applicationStatus.data.appln && applicationStatus.data.appln.reason != null ? 
                                view = (
                                    <div className="w3-col m6 s12 h7t-margin-top-25">
                                        <a
                                            onClick={() => this.setState({
                                                showModal: true,
                                                jobStatus: 'job_declined'
                                            })}
                                        >View</a>
                                    </div>
                                ): (applicationStatus && applicationStatus.data && applicationStatus.data.appln && applicationStatus.data.appln.offer_letter_path != null && applicationStatus.data.appln.offer_letter_path.length>0) ? view = (
                                    <div className="w3-col m6 s12 h7t-margin-top-25">
                                       <a href={applicationStatus.data.appln.offer_letter_path} download>View</a>
                                    </div>
                                ) : ''
                            }
                        </div> : ''}
                        { applicationStatus && applicationStatus.data && applicationStatus.data.appln.profile_status == 'Archived' && applicationStatus.data.appln.status == 'In Progress' ?
                            null :
                            <ApplicationFeedbacks
                                application_id={application.data.appln.id}
                                applicant_id={userInfo.applicant_user_id}
                                applicantEmail={userInfo.email}
                                applicationFeedback={applicationFeedback}
                                applicationStatus={applicationStatus}
                                scheduleApplicationStatus={scheduleApplicationStatus}
                                getFeedbackStatus={this.feedbackResult}
                            /> }
                        {showNotifyCandidate
                            ? (
                                <NotifyCandidate
                                    onModalClose={() => this.setState({ showNotifyCandidate: false })}
                                    workflowStatesList={workflowStatesList}
                                    candidate_Name={userInfo.display_name}
                                    hr_Name={user.display_name}
                                    application_Id={applnId}
                                    applicant_Hired={stepperDatas.candidateHired}
                                    applicant_Rejected={stepperDatas.candidateRejected}
                                    applied_Post={userInfo.job_title}
                                    office_Name={userInfo.name}
                                />
                            ) : null
                        }
                        {addBacktoJob
                            ? (
                                <ProfileAddEditModal
                                    profileStatus="on-going"
                                    profileEditData={userInfo}
                                    changeProfileStatus='AddBacktoJob'
                                    onModalClose={() => this.setState({ addBacktoJob: '' })}
                                />
                            ) : null
                        }
                    </div>
                ) : null}
                {showModal 
                    ? (
                        <OfferModal 
                            applicationId={application.data.appln.id}
                            onModalClose={() => this.setState({ showModal: false }, this.updateStatus)}
                            jobStatus={jobStatus}
                            applicationEditData={ applicationStatus.data.appln? applicationStatus.data.appln :''}
                        />
                    ) : null
                }
                {confirmModal 
                    ? (
                        <ConfirmationModal
                            title="Confirm"
                            modalContent={modalContent}
                            confirmBtn="Yes"
                            cancelBtn="No"
                            handleCancel={this.handleCancel}
                            handleConfirm={() => this.setState({ confirmModal:false, showModal: true })}
                            onModalClose={this.handleCancel}
                        />   
                    ) : null }
                { positionsModal
                    ? (
                        <Modal modalCls="w3-show" onClose={this.positionsModalClose} title="Confirm" widthCls="chp-small-dlg">
                            <div className="w3-container">
                                <p>The open positions are filled. Change number of positions in this job profile.</p>
                            </div>
                            <div className="w3-bar w3-panel">
                                <Button className="w3-margin-right w3-margin-bottom h7t-secondary-btn" onClick={this.positionsModalClose}>Cancel</Button>
                                <Button className="w3-margin-bottom h7t-primary-btn" onClick={this.handleCreate}>Edit Job</Button>
                            </div>
                        </Modal>
                ) : null }

                {jobCreateData
                    ? (
                        <JobAddEditModal
                        jobEditData={jobCreateData}
                        applicantId={application.data.appln.id}
                        onModalClose={() => this.setState({ jobCreateData: '' })}
                    />
                ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    const scheduleWorkflowList = (state.scheduleWorkflowList && state.scheduleWorkflowList.data) ? state.scheduleWorkflowList.data : state.scheduleWorkflowList;
    const applicationById = (state.applicationById && state.applicationById.data) ? state.applicationById.data : state.applicationById;
    const workflowState = (state.workflowState && state.workflowState.data) ? state.workflowState.data : state.workflowState;
    const applicationStatus = state && state.applicationStatus;
    return {
        applicationFeedback: state.applicationFeedback,
        applicationStatus: state.applicationStatus,
        scheduleApplicationStatus: state.scheduleApplicationStatus,
        currentUser,
        workflowState,
        scheduleWorkflowList,
        applicationById,
        applicationStatus,
        applicantsProfileStatus: state.applicantsProfileStatus,
    };
}
export default connect(mapStateToProps, {
    getUserResumeViews,
    getUserDetails,
    getJobApplnFeedback,
    setAppMessage,
    getScheduleApplicationStatus,
    postChangeCandidateProfileStatus,
    getApplicationStatusById,
    getApplicationStatus,
    getWorkflowState,
    getScheduleWorkflowList,
    postUpdateUserRating,
    getApplicantsProfileStatus,
})(ViewJobApplications);
