import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Grid, Paper, Button, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockIcon from '@material-ui/icons/Lock';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import WorkIcon from '@material-ui/icons/Work';
import LinkIcon from '@material-ui/icons/Link';
import copy from 'copy-to-clipboard';
import {
    getCurrentJobList,
    getCurrentWorkflows,
    setAppMessage,
    getJobTemplateList,
    getAllCurrentJobList,
    postRemoveJobPosition,
    getApplicationTitleList,
    postSendMailToApplicants,
    getJobTitleList,
    getActiveJobs,
    getJobById,
} from '../../js/actions';
import JobAddEditModal from './JobAddEditModal';
import { LoadingIcon } from '../Common/HelperComponents';
import WorkflowDeleteModal from '../Common/DeleteRecordModal';
import JobTemplateModal from './JobTemplateModal';
import Pagination from '../Common/Pagination';
import SendConfigMail from './SendConfigMail';
import JobDescriptionModal from '../Dashboard/JobDescriptionModal';
import SearchInputBase from '../Common/MaterialUI/SearchInputBase';
import JobFilters from './JobFilters';
import { newCalculateAgeing, getPermissions } from '../Common/Constants';


class JobList extends Component {
    static propTypes = {
        jobList: PropTypes.object,
        params: PropTypes.object,
        setAppMessage: PropTypes.func,
        jobTemplateList: PropTypes.object,
        currentWorkflows: PropTypes.object,
        paginationDetails: PropTypes.object,
        getCurrentJobList: PropTypes.func,
        getJobTemplateList: PropTypes.func,
        getCurrentWorkflows: PropTypes.func,
        getAllCurrentJobList: PropTypes.func,
        postRemoveJobPosition: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            deleteId: '',
            jobEditData: '',
            showJobDescription: '',
            showJobTemplate: false,
            sendMailJobId: null,
            filterByStatus: 'all',
            filterBySearch: '',
            filterOn: {
                job_id: '',
                posted_on: '',
                location: '',
                opened: '',
                closed: '',
                search: '',
                filter: 'all',
                owner: '',
            },
            textToCopy: "Copy Job Url",
            btnText: "Copy Job Url",
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleJD = this.handleJD.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleJobTemplate = this.handleJobTemplate.bind(this);
        this.onSendMail = this.onSendMail.bind(this);
        this.filterByStatus = this.filterByStatus.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.handleJobFilters = this.handleJobFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleCopyJob = this.handleCopyJob.bind(this);
        this.handleShareJob = this.handleShareJob.bind(this);
        this.outFunc = this.outFunc.bind(this);
    }

    componentDidMount() {
        const { jobList, currentWorkflows, jobTemplateList } = this.props;
        this.props.getCurrentJobList();
        if (!currentWorkflows) {
            this.props.getCurrentWorkflows();
        }
        if (!jobTemplateList) {
            this.props.getJobTemplateList();
        }
        this.props.getApplicationTitleList();
        this.props.getJobTitleList();
        this.props.getActiveJobs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.officeId !== this.props.match.params.officeId) {
            this.props.getCurrentJobList();
            this.props.getCurrentWorkflows();
            this.props.getJobTemplateList();
        }
    }

    onSendMail(content) {
        const { sendMailJobId } = this.state;
        content.jobId = sendMailJobId;
        this.props.postSendMailToApplicants(content).then((resp) => {
            if (resp && resp.data && resp.data.success) {
                this.props.setAppMessage('success', 'Mail send successfully!');
                this.setState({
                    sendMailJobId: null,
                });
            }
        });
    }

    handleEdit(JobListData) {
        this.setState({ jobEditData: JobListData });
    }

    handleCopyJob(JobData) {
        const selectedJob = {};
        selectedJob.job_title = JobData.job_title;
        selectedJob.description = JobData.description;
        selectedJob.skills_required =JobData.skills_required;
        selectedJob.universities = JobData.universities;
        selectedJob.certifications = JobData.certifications;
        selectedJob.office_id = JobData.office_id;
        selectedJob.company_id = JobData.company_id;
        selectedJob.min_experience_required = JobData.min_experience_required;
        selectedJob.max_experience_required = JobData.max_experience_required;
        selectedJob.positions_open = JobData.positions_open;
        selectedJob.level_of_hiring = JobData.level_of_hiring;
        selectedJob.salary_max = JobData.salary_max;
        selectedJob.salary_min = JobData.salary_min;
        selectedJob.workflow_id = JobData.workflow_id;
        selectedJob.remote_Jobs = JobData.remote_Jobs;
        selectedJob.companyName = JobData.companyName;
        selectedJob.companyWebsite = JobData.companyWebsite;
        selectedJob.companyType = JobData.companyType;
        selectedJob.companyCategory = JobData.companyCategory;
        selectedJob.companySize = JobData.companySize;
        selectedJob.companyAbout = JobData.companyAbout;
        this.setState({ jobEditData: selectedJob });
    }

    handleShareJob(jobId) {
        const { currentUser: { user } } = this.props;
        const { textToCopy } = this.state;
        const pathUrl = window.location.href;
        var shareJobUrl = pathUrl.replace('jobs','share-job');
        this.setState({textToCopy: `${shareJobUrl}?jobId=${jobId}`}, () => {
            copy(this.state.textToCopy);
        }); 
        this.setState({ btnText: "Copied!" });
    }

    outFunc() {
        var tooltip = document.getElementById("myTooltip");
        tooltip.title = "Copy Job Url";
        this.setState({ btnText: tooltip.title });
    }

    handleDelete(id) {
        if (id === true) {
            const { deleteId } = this.state;
            const { paginationDetails } = this.props;
            let currentPage = paginationDetails.current_page;
            this.props.postRemoveJobPosition(deleteId).then((resp) => {
                if (resp && resp.data && resp.data.success) {
                    if (paginationDetails &&
                        paginationDetails.data &&
                        paginationDetails.data.length === 1 &&
                        paginationDetails.current_page > 1) {
                        currentPage = paginationDetails.current_page - 1;
                    }
                    this.props.getCurrentJobList(currentPage);
                    this.props.getAllCurrentJobList('all');
                    this.props.getCurrentWorkflows();
                    this.props.getJobTitleList();
                    this.props.setAppMessage('success', 'Job deleted successfully!');
                    $('.close-button').click();
                }
            });
        } else {
            this.setState({ deleteId: id });
        }
    }

    handleJD(showJobDescription) {
        this.setState({ showJobDescription });
    }

    handleJobTemplate() {
        const { showJobTemplate } = this.state;
        this.setState({
            showJobTemplate: !showJobTemplate,
        });
    }

    handleSendMail(id) {
        this.setState({
            sendMailJobId: id,
        });
    }

    searchFilter(event) {
        const { value } = event.target;
        if (!value) {
            this.handlePagination();
        }
        this.setState({
            filterBySearch: value,
        });
    }

    handleJobFilters(filters) {
        const { filterOn } = this.state;
        this.setState({ filterOn: filters }, () => {
            this.handlePagination();
        });
    }

    clearFilters(newfilterOn) {
        const { filterOn, filterBySearch } = this.state;
        this.setState({ filterOn: newfilterOn, filterBySearch: '' }, () => {
            this.handlePagination();
        });
    }

    handlePagination(page) {
        const { filterByStatus, filterBySearch, filterOn } = this.state;
        const data = {};
        data.posted_on = filterOn.posted_on;
        data.jobId = filterOn.job_id;
        data.opened = filterOn.opened;
        data.closed = filterOn.closed;
        data.owner = filterOn.owner;
        data.location = filterOn.location;
        data.search = filterBySearch;
        this.props.getCurrentJobList(page, filterOn.filter, data);
    }

    filterByStatus(e) {
        const { value } = e.target;
        const filterByStatus = value;
        this.props.getCurrentJobList(1, filterByStatus);
        this.setState({
            filterByStatus,
        });
    }

    render() {
        let jobData = [];
        const {
            jobList, currentWorkflows, paginationDetails, jobTemplateList,
            applicationTitleList, currentUser, filterBySearch,
            location,
        } = this.props;
        const {
            jobEditData, deleteId, showJobDescription, showJobTemplate, sendMailJobId, btnText
        } = this.state;

        if (!jobList || !jobList.data) {
            return <LoadingIcon />;
        }

        let companyId = null;
        let officeId = null;

        const permission = getPermissions(currentUser);

        if (location.state) {
            companyId = location.state.companyId;
            officeId = location.state.officeId;
        } else {
            companyId = COMPANY_ID;
            officeId = OFFICE_ID;
        }

        if (jobList.data.length > 0) {
            jobList.data.forEach((item, idx) => {
                let inProgress = false;
                if (applicationTitleList && applicationTitleList.data) {
                    inProgress = applicationTitleList.data.find((job) => {
                        return job.job_id == item.id;
                    });
                }

                const date = newCalculateAgeing(item.created_at);
                const ageing = date===0?'Today':date===1?'1 Day Ago':`${date} Days Ago`;

                let showEdit = true;
                if((item.status === "active" && permission == 'Hiring Manager') || (item.status === "unpublish")) {
                    showEdit = false;
                } else if((item.status === "saved" && permission == 'Hiring Manager') && (item.creator_user_id !== currentUser.user.id)) {
                    showEdit = false;
                }

                jobData.push(
                    <Grid className={`job-card card-bg ${item.status}`} item xs={12} sm={4} md={4} lg={4} key={`job-${item.id}`}>
                        <Paper className="center-align">
                            <div className="card-content white-text h7t-cursor-pointer">
                                <div className="w3-col s12">    
                                    <div className="w3-col s6 m6">
                                        <div className="white-text w3-left h7t-font-size-13">Posted - {date===0?'Today':date===1?'1 Day Ago':`${date} Days Ago`} </div> <br />
                                        <div className="white-text w3-left" style={{ fontSize: 11 }}>{moment(item.created_at).format('MMM Do, YYYY')}</div>
                                    </div>
                                    <div className="w3-col s6 m6">
                                        <p className={`white-text w3-right capitalize h7t-font-size-13 ${item.status==='saved'?'gradient-45deg-purple-deep-orange':''}`}>{(item.status==='active')?'Published':item.status==='saved'?'Saved':'UnPublished'}</p>
                                    </div>
                                </div>
                                <div className="numberCircle w3-center">Job Id {item.id}</div>
                                <h5 className="white-text mb-1 bold-header h7t-ellipsis" style={{ minHeight: '38px' }}>
                                    {item.job_title}
                                </h5>   
                                <p className="m-0 h7t-font-size-13">{item.display_name}</p>
                                <div className="w3-row" style={{marginTop:'22px'}}>
                                    <p className="w3-col s4 m3 card-bottom-text">Open <div>{item.positions_open}</div></p>
                                    <p className="w3-col s4 m3 card-bottom-text">Applied <div>{item.total_applications_count}</div></p>
                                    <p className="w3-col s4 m3 card-bottom-text">Hired <div>{(item.hired) ? item.hired : 0}</div></p>
                                    <p className="w3-col s4 m3">Closed <div>{(item.positions_filled) ? item.positions_filled : 0}</div></p>
                                </div>
                                <hr />
                                <div className="w3-row" style={{ marginTop: 5 }}>
                                     <div className="w3-col s4 m4 ">
                                     <p className="white-text card-bottom-text h7t-font-size-13">{item.views} Views</p>
                                    </div>
                                    <div className="w3-col s4 m4">
                                        <p 
                                            className={`white-text h7t-font-size-13 ${item && item.current_office && item.current_office.city_info && item.current_office.city_info.term ? 'card-bottom-text':''}`}>
                                            {item && item.current_office && item.current_office.city_info && item.current_office.city_info.term}
                                        </p>
                                    </div>
                                    <div className="w3-col s4 m4">
                                        <p 
                                            className="white-text h7t-font-size-13" style={{ padding: 2 }}>
                                            {`${item.min_experience_required} - ${item.max_experience_required} Yrs`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                        <div className="overlay-card">
                            <div className="w3-row w3-padding overlay-content">
                                {(permission !== 'Panel Member') 
                                    ? (
                                        <div className="w3-col">
                                            {showEdit && 
                                            (
                                                <Tooltip title="Edit">
                                                    <button
                                                        type="button"
                                                        className="w3-button padding-very-small w3-hover-teal icon-border w3-white"
                                                        onClick={() => this.handleEdit(item)} 
                                                        >
                                                        <i className="material-icons">edit</i> 
                                                    </button>
                                                </Tooltip>
                                            ) }

                                            {item.status === 'active' &&
                                            (
                                                <Tooltip title={btnText} id="myTooltip">
                                                    <button 
                                                        type="button"
                                                        className="w3-button padding-very-small icon-border w3-hover-blue w3-white"
                                                        onClick={() => this.handleShareJob(item.id)}
                                                        onMouseOut={() => this.outFunc()}
                                                    >
                                                        <LinkIcon />
                                                    </button>
                                                </Tooltip>
                                            ) }

                                            {item.status !== 'unpublish' && permission !== 'Hiring Manager' && 
                                            (
                                                <Tooltip title="Send Mail">
                                                    <button
                                                        type="button"
                                                        className="w3-button padding-very-small icon-border w3-white" 
                                                        onClick={() => this.handleSendMail(item.id)} 
                                                        >
                                                            <i className="material-icons">email</i>
                                                    </button>
                                                </Tooltip>
                                            ) }
                                            <Tooltip title="View JD">
                                                <button
                                                    type="button"
                                                    className="w3-button padding-very-small icon-border w3-hover-blue w3-white"
                                                    onClick={() => this.handleJD(item)}
                                                >
                                                    <i className="material-icons">remove_red_eye</i>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Copy JD">
                                                <button 
                                                    type="button"
                                                    className="w3-button padding-very-small icon-border w3-hover-blue w3-white"
                                                    onClick={() => this.handleCopyJob(item)}
                                                >
                                                    <FileCopyIcon />
                                                </button>
                                            </Tooltip>
                                            {!inProgress && showEdit &&
                                            (
                                                <Tooltip title="Delete">
                                                    <button
                                                        type="button"
                                                        className="w3-button padding-very-small icon-border w3-hover-red w3-white" 
                                                        onClick={() => this.handleDelete(item.id)} 
                                                        >
                                                            <i className="material-icons">delete</i> 
                                                        </button>
                                                </Tooltip>
                                            ) }
                                        </div>
                                ) : null}
                            </div>
                        </div>
                    </Grid>,
                );
            });
        } else {
            jobData = (
                <Grid item xs={12}>
                    <div className="w3-margin-bottom w3-margin-top h7t-table-data">
                        <div className="w3-row w3-padding">
                            <div className="w3-center w3-margin-top">
                                <span className="ion-ios-briefcase-outline" style={{ fontSize: '120px' }} />
                                <p className="w3-margin-top"><strong>No jobs under this office to display!</strong></p>
                            </div>
                            <div className="h7t-flex-container w3-margin-top">
                                <ul className="h7t-feature-description">
                                    <li>Add workflows and their states from manage workflow tab.</li>
                                    <li>Click on Add job button to create new jobs.</li>
                                    <li>Select job from template.</li>
                                    <li>The application will be processed based on the states of the workflow selected for this job.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Grid>
                
            );
        }

        return (
            <div className="content-margin">
                <div className="w3-row w3-margin-top">
                    <div className="w3-col m4 s12 h7t-margin-mobile-view">
                        <h3 className="h-margin-top-0">Jobs</h3>
                    </div>
                    {(currentWorkflows && currentWorkflows.data && currentWorkflows.data.length && (permission !== 'Panel Member'))
                        ? (
                            <div className="w3-col m8 s12">
                                <Button
                                    variant="contained"
                                    className="w3-margin-bottom w3-right h7t-secondary-btn"
                                    onClick={() => this.handleEdit({})}
                                >
                                    Add Job
                                </Button>
                                {permission !== "Hiring Manager" &&
                                <Button
                                    variant="contained"
                                    className="w3-margin-bottom w3-right h7t-primary-btn h7t-margin-right-mobile"
                                    onClick={() => this.handleJobTemplate()}
                                >
                                    Select Job from Template
                                </Button> }
                            </div>
                        ) : (permission === 'Management' || permission === 'HR') ? (
                            <div className="w3-col m8 s12">
                                <NavLink to={{
                                    pathname: `/e/${companyId}/${officeId}/hiring-workflow`,
                                    state: {
                                        companyId,
                                        officeId
                                    }
                                }}>
                                    <Button
                                        variant="contained"
                                        className="w3-right w3-margin-bottom h7t-primary-btn h7t-left-mobile"
                                    >
                                        Add workflows to enable add job
                                    </Button>
                                </NavLink>
                            </div>
                        ) : null}
                </div>
                <div className="w3-row">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper className="h7t-Paper overview-graph seeker-job-overview-graph-height h7t-table-data">
                                 <WorkIcon className="w3-margin-bottom" />
                                <p>All Jobs</p>
                                <p className="count">{jobList && jobList.counts && jobList.counts.total_jobs}</p> 
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper className="h7t-Paper overview-graph seeker-job-overview-graph-height h7t-table-data">
                                <OpenInNewIcon className="w3-margin-bottom" />
                                <p>Open Positions</p>
                                <p className="count">{(jobList && jobList.counts && jobList.counts.open_positions) ? jobList.counts.open_positions : 0 }</p>  
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper className="h7t-Paper overview-graph seeker-job-overview-graph-height h7t-table-data">
                                <LockIcon className="w3-margin-bottom" />
                                <p>Closed Positions</p>
                                <p className="count">{(jobList && jobList.counts && jobList.counts.closed_positions) ? jobList.counts.closed_positions : 0 }</p> 
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div className="w3-row w3-margin-top">
                    <div className="w3-col m10 s10 w3-margin-bottom">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.handlePagination();
                        }}
                        >
                            <SearchInputBase
                                placeholder="Search for job title"
                                customClass="w3-margin-top"
                                onChange={e => this.searchFilter(e)}
                                value={filterBySearch}
                            />
                        </form>
                    </div>
                    <div className="w3-col m2 s2 w3-right">
                        <JobFilters
                            clearFilterValues={this.clearFilters}
                            handleJobFilters={this.handleJobFilters}
                        />
                    </div>
                </div>
                <div>
                    <div className="w3-row w3-margin-top">
                        <Grid container spacing={2}>
                            {jobData}
                        </Grid>
                    </div>

                    {jobEditData
                        ? (
                            <JobAddEditModal
                                jobEditData={jobEditData}
                                currentPage={paginationDetails.current_page}
                                onModalClose={() => this.setState({ jobEditData: '' })}
                            />
                        ) : null}

                    {deleteId
                        ? (
                            <WorkflowDeleteModal
                                handleDelete={this.handleDelete}
                                deleteMessage="job"
                                thisMsg={true}
                                onModalClose={() => this.setState({ deleteId: '' })}
                            />
                        ) : null }
                    {showJobDescription
                        ? (
                            <JobDescriptionModal employerJdView
                                id="job_dec_modal"
                                jobDescription={showJobDescription}
                                onModalClose={() => this.setState({ showJobDescription: '' }) }
                            />
                        ) : null}

                    {showJobTemplate
                        ? (
                            <JobTemplateModal
                                templateList={jobTemplateList}
                                onModalClose={() => this.setState({ showJobTemplate: !showJobTemplate })}
                            />
                        ) : null }
                    {sendMailJobId
                        ? (
                            <SendConfigMail
                                onSendMail={this.onSendMail}
                                onModalClose={() => this.setState({ sendMailJobId: null })}
                            />
                        ) : null }
                </div>
                {(paginationDetails.last_page > 1)
                    ? (
                        <Pagination
                            paginationDetails={paginationDetails}
                            handlePagination={this.handlePagination}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const jobList = state.jobList && state.jobList.data;
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    const currentWorkflows = state.currentWorkflows && state.currentWorkflows.data;
    return {
        jobList,
        paginationDetails: jobList,
        currentWorkflows,
        jobTemplateList: state.jobTemplateList,
        applicationTitleList: state.applicationTitleList,
        currentUser,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentJobList,
    getJobTemplateList,
    getCurrentWorkflows,
    getAllCurrentJobList,
    postRemoveJobPosition,
    getApplicationTitleList,
    getJobTitleList,
    postSendMailToApplicants,
    getActiveJobs,
    getJobById,
})(JobList);
