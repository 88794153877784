import moment from 'moment';

const Constants = {
    STACKOVERFLOW: 'stackoverflow',
}

export const channelDomainUrl = {
    [Constants.STACKOVERFLOW]: 'https://stackoverflow.com',
}

export const discoverFilterConfig = {
    yoeInitialRange: [0, 15],
    yoeMinLimit: 0,
    yoeMaxLimit: 15,
    yoeOffset: 5,
    yoeStepValue: .2,
};

export const applicantFilterConfig = {
    ratingInitialRange: [0, 5],
    ratingMinLimit: 0,
    ratingMaxLimit: 5,
    ratingOffset: 1,
    ratingStepValue: .2,
};

export const sidebarMenu = {
    defaultMenu : [
        { label: 'dashboard', icon: 'dashboard' },
        { label: 'jobs', icon: 'work' },
        { label: 'discover-candidates', icon: 'search' },
    ],
    managementMenu : [
        { label: 'dashboard', icon: 'dashboard' },
        { label: 'jobs', icon: 'work' },
        {label: 'applicants', icon: 'people',
            items: [
                {label: 'on-going', icon: 'radio_button_unchecked'},
                {label: 'archived', icon: 'radio_button_unchecked'},
            ],
        },
        {label: 'my-calendar', icon: 'today'},
        { label: 'discover-candidates', icon: 'search' },
        { label: 'hiring-workflow', icon: 'list' },
        { label: 'manage-company', icon: 'settings' },
    ],
    hrMenu : [
        {label: 'dashboard', icon: 'dashboard'},
        {label: 'jobs', icon: 'work'},
        {label: 'applicants', icon: 'people',
            items: [
                {label: 'on-going', icon: 'radio_button_unchecked'},
                {label: 'archived', icon: 'radio_button_unchecked'},
            ],
        },
        {label: 'my-calendar', icon: 'today'},
        {label: 'discover-candidates', icon: 'search'},
        {label: 'hiring-workflow', icon: 'list'},
        {label: 'manage-company', icon: 'settings'},
    ],
    panelMemberMenu : [
        { label: 'dashboard', icon: 'dashboard' },
        {label: 'applicants', icon: 'people',
            items: [
                {label: 'on-going', icon: 'radio_button_unchecked'},
                {label: 'archived', icon: 'radio_button_unchecked'},
            ],
        },
        { label: 'my-calendar', icon: 'today' },
        { label: 'discover-candidates', icon: 'search' },
    ],
    hiringManagerMenu : [
        {label: 'dashboard', icon: 'dashboard'},
        {label: 'jobs', icon: 'work'},
        {label: 'applicants', icon: 'people',
            items: [
                {label: 'on-going', icon: 'radio_button_unchecked'},
                {label: 'archived', icon: 'radio_button_unchecked'},
            ],
        },
        {label: 'discover-candidates', icon: 'search'},
    ],
    seekerMenu : [
        { label: 'job-dashboard', icon: 'dashboard' },
        { label: 'profile', icon: 'person' },
        { label: 'my-resume', icon: 'description' },
    ],
};

export const getScreenName = userData => {
    const displayName = `${userData.display_name}`.toLocaleLowerCase().trim().replace(/[^A-Z0-9]+/ig, ' ').replace(/\s+/g, '-');
    return `${displayName}-${userData.screen_name}-${userData.id}`.toLocaleLowerCase();
}

export const calculateAgeing = (ageDate) => {
    const someday = moment(ageDate);
    const date = moment().diff(someday, 'days');
    return date;
};

export const newCalculateAgeing = (date) => {
    var diffInDays = 0;
    var appliedDate = new Date(date.split(' ')[0]);
    var dateObj = new Date();
    var diffInTime = dateObj.getTime() - appliedDate.getTime();
    return parseInt(diffInDays = diffInTime / (1000 * 3600 * 24));
}

export const getPermissions = (currentUser) => {
    var permission = null;
    if (currentUser && currentUser.companies) {
        const company = currentUser.companies.find((com) => {
            return COMPANY_ID == com.company_id;
        });

        if (company && company.office_list) {
            const office = company.office_list.find((off) => {
                return off.id == OFFICE_ID;
            });
            if (office && office.permissions) {
                if (office.permissions.indexOf('Management') !== -1) {
                    return permission = 'Management';
                } else if (office.permissions.indexOf('HR') !== -1) {
                    return permission = 'HR';
                } else if (office.permissions.indexOf('Panel Member') !== -1) {
                    return permission = 'Panel Member';
                } else if (office.permissions.indexOf('Hiring Manager') !== -1) {
                    return permission = 'Hiring Manager';
                }
            }
        }
    }
}

export default Constants;