import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Rating } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { ResponsivePie } from '@nivo/pie';
import { Grid, Box } from '@material-ui/core';
import {
    getCurrentDetailsList,
    setAppMessage,
    postUserResumeBlockDelete,
    postUserProjectDelete,
    postChangeProfilePic,
    getCurrentUser,
    getProjectList,
    postEditProfileLink,
    getUserDetails,
    setData,
} from '../../../js/actions';
import { LoadingIcon } from '../../Common/HelperComponents';
import ProfileDeleteModal from '../../Common/DeleteRecordModal';
import AddDetails from './AddDetailsModal';
import UserDetailsEditModal from './UserDetailsEditModal';
import ProfilePicUpload from './ProfilePicUpload';
import SkillsRatingModal from './SkillsRatingModal';
import AddProjectModal from './SeekerProjects';
import CertificateModal from './CertificateModal';
import PortfolioLinksModal from './PortfolioLinksModal';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import NotFound from '../../Common/NotFound';


class Profile extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        resumeDetail: PropTypes.object,
        projectList: PropTypes.object,
        setAppMessage: PropTypes.func,
        getCurrentUser: PropTypes.func,
        postChangeProfilePic: PropTypes.func,
        getCurrentDetailsList: PropTypes.func,
        postUserResumeBlockDelete: PropTypes.func,
        getProjectList: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            editData: '',
            projectData: '',
            deleteId: '',
            deleteProject: '',
            profile_pic: false,
            userDetailsEditModal: false,
            showRatingModal: false,
            showCertificateModal: false,
            certificateData: '',
            workData: '',
            educationData: '',
            form: {
                projectList: [],
            },
            showPortfolioModal: false,
            editPortfolioData: '',
            deleteMedia: '',
            profileFound: true,
            // myProfile: false,
        };

        this.handleEditDetails = this.handleEditDetails.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleProjectDelete = this.handleProjectDelete.bind(this);
        this.handleUserDetailsEdit = this.handleUserDetailsEdit.bind(this);
        this.handleRateSkill = this.handleRateSkill.bind(this);
        this.handleCertificate = this.handleCertificate.bind(this);
        this.getCertificates = this.getCertificates.bind(this);
        this.onCertificateModalClose = this.onCertificateModalClose.bind(this);
        this.handlePortfolioLink = this.handlePortfolioLink.bind(this);
        this.handleMediaAction = this.handleMediaAction.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeletion = this.handleDeletion.bind(this);
    }

    componentDidMount() {
        const { currentUser } = this.props;
        if (!currentUser || !currentUser.user) {
            this.props.getCurrentUser().then(resp => {
                const response = resp.data;
                this.getUserProfileDetails(response.data);
            });
        } else {
            this.getUserProfileDetails(currentUser);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.resumeDetail && newProps.resumeDetail.data) {
            this.getCertificates(newProps.resumeDetail.data);
        }

        if (newProps.match.params.screenName !== this.props.match.params.screenName) {
            this.props.getCurrentUser().then(resp => {
                const response = resp.data;
                this.setState({
                    profileFound: true,
                }, () => this.getUserProfileDetails(response.data));
            });
        }
    }

    getUserProfileDetails(userData) {
        let { match: { params: { screenName } } } = this.props;
        let myProfile = false;
        const screenNameData = screenName.split('-');
        let userProfileId = screenNameData[screenNameData.length - 1];

        if (parseInt(userData.user.id) === parseInt(userProfileId)) {
            userProfileId = userData.user.id;
            myProfile = true;
        }
        this.props.setData('myProfile', myProfile);
        this.props.getUserDetails({ user_id: userProfileId, screenName }).then(resp => {
            if (!resp || (resp.data && resp.data.data && !resp.data.data.user)) {
                this.setState({
                    profileFound: false,
                });
                return;
            }
            this.props.getProjectList({ user_id: userProfileId, page: 1 });
            this.props.getCurrentDetailsList({ user_id: userProfileId, page: 1 }).then((resp) => {
                const res = resp && resp.data;
                if (res && res.data) {
                    this.getCertificates(res.data);
                }
            });
        });
    }

    onCertificateModalClose() {
        this.setState({
            showCertificateModal: false,
        });
    }

    getCertificates(data) {
        if (data) {
            const filterCertificate = data.filter(item => item.type === 'Certificate');
            const filterWork = data.filter(item => item.type === 'Experience');
            const filterEducation = data.filter(item => item.type === 'Education');
            this.setState({
                certificateData: filterCertificate,
                workData: filterWork,
                educationData: filterEducation,
            });
        }
    }

    handleEditDetails(pos, type) {
        let editData = {
            title: '',
            type,
            description: '',
            institution: '',
            start_date: '',
            end_date: '',
            skills: '',
            projects: [],
        };
        if (pos !== 'Add') {
            editData = JSON.parse(JSON.stringify(this.props.resumeDetail.data[pos]));
            editData.arrloc = pos;
        }
        this.setState({ editData });
    }

    handleAddProject(project = {}) {
        let projectData = Object.assign({
            title: '',
            url: '',
            date_from: '',
            date_to: '',
            responsibilities: '',
            checkProject: '',
        }, project);
        projectData.checkProject = projectData.date_to ? false : projectData.id && projectData.date_from ? true : false;
        this.setState({ projectData });
    }

    handleProjectDelete(id) {
        if (id === true) {
            const { deleteProject } = this.state;
            this.props.postUserProjectDelete(deleteProject).then((response) => {
                if (response && response.data && response.data.success) {
                    this.props.getCurrentDetailsList({ page: 1 });
                    this.props.getCurrentUser();
                    this.props.getProjectList();
                    this.props.setAppMessage('success', 'Job deleted successfully!');
                    $('.close-button').click();
                }
            });
        } else {
            this.setState({ deleteProject: id });
        }
    }

    handleDelete(id) {
        if (id === true) {
            const { deleteId } = this.state;
            this.props.postUserResumeBlockDelete(deleteId).then((response) => {
                if (response && response.data && response.data.success) {
                    this.props.getCurrentDetailsList({ page: 1 });
                    this.props.getCurrentUser();
                    this.props.setAppMessage('success', 'Job deleted successfully!');
                    $('.close-button').click();
                }
            });
        } else {
            this.setState({ deleteId: id });
        }
    }

    handleUserDetailsEdit() {
        this.setState({
            userDetailsEditModal: true,
        });
    }

    handleOpenUploadModal() {
        this.setState({
            profile_pic: true,
        });
    }

    handleRateSkill() {
        this.setState({
            showRatingModal: true,
        });
    }

    handleCertificate() {
        this.setState({
            showCertificateModal: true,
        });
    }

    handlePortfolioLink() {
        this.setState({
            showPortfolioModal: true,
        });
    }

    handleMediaAction(action, index, e) {
        const PortfolioData = {
            action,
            index,
            e,
        };
        this.setState({
            editPortfolioData: PortfolioData,
            showPortfolioModal: true,
        });
    }

    handleSubmit(e, media) {
        const { editPortfolioData } = this.state;
        e.preventDefault();
        const data = {};
        data.profile_links = media;
        this.props.postEditProfileLink(data).then((response) => {
            const resp = response && response.data;
            if (resp) {
                if (resp.success) {
                    this.setState({ saveChanges: false });
                    let successMsg = 'PortfolioLink Added Successfully';
                    if (editPortfolioData) {
                        successMsg = 'PortfolioLink Edited Successfully';
                    }
                    this.props.setAppMessage('success', successMsg);
                    this.props.getCurrentUser();
                } else if (resp.data) {
                    this.setState({
                        errors: resp.data,
                    });
                }
            }
        });
    }

    handleDeletion(mediaUrl) {
        if (mediaUrl === true) {
            const { currentUser: { profile } } = this.props;
            const { deleteMedia } = this.state;
            const portfolioLinks = JSON.parse(profile.profile_links);
            portfolioLinks.map((links, idx) => {
                if (links.mediaUrl === deleteMedia) {
                    portfolioLinks.splice(idx, 1);
                    const data = {};
                    data.profile_links = portfolioLinks;
                    this.props.postEditProfileLink(data).then((response) => {
                        const resp = response && response.data;
                        if (resp) {
                            if (resp.success) {
                                this.props.setAppMessage('success', 'Portfolio Link Deleted  Successfully');
                                this.props.getCurrentUser();
                                this.setState({
                                    deleteMedia: '',
                                });
                            } else if (resp.data) {
                                this.setState({
                                    errors: resp.data,
                                });
                            }
                        }
                    });
                }
            });
        } else {
            this.setState({ deleteMedia: mediaUrl });
        }
    }

    render() {
        let educationalDetailList = [];
        let workExperienceList = [];
        let WorkingProjectList = [];
        let listingProjects = [];
        let profileView = null;
        let profileLinks = [];
        let rateSkillsBlock = [];
        let certify = [];
        const { resumeDetail, currentUser: { user, profile, skillsRating }, projectList, myProfile } = this.props;
        const {
            deleteId,
            deleteProject,
            editData,
            userDetailsEditModal,
            profile_pic,
            showRatingModal,
            projectData,
            showCertificateModal,
            certificateData,
            deleteMedia,
            showPortfolioModal,
            editPortfolioData,
            workData,
            educationData,
        } = this.state;
        const margin = {
            top: 20, right: 0, bottom: 20, left: 0,
        };

        if (!this.state.profileFound) {
            return (
                <NotFound>
                    <div>Profile Not Found</div>
                </NotFound>
            );
        }

        if (!resumeDetail || !resumeDetail.data) {
            return <LoadingIcon />;
        }

        const styles = {
            root: {
                fontFamily: 'consolas, sans-serif',
                textAlign: 'center',
                position: 'relative',
                height: 200,
            },
            overlay: {
                position: 'absolute',
                top: 0,
                right: margin.right,
                bottom: 0,
                left: margin.left,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 48,
                color: 'black',
                // background: '#FFFFFF33',
                textAlign: 'center',
                // This is important to preserve the chart interactivity
                pointerEvents: 'none',
            },
            totalLabel: {
                fontSize: 24,
            },
        };

        const data = [];

        if (profile) {
            let count = 0;
            if (profile.profile_pic) {
                count = 50;
            }
            if (profile.relevant_experience || profile.relevant_experience === 0 || profile.relevant_experience_month || profile.relevant_experience_month === 0) {
                count += 50;
            }
            if (count > 0) {
                data.push({
                    id: 'Profile',
                    label: 'Profile',
                    value: (count / 8).toFixed(2),
                    color: 'hsl(72, 70%, 50%)'
                });
            }
        }
        if (user) {
            let count = 0;
            if (user.phone_number) {
                count = 50;
            }
            if (user.dob) {
                count += 50;
            }
            if (count > 0) {
                data.push({
                    id: 'userDetails',
                    label: 'userDetails',
                    value: (count / 8).toFixed(2),
                    color: 'hsl(72, 70%, 50%)',
                });
            }
        }
        if (skillsRating && skillsRating.length) {
            data.push({
                id: 'Skill',
                label: 'skill',
                value: (100 / 8).toFixed(2),
                color: 'hsl(72, 70%, 50%)',
            });
        }
        if (certificateData.length) {
            data.push({
                id: 'Certificate',
                label: 'Certificate',
                value: (100 / 8).toFixed(2),
                color: 'hsl(72, 70%, 50%)',
            });
        }
        if (educationData.length) {
            data.push({
                id: 'Education',
                label: 'Education',
                value: (100 / 8).toFixed(2),
                color: 'hsl(72, 70%, 50%)'
            });
        }
        if (workData.length) {
            data.push({
                id: 'Experience',
                label: 'Experience',
                value: (100 / 8).toFixed(2),
                color: 'hsl(72, 70%, 50%)',
            });
        }
        if (profile && profile.profile_links) {
            const mediaBlock = JSON.parse(profile.profile_links);
            if (mediaBlock && mediaBlock.length > 0) {
                data.push({
                    id: 'Portfolio',
                    label: 'Portfolio',
                    value: (100 / 8).toFixed(2),
                    color: 'hsl(240, 100%, 50%)',
                });
            }
        }
        if (projectList && projectList.data && projectList.data.length) {
            data.push({
                id: 'Projects',
                label: 'Projects',
                value: (100 / 8).toFixed(2),
                color: 'hsl(72, 70%, 50%)',
            });
        }

        let totalPercentage = 0;
        data.map((profileDeatils) => {
            if (Number(profileDeatils.value)) {
                totalPercentage += Number(profileDeatils.value);
            }
        });
        totalPercentage = Math.ceil(totalPercentage);
        data.push({
            id: 'empty',
            label: 'empty',
            value: 100 - totalPercentage,
        });

        if (user || profile) {
            profileView = (
                <Grid container alignItems="center" className="w3-bottombar h7t-profile-in-mobile h7t-table-data w3-padding">
                    <Grid item sm={8} md={9} container spacing={1} style={{ display: 'flex', justifyItems: 'space-between' }}>
                        <div>
                            <Grid item sm={4} md={4}>
                                <div className="h7t-profile-picture">
                                    <img
                                        style={{ width: '160px', height: '160px', alignItems: 'center', objectFit: 'contain', border: '1px solid #e7e7e7' }}
                                        alt=""
                                        className="w3-circle h7t-profile-photo-grid"
                                        src={(profile && profile.profile_pic) ? profile.profile_pic : 'https://www.w3schools.com/w3css/img_avatar6.png'}
                                    />
                                    {myProfile && (
                                        <div className="h7t-upload-profile-picture">
                                            <button
                                                type="button"
                                                className="w3-button w3-black w3-padding-small w3-opacity-min w3-circle"
                                                onClick={this.handleOpenUploadModal.bind(this)}
                                            >
                                                <i className="ion-camera w3-large" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Grid item sm={8} md={10} className="w3-padding">
                                <Box>
                                    <div>
                                        <Box display="flex">
                                            <div style={{ marginRight: '10px' }}>
                                                <h4 className="h7t-modal-title">{user.display_name}</h4>
                                            </div>
                                            {myProfile && (
                                                <div style={{ marginTop: '10px' }}>
                                                    <Button
                                                        className="w3-hover-teal w3-padding-small icon-border h7t-min-width-auto"
                                                        title="Edit"
                                                        onClick={this.handleUserDetailsEdit}
                                                    >
                                                        <span className="ion-edit" aria-hidden="true"></span>
                                                    </Button>
                                                </div>
                                            )}
                                        </Box>
                                        <div>{(profile && profile.functional_area) ? profile.functional_area : null}</div>
                                        <div>{(profile && profile.current_loc_name && profile.current_loc_name.term) ? profile.current_loc_name.term : null}</div>
                                        <div>{((user.phone_number && user.phone_number.includes('NOT_UPDATED')) || !user.phone_number) ? '' : user.phone_number}</div>
                                        <div>{(user && user.email) ? user.email : null}</div>
                                    </div>
                                </Box>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item sm={4} md={3}>
                        <Paper className="h7t-Paper" style={{ backgroundColor: '#e9e9e9' }}>
                            <div style={styles.root}>
                                <ResponsivePie
                                    margin={margin}
                                    data={data}
                                    innerRadius={0.7}
                                    startAngle={0}
                                    endAngle={360}
                                    colors="green"
                                    fit={false}
                                    // borderWidth={1}
                                    // borderColor='green'
                                    padAngle={0}
                                    isInteractive={false}
                                    enableRadialLabels={false}
                                    enableSlicesLabels={false}
                                    defs={[
                                        {
                                            id: 'work_id',
                                            type: 'patternLines',
                                            background: 'lightgrey',
                                            color: 'lightgrey',
                                        }]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'empty'
                                            },
                                            id: 'work_id'
                                        }]}
                                />
                                <div style={styles.overlay}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{totalPercentage}</span><span style={{ fontSize: 30 }}>%</span>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                </Grid>
            );

            const profileNoData = (
                <div className="h7t-timeline-box h7t-table-data">
                    <div className="h7t-timeline-body">
                        <div className="h7t-timeline center-align">Share your portfolio links here</div>
                    </div>
                </div>
            );

            if (profile && profile.profile_links) {
                const mediaBlock = JSON.parse(profile.profile_links);
                if (mediaBlock && mediaBlock.length > 0) {
                    mediaBlock.map((block, idx) => {
                        const url = block.mediaUrl.includes('http') || block.mediaUrl.includes('https') ? block.mediaUrl : `//${block.mediaUrl}`;
                        profileLinks.push(
                            <div key={idx} className="w3-col m6 w3-margin-bottom-4" id="profile-links">
                                <div className="w3-row w3-padding w3-tooltip w3-light-grey">
                                    <span className="w3-text w3-tag w3-small w3-round-large h7t-tooltip">{block.title}</span>
                                    <div className="w3-col m7 s7 h7t-ellipsis">{block.title}</div>
                                    <div className="w3-col m5 s5 w3-right-align">
                                        {myProfile && (
                                            <React.Fragment>
                                                <Tooltip title="Edit">
                                                    <Button
                                                        className="w3-hover-teal w3-padding-small icon-border h7t-min-width-auto h7t-margin-right"
                                                        onClick={e => this.handleMediaAction(block, idx, e)}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span className="ion-edit" aria-hidden="true" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <Button
                                                        className="w3-hover-red w3-padding-small icon-border h7t-min-width-auto h7t-margin-right"
                                                        onClick={e => this.handleDeletion(block.mediaUrl)}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <span className="ion-trash-a" aria-hidden="true" />
                                                    </Button>
                                                </Tooltip>
                                            </React.Fragment>
                                        )}
                                        <Tooltip title="View">
                                            <a href={url} target="blank">
                                                <Button
                                                    className="w3-hover-blue w3-padding-small icon-border h7t-min-width-auto" style={{ marginBottom: 0 }}>
                                                    <span className="ion-eye" aria-hidden="true" />
                                                </Button>
                                            </a>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>,
                        );
                    });
                } else {
                    profileLinks = profileNoData;
                }
            } else {
                profileLinks = profileNoData;
            }
        }

        if (projectList && projectList.data && projectList.data.length > 0) {
            projectList.data.forEach((item, idx) => {
                const url = item.url.includes('http') || item.url.includes('https') ? item.url : `//${item.url}`;
                WorkingProjectList = (
                    <li key={idx} className="project-list-border">
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item md={8}>
                                <div><strong>{item.title}</strong>{`${item.company ? ' | '+item.company : ''}`} </div>
                                <div><a className="h7t-hyperlink-color" href={url} target="blank">{item.url}</a></div>
                                {item.date_from ? (
                                    <div className="w3-margin-bottom-4">{moment(item.date_from).format('MMM Do, YYYY')} to {item.date_to ? moment(item.date_to).format('MMM Do, YYYY') : 'Present'}</div>
                                ) : null}
                            </Grid>
                            {myProfile && (
                                <Grid item md={4}>
                                    <div className="w3-col m12 w3-right-align">
                                        <Tooltip title="Edit">
                                            <Button
                                                className="w3-hover-teal w3-padding-small icon-border h7t-min-width-auto h7t-margin-right"
                                                onClick={() => this.handleAddProject(item)}
                                            >
                                                <span className="ion-edit" aria-hidden="true" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <Button
                                                className="w3-hover-red w3-padding-small icon-border h7t-min-width-auto"
                                                onClick={() => this.handleProjectDelete(item.id)}
                                            >
                                                <span className="ion-trash-a" aria-hidden="true" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            )}
                            <Grid item md={12}>
                                <small className="h7t-ql-editor h7t-list" dangerouslySetInnerHTML={{ __html: item.responsibilities }} />
                            </Grid>
                        </Grid>
                    </li>
                );
                listingProjects.push(WorkingProjectList);
            });
        } else {
            listingProjects = <div className="center-align">Add the projects you have worked on</div>;
        }

        if (resumeDetail.data.length > 0) {
            resumeDetail.data.forEach((item, idx) => {
                const profileBlock = (
                    <li key={idx} className="project-list-border">
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item md={8}>
                                <div><strong>{item.title}</strong> | {item.institution} </div>
                                <div className="w3-margin-bottom-4">{moment(item.start_date).format('MMM Do, YYYY')} to {item.end_date ? moment(item.end_date).format('MMM Do, YYYY') : 'Present'}</div>
                            </Grid>
                            {myProfile && (
                                <Grid item md={4}>
                                    <div className="w3-right-align">
                                        <Tooltip title="Edit">
                                            <Button
                                                className="w3-hover-teal w3-padding-small icon-border h7t-min-width-auto h7t-margin-right"
                                                onClick={() => this.handleEditDetails(idx)}
                                            >
                                                <span className="ion-edit" aria-hidden="true" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <Button
                                                className="w3-hover-red w3-padding-small icon-border h7t-min-width-auto"
                                                onClick={() => this.handleDelete(item.id)}
                                            >
                                                <span className="ion-trash-a" aria-hidden="true" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            )}
                            <Grid item md={12}>
                                <small className="h7t-ql-editor h7t-list" dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Grid>
                        </Grid>
                    </li>
                );

                if (item.type === 'Education') {
                    educationalDetailList.push(profileBlock);
                } else if (item.type === 'Experience') {
                    workExperienceList.push(profileBlock);
                }
            });
            if (educationalDetailList.length === 0) {
                educationalDetailList = <div className="center-align">All of your education details goes here</div>;
            }
            if (workExperienceList.length === 0) {
                workExperienceList = <div className="center-align">Share your work experience here</div>;
            }
            if (WorkingProjectList.length === 0) {
                WorkingProjectList = <div className="center-align">Add the projects you have worked on</div>;
            }
        } else {
            educationalDetailList = <div className="center-align">All of your education details goes here</div>;
            workExperienceList = <div className="center-align">Share your work experience here</div>;
            WorkingProjectList = <div className="center-align">Add the projects you have worked on</div>;
        }

        if (skillsRating && skillsRating.length) {
            skillsRating.map((item, idx) => {
                rateSkillsBlock.push(
                    <div className="w3-row w3-margin-top" key={`skillRate_${idx}`}>
                        <div className="w3-col m12">
                            <div className="w3-col m8">{item.skill}</div>
                            <div className="w3-col m4 rating">
                                <Rating readOnly value={item.star_rating} />
                            </div>
                        </div>
                    </div>,
                );
            });
        } else {
            rateSkillsBlock = <div className="center-align">Let the recruiter know about your skills</div>;
        }

        if (certificateData && certificateData.length) {
            certificateData.map((item, idx) => {
                certify.push(
                    <div className="w3-row w3-margin-top">
                        <div className="w3-col m12 w3-margin-bottom-4">
                            <div className="w3-col m9">{item.certificate}</div>
                            {/* <div className="w3-col m6">
                                <div className="w3-col m4">{item.yoe} />
                            </div> */}
                            <div className="w3-col m3">Valid up to : {item.year_of_expire}</div>
                        </div>
                    </div>,
                );
            });
        } else {
            certify = <div className="center-align">Add your certifications</div>;
        }

        return (
            <div className="h7t-padding-mobile-view">
                <div className="h7t-margin-mobile-view w3-margin-top content-margin h7t-margin-bottom-40">
                    {profileView}
                    {profile && profile.short_summary
                        ? (
                            <div className="w3-margin-top w3-row">
                                <strong>
                                    <span className="ion-person w3-large" />
                                    &nbsp;Short Summary
                                </strong>
                                <div className="w3-col s12 m12 w3-padding h7t-ql-editor h7t-list" dangerouslySetInnerHTML={{ __html: profile.short_summary }} />
                            </div>
                        ) : null}
                    <div className="w3-row w3-margin-top w3-margin-bottom-4">
                        <div className="w3-col margin-bottom m6 profile-padding-right">
                            {myProfile && (
                                <Button
                                    variant="contained"
                                    className="w3-right h7t-cursor-pointer h7t-primary-btn "
                                    onClick={this.handleRateSkill}
                                >
                                    Rate your Skills
                                </Button>
                            )}
                            <strong>
                                <span className="ion-star w3-large" />
                                &nbsp;Skills
                            </strong>
                        </div>
                        <div className="w3-col margin-bottom m6 profile-padding-left">
                            {myProfile && (
                                <Button
                                    variant="contained"
                                    className="w3-right h7t-cursor-pointer h7t-primary-btn "
                                    onClick={this.handleCertificate}
                                >
                                    <span className="ion-plus-circled" />
                                    &nbsp;Certification
                                </Button>
                            )}
                            <strong>
                                <span className="ion-android-document w3-large" />
                                &nbsp;Certification
                            </strong>
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="h7t-timeline-box w3-col m6 profile-padding-right">
                            <div className="h7t-timeline-body">
                                <div className="h7t-timeline">
                                    {rateSkillsBlock}
                                </div>
                            </div>
                        </div>
                        <div className="h7t-timeline-box w3-col m6 profile-padding-left">
                            <div className="h7t-timeline-body">
                                <div className="h7t-timeline">
                                    {certify}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-row w3-margin-top w3-margin-bottom-4">
                        {myProfile && (
                            <Button
                                variant="contained"
                                className="w3-right h7t-cursor-pointer h7t-primary-btn "
                                onClick={() => this.handleEditDetails('Add', 'Experience')}
                            >
                                <span className="ion-plus-circled" />
                                &nbsp;Experience
                            </Button>
                        )}
                        <strong>
                            <span className="ion-document-text w3-large" />
                            &nbsp;Experience
                        </strong>
                    </div>
                    <div className="h7t-timeline-box h7t-table-data">
                        <div className="h7t-timeline-body">
                            <ul className="h7t-timeline hide-bottom-border" style={{ listStyle: 'none' }}>
                                {workExperienceList}
                            </ul>
                        </div>
                    </div>
                    <div className="w3-row w3-margin-top w3-margin-bottom-4">
                        {myProfile && (
                            <Button
                                variant="contained"
                                className="w3-right h7t-cursor-pointer h7t-primary-btn"
                                onClick={() => this.handleEditDetails('Add', 'Education')}
                            >
                                <span className="ion-plus-circled" />
                                &nbsp;Education
                            </Button>
                        )}
                        <strong>
                            <span className="ion-university w3-large" />
                            &nbsp;Education
                        </strong>
                    </div>
                    <div className="h7t-timeline-box w3-margin-bottom-4 h7t-table-data">
                        <div className="h7t-timeline-body">
                            <ul className="h7t-timeline hide-bottom-border">
                                {educationalDetailList}
                            </ul>
                        </div>
                    </div>

                    <div className="w3-row w3-margin-top w3-margin-bottom-4">
                        {myProfile && (
                            <Button
                                variant="contained"
                                className="w3-right h7t-cursor-pointer h7t-primary-btn "
                                onClick={() => this.handleAddProject()}
                            >
                                <span className="ion-plus-circled" />
                                &nbsp;Project
                            </Button>
                        )}
                        <strong>
                            <span className="ion-ios-albums w3-large" />
                            &nbsp;Add Project
                        </strong>
                    </div>
                    <div className="h7t-timeline-box h7t-table-data">
                        <div className="h7t-timeline-body">
                            <ul className="h7t-timeline hide-bottom-border">
                                {listingProjects}
                            </ul>
                        </div>
                    </div>
                    <div className="w3-row w3-margin-top w3-margin-bottom-4">
                        {myProfile && (
                            <Button
                                variant="contained"
                                className="w3-right h7t-cursor-pointer h7t-primary-btn"
                                onClick={this.handlePortfolioLink}
                            >
                                <span className="ion-plus-circled" />
                                &nbsp;Portfolio Links
                            </Button>
                        )}
                        <strong>
                            <span className="ion-social-buffer w3-large" />
                            &nbsp;Portfolio
                        </strong>
                    </div>
                    <div className="w3-row">
                        {profileLinks}
                    </div>
                </div>

                {editData
                    ? (
                        <AddDetails
                            editData={editData}
                            onModalClose={() => this.setState({ editData: '' })}
                        />
                    ) : null}

                {projectData
                    ? (
                        <AddProjectModal
                            project={projectData}
                            onModalClose={() => this.setState({ projectData: null })}
                        />
                    ) : null}

                {deleteId
                    ? (
                        <ProfileDeleteModal
                            handleDelete={this.handleDelete}
                            deleteMessage="details"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteId: '' })}
                        />
                    ) : null}

                {deleteProject
                    ? (
                        <ProfileDeleteModal
                            handleDelete={this.handleProjectDelete}
                            deleteMessage="details"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteProject: '' })}
                        />
                    ) : null}

                {deleteMedia
                    ? (
                        <ProfileDeleteModal
                            handleDelete={this.handleDeletion}
                            deleteMessage="Link"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteMedia: '' })}
                        />
                    ) : null}

                {userDetailsEditModal
                    ? (
                        <UserDetailsEditModal
                            onModalClose={() => this.setState({ userDetailsEditModal: false })}
                        />
                    ) : null}

                {profile_pic
                    ? (
                        <ProfilePicUpload
                            onModalClose={() => this.setState({ profile_pic: false })}
                        />
                    ) : null}

                {showRatingModal
                    ? (
                        <SkillsRatingModal
                            existingUserSkillsRate={skillsRating ? skillsRating : []}
                            onModalClose={() => this.setState({ showRatingModal: false })}
                        />
                    ) : null}

                {showCertificateModal
                    ? (
                        <CertificateModal
                            existingUserCertificate={certificateData ? certificateData : []}
                            onModalClose={this.onCertificateModalClose}
                        />
                    ) : null}
                {showPortfolioModal
                    ? (
                        <PortfolioLinksModal
                            handleSubmit={this.handleSubmit}
                            editPortfolioData={editPortfolioData}
                            onModalClose={() => this.setState({ showPortfolioModal: false, editPortfolioData: null, })}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    let currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    if (state.userDetails && state.userDetails.data && !state.myProfile) {
        currentUser = state.userDetails.data;
    }
    return {
        projectList: state.projectList,
        resumeDetail: state.resumeDetail,
        currentUser,
        myProfile: state.myProfile,
    };
}
export default withRouter(connect(mapStateToProps, {
    getCurrentDetailsList,
    setAppMessage,
    postUserResumeBlockDelete,
    postUserProjectDelete,
    postChangeProfilePic,
    getCurrentUser,
    getProjectList,
    postEditProfileLink,
    getUserDetails,
    setData,
})(Profile));
