import thunkMiddleWare from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import rootReducer from './Reducerlists';
const loggerMiddleware = createLogger({ collapsed: true });
const middlewares = [thunkMiddleWare];

__DEV__ && middlewares.push(loggerMiddleware);

const initialState = {
    homePageView: 'login',
    jobApplications: null,
    currentUser: null,
    jobList: null,
    appMessage: {
        messageType: '',
        messageText: '',
    },
    profileList: null,
    uiModalState: {

    },
    userRole: null,
    currentOfficeList: null,
    currentCompany: null,
    currentWorkflows: null,
    userAccess: null,
    workflowState: null,
    applicationFeedback: null,
    applicationStatus: null,
    listInvite: null,
    resumeDetail: null,
    meta: null,
    hideSideBarMenu:false,
};

function uiModalStates(state = {}, action) {
    console.log('Adding changes for actions');
    if (!action || action.type !== 'SET_UI_MODAL') {
        return state;
    }
    const param = {};
    param[action.key] = action.value;
    return Object.assign({}, state, param);
}
const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares),
);
// console.log(store.getState());
window.gHiringStore = store;
export default store;
