import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, Grid, Tooltip, Tabs, Tab, Typography, Box
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import {
    getAllJobApplications,
    setAppMessage,
    getScheduleWorkflowCalendarList,
    getCurrentUser,
    postProfileDelete,
    getUserResumeViews,
    getUserDetails,
    getApplicationTitleList,
    getApplicationStatusList,
    getActiveJobs,
} from '../../js/actions';
import Modal from '../Common/Modal';
import Pagination from '../Common/Pagination';
import PreviewResumeModal from '../Common/PreviewResumeModal';
import ProfileAddEditModal from '../Reachout/ProfileAddEditModal';
import ProfileDeleteModal from '../Common/DeleteRecordModal';
import ApplicantFilters from './ApplicantFilters';
import { LoadingIcon } from '../Common/HelperComponents';
import SearchInputBase from '../Common/MaterialUI/SearchInputBase';
import { newCalculateAgeing } from '../Common/Constants';

const JobApplicationRow = ({
    item,
    handleViewApplication,
    handleViewResume,
    handleEdit,
    handleDelete,
    permission,
}) => {

    const date = newCalculateAgeing(item.applied_at);
    
    return (
        <TableRow style={{opacity:item.job_status === "unpublish" ? "0.5" : ""}} onClick={() => handleViewApplication(item)} className="h7t-cursor-pointer">
            <TableCell>{moment(item.applied_at).format('MMM Do, YYYY') || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.display_name || item.profile_display_name}</TableCell>
            <TableCell>{date===1?'1 Day':`${date} Days`}</TableCell>
            <TableCell>{item.job_title}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                {item.relevant_experience || item.relevant_experience_month
                    ? `${item.relevant_experience !== 0 //Reachout Candidates
                            ? `${item.relevant_experience}y` : ''} 
                        ${item.relevant_experience_month !== 0
                            ? `${item.relevant_experience_month}m` : ''}` 
                    : item.experience_years || item.experience_months
                        ? `${item.experience_years !== 0
                                ? `${item.experience_years}y` : ''} 
                            ${item.experience_months !== 0
                                ? `${item.experience_months}m` : ''}`
                        : <span className="w3-text-grey"> - </span>
                }
            </TableCell>
            <TableCell>{item.notice_period || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.status !== 'In Progress' ? item.status : item.current_status || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.current_ctc || item.profile_current_ctc || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.expected_ctc || item.expected_ctc_min || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.rating || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.status === 'In Progress' && item.action_date ? moment(item.action_date).format('MMM Do, YYYY') : <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.assigneeName || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell>{item.owner || <span className="w3-text-grey"> - </span>}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                {item.profile_resume_file ? (
                    <Tooltip title="Download">
                        <a href={item.profile_resume_file} target="_new" onClick={e => e.stopPropagation()} download>  
                            <button
                                type="button"
                                className="w3-button icon-border w3-hover-blue w3-padding-small"
                                onClick={e => e.stopPropagation()}
                            >
                                <span className="ion-android-download" />
                            </button>
                        </a>
                    </Tooltip>
                ) : (
                    <Tooltip title="View Resume">
                        <button
                            type="button"
                            className="w3-button icon-border w3-hover-blue w3-padding-small"
                            onClick={e => handleViewResume(e, item.resume_view_id)}
                        >
                            <span className="ion-eye" />
                        </button>
                    </Tooltip>
                )}
                {item.applicant_profile_id && permission !== 'Panel Member' ? (
                    <React.Fragment>
                        <Tooltip title="Edit">
                            <button
                                type="button"
                                className="w3-button icon-border w3-hover-teal w3-padding-small"
                                onClick={e => handleEdit(e, item)}
                            >
                                <span className="ion-edit" />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <button
                                type="button"
                                className="w3-button icon-border w3-hover-red w3-padding-small"
                                onClick={e => handleDelete(item, e)}
                            >
                                <span className="ion-trash-a" />
                            </button>
                        </Tooltip>
                    </React.Fragment>
                ) : ''}
            </TableCell>
        </TableRow>
    );
};

const TabPanel = ({
	children, 
	value, 
	index, 
	...other
}) => {
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
      	aria-labelledby={`simple-tab-${index}`}
      	{...other}
	  >
		{value === index && <Box p={0}>{children}</Box>}
	  </Typography>
	);
  };
  
  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
  };

	function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }

class JobApplications extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        activeJobs: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            filterBySearch: '',
            previewResume: '',
            deleteId: '',
            profileEditData: '',
            CalendarData: [],
            filterOn: {
                job_id: '',
                notice_period: '',
                step: '',
                rating: '',
                owner: '',
                search: '',
                status: 'all',
                assigned_to: '',
            },
            tabNumber:0,
        };
        this.handleViewResume = this.handleViewResume.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleJobFilters = this.handleJobFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    componentDidMount() {
        const {
            getAllJobApplications, getScheduleWorkflowCalendarList, getApplicationTitleList, getApplicationStatusList, getActiveJobs, activeJobs
        } = this.props;
        getAllJobApplications({ page: 1, profileStatus: 'on-going' });
        getApplicationTitleList();
        getApplicationStatusList();
        getScheduleWorkflowCalendarList().then((resp) => {
            const calendarDetails = resp.data.data;
            this.setState({
                CalendarData: calendarDetails,
            });
        });
        if(!activeJobs) {
            getActiveJobs();
        }
    }

    handleEdit(e, data) {
        e.stopPropagation();
        const dataVal = {
            display_name: '',
            relevant_experience: '',
            relevant_experience_month: '',
            current_ctc: '',
            expected_ctc_min: '',
            resume_file_path: '',
            resume_text: '',
            profile_info: '',
            email: '',
            channel: 'Other',
            other_text_channel: 'HiringnXt',
            job_in_consideration: '',
            phone_number: '',
            visibility: '',
            profile_status: 'On-going',
        };
        if (data !== 'Add') {
            dataVal.display_name = data.profile_display_name;
            dataVal.relevant_experience = data.relevant_experience;
            dataVal.relevant_experience_month = data.relevant_experience_month;
            dataVal.current_ctc = data.profile_current_ctc;
            dataVal.expected_ctc_min = data.expected_ctc_min;
            dataVal.resume_file_path = data.profile_resume_file;
            dataVal.resume_text = data.resume_text;
            dataVal.profile_info = data.profile_info;
            dataVal.email = data.email;
            dataVal.job_in_consideration = data.job_in_consideration;
            dataVal.phone_number = data.phone_number;
            dataVal.visibility = data.visibility;
            dataVal.channel = data.channel;
            dataVal.reffered_by = data.reffered_by;
            dataVal.profile_id = data.profile_id;
        }
        this.setState({ profileEditData: dataVal });
    }

    handleDelete(data, e) {
        const {
            paginationDetails, postProfileDelete, setAppMessage, getApplicationStatusList,
        } = this.props;
        let currentPage = paginationDetails.current_page;
        if (data === true) {
            const { deleteId } = this.state;
            postProfileDelete(deleteId).then((response) => {
                if (response.data.success) {
                    if (paginationDetails
                        && paginationDetails.data
                        && paginationDetails.data.length === 1
                        && paginationDetails.current_page > 1) {
                        currentPage = paginationDetails.current_page - 1;
                    }
                    this.handlePagination('', currentPage - 1);
                    getApplicationStatusList();
                    setAppMessage('success', 'Profile deleted successfully!');
                    $('.close-button').click();
                }
            });
        } else {
            e.stopPropagation();
            this.setState({ deleteId: data });
        }
    }

    searchFilter(event) {
        const { value } = event.target;
        if (!value) {
            this.handlePagination();
        }
        this.setState({
            filterBySearch: value,
        });
    }

    clearFilters(filterOn) {
        this.setState({
            filterOn,
            filterBySearch: '',
        }, () => {
            this.handlePagination();
        });
    }

    handleJobFilters(filterOn) {
        this.setState({ filterOn }, () => {
            this.handlePagination();
        });
    }

    handlePagination(e, page = 0) {
        const {
            filterBySearch, filterOn,
        } = this.state;
        const data = {};
        data.page = page + 1;
        data.jobId = filterOn.job_id;
        data.notice_period = filterOn.notice_period;
        data.step = filterOn.step;
        data.ratingRange = filterOn.ratingRange;
        data.owner = filterOn.owner;
        data.status = filterOn.status;
        data.assigned_to = filterOn.assigned_to;
        data.profileStatus = 'on-going';
        if (filterBySearch) {
            data.search = filterBySearch;
        }
        this.props.getAllJobApplications(data);
    }

    handleViewApplication(item) {
        const { company_id, office_id, id } = item;
        const url = `/e/${company_id}/${office_id}/on-going/${id}`;
        this.props.history.push({
            pathname: url,
            state: {
                companyId: company_id,
                officeId: office_id,
            }
        });
    }

    handleViewResume(e, resumeViewId) {
        e.stopPropagation();
        const { currentUser, currentUser: { user } } = this.props;
        if (resumeViewId) {
            this.props.getUserResumeViews(resumeViewId).then((res) => {
                const previewResume = res.data && res.data.data;
                let userData = currentUser;
                if (userData.id != previewResume.user_id) {
                    this.props.getUserDetails({user_id: previewResume.user_id }).then((userResp) => {
                        userData = userResp.data && userResp.data.data;
                        this.setState({
                            userData,
                        });
                    });
                }
                this.setState({ userData, previewResume });
            });
        }
    }

    handleOpenCalenderModal() {
        const { currentUser: { user: { current_company_id, current_office_id } } } = this.props;
        const url = {
            pathname: `/e/${current_company_id}/${current_office_id}/my-calendar`,
            state: {
                companyId: current_company_id,
                officeId: current_office_id
            }
        };
        this.props.history.push(url);
    }

    render() {
        const {
            allJobApplications, paginationDetails, currentUser, applicationStatusList, activeJobs
        } = this.props;
        const {
            filterBySearch, filterOn, previewResume, profileEditData, deleteId, CalendarData, tabNumber
        } = this.state;
        const tableRow = [];
        let noData = null;
        let disableAddProfile = false;
        let applicantData = [];

        if (!allJobApplications) {
            return <LoadingIcon />;
        }

        const calendar = {
            today: [],
            tomorrow: [],
        };
        if (CalendarData && CalendarData.length) {
            CalendarData.map((data) => {
                const timeDetails = new Date(data.action_time);
                const dateDetails = new Date(data.action_date);
                const newDate = new Date();
                if (newDate.getDate() == dateDetails.getDate()) {
                    calendar.today.push(
                        {
                            time: moment(timeDetails).format('LT'),
                            round: data.state_name,
                            when: 'Today',
                        },
                    );
                } else if (newDate.getDate() + 1 == dateDetails.getDate()) {
                    calendar.tomorrow.push(
                        {
                            time: moment(timeDetails).format('LT'),
                            round: data.state_name,
                            when: 'Tomorrow',
                        },
                    );
                }
            });
        }

        let permission = null;
        if (currentUser && currentUser.companies) {
            const comapny = currentUser.companies.find((com) => {
                return COMPANY_ID == com.company_id;
            });

            if (comapny && comapny.office_list) {
                const office = comapny.office_list.find((off) => {
                    return off.id == OFFICE_ID;
                });
                if (office && office.permissions) {
                    if (office.permissions.indexOf('Management') !== -1) {
                        permission = 'Management';
                    } else if (office.permissions.indexOf('HR') !== -1) {
                        permission = 'HR';
                    } else if (office.permissions.indexOf('Panel Member') !== -1) {
                        permission = 'Panel Member';
                    }
                }
            }
        }
        const self = this;
        if (allJobApplications && allJobApplications.data && allJobApplications.data.length) {
            allJobApplications.data.map((item, idx) => {
                    tableRow.push(<JobApplicationRow
                        key={`id_${idx}`}
                        item={item}
                        handleEdit={self.handleEdit.bind(self)}
                        handleDelete={self.handleDelete.bind(self)}
                        handleViewApplication={self.handleViewApplication.bind(self)}
                        handleViewResume={this.handleViewResume}
                        permission={permission}
                    />)
            });
        } else {
            noData = (
                <div className="h7t-table-data">
                    <div className="w3-center w3-margin-top">
                        <span className="ion-ios-paper-outline" style={{ fontSize: '120px' }} />
                        <p className="w3-margin-top"><strong>No job applications under this office to display!</strong></p>
                    </div>
                    <div className="h7t-flex-container w3-margin-top">
                        <ul className="h7t-feature-description">
                            <li>View job applications and their current status.</li>
                            <li>Add profiles to a job post to reach out to candidates.</li>
                            <li>Click on the eye icon to view feedbacks of each application.</li>
                            <li>Assign to yourself to perform any action on the application.</li>
                            <li>Add feedback and choose next level of the application process from the list.</li>
                        </ul>
                    </div>
                </div>
            );
        }

        if(activeJobs && activeJobs.data && activeJobs.data.length <=0) {
            disableAddProfile = true;
        }

        if (allJobApplications && allJobApplications.data && allJobApplications.data.length) {
            allJobApplications.data.map((item, idx) => {
                const date = newCalculateAgeing(item.applied_at);
                applicantData.push(
                    <Grid className={`applicant-card`} item xs={12} sm={12} md={12}>
                        <Paper className="h7t-Paper overview-graph h7t-applicant-card-height h7t-cursor-pointer w3-margin-top" style={{ background: 'white' }}>
                            <div className="w3-row h7t-padding-0-20">
                                <div className="w3-row">
                                    <span className="w3-col m1 s3"><img className="h7t-applicant-img w3-circle w3-left w3-margin-right" style={{ border: '1px solid #e7e7e7' }} alt="" src={ (item.profile_pic) ? item.profile_pic : 'https://www.w3schools.com/w3css/img_avatar6.png'} /></span>
                                    <span className="w3-col m3 s9"><span className="w3-left w3-margin-right h7t-font-size-18 h7t-capitalize-text">{ item.display_name || item.profile_display_name}</span></span>
                                    <span className="w3-col m4 s12"><span className="w3-center w3-margin-left"><span className="w3-text-grey">Applied on : </span><span>{ moment(item.applied_at).format('MMM Do, YYYY') || <span className="w3-text-grey"> - </span>}</span></span></span>
                                    <span className="w3-col m4 s12">
                                        <div className="w3-row">
                                            <span className=" w3-row w3-right h7t-rating-bg">Rating: { item.rating || <span className="w3-text-grey"> - </span>}</span>
                                            <span className="w3-row m4 s12">
                                                <div className="w3-right h7t-step-bg w3-margin-top">{item.status !== 'In Progress' ? item.status : item.current_status || <span className="w3-text-grey"> - </span>}
                                                    {item.action_date && item.action_time ? <div className="w3-margin-left h7t-font-size-13">On  {moment(item.action_date).format('Do, MMM')}  {moment(item.action_time).format('hh:mm a')}</div>
                                                    : <div className="w3-margin-left h7t-font-size-13">On  - Not Yet Scheduled</div>}
                                                </div>
                                            </span>
                                        </div>
                                    </span>                                    
                                </div>
                                <div className="w3-row">
                                    <span className="w3-col m2 s12"><span className="w3-left"><span className="w3-text-grey">Age : </span><span>{ date===1?'1 Day':`${date} Days`}</span></span></span>
                                    <span className="w3-col m4 s12"><span className="w3-left"><span className="w3-text-grey">Job : </span><span>{ item.job_title}</span></span></span>
                                    <span className="w3-col m1 s12"><span className="w3-left"><span className="w3-text-grey">ctc : </span><span>{ item.current_ctc || item.profile_current_ctc || <span className="w3-text-grey"> - </span>}</span></span></span>
                                    <span className="w3-col m1 s12"><span className="w3-left"><span className="w3-text-grey">ectc : </span><span>{ item.expected_ctc || item.expected_ctc_min || <span className="w3-text-grey"> - </span>}</span></span></span> 
                                   
                                </div>
                                <div className="w3-row w3-margin-top center-align">
                                    <p className="w3-left w3-text-grey">Assigned to: <span className="h7t-applicant-box h7t-assigned-to"><img className="h7t-applicant-img2 w3-circle" style={{ border: '1px solid #e7e7e7' }} alt="" src={ (item.assignedto_pic) ? item.assignedto_pic :"https://www.w3schools.com/w3css/img_avatar6.png" }/><span className="ml-5">{item.assigneeName || <span className="w3-text-grey">Unassigned</span>}</span></span></p>
                                    <span className="owner-box w3-text-grey">owner: <span className="h7t-applicant-box h7t-assigned-to"><img className="h7t-applicant-img2 w3-circle" style={{ border: '1px solid #e7e7e7' }} alt="" src={ (item.owner_pic) ? item.owner_pic :"https://www.w3schools.com/w3css/img_avatar6.png" }/><span className="ml-5">{item.owner || <span className="w3-text-grey"> - </span>}</span></span></span>
                                </div> 
                                <div className="applicant-overlay-card">
                                    <div className="w3-row applicant-overlay-content">
                                        <div className="w3-col w3-padding h7t-applicant-tooltips">  
                                            {item.profile_resume_file ? (
                                                <Tooltip title="Download">
                                                    <a href={item.profile_resume_file} target="_new" onClick={e => e.stopPropagation()} download>
                                                        <button
                                                            type="button"
                                                            className="w3-button icon-border w3-hover-blue w3-padding-small h7t-applicant-button"
                                                            onClick={e => e.stopPropagation()}
                                                        >
                                                            <span className="ion-android-download" />
                                                        </button>
                                                    </a>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="View Resume">
                                                    <button
                                                        type="button"
                                                        className="w3-button icon-border w3-hover-blue w3-padding-small h7t-applicant-button"
                                                        onClick={e => this.handleViewResume(e, item.resume_view_id)}
                                                    >
                                                        <span className="ion-eye" />
                                                    </button>
                                                </Tooltip>
                                                )}
                                            {item.applicant_profile_id && permission !== 'Panel Member' ? (
                                                <React.Fragment>
                                                    <Tooltip title="Edit">
                                                        <button
                                                            type="button"
                                                            className="w3-button icon-border w3-hover-teal w3-padding-small h7t-applicant-button"
                                                            onClick={e => this.handleEdit(e, item)}
                                                        >
                                                            <span className="ion-edit" />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <button
                                                            type="button"
                                                            className="w3-button icon-border w3-hover-red w3-padding-small h7t-applicant-button"
                                                            onClick={e => this.handleDelete(item, e)}
                                                        >
                                                            <span className="ion-trash-a" />
                                                        </button>
                                                    </Tooltip>
                                                </React.Fragment>
                                            ) : ''}
                                            <Tooltip title="View Application">
                                                    <button
                                                        type="button"
                                                        className="w3-button icon-border w3-hover-blue w3-padding-small h7t-applicant-button"
                                                        onClick={() => this.handleViewApplication(item)}
                                                    >
                                                        <span className="ion-arrow-right-c" />
                                                    </button>
                                                </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Grid>,
                )
            })
        }
        return (
            <div className="pt20 content-margin job-apps">
                <div className="w3-row">
                    <h3>Applicants</h3>
                </div>
                {(permission !== 'Panel Member')
                    ? (
                        <div className="w3-row">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2} md={2} onClick={e => this.handleEdit(e, 'Add')} className={`${disableAddProfile ? 'w3-disabled' : ''}`}>
                                    <Paper className="h7t-Paper overview-graph card-height h7t-table-data dashed-border h7t-cursor-pointer w3-margin-bottom" style={{ background: 'white' }}>
                                        <div className="w3-row">
                                            <AddIcon style={{ marginBottom: '0.2em' }} fontSize="large"/>
                                        </div>
                                        <p className="title">Add Profiles</p>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                        <div className="w3-row">
                                            <p><i className="material-icons">people</i></p>
                                        </div>
                                        <p className="title">Applicants</p>
                                        <p className="total-count">{applicationStatusList && applicationStatusList.totalApplicants}</p>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                        <p><i className="material-icons">more_horiz</i></p>
                                        <p className="title">In Progress</p>
                                        <p className="total-count">{applicationStatusList && applicationStatusList.inProgress}</p>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                        <p><i className="material-icons">thumb_up</i></p>
                                        <p className="title">Hired</p>
                                        <p className="total-count">{applicationStatusList && applicationStatusList.hired}</p>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                        <p><i className="material-icons">thumb_down</i></p>
                                        <p className="title">Rejected</p>
                                        <p className="total-count">{applicationStatusList && applicationStatusList.rejected}</p>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <Paper className="h7t-Paper overview-graph card-height h7t-table-data w3-margin-bottom">
                                        <p><i className="material-icons">done_all</i></p>
                                        <p className="title">Closed</p>
                                        <p className="total-count">{applicationStatusList && applicationStatusList.closed}</p>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <div className="w3-row">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <p><strong> Today</strong></p>
                                    {calendar && calendar.today && calendar.today.length ? (
                                        <Paper style={{ height: '110px' }} className="h7t-Paper w3-padding w3-center h7t-table-data h7t-cursor-pointer" onClick={() => this.handleOpenCalenderModal()}> 
                                            <p className="h7t-social-btn-align">Time : {calendar.today[0]['time']} </p>
                                            <p className="h7t-social-btn-align">Round : {calendar.today[0]['round']} </p>
                                            {calendar.today.length > 1 ? (
                                                <p style={{ textAlign: 'end', textDecoration: 'underline', marginBottom: '0px' }}>View More </p>
                                            ) : null}
                                        </Paper>
                                    ) : (
                                        <Paper style={{ height: '110px' }} className="h7t-Paper w3-padding w3-center h7t-table-data">
                                            <p className="h7t-social-btn-align">No Records,</p>
                                            <p className="h7t-social-btn-align">Let HR know</p>
                                        </Paper>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <p><strong> Tomorrow</strong></p>
                                    {calendar && calendar.tomorrow && calendar.tomorrow.length ? (
                                        <Paper style={{ height: '110px' }} className="h7t-Paper w3-padding w3-center h7t-table-data h7t-cursor-pointer" onClick={() => this.handleOpenCalenderModal()}>
                                            <p className="h7t-social-btn-align">Time : {calendar.tomorrow[0]['time']} </p>
                                            <p className="h7t-social-btn-align">Round : {calendar.tomorrow[0]['round']} </p>
                                            {calendar.tomorrow.length > 1 ? (
                                                <p style={{ textAlign: 'end', textDecoration: 'underline' }}>View More </p>
                                            ) : null}
                                        </Paper>
                                    ) : (
                                        <Paper style={{ height:'110px' }} className="h7t-Paper w3-padding w3-center h7t-table-data">
                                            <p className="h7t-social-btn-align">No Records,</p>
                                            <p className="h7t-social-btn-align">Let HR know</p>
                                        </Paper>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    )}
                <div className="w3-row w3-margin-top">
                    <div className="w3-col m10 s10 w3-margin-bottom">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.handlePagination();
                        }}
                        >
                            <SearchInputBase
                                placeholder="Search for job title or applicant name"
                                customClass="w3-margin-top"
                                onChange={e => this.searchFilter(e)}
                                value={filterBySearch}
                            />
                        </form>
                    </div>
                    <div className="w3-col m2 s2 w3-right">
                        <ApplicantFilters
                            filterOn={filterOn}
                            clearFilterValues={this.clearFilters}
                            handleJobFilters={this.handleJobFilters}
                        />
                    </div>
                </div>
                <div className="w3-row h7t-content w3-margin-top">
                    <Paper style={{ flexGrow: 1 }}>
                        <Tabs
                            value={tabNumber}
                            onChange={(e,newValue) => this.setState({ tabNumber:newValue })}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="simple tabs example"
                            className="tab-border"
                        >
                            <Tab label="List View" className="h7t-capitalize-text" {...a11yProps(0)} />
                            <Tab label="Table View" className="h7t-capitalize-text" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={tabNumber} index={0} >
                            <div className="w3-row w3-padding w3-padding-16" style={{ backgroundColor: '#EAEAEA' }}>
                                {noData || (<Grid container spacing={1}>
                                    {applicantData}
                               </Grid>)
                                } 
                                {(paginationDetails.last_page > 1)
                                    ? (
                                        <Pagination
                                            paginationDetails={paginationDetails}
                                            handlePagination={(currentPage) => this.handlePagination('', currentPage-1)}
                                        />
                                    ) : null}          
                            </div>
					    </TabPanel>
                        <TabPanel value={tabNumber} index={1} >
                            {noData || (
                                 <Paper>
                                    <TableContainer>
                                        <Table className="table-striped">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Applied On
                                                </TableCell>
                                                    <TableCell>
                                                        Name
                                                </TableCell>
                                                    <TableCell>
                                                        Ageing
                                                </TableCell>
                                                    <TableCell>
                                                        Job Role
                                                </TableCell>
                                                    <TableCell>
                                                        YOE
                                                </TableCell>
                                                    <TableCell>
                                                        Notice Period
                                                </TableCell>
                                                    <TableCell>
                                                        Step
                                                </TableCell>
                                                    <TableCell><Tooltip title="Current CTC (LPA)"><span>C-CTC</span></Tooltip></TableCell>
                                                    <TableCell><Tooltip title="Expected CTC (LPA)"><span>E-CTC</span></Tooltip></TableCell>
                                                    <TableCell>Rating</TableCell>
                                                    <TableCell>Scheduled Date</TableCell>
                                                    <TableCell>Assigned to</TableCell>
                                                    <TableCell>Owner</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableRow}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={paginationDetails.total}
                                        rowsPerPage={paginationDetails.per_page}
                                        page={paginationDetails.current_page - 1}
                                        onChangePage={this.handlePagination}
                                    />
                                </Paper>
                            )}
                        </TabPanel>
                    </Paper>
                </div>

                {previewResume
                    ? (
                        <Modal modalCls="w3-show" onClose={() => this.setState({ previewResume: false })}>
                            <div className="w3-container h-margin-top-35 h7t-margin-bottom-40" style={{ padding: '0px 40px' }}>
                                <PreviewResumeModal
                                    employer
                                    previewData={previewResume}
                                />
                            </div>
                        </Modal>
                    ) : null}

                {profileEditData && !disableAddProfile
                    ? (
                        <ProfileAddEditModal
                            profileStatus="on-going"
                            profileEditData={profileEditData}
                            currentPage={paginationDetails.current_page}
                            onModalClose={() => this.setState({ profileEditData: '' })}
                        />
                    ) : null}

                {deleteId
                    ? (
                        <ProfileDeleteModal
                            handleDelete={this.handleDelete}
                            deleteMessage="profile"
                            thisMsg={true}
                            onModalClose={() => this.setState({ deleteId: '' })}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    const allJobApplications = state.allJobApplications && state.allJobApplications.data;
    const applicationStatusList = state.applicationStatusList && state.applicationStatusList.data;
    const activeJobs = state && state.activeJobs;
    return {
        allJobApplications,
        paginationDetails: allJobApplications,
        currentUser,
        applicationStatusList,
        activeJobs,
    };
}
export default withRouter(connect(mapStateToProps, {
    getAllJobApplications,
    setAppMessage,
    getScheduleWorkflowCalendarList,
    getCurrentUser,
    postProfileDelete,
    getUserResumeViews,
    getUserDetails,
    getApplicationTitleList,
    getApplicationStatusList,
    getActiveJobs,
})(JobApplications));