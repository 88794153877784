import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withRouter,
    NavLink,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge, Drawer } from '@material-ui/core';
import {
    postResetCurrentCompanyOffice, getListInvite, getUnreadNotifications, resetState, postMarkAsReadNotifications,
} from '../../js/actions';
import CompanyOfficeForm from '../Dashboard/CompanyOfficeForm';
import UserNotifications from '../Notification/UserNotifications';
import { getScreenName } from './Constants';

class Header extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
        postResetCurrentCompanyOffice: PropTypes.func,
        listInvite: PropTypes.object,
        userUnreadNotifications: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            companyOffice: '',
            openDrawer: false,
        };
        this.handleSwitchUser = this.handleSwitchUser.bind(this);
        this.handleSideMenuOpen = this.handleSideMenuOpen.bind(this);
        this.handleCreateCompany = this.handleCreateCompany.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    componentDidMount() {
        this.props.getListInvite();
        this.props.getUnreadNotifications();
    }

    toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open === false) {
            const { userUnreadNotifications } = this.props;
            if (userUnreadNotifications && userUnreadNotifications.data && userUnreadNotifications.data.length) {
                this.props.postMarkAsReadNotifications().then(() => {
                    this.props.getUnreadNotifications();
                });
            }
        }
        this.setState({ openDrawer: open });
    }

    handleSwitchUser(userView) {
        const { currentUser } = this.props;
        let url = userView === 'seeker' ? '/s/job-dashboard' : '/e/dashboard';
        if (userView === 'company' && currentUser && currentUser.user && currentUser.user.current_company_id && currentUser.user.current_office_id) {
            const companyId = currentUser.user.current_company_id;
            const officeId = currentUser.user.current_office_id;
            url = {
                pathname: `/e/${companyId}/${officeId}/dashboard`,
                state: {
                    companyId,
                    officeId,
                },
            };
        }
        this.props.history.push(url);
        window.location.reload();
    }

    handleSideMenuOpen() {
        document.getElementById('side-bar-menu').style.width = '60%';
        document.getElementById('main').style.marginLeft = '60%';
        document.getElementById('main').style.marginRight = '-60%';
        document.getElementById('side-bar-menu').style.display = 'block';
        document.getElementById('page-overlay').style.display = 'block';
    }

    handleCreateCompany() {
        this.props.postResetCurrentCompanyOffice().then(() => {
            this.setState({ companyOffice: 'show' });
        });
    }

    render() {
        let navbarMenu = null;
        const {
            currentUser, currentUser: { companies }, listInvite,
            userUnreadNotifications, companyId, officeId, hideSideBarMenu,
        } = this.props;
        if (companyId) {
            window.COMPANY_ID = companyId;
            window.OFFICE_ID = officeId;
        }

        const { companyOffice, openDrawer } = this.state;
        const pathUrl = window.location.pathname;
        const companyList = [];
        const officeMenuSwitch = [];
        let currentOfficeName = null;
        let notificationCount = 0;
        let headerLogoUrl = '/';
        if (listInvite && listInvite.data && listInvite.data.length) {
            notificationCount += listInvite.data.length;
        }
        if (userUnreadNotifications && userUnreadNotifications.data && userUnreadNotifications.data.length) {
            notificationCount += userUnreadNotifications.data.length;
        }

        const notificationBell = (
            <div className="w3-bar-item" style={{ paddingTop: '2px' }}>
                <Badge badgeContent={notificationCount} color="secondary" onClick={this.toggleDrawer(true)}>
                    <i className={`${notificationCount ? 'ion-android-notifications ' : 'ion-android-notifications-none '} w3-xlarge h7t-cursor-pointer`} />
                </Badge>
            </div>
        );

        if (companyId) {
            headerLogoUrl = {
                pathname: hideSideBarMenu === true ? `/e/${companyId}/${officeId}/manage-company`: `/e/${companyId}/${officeId}/dashboard`,
                state: {
                    companyId,
                    officeId,
                }
            };
            if (companies && companies.length > 0) {
                companies.map((company) => {
                    if (company.office_list && company.office_list.length) {
                        let activeCompany = '';
                        if (company.company_id == companyId) {
                            activeCompany = 'h-active-row';
                            company.office_list.map((offList) => {
                                let active = '';
                                if (offList.id == officeId) {
                                    currentOfficeName = offList.name;
                                    active = 'h-active-row';
                                }
                                officeMenuSwitch.push(
                                    <NavLink
                                        className={`w3-bar-item w3-border-bottom ${active}`}
                                        to={{
                                            pathname: hideSideBarMenu === true ? `/e/${company.company_id}/${offList.id}/manage-company`: `/e/${company.company_id}/${offList.id}/dashboard`,
                                            state: {
                                                companyId: company.company_id,
                                                officeId: offList.id,
                                            }
                                        }}
                                    >
                                        {offList.name}
                                    </NavLink>,
                                );
                            });
                        }
                        companyList.push(
                            <NavLink
                                key={company.company_id}
                                className={`w3-bar-item ${activeCompany}`}
                                to={{
                                    pathname: hideSideBarMenu === true ? `/e/${company.company_id}/${company.office_list[0].id}/manage-company`: `/e/${company.company_id}/${company.office_list[0].id}/dashboard`,
                                    state: {
                                        companyId: company.company_id,
                                        officeId: company.office_list[0].id,
                                    }
                                }}
                            >
                                {company.company_name}
                            </NavLink>,
                        );
                    }
                });
            }
            navbarMenu = (
                <div className="w3-right">
                    {notificationBell}
                    {currentOfficeName ? (
                        <div className="w3-bar-item w3-dropdown-hover">
                            {currentOfficeName}
                            <i className="ion-arrow-down-b w3-padding-small" />
                            <div className="w3-dropdown-content w3-bar-block w3-card-4 w3-round">
                                {officeMenuSwitch}
                            </div>
                        </div>
                    ) : null}
                    <div className="w3-bar-item w3-dropdown-hover">
                        {(currentUser && currentUser.user) ? currentUser.user.display_name : ''}
                        <i className="ion-arrow-down-b w3-padding-small" />
                        <div className="w3-dropdown-content w3-bar-block w3-card-4 w3-round" style={{ right: 20 }}>
                            <a className={`w3-bar-item w3-border-bottom ${hideSideBarMenu===true ? 'w3-disabled' :''}`} disabled={hideSideBarMenu} onClick={() => this.handleCreateCompany()}>Create Company</a>
                            {companyList} 
                            <NavLink
                                className="w3-bar-item w3-border-bottom"
                                activeClassName="header-active-class"
                                to={{
                                    pathname: '/e/settings',
                                    state: {
                                        companyId,
                                        officeId
                                    }
                                }}
                            >Profile Settings</NavLink>
                            <button className="w3-bar-item h7t-cursor-pointer" onClick={() => {
                                localStorage.removeItem('accessToken');
                                this.props.resetState();
                                document.location.href = '/'; 
                            }}>Logout</button>
                        </div>
                    </div>
                </div>
            );
        } else if (pathUrl.indexOf('/s/') !== -1) {
            headerLogoUrl = "/s/job-dashboard";
            const screenName = getScreenName(currentUser.user);
            navbarMenu = (
                <div className="w3-right">
                    {notificationBell}
                    <div className="w3-bar-item w3-dropdown-hover">
                        Hi {currentUser ? currentUser.user.display_name : ''}
                        <i className="ion-arrow-down-b w3-padding-small" />
                        <div className="w3-dropdown-content w3-bar-block w3-card-4 w3-round" style={{ right: 20 }}>
                            <NavLink className="w3-bar-item" to={`/s/profile/${screenName}`}>Profile</NavLink>
                            {/* <NavLink className="w3-bar-item" to="/s/applications">My Applications</NavLink> */}
                            <NavLink className="w3-bar-item w3-border-bottom" activeClassName="header-active-class" to="/s/settings">Settings</NavLink>
                           
                            <button className="w3-bar-item h7t-cursor-pointer" onClick={() => {
                                localStorage.removeItem('accessToken');
                                this.props.resetState();
                                document.location.href = '/'; 
                            }}>Logout</button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="h7t-sticky-header">
                    <div className="w3-bar">
                        <button
                            type="button"
                            className="w3-bar-item h7t-hover-bar-icon w3-button w3-xlarge w3-hide-medium w3-hide-large"
                            onClick={e => this.handleSideMenuOpen(e)}
                        >&#9776;</button>
                        <div className="w3-padding">
                            <div className="h7t-logo-container">
                                <NavLink className="w3-bar-item" to={headerLogoUrl}>
                                    {/*<div
                                        style={{ fontSize: '18px' }}
                                    >
                                        <span><strong>Career</strong></span>
                                        <span className="w3-text-aqua">Connect</span>
                                    </div>*/}
                                    <img
                                        className="h7t-header-logo"
                                        src="https://assets.vegasonweb.com/hiring/logos/hiring_nxt_logo.png"
                                        alt=""
                                    />
                                </NavLink>
                            </div>
                            {navbarMenu}
                        </div>
                    </div>
                </div>
                <div className="w3-bar-block">
                    {companyOffice
                        ? (
                            <CompanyOfficeForm
                                onModalClose={() => this.setState({ companyOffice: '' })}
                            />
                        ) : null}
                </div>
                <Drawer anchor="right" open={openDrawer} onClose={this.toggleDrawer(false)}>
                    <div className="notification-drawer">
                        <UserNotifications closeDrawer={this.toggleDrawer(false)} />
                    </div>
                </Drawer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = (state && state.currentUser && state.currentUser.data) ? state.currentUser.data : (state && state.currentUser) ? state.currentUser : '';
    const hideSideBarMenu = state && state.hideSideBarMenu;
    return {
        currentUser,
        listInvite: state.listInvite,
        userUnreadNotifications: state.userUnreadNotifications,
        hideSideBarMenu: state.hideSideBarMenu,
    };
}

export default withRouter(connect(mapStateToProps, {
    postResetCurrentCompanyOffice,
    getListInvite,
    getUnreadNotifications,
    postMarkAsReadNotifications,
    resetState,
})(Header));
