import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import ResetPassword from '../components/Common/ResetPassword';
import Home from '../components/Home/HomePages';
import Employer from '../components/Home/Employer';
import JobSeekers from '../components/Home/JobSeekers';
import StickyInfoBar from './Common/StickyInfoBar';

const querystring = require('querystring');
import axios from 'axios';
import { LoadingIcon } from './Common/HelperComponents';
import WidgetLogin from './Widget/WidgetLogin';
import AboutUs from './Common/AboutUs';
import TermsOfService from './Common/TermsOfService';
import PrivacyPolicy from './Common/PrivacyPolicy';

const instance = axios.create({
    timeout: 1000,
    transformRequest: [function (data, headers) {
        const token = localStorage.getItem('accessToken');
        if(token) {
            headers['token'] = token;
        }
        return data;
      }],
    headers: {'X-Custom-Header': 'foobar'}
  });

class Login extends React.Component {
    componentDidMount() {
        const out = querystring.decode(this.props.location.search.replace('?',''));
        if (!out.code) {
            return;
        }
        let stateData = null;
        if (this.props.location.pathname.indexOf('google') !== -1) {
            stateData = JSON.parse(out.state);
        }
        axios.post('/v1/api/auth/connect', Object.assign({}, out, {
            provider: this.props.match.params.provider,
        })).then(resp => {
            if (resp && resp.data && resp.data.data && resp.data.data.token) {
                localStorage.setItem('accessToken', resp.data.data.token);
            }
            let redirectUrl = '/';
            const loginRedirectUrl = localStorage.getItem('loginRedirectUrl')
            if (loginRedirectUrl) {
                redirectUrl = loginRedirectUrl;
                localStorage.removeItem('loginRedirectUrl');
            }
            document.location.href = (stateData && stateData.redirectUrl) || redirectUrl;
        });
    }
    render() {
        return <LoadingIcon />
    }
}

class ApplicationSite extends Component {
    static propTypes = {
        appMessage: PropTypes.object,
    };
    componentDidMount() {
        const menuBar = document.getElementById('h7t_menu_bar');
        if (menuBar) {
            menuBar.style.display = 'none';
        }
    }
    render() {
        const { appMessage } = this.props;

        return (
            <div>
                <div className="w3-row content w3-white" style={{ minHeight: '100vh' }}>
                    {appMessage.messageText && <StickyInfoBar msgType={appMessage.messageType} msgText={appMessage.messageText} />}
                    <Switch>
                        <Route path="/home" component={Home} />
                        <Route path="/employers" component={Employer} /> 
                        <Route path="/oa/:provider" component={Login} />
                        <Route path="/oa-sdk/:provider" component={WidgetLogin}/>
                        <Route path="/job-seekers" component={JobSeekers} />
                        <Route path="/reset-password/:token" component={ResetPassword} />
                        <Route path="/about-us" component={AboutUs} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/Terms-of-service" component={TermsOfService} />
                        <Route path="/" component={Home} />
                    </Switch>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        appMessage: state.appMessage,
    };
}

export default connect(mapStateToProps, {
})(withRouter(ApplicationSite));

