import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { LoadingIcon } from './HelperComponents';
import { getDiscoverResumes, getDiscoverCrawlerProfiles, postDiscoverProfiles } from '../../js/actions';
import Pagination from './Pagination';
import SearchInputBase from './MaterialUI/SearchInputBase';
import { sampleProfileData } from '../Common/samplaData';
import { channelDomainUrl } from '../Common/Constants';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination, Grid, Tooltip
} from '@material-ui/core';
import DiscoverFilters from './DiscoverFilters';

const DiscoverResumeRow = ({
    item,
    getDesiredProfileSection,
}) => {
    const profileInfo = getDesiredProfileSection({ type: 'info', profileSections: item.profileSections });
    const skillInfo = getDesiredProfileSection({ type: 'skills', profileSections: item.profileSections });
    let skills = '-';
    if (skillInfo) {
        skills = '';
        skillInfo.data.map((skill, idx) => {
            let separator = ', ';
            if (idx === skillInfo.data.length - 1) {
                separator = '';
            }
            skills += `${skill.name}${separator}`;
        });
    }
    const YOE = item.effectiveDate ? moment(item.effectiveDate) : '-';
    return (
        <TableRow>
            <TableCell>{item.personInfo.name}</TableCell>
            <TableCell>{profileInfo ? profileInfo.data.designation : '-'}</TableCell>
            <TableCell>{skills}</TableCell>
            <TableCell>{YOE}</TableCell>
            <TableCell>{item.workPreference.expectedCTC}</TableCell>
            <TableCell>{item.employer.channelName ? item.employer.channelName : '-'}</TableCell>
        </TableRow>
    );
}

const DiscoverProfileRow = ({
    item,
}) => {
    const channelName = Object.keys(item)[0];
    const { about, topTags, profileUrl } = item[channelName];
    const channelDomain = channelDomainUrl[channelName];
    let skills = '-';
    if (topTags) {
        skills = '';
        topTags.map((tag, idx) => {
            let separator = ', ';
            if (idx === topTags.length - 1) {
                separator = '';
            }
            skills += `${tag.name}${separator}`;
        });
    }
    return (
        <TableRow>
            <TableCell>
                {about.userName
                    ? profileUrl
                        ? (
                            <a
                                className="h7t-capitalize-text"
                                href={`${channelDomain}${profileUrl}`}
                                target="_blank"
                            >{about.userName}</a>
                        ) : about.userName
                    : '-'}
            </TableCell>
            <TableCell>-</TableCell>
            <TableCell>{skills}</TableCell>
            <TableCell>{about.userExperience ? about.userExperience : '-'}</TableCell>
            <TableCell>-</TableCell>
            <TableCell>
                {profileUrl ? (
                    <a
                        className="h7t-hyperlink-color"
                        href={`${channelDomain}${profileUrl}`}
                        target="_blank"
                    >Go to profile</a>
                ) : '-'}
            </TableCell>
            <TableCell>{channelName}</TableCell>
        </TableRow>
    );
}

class Discover extends Component {
    constructor(args) {
        super(args);
        this.state = {
            searchKeyword: '',
            searchAll: true,
            progressBar: false,
            switchPage: false,
            filterOn: {
                location: '',
                yoeRange: '',
                skills: '',
                ctc_range: '',
                channel: '',
            },
            searchActive: false,
            error: '',
        };
        this.clearFilters = this.clearFilters.bind(this);
        this.handleSearchResume = this.handleSearchResume.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSearchKeyword = this.handleSearchKeyword.bind(this);
        this.handleDiscoverFilters = this.handleDiscoverFilters.bind(this);
        this.handleDiscoverCrawlerProfiles = this.handleDiscoverCrawlerProfiles.bind(this);
        this.handleDiscoverProfiles = this.handleDiscoverProfiles.bind(this);
        this.getDesiredProfileSection = this.getDesiredProfileSection.bind(this);
    }

    componentDidMount() {
        this.handleDiscoverProfiles();
    }

    handleSearchKeyword(e) {
        const searchKeyword = e.target.value;
        this.setState({ searchKeyword, error: '' });
    }

    handleSearchResume() {
        const { searchKeyword } = this.state;
        this.setState({
            progressBar: true,
        });
        this.props.getDiscoverResumes(searchKeyword, 1).then((resp) => {
            if (resp && resp.data) {
                this.setState({ progressBar: false });
            }
        });
    }

    handleDiscoverFilters(filterOn) {
        this.setState({
            filterOn,
            error: ''
        }, () => {
            this.handleDiscoverProfiles();
        });
    }

    clearFilters(filterOn) {
        this.setState({
            filterOn,
            error: ''
        }, () => {
            this.handleDiscoverProfiles();
        })
    }

    validateData() {
        let isValid = true;
        let { searchKeyword, error } = this.state;
        if (!searchKeyword) {
            isValid = false;
            error = 'This field is mandatory';
        }

        if (error) {
            this.setState({ error });
        }

        return isValid;
    }

    handleDiscoverProfiles(e, page = 0) {
        const { searchKeyword, filterOn } = this.state;
        // Enable below code to validate search string.
        // if (!this.validateData()) {
        //     return;
        // }
        this.setState({
            progressBar: true,
            searchActive: true,
        });
        let searchData = JSON.parse(JSON.stringify(filterOn));
        searchData.searchKeyword = searchKeyword;
        searchData.searchAll = !(Object.keys(searchData).some(key => searchData[key] !== ''));
        this.props.postDiscoverProfiles({ search: searchData, page: page + 1, size: 100 }).then(() => {
            this.setState({ progressBar: false });
        });
    }

    handleDiscoverCrawlerProfiles(e, page = 0) {
        const { searchKeyword } = this.state;
        this.setState({
            progressBar: true,
        });
        this.props.getDiscoverCrawlerProfiles({ q: searchKeyword, page: page + 1 }).then(() => {
            this.setState({ progressBar: false });
        });
    }

    handlePagination(page) {
        const { searchKeyword } = this.state;
        this.setState({ switchPage: true });
        this.props.getDiscoverResumes(searchKeyword, page).then((resp) => {
            if (resp && resp.data && resp.data.success) {
                this.setState({ switchPage: false });
            }
        });
    }

    getDesiredProfileSection(profileData) {
        const { type, profileSections } = profileData;
        let desiredProfileSection = null;
        profileSections.some(profileItem => {
            if (profileItem.type === type) {
                desiredProfileSection = profileItem;
                return true;
            }
            return false;
        });
        return desiredProfileSection;
    }

    render() {
        const { discoverResume, discoverCrawlerProfiles, discoverProfiles } = this.props;
        const { searchKeyword, progressBar, switchPage, searchActive, error } = this.state;
        let selectedResumes = [];
        let discoverContent = null;
        let tableRow = [];
        let discoverTableRow = [];


        if (progressBar || switchPage) {
            discoverContent = <LoadingIcon />;
        } else if (discoverResume && discoverResume.data) {
            discoverResume.data.map((resume, idx) => {
                const details = [];
                const length = resume._source.experience.length > 3 ? 3 : resume._source.experience.length;
                for (let i = 0; i < length; i++) {
                    const exp = resume._source.experience[i];
                    details.push(<div>{exp.title} - <span className="w3-text-grey">{exp.company}</span></div>);
                }

                if (resume._source.education.length > 0) {
                    details.push(<div>{resume._source.education[0].school ? resume._source.education[0].school + ' -' : ''} <span className="w3-text-grey">{resume._source.education[0].degree}</span></div>);
                }

                discoverContent.push(
                    <div key={idx} className="w3-container w3-margin">
                        <Link to={`e/view-resume/${resume._id}`} target="_blank">
                            <h5 className="w3-text-indigo">{resume._source.title ? resume._source.title : searchKeyword}</h5>
                        </Link>
                        {details}
                    </div>,
                );
            });
        } else if (discoverProfiles && discoverProfiles.data && discoverProfiles.data.length) {
            discoverProfiles.data.map((item, idx) => {
                discoverTableRow.push(
                    <DiscoverProfileRow
                        key={`id_${idx}`}
                        item={item._source}
                    />
                );
            });
            discoverContent = (
                <Paper>
                    <TableContainer>
                        <Table className="table-striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Candidate Name</TableCell>
                                    <TableCell>Current Title</TableCell>
                                    <TableCell>Skills</TableCell>
                                    <TableCell>YOE</TableCell>
                                    <TableCell>Excepted CTC</TableCell>
                                    <TableCell>Profile Url</TableCell>
                                    <TableCell>Channel Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {tableRow} */}
                                {discoverTableRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={discoverProfiles.total}
                        rowsPerPage={discoverProfiles.per_page}
                        page={discoverProfiles.current_page - 1}
                        onChangePage={this.handleDiscoverProfiles}
                    />
                </Paper>
            );
        } else {
            discoverContent = <h5 className="w3-center">
                {searchActive && discoverProfiles && discoverProfiles.data.length === 0 ? 'No matching profiles' : 'Discover Profiles'}
            </h5>;
        }

        // Code snippet to show sample profile data

        // discoverContent = sampleProfileData.map((item, idx) => {
        //     tableRow.push(
        //         <DiscoverResumeRow
        //             key={`id_${idx}`}
        //             item={item}
        //             getDesiredProfileSection={this.getDesiredProfileSection}
        //         />
        //     )
        // });

        return (
            <div className="content-margin">
                <div className="w3-row-padding">
                    <div className="w3-col m10 s10">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            this.handleDiscoverProfiles();
                        }}>
                            <SearchInputBase
                                placeholder="Search for name, skills or channel"
                                customClass="w3-margin-top"
                                onChange={this.handleSearchKeyword}
                                value={searchKeyword}
                                error={error}
                            />
                        </form>
                    </div>
                    <div className="w3-col m2 s2">
                        <DiscoverFilters
                            handleDiscoverFilters={this.handleDiscoverFilters}
                            clearFilterValues={this.clearFilters}
                        />
                    </div>
                </div>
                {/* {selectedResumes} */}
                <div className="w3-margin-top w3-margin-bottom">
                    {discoverContent}
                </div>
                {(discoverResume && discoverResume.last_page > 1 && !progressBar && !switchPage)
                    ? (
                        <Pagination
                            paginationDetails={discoverResume}
                            handlePagination={this.handlePagination}
                        />
                    ) : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const discoverResume = state.discoverResume && state.discoverResume.data;
    const discoverProfiles = state.discoverProfiles && state.discoverProfiles.data;
    const discoverCrawlerProfiles = state.discoverCrawlerProfiles && state.discoverCrawlerProfiles.data;
    return {
        discoverResume,
        discoverProfiles,
        discoverCrawlerProfiles,
    };
}

export default connect(mapStateToProps, {
    getDiscoverResumes,
    postDiscoverProfiles,
    getDiscoverCrawlerProfiles,
})(Discover);
