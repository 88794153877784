import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { Button, Paper, Tabs, Tab, Typography, Box, Tooltip } from '@material-ui/core';
import {
	getCurrentOfficeList,
	getCurrentCompany,
	postDeleteOffice,
	setAppMessage,
	getCurrentUser,
	postUpdateSortOffice,
} from '../../js/actions';
import CompanyForm from './CompanyForm';
import UserRole from '../Role/UserRole';
import { LoadingIcon } from '../Common/HelperComponents';
import OfficeListEditModal from './OfficeListEditModal';
import Modal from '../Common/Modal';
import OfficeDeleteModal from '../Common/DeleteRecordModal';
import PlansAndPackages from './PlansAndPackages';
import { getPermissions } from  '../Common/Constants';

const SortableItem = SortableElement(({
	value,
	currentUser,
	disabled,
	handleDelete,
	handleOfficeEdit,
}) => {
	const commaLine2 = (value.address_line_2 && value.address_line_3) ? ', ' : null;
	const commaLine1 = (value.address_line_1 && value.address_line_2) ? ', ' : null;
	const commaCity = (value.cityName && value.stateName) ? ', ' : null;
	const commaState = (value.countryName && value.stateName) ? ', ' : null;
	const deleteData = {
		id: value.id,
		disabled,
	};
	
	const permission = getPermissions(currentUser);
	return (
		<div className="w3-margin-top w3-margin-bottom h7t-table-data h7t-hover-move-arrow w3-col m6">
		 	<Paper style={{ flexGrow: 1 }} className="w3-padding"> 
				<div className="w3-row">
					<div className="w3-col m6">
						<strong>{value.name}</strong><br />
					</div>
					<div className="w3-col m6">
						{((value.package_status == 'ACTIVE') && permission == 'Management')
						? (
							<div className="w3-right-align">
								<Tooltip title="Edit">
									<button
										type="button"
										className="w3-button w3-padding-small w3-hover-teal icon-border"
										onClick={() => handleOfficeEdit(value)}
									>
										<span className="ion-edit" aria-hidden="true" />
									</button>
								</Tooltip>
								<Tooltip title="Delete">
									<button
										type="button"
										className={`w3-button w3-padding-small w3-hover-red icon-border ${disabled}`}
										onClick={() => handleDelete(deleteData)}
									>
										<span className="ion-trash-a" aria-hidden="true" />
									</button>
								</Tooltip>
							</div>
						) : null}
					</div>
				</div>

				{value.address_line_1 || ''}{commaLine1}{value.address_line_2 || ''}{commaLine2}{value.address_line_3 || ''}
				<div>{value.cityName || ''}{commaCity}{value.stateName || ''}{commaState}{value.countryName || ''}</div>
				<div>{value.pin_code ? `PIN : ${value.pin_code}` : ''}</div>
				<div>{value.phone_number ? `Phone : ${value.phone_number}` : ''}</div> 
			 </Paper> 
		</div>
	);
});

const SortableList = SortableContainer(({
	items,
	currentUser,
	disabled,
	handleOfficeEdit,
	handleDelete,
}) => {
	return (
		<div className="w3-row-padding" style={{ display:'flex',flexWrap:'wrap' }}>
			{items.map((value, index) => (
				<SortableItem
					key={`item-${index}`}
					index={index}
					value={value}
					currentUser={currentUser}
					disabled={disabled}
					handleOfficeEdit={handleOfficeEdit}
					handleDelete={handleDelete}
				/>
			))}
		</div>
	);
});

const TabPanel = ({
	children, 
	value, 
	index, 
	...other
}) => {
	return (
	  <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
      	aria-labelledby={`simple-tab-${index}`}
      	{...other}
	  >
		{value === index && <Box p={3}>{children}</Box>}
	  </Typography>
	);
  };
  
  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
  };

	function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }

class ManageCompany extends Component {
	static propTypes = {
		params: PropTypes.object,
		currentUser: PropTypes.object,
		currentOfficeList: PropTypes.object,
		currentCompany: PropTypes.object,
		getCurrentOfficeList: PropTypes.func,
		getCurrentCompany: PropTypes.func,
		postDeleteOffice: PropTypes.func,
		setAppMessage: PropTypes.func,
		getCurrentUser: PropTypes.func,
	}

	constructor(args) {
		super(args);

		this.state = {
			deleteId: '',
			officeEditData: '',
			enableSaveBtn: false,
			items: [],
			value:0,
		};
		this.handleOfficeEdit = this.handleOfficeEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.onSortEnd = this.onSortEnd.bind(this);
		this.saveSortOrder = this.saveSortOrder.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const { currentOfficeList, currentCompany } = this.props;
		if (!currentOfficeList) {
			this.props.getCurrentOfficeList().then((resp) => {
				if (resp && resp.data) {
					this.setState({
						items: resp.data.data,
					});
				}
			});
		}
		this.props.getCurrentCompany();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.currentOfficeList && (nextProps.currentOfficeList !== this.state.items)) {
			this.setState({
				items: nextProps.currentOfficeList.data,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.officeId !== this.props.match.params.officeId) {
			this.props.getCurrentOfficeList();
			this.props.getCurrentCompany();
		}
	}

	onSortEnd({ oldIndex, newIndex }) {
		if (oldIndex !== newIndex) {
			let { items, enableSaveBtn } = this.state;
			if (!enableSaveBtn) {
				enableSaveBtn = !enableSaveBtn;
			}
			this.setState({
				enableSaveBtn,
				items: arrayMove(items, oldIndex, newIndex),
			});
		}
	}

	handleOfficeEdit(data) {
		let dataVal = data;
		if (dataVal === 'Add') {
			dataVal = {
				name: '',
				address_line_1: '',
				address_line_2: '',
				address_line_3: '',
				latitude: '',
				longitude: '',
				country: '',
				state: '',
				city: '',
				pin_code: '',
				phone_number: '',
				time_zone: '',
				currency_code: '',
				preference_order: '',
			};
		}
		this.setState({ officeEditData: dataVal });
	}

	handleDelete(data) {
		if (data.disabled) {
			return;
		}

		if (data === true) {
			const { deleteId } = this.state;
			this.props.postDeleteOffice(deleteId).then((resp) => {
				const response = resp && resp.data;
				if (response && response.success) {
					this.setState({ deleteId: '' })
					if (response.data.id == OFFICE_ID) {
						const { currentOfficeList, location: { state } } = this.props;
						let officeId = null;
						let redirectUrl = '/';
						if (currentOfficeList.data.length) {
							currentOfficeList.data.some(officeItem => {
								if (officeItem.id !== OFFICE_ID) {
									officeId = officeItem.id;
									return true;
								}
								return false;
							});
						}
						if (officeId) {
							redirectUrl = {
								pathname: `/e/${state.companyId}/${officeId}/manage-company`,
								state: {
									companyId: state.companyId,
									officeId,
								}
							}
						}
						this.props.history.push(redirectUrl);
						this.props.getCurrentUser();
					}
					this.props.getCurrentOfficeList();
					this.props.setAppMessage('success', 'Office deleted successfully!');
					$('.close-button').click();
				}
			});
		} else {
			this.setState({ deleteId: data.id });
		}
	}

	saveSortOrder() {
		const { items } = this.state;
		const sortedList = {
			finalList: [],
		};
		items.map((sort, idx) => {
			if (sort.preference_order !== idx + 1) {
				sort.preference_order = idx + 1;
				sortedList.finalList.push(sort);
			}
		});

		this.props.postUpdateSortOffice(sortedList).then((resp) => {
			if (resp.data.success) {
				this.props.setAppMessage('success', 'Office order saved successfully!');
				this.setState({ enableSaveBtn: false });
				this.props.getCurrentOfficeList();
			}
		});
	}

	handleChange(event, newValue) {
		const { value } = this.state;
		this.setState({ value:newValue });
	}

	render() {
		const { currentOfficeList, currentCompany, currentUser, hideSideBarMenu } = this.props;
		const {
			officeEditData, deleteId, items, enableSaveBtn, value,
		} = this.state;
		let officeList = [];
		let noteMsg = '';
		if( hideSideBarMenu === true && currentCompany && currentCompany.data && currentCompany.data.allowed_domains === null ) {
			noteMsg = "Note: Please fill all required fields";
		} else if (hideSideBarMenu === true && currentOfficeList && currentOfficeList.data.some(ofc => ofc.city === null)) {
			noteMsg = "Note: Please update office details to enable the menu options";
		} else if (hideSideBarMenu === true) {
			noteMsg = "Note: Please update details to enable the menu options";
		}

		const note = (
			<p className=" w3-padding w3-text-red w3-margin-top" style={{ marginLeft:'6px' }}>
				{noteMsg}
			</p>
		);
		if (!currentOfficeList || !currentOfficeList.data) {
			return <LoadingIcon />;
		}

		if (items && items.length > 0) {
			const disabled = items.length === 1 ? 'w3-disabled' : '';
			if (enableSaveBtn) {
				officeList.push(
					<div className="w3-container">
						<Button
							className="w3-margin-top w3-padding-small h7t-primary-btn"
							onClick={this.saveSortOrder}
						>Save</Button>
					</div>,
				);
			}
			officeList.push(
				<SortableList
					shouldUseDragHandle={true}
					axis="xy"
					items={items}
					distance={5}
					onSortEnd={this.onSortEnd}
					disabled={disabled}
					currentUser={currentUser}
					handleOfficeEdit={this.handleOfficeEdit}
					handleDelete={this.handleDelete}
				/>,
			);
		} else {
			officeList = <div className="w3-center">No office to display!</div>;
		}

		return (
			<div className="w3-row content-margin w3-margin-top">
				<Paper style={{ flexGrow: 1 }}>
					<Tabs
						value={value}
						onChange={this.handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
						aria-label="simple tabs example"
						className="tab-border"
					>
						<Tab label="Company Information" className="h7t-capitalize-text" {...a11yProps(0)} />
						<Tab label="User Roles" className={`h7t-capitalize-text ${hideSideBarMenu === true ? 'w3-disabled': ''} `} disabled ={hideSideBarMenu} {...a11yProps(1)} />
					</Tabs>
					<TabPanel value={value} index={0} >
						<div className="w3-row">
							<div className="w3-col m12 h7t-table-data w3-margin-bottom">
										{note}
										<CompanyForm companyData={currentCompany} />
							</div>
							<div className="w3-col h7t-table-data w3-margin-bottom">
								<div className="w3-margin">
									<div className="w3-container">
										<span className="h7t-modal-subtitle w3-text-black"><strong>Office(s)</strong></span>
										{(currentCompany && currentCompany.data && currentCompany.data.owner_user_id === currentUser.user.id)
												? (
													<Button
														variant="contained"
														className={`w3-right h7t-primary-btn ${hideSideBarMenu ? 'w3-disabled' : ''}`}
														onClick={() => this.handleOfficeEdit('Add')}
														disabled={hideSideBarMenu}
													>Add Office
                            						</Button>
												) : null
											}
									</div>
								</div>
								<div style={{ marginLeft: '32px', marginRight: '32px' }}>
									{officeList}
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel value={value} index={1} >
						<UserRole />
					</TabPanel>
				</Paper>

					
				{/* <div className="w3-col m12">
                    <PlansAndPackages />
                </div> */}

				{officeEditData
					? (
						<Modal modalCls="w3-show" onClose={() => this.setState({ officeEditData: '' })}>
							<OfficeListEditModal
								officeEditData={officeEditData}
								onModalClose={() => this.setState({ officeEditData: '' })}
							/>
						</Modal>
					) : null}

				{(deleteId)
					? (
						<OfficeDeleteModal
							handleDelete={this.handleDelete.bind(this)}
							deleteMessage="office"
							thisMsg={true}
							onModalClose={() => this.setState({ deleteId: '' })}
						/>
					) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
	const hideSideBarMenu = state && state.hideSideBarMenu;
	return {
		currentOfficeList: state.currentOfficeList,
		currentCompany: state.currentCompany,
		currentUser,
		hideSideBarMenu,
	};
}
export default connect(mapStateToProps, {
	getCurrentOfficeList,
	getCurrentCompany,
	postDeleteOffice,
	setAppMessage,
	getCurrentUser,
	postUpdateSortOffice,
})(ManageCompany);
