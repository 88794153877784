import React, { Component } from 'react';
import {
    Button, TableContainer, Table,
    TableHead, TableRow, Tooltip,
    TableCell, TableBody, Paper, TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import {
    getWorkflowTemplateList,
    getCurrentWorkflows,
    getWorkflowState,
    postWorkflowDelete,
    postWorkflowStateDelete,
    setAppMessage,
    getManage,
} from '../../js/actions';
import WorkflowAddEditModal from './ModalAddEditWorkflow';
import WorkflowDeleteModal from '../Common/DeleteRecordModal';
import { LoadingIcon } from '../Common/HelperComponents';
import WorkflowTemplateModal from './WorkflowTemplateModal';

const Workflow = ({
    item,
    handleAddEditProcess,
    handleDelete,
    params,
}) => {
    const disabledCls = item.count_jobs_used ? 'w3-disabled' : '';
    return (
        <TableRow key={item.id}>
            <TableCell scope="row">{item.name}</TableCell>
            <TableCell><div className="h7t-text-muted">{`${item.department || ''}`}</div></TableCell>
            <TableCell><div className="h7t-text-muted">{moment(item.created_at).format('MMM Do, YYYY')}</div></TableCell>
            <TableCell><div className="h7t-text-muted">{item.display_name}</div></TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
                <div className="w3-bar">
                    <Tooltip title="Edit">
                        <button
                            type="button"
                            className="w3-button w3-hover-teal w3-padding-small icon-border"
                            onClick={() => handleAddEditProcess(item)}
                        >
                            <span className="ion-edit" aria-hidden="true" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <button
                            type="button"
                            className={`w3-button w3-padding-small w3-hover-red icon-border`}
                            disabled={disabledCls}
                            onClick={() => handleDelete(item)}
                        >
                            <span className="ion-trash-a" aria-hidden="true" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Preview Workflow">
                        <span>
                            <NavLink
                                className="w3-button w3-padding-small w3-hover-blue icon-border"
                                to={{
                                    pathname: `/e/${params.companyId}/${params.officeId}/hiring-workflow/${item.id}`,
                                    state: {
                                        companyId: params.companyId,
                                        officeId: params.officeId,
                                    }
                                }}
                            >
                                <span className="icon ion-eye" aria-hidden="true" />
                            </NavLink>
                        </span>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    );
};

Workflow.propTypes = {
    item: PropTypes.object,
    selected: PropTypes.number,
    handleRow: PropTypes.func,
    handleAddEditProcess: PropTypes.func,
    handleDelete: PropTypes.func,
    params: PropTypes.object,
};

class ManageWorkflow extends Component {
    static propTypes = {
        params: PropTypes.object,
        userRole: PropTypes.object,
        getManage: PropTypes.func,
        setAppMessage: PropTypes.func,
        workflowState: PropTypes.object,
        currentWorkflows: PropTypes.object,
        getWorkflowState: PropTypes.func,
        postWorkflowDelete: PropTypes.func,
        getCurrentWorkflows: PropTypes.func,
        workflowTemplateList: PropTypes.object,
        getWorkflowTemplateList: PropTypes.func,
        postWorkflowStateDelete: PropTypes.func,
        paginationDetails: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            selected: null,
            deleteId: '',
            hiringEditData: '',
            showWorkflowTemplate: false,
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddEditProcess = this.handleAddEditProcess.bind(this);
        this.handleRow = this.handleRow.bind(this);
        this.handleWorkflowTemplate = this.handleWorkflowTemplate.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        const { currentWorkflows, workflowTemplateList } = this.props;
        if (!currentWorkflows) {
            this.props.getCurrentWorkflows(1);
        }
        if (!workflowTemplateList) {
            this.props.getWorkflowTemplateList();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentWorkflows
            && nextProps.currentWorkflows.data
            && nextProps.currentWorkflows.data.length
            && !this.state.selected) {
            this.setState({
                selected: nextProps.currentWorkflows.data[0].id,
            });
            this.props.getWorkflowState(nextProps.currentWorkflows.data[0].id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.officeId != this.props.match.params.officeId) {
            this.props.getCurrentWorkflows().then((resp) => {
                const response = resp && resp.data;
                if (response && response.data && response.data.length) {
                    this.setState({
                        selected: response.data[0].id,
                    });
                    this.props.getWorkflowState(response.data[0].id);
                }
            });
            this.props.getWorkflowTemplateList();
        }
    }

    handleAddEditProcess(data) {
        let dataVal = data;
        const { userRole } = this.props;
        if (!userRole) {
            this.props.getManage();
        }
        if (dataVal === 'Add') {
            dataVal = {
                name: '',
                description: '',
            };
        }
        this.setState({ hiringEditData: dataVal });
    }

    updateResponse(response) {
        if (response.success) {
            this.props.setAppMessage('success', 'Record deleted successfully!');
            $('.close-button').click();
        }
    }

    handleDelete(data, callback) {
        if (data.count_jobs_used) {
            this.props.setAppMessage('fail', 'Unable to delete workflow. Workflow in use with a Job');
            return;
        }

        if (data === true) {
            const { deleteId } = this.state;
            const { currentWorkflows } = this.props;
            if (deleteId.workflow_id && deleteId.state_name) {
                deleteId.workflow_state_id = deleteId.id;
                this.props.postWorkflowStateDelete(deleteId).then((response) => {
                    this.updateResponse(response.data);
                    this.props.getWorkflowState(deleteId.workflow_id);
                });
            } else {
                deleteId.workflow_id = deleteId.id;
                const { paginationDetails } = this.props;
                this.props.postWorkflowDelete(deleteId).then((response) => {
                    if (response.data && response.data.success) {
                        this.updateResponse(response.data);
                        let currentPage = paginationDetails.current_page;
                        if (paginationDetails && paginationDetails.data && paginationDetails.data.length === 1 && paginationDetails.current_page > 1) {
                            currentPage = paginationDetails.current_page - 1;
                        }
                        this.props.getCurrentWorkflows(currentPage);
                        this.props.getWorkflowState(currentWorkflows.data[0].id);
                        this.setState({ selected: currentWorkflows.data[0].id });
                    } else {
                        callback && callback(response.data);
                    }
                });
            }
        } else {
            this.setState({ deleteId: data });
        }
    }

    handleRow(item) {
        const { selected } = this.state;
        if (selected === item.id) {
            return;
        }
        this.setState({
            selected: item.id,
        }, () => {
            this.props.getWorkflowState(item.id);
        });
    }

    handleWorkflowTemplate() {
        const { userRole } = this.props;
        const { showWorkflowTemplate } = this.state;
        if (!userRole) {
            this.props.getManage();
        }
        this.setState({
            showWorkflowTemplate: !showWorkflowTemplate,
        });
    }

    handlePagination(e, page) {
        this.props.getCurrentWorkflows(page + 1);
    }

    render() {
        let list = [];
        let noList = null;
        const {
            currentWorkflows, userRole, workflowTemplateList, match:{params}, paginationDetails,
        } = this.props;
        const {
            hiringEditData, deleteId, selected, showWorkflowTemplate,
        } = this.state;

        if (!currentWorkflows || !currentWorkflows.data) {
            return <LoadingIcon />;
        }

        if (currentWorkflows.data.length) {
            currentWorkflows.data.forEach((item, idx) => {
                list.push(<Workflow
                    key={`idx_${idx}`}
                    item={item}
                    handleAddEditProcess={this.handleAddEditProcess}
                    handleDelete={this.handleDelete}
                    handleRow={this.handleRow}
                    selected={selected}
                    params={params}
                />);
            });
        } else {
            noList = (
                <div className="h7t-table-data">
                    <div className="w3-center w3-large w3-padding-24">
                        No hiring workflows to display!
                    </div>
                </div>
            );
        }

        return (
            <div className="content-margin w3-row">
                <div className="w3-row w3-margin-top w3-margin-bottom">
                    <div className="w3-col m6 s12">
                        <h3 className="h-margin-top-0">Hiring Workflows</h3>
                    </div>
                    <div className="w3-col m6 s12">
                        <div className="w3-row">
                            <div className="w3-col-m6 s12">
                                <Button
                                    variant="contained"
                                    className="h7t-secondary-btn w3-right w3-margin-left w3-margin-bottom"
                                    onClick={() => this.handleAddEditProcess('Add')}
                                >
                                    Create Hiring Workflow
                                </Button>
                            </div>
                            <div className="w3-col-m6 s12">
                                <Button
                                    variant="contained"
                                    className="h7t-primary-btn w3-right"
                                    onClick={() => this.handleWorkflowTemplate()}
                                >
                                    Select Workflow from Template
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w3-col m12">
                    <div className="h-margin-top-0">
                        {noList ? noList : (
                            <Paper>
                                <TableContainer>
                                    <Table className="table-striped">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Department</TableCell>
                                                <TableCell>Created On</TableCell>
                                                <TableCell>Created By</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                  <TablePagination
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={paginationDetails.total}
                                    rowsPerPage={paginationDetails.per_page}
                                    page={paginationDetails.current_page - 1}
                                    onChangePage={this.handlePagination}
                                />  
                            </Paper>
                        )}
                    </div>
                </div>
                {  hiringEditData && userRole && (
                        <WorkflowAddEditModal
                            hiringEditData={hiringEditData}
                            handleRow={this.handleRow}
                            templateList={workflowTemplateList}
                            userRole={userRole}
                            currentPage={paginationDetails.current_page}
                            onModalClose={() => this.setState({ hiringEditData: '' })}
                        />
                ) }
                { deleteId && (
                    <WorkflowDeleteModal
                        handleDelete={this.handleDelete}
                        deleteMessage="workflow"
                        thisMsg={true}
                        onModalClose={() => this.setState({ deleteId: '' })}
                    />
                ) }
                { showWorkflowTemplate && userRole && (
                    <WorkflowTemplateModal
                        title="Workflow Templates"
                        handleRow={this.handleRow}
                        templateList={workflowTemplateList}
                        userRole={userRole}
                        onModalClose={() => this.setState({ showWorkflowTemplate: !showWorkflowTemplate })}
                    />
                ) }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentWorkflows = state.currentWorkflows && state.currentWorkflows.data;
    return {
        workflowTemplateList: state.workflowTemplateList,
        currentWorkflows,
        paginationDetails: currentWorkflows,
        workflowState: state.workflowState,
        userRole: state.userRole,
    };
}
export default connect(mapStateToProps, {
    getCurrentWorkflows,
    getWorkflowState,
    getWorkflowTemplateList,
    postWorkflowDelete,
    postWorkflowStateDelete,
    setAppMessage,
    getManage,
})(ManageWorkflow);
