import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { Button } from '@material-ui/core';

class ConfirmationModal extends Component {

    render() {
        const { onModalClose, title, modalContent, confirmBtn, cancelBtn, handleCancel, handleConfirm } = this.props;
        return (
            <Modal modalCls="w3-show" onClose={onModalClose} title={title} widthCls="chp-small-dlg">
                <div className="w3-container">
                    {modalContent}
                </div>
                <div className="w3-bar w3-panel">
                    <Button className="w3-margin-right w3-margin-bottom h7t-secondary-btn" onClick={handleConfirm}>{confirmBtn}</Button>
                    <Button className="w3-margin-bottom h7t-primary-btn" onClick={handleCancel}>{cancelBtn}</Button>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
})(ConfirmationModal);
