import React from 'react';

const ApplicationFooter = () => (
    <footer className="h7t-footer" id="footerHeight">
        <div className="h7t-copy-right w3-text-white w3-center">
            <div className="container" style={{ background: 'linear-gradient(45deg,#303f9f,#7b1fa2)' }}>
                <div className="w3-row w3-display-container">
                    <div className="w3-margin-left w3-left">
                        <p className="w3-text-white">© Hiring NXT All rights reserved.</p>
                    </div>
                    <div className="w3-bar w3-display-right">
                        <div className="w3-bar-item"><a href="/about-us" target="_blank">About Us</a></div>
                        <div className="w3-bar-item"><a href="mailto:mail@ceegees.in" target="_top">Contact Us</a></div>
                        <div className="w3-bar-item"><a href="/privacy-policy" target="_blank">Privacy Policy</a></div>
                        <div className="w3-bar-item"><a href="/terms-of-service" target="_blank">Terms of Service</a></div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default ApplicationFooter;
