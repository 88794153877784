/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Button, FormHelperText } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    getUsersByRoleId,
    setAppMessage,
    getJobApplnFeedback,
    getApplicationStatus,
    getUserDetails,
    postAssignSchedule,
    getScheduleWorkflowList,
    postAssignScheduleEdit,
    getUnreadNotifications,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { InputTextField, InputSelectField, RadioButtonMUI } from '../Common/MaterialUI/HelperInputs';

let roleList = [];
class ScheduleRole extends Component {
    static propTypes = {
        metaData: PropTypes.object,
        currentPage: PropTypes.number,
        getUsersByRoleId: PropTypes.func,
        setAppMessage: PropTypes.func,
        getJobApplnFeedback: PropTypes.func,
        getApplicationStatus: PropTypes.func,
        getUserDetails: PropTypes.func,
        postAssignSchedule: PropTypes.func,
        getScheduleWorkflowList: PropTypes.func,
        postAssignScheduleEdit: PropTypes.func,
        applicationStatus: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {
                schedule_user_id: '',
                action_date: moment(new Date()).format('YYYY-MM-DD'),
                action_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                where: '',
                description: '',
                meeting_room: '',
                notify: '',
            },
            roles_list: [],
            disableBtn: false,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChangeInterviewer = this.handleChangeInterviewer.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
    }

    componentDidMount() {
        const {
            officeId,
            getUsersByRoleId,
            getUserDetails,
            editData,
            applnId,
            workflowStates,
        } = this.props;
        const actor_Role_Id = workflowStates && workflowStates .actor_role_id;
        if (editData) {
            this.props.getScheduleWorkflowList(applnId).then((res) => {
                if (res.data.success == true) {
                    const response = res.data.data;
                    let schedule_Details = '';
                    response.some((schedule) => {
                        if (schedule.state_id == workflowStates.id) {
                            schedule_Details = schedule;
                            return true;
                        }
                    });
                    this.setState({
                        formData: Object.assign({}, schedule_Details),
                    });
                }
            });
        }

        getUsersByRoleId({ officeId, actor_Role_Id }).then((resp) => {
            const response = resp.data.data;
            roleList = [{ val: '', name: 'Select Interviewer' }];
            response.map((roles) => {
                if (roles.user_id) {
                    getUserDetails({user_id: roles.user_id }).then((res) => {
                        const user_Name = res.data.data.user.display_name;
                        roleList.push({
                            val: roles.user_id,
                            email: roles.email,
                            phoneNum: roles.phone_number,
                            name: user_Name,
                            status: roles.status,
                            type: 'customOption',
                        });
                        this.setState({
                            roles_list: roleList,
                        });
                    });
                    return;
                }
                roleList.push({
                    val: roles.user_id,
                    email: roles.email,
                    phoneNum: roles.phone_number,
                    name: roles.email,
                    status: roles.status,
                    type: 'customOption',
                });
                this.setState({
                    roles_list: roleList,
                });
            });
        });
    }

    validateData() {
        const { formData, errors } = this.state;
        let hasErrors = false;
        const ignoreList = [
            'meeting_room',
            'description',
            'lifecycle_end_id',
            'lifecycle_id',
            'schedule_role_id',
            'profile_pic',
            'notified_at',
        ];
       
        if (formData.action_time && (moment(new Date()).format('HH:mm:ss') > moment(formData.action_time).format('HH:mm:ss'))) {
            if (formData.action_date && moment(new Date(formData.action_date)).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD')) {
                hasErrors = true;
                errors.action_time = 'Time should be greater than current time';
            }
        }

        Object.keys(formData).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (formData[key]) === 'string') {
                    if (!formData[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return true;
    }

    handleFormSubmit(e) {
        e.preventDefault();
        const validData = this.validateData();
        const { formData } = this.state;
        let email = '';
        let phoneNumber = '';
        const {
            workflowStates,
            officeId,
            applnId,
            editData,
        } = this.props;
        const {
            setAppMessage,
            getJobApplnFeedback,
            getApplicationStatus,
            postAssignSchedule,
            getScheduleWorkflowList,
            onModalClose,
            postAssignScheduleEdit,
            getUnreadNotifications,
        } = this.props;

        let addOrEditApiCall = {};
        let apicall = '';
        let successMsg = '';
        if (!validData) {
            return;
        }
        this.setState({
            disableBtn: true,
        });
        const selectedEmail = roleList.some((list) => {
            if (list.val == formData.schedule_user_id) {
                email = list.email;
                phoneNumber = list.phoneNum;
                return true;
            }
        });
        if (selectedEmail) {
            formData.selectedUserEmail = email;
            formData.selectedUserphone = phoneNumber;
        }
        formData.officeId = officeId;
        formData.applnId = applnId;
        formData.companyId = workflowStates.company_id;

        getJobApplnFeedback(applnId);

        getApplicationStatus(applnId).then(() => {
            formData.state_id = workflowStates.id;
            formData.stateName = workflowStates.state_name;
            if (!editData) {
                addOrEditApiCall.postAssignSchedule = postAssignSchedule;
                apicall = 'postAssignSchedule';
                successMsg = 'Application assigned to role user for Action';                
            } else {
                addOrEditApiCall.postAssignScheduleEdit = postAssignScheduleEdit;
                apicall = 'postAssignScheduleEdit';
                successMsg = 'Scheduled application edited successfully';
            }
            addOrEditApiCall[apicall](formData).then(() => {
                this.setState({
                    disableBtn: false,
                });
                getScheduleWorkflowList(applnId);
                getApplicationStatus(applnId);
                getUnreadNotifications();
                onModalClose();
                setAppMessage('success', successMsg);
            });
        });
    }

    handleChangeInterviewer(evt) {
        const { name, value } = evt.target;
        const { formData, errors } = this.state;

        formData[name] = value;
        errors[name] = '';
        this.setState({ formData, errors });
    }

    handleDateChange(date) {
        const { formData, errors } = this.state;
        formData.action_date = moment(date).format('YYYY/MM/DD');
        if (errors.action_date) {
            errors.action_date = null;
            this.setState({ errors });
        }
        this.setState({
            formData,
        });
    }

    handleTimeChange(date) {
        const { formData, errors } = this.state;
        formData.action_time = moment(date).format("YYYY-MM-DD HH:mm:ss");
        if (errors.action_time) {
            errors.action_time = null;
            this.setState({ errors });
        }
        this.setState({
            formData,
        });
    }

    handleChangeDescription(content) {
        const { errors, formData } = this.state;
        if (errors.description) {
            errors.description = null;
            this.setState({ errors });
        }
        formData.description = (content == '<p><br></p>') ? '' : content;
        this.setState({ formData });
    }

    render() {
        const {
            formData, roles_list, errors, disableBtn,
        } = this.state;
        const { onModalClose, workflowStates } = this.props;
        const whereOptions = [
            { val: '', name: 'Where' },
            { val: 'Office', name: 'Office' },
            { val: 'Telephonic', name: 'Telephonic' },
            { val: 'Other', name: 'Other' },
        ];
        return (
            <Modal modalCls="w3-show" onClose={onModalClose}>
                <form className="form-horizontal">
                    <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">{workflowStates.state_name}</h4>  
                    <div>
                        <InputSelectField
                            label="Interviewer"
                            name="schedule_user_id"
                            id="schedule_user_id"
                            value={formData.schedule_user_id ? formData.schedule_user_id : ''}
                            onChange={this.handleChangeInterviewer}
                            options={roles_list}
                            errors={errors.schedule_user_id}
                            required
                        />
                        <Grid container spacing={3} className="w3-panel">
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        error={errors.action_date ? true : false}
                                        helperText={errors.action_date}
                                        format="MMM do, yyyy"
                                        inputProps={{readOnly: true}}
                                        inputVariant="outlined"
                                        id="date-picker-inline"
                                        label="Schedule Date"
                                        value={formData.action_date}
                                        onChange={this.handleDateChange}
                                        minDate={new Date()}
                                        size="small"
                                        required
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        error={errors.action_time ? true : false}
                                        helperText={errors.action_time}
                                        id="time-picker"
                                        inputVariant="outlined"
                                        label="Schedule Time"
                                        inputProps={{readOnly: true}}
                                        value={formData.action_time}
                                        onChange={this.handleTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        size="small"
                                        required
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <InputSelectField
                                    label="Where"
                                    name="where"
                                    id="where"
                                    value={formData.where}
                                    onChange={this.handleChangeInterviewer}
                                    options={whereOptions}
                                    errors={errors.where}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputTextField
                                    label="Meeting room"
                                    value={formData.meeting_room}
                                    name="meeting_room"
                                    id="meeting_room"
                                    onChange={this.handleChangeInterviewer}
                                />
                            </Grid>
                        </Grid>
                        
                        <div className="w3-margin-left">
                            <label for="notify" className="w3-margin-right">Notify Via</label>
                            <RadioButtonMUI
                                name="notify"
                                id="notify_email"
                                checked={formData.notify === 'email' ? 'checked' : undefined}
                                value="email"
                                onChange={this.handleChangeInterviewer}
                                label="Email"
                            />
                            <RadioButtonMUI
                                name="notify"
                                id="notify_sms"
                                value="sms"
                                checked={formData.notify === 'sms' ? 'checked' : undefined}
                                onChange={this.handleChangeInterviewer}
                                label="SMS"
                            />
                            <RadioButtonMUI
                                name="notify"
                                id="notify_whtsp"
                                value="whatsapp"
                                checked={formData.notify === 'whatsapp' ? 'checked' : undefined}
                                onChange={this.handleChangeInterviewer}
                                disabled
                                label="Whatsapp"
                            />
                            <FormHelperText error={errors.notify} className="w3-margin-left" style={{ marginTop: '-8px' }}>{errors.notify}</FormHelperText>
                        </div>
                        <InputTextField
                            label="Description"
                            name="description"
                            id="description"
                            value={formData.description ? formData.description : ''}
                            onChange={this.handleChangeInterviewer}
                            multiline
                            rows="4"
                        />
                    </div>
                    
                    <div className="w3-bar w3-panel">
                        <Button
                            variant="contained"
                            disabled={disableBtn}
                            onClick={evt => this.handleFormSubmit(evt)}
                            className={`${disableBtn ? 'w3-disabled' : ''} w3-margin-right h7t-secondary-btn`}
                        >
                            Schedule
                        </Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const applicationStatus = state.applicationStatus;
    return {
        applicationStatus,
    };
}

export default connect(mapStateToProps, {
    getUsersByRoleId,
    setAppMessage,
    getJobApplnFeedback,
    getApplicationStatus,
    getUserDetails,
    postAssignSchedule,
    getScheduleWorkflowList,
    postAssignScheduleEdit,
    getUnreadNotifications,
})(ScheduleRole);
