import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../Common/Modal';
import {
    getCurrentUser,
    setAppMessage,
} from '../../../js/actions';
import { InputTextField } from '../../Common/MaterialUI/HelperInputs';
import { Button } from '@material-ui/core';

class PortfolioLinksModal extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        currentUser: PropTypes.object,
        editPortfolioData: PropTypes.object,
    }

    constructor(args) {
        super(args);
        this.state = {
            errors: {},
            mediaBlock: [],
            mediaForm: {
                mediaUrl: '',
                title: '',
            },
            btnText: 'Save',
            mediaErrors: {},
        };

        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleAddMedia = this.handleAddMedia.bind(this);
    }

    componentDidMount() {
        const { currentUser, editPortfolioData } = this.props;
        if (!currentUser) {
            this.props.getCurrentUser();
        }
        if (currentUser) {
            const userDetails = JSON.parse(JSON.stringify(currentUser));
            let mediaBlock = [];
            if (userDetails.profile) {
                if (userDetails.profile.profile_links) {
                    mediaBlock = JSON.parse(userDetails.profile.profile_links);
                } this.setState({
                    mediaBlock,
                });
            }
        }
        if (editPortfolioData) {
            const data = Object.assign({}, editPortfolioData.action);
            data.id = editPortfolioData.index + 1;
            this.setState({
                mediaForm: data,
                mediaErrors: {},
                btnText: 'Update',
            });
        }
    }

    handleMediaChange(evt) {
        const { mediaForm, mediaErrors } = this.state;
        const targetElement = $(evt.target).attr('id');

        if (mediaErrors[targetElement]) {
            mediaErrors[targetElement] = null;
            this.setState({ mediaErrors });
        }

        mediaForm[targetElement] = $(evt.target).val();
        this.setState({ mediaForm });
    }

    handleAddMedia(e, flag = false) {
        const {
            mediaBlock, mediaForm, mediaErrors,
        } = this.state;
        let hasErrors = false;
        if (mediaForm.mediaUrl) {
            const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            const regex = new RegExp(urlRegex);
            if (!mediaForm.mediaUrl.match(regex)) {
                hasErrors = true;
                mediaErrors.mediaUrl = 'Url with http://, https:// and www is accepted';
            } else {
                mediaBlock.map((url, idx) => {
                    if ((url.mediaUrl == mediaForm.mediaUrl) && (mediaForm.id != idx + 1)) {
                        hasErrors = true;
                        mediaErrors.mediaUrl = 'This url already added';
                    }
                });
            }
        }

        Object.keys(mediaForm).forEach((key) => {
            if (key !== 'description') {
                if (typeof (mediaForm[key]) === 'string') {
                    if (!mediaForm[key].match(/\w/)) {
                        hasErrors = true;
                        mediaErrors[key] = 'This field is mandatory';
                    }
                }
                if (!mediaForm[key] || mediaForm[key] === ' ') {
                    hasErrors = true;
                    mediaErrors[key] = 'This field is mandatory';
                }
            }
        });

        if (hasErrors) {
            this.setState({ mediaErrors });
        } else {
            this.props.handleSubmit(e, mediaBlock);
            if (flag) {
                $('.close-button').click();
            }
            if (mediaForm.id) {
                const index = mediaForm.id - 1;
                mediaBlock[index] = mediaForm;
            } else {
                mediaBlock.push(mediaForm);
            }

            this.setState({
                mediaBlock,
                mediaForm: {
                    mediaUrl: '',
                    title: '',
                },
            });
        }
    }

    render() {
        const { onModalClose, editPortfolioData } = this.props;
        const { btnText, mediaErrors, mediaForm } = this.state;
        let mediaUrl = '';

        mediaUrl = (
            <div>
                <div>
                    <InputTextField
                        name="title"
                        id="title"
                        label="Title"
                        required
                        value={mediaForm.title}
                        onChange={this.handleMediaChange}
                        errors={mediaErrors.title}
                        autoFocus
                    />
                </div>
                <div>
                    <InputTextField
                        name="mediaUrl"
                        id="mediaUrl"
                        label="URL"
                        placeholder="Eg: http://example.com / www.example.com"
                        required
                        value={mediaForm.mediaUrl}
                        onChange={this.handleMediaChange}
                        errors={mediaErrors.mediaUrl}
                    />
                </div>
            </div>
        );

        return (
            <Modal modalCls="w3-show"
                onClose={onModalClose}
                title="Portfolio Links"
                widthCls="chp-small-dlg"
            >
                <form className="form-horizontal">
                    {mediaUrl}
                    <div className="w3-row w3-padding">
                        {!editPortfolioData ? (
                            <Button 
                                variant="contained"
                                 className="w3-margin-right h7t-margin-10 h7t-secondary-btn" 
                                 onClick={e => this.handleAddMedia(e)}>
                                 <span className="ion-plus-circled" />
                                &nbsp;Save & Add Another Link
                            </Button>
                        ) : null}

                        <Button
                            variant="contained"
                            className="h7t-margin-10 h7t-primary-btn w3-right"
                            onClick={e => this.handleAddMedia(e, true)}
                            >
                            {btnText}
                        </Button>

                    </div>
                </form>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
    };
}

export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentUser,
})(PortfolioLinksModal);
