import React, { Fragment } from 'react';
import Footer from './ApplicationFooter';
import {
    withRouter,
    NavLink,
} from 'react-router-dom';
import Header from './Header';

class AboutUs extends React.Component {
    render() {
        return(
            <Fragment>
                <div className="w3-top">
                    <Header />
                </div>
                <div className="w3-row h7t-homepage">
                    <h5 className="w3-container w3-content margin-top-100 margin-bottom-100" style={{ minHeight: '100vh' }}>
                        Hiring NXT<br></br><br></br>
                
                        HiringNXT was built to solve some of our own hiring problems. Keeping track of number of job open positions,
                        hires,
                        offers made, feedback were all tracked on different channels. That is when the idea of HiringNXT was
                        born.<br></br><br></br>
                
                        HiringNXT simply put, will solve your hiring problems and make the process of hiring more
                        efficient.<br></br><br></br>
                
                        Of course, we are new, young and excited. We would like to work with you, learn from you and deliver to you.
                    </h5>
                <footer className="footer-bottom-fixed">
                <Footer />
                </footer>
                
                
            </div>
            </Fragment>
        );
    }
}

export default AboutUs;