import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Rating } from '@material-ui/lab';
import moment from 'moment';
import { getUserDetails } from '../../js/actions';
import { getScreenName } from './Constants';
import { Button } from '@material-ui/core';

class PreviewResumeModal extends Component {
    static propTypes = {
        currentUser: PropTypes.shape({
            user: PropTypes.object,
            profile: PropTypes.object,
        }),
        previewData: PropTypes.object,
        employer: PropTypes.bool,
    };

    constructor(args) {
        super(args);

        this.state = {
            userResume: {},
        };
    }

    componentDidMount() {
        const { previewData, currentUser, currentUser: { user } } = this.props;
        let userResume = currentUser;
        if (user.id != previewData.user_id) {
            this.props.getUserDetails({ user_id: previewData.user_id }).then((resp) => {
                if (resp && resp.data && resp.data.success) {
                    userResume = resp.data.data;
                    this.setState({ userResume });
                }
            });
        }
        this.setState({ userResume });
    }

    render() {
        const { previewData, employer } = this.props;
        const { userResume: { user, profile, skillsRating } } = this.state;
        const educationalDetails = [];
        const workDetails = [];
        const profileLinks = [];
        const certificateDetails = [];
        const userSkillsRating = [];
        let resumeTopSection = null;
        let additionalInformation = null;

        if (previewData && previewData.resume_view_block && previewData.resume_view_block.length) {
            previewData.resume_view_block.map((block) => {
                if (block.resume_block) {
                    if (block.resume_block.type === 'Certificate') {
                        certificateDetails.push(
                            <li key={block.id}>
                                <div><strong>{block.resume_block.certificate}</strong> {`${block.resume_block.year_of_expire !== 'No Expiry' ? '- Valid upto '+block.resume_block.year_of_expire : ''}`} </div>
                            </li>,
                        );
                        return;
                    }
                    const resumeBlocks = (
                        <li className="w3-margin-bottom" key={block.id}>
                            <div>{block.resume_block.title} | {block.resume_block.institution}</div>
                            {block.resume_block.start_date ? <div>{moment(block.resume_block.start_date).format('MMM Do, YYYY')} to {block.resume_block.end_date ? moment(block.resume_block.end_date).format('MMM Do, YYYY') : 'Present'}</div> : null}
                            {block.resume_block.skills ? <div><strong>Skills</strong> : {block.resume_block.skills.split(',').join(', ')}</div> : null }
                            {block.resume_block.description
                                ? (
                                    <div>
                                        <p style={{ margin: '5px 0px', fontWeight: 'bold' }}>Description</p>
                                        <div className="h7t-ql-editor" dangerouslySetInnerHTML={{ __html: block.resume_block.description }} />
                                    </div>
                                ) : null}
                            {block.resume_block.projects && block.resume_block.projects.length
                                ? (
                                    <div>
                                        <p style={{ margin: '5px 0px', fontWeight: 'bold' }}>Projects</p>
                                        <ol>
                                            {block.resume_block.projects.map((prjt) => {
                                                let duration = '';
                                                if (prjt.date_from && prjt.date_from !== '0000-00-00') {
                                                    const end = prjt.date_to && prjt.date_to !== '0000-00-00' ? prjt.date_to : 'Present';
                                                    duration = `${prjt.date_from} to ${end}`;
                                                }
                                                return (
                                                    <li>
                                                        {`${prjt.title} `}
                                                        {prjt.url
                                                            ? (
                                                                <a href={prjt.url} target="_new" className="h7t-hyperlink-color">
                                                                    {`(${prjt.url})`}
                                                                </a>
                                                            ) : ''}
                                                        <br />
                                                        {duration}
                                                        {prjt.responsibilities
                                                            ? (
                                                                <div>
                                                                    <p style={{ marginBottom: 5 }}>Responsibilities :</p>
                                                                    <div className="h7t-ql-editor" dangerouslySetInnerHTML={{ __html: prjt.responsibilities }} />
                                                                </div>
                                                            ) : null}
                                                    </li>
                                                );
                                            })}
                                        </ol>
                                    </div>
                                ) : null}
                        </li>
                    );
                    if (block.resume_block.type === 'Education') {
                        educationalDetails.push(resumeBlocks);
                    } else if (block.resume_block.type === 'Experience') {
                        workDetails.push(resumeBlocks);
                    }
                }
            });
        }

        if (profile) {
            if (profile.profile_links) {
                const linkBlocks = JSON.parse(profile.profile_links);
                linkBlocks.map((block, idx) => {
                    profileLinks.push(
                        <li className="w3-margin-bottom" key={idx}>
                            <div>{block.title} | <a href={block.mediaUrl} target="blank" className="h7t-hyperlink-color">{block.mediaUrl}</a></div>
                            <div>{block.description}</div>
                        </li>,
                    );
                });
            }
            let ExpYear = '';
            let ExpMonth = '';
            if (profile.relevant_experience) {
                const yy = profile.relevant_experience > 1 ? 'Years ' : 'Year ';
                ExpYear = `${profile.relevant_experience} ${yy}`;
            }
            if (profile.relevant_experience_month) {
                const mm = profile.relevant_experience_month > 1 ? 'Months' : 'Month';
                ExpMonth = `${profile.relevant_experience_month} ${mm}`;
            }
            additionalInformation = `${ExpYear}${ExpMonth}`;
        }

        if (user) {
            const screenName = getScreenName(this.props.currentUser.user);
            resumeTopSection = (
                <div className="w3-bottombar w3-row h7t-profile-in-mobile w3-padding-16">
                    <div className="w3-col m8 h7t-order-3">
                        <div className="h7t-modal-subtitle">{user.display_name}</div>
                        <div className="capitalize-first word-break w3-large">{previewData.title}</div>
                        <div>{additionalInformation}</div>
                        <div>
                            {profile && profile.current_loc_name ? profile.current_loc_name.term : ''}
                        </div>
                    </div>
                    <div className="w3-col m4 h7t-order-2">
                        {((user.phone_number && user.phone_number.includes('NOT_UPDATED')) || !user.phone_number) ? '' : (
                            <div className="h7t-display-flex">
                                <PhoneIcon fontSize="small" className="w3-margin-right w3-margin-bottom-4" />
                                {user.phone_number}
                            </div>
                        )}
                        <div className="word-break h7t-display-flex">
                            <EmailIcon fontSize="small" className="w3-margin-right w3-margin-bottom-4" />
                            {user.email}
                        </div>
                        {!user.dob || user.dob === '0000-00-00' ? '' : (
                            <div className="h7t-display-flex">
                                <CalendarTodayIcon fontSize="small" className="w3-margin-right w3-margin-bottom-4" />
                                {moment(user.dob).format('MMM Do, YYYY')}
                            </div>
                        )}
                    </div>
                    {!employer && !previewData.resume_file_path
                        ? (
                            <div className="w3-row w3-display-container">
                                <NavLink to={`/s/profile/${screenName}`} className="w3-display-bottomright w3-text-blue">
                                    Go to Profile
                                </NavLink>
                            </div>
                        ) : null}
                </div>
            );
        }

        if (skillsRating && skillsRating.length) {
            skillsRating.map((item, idx) => {
                userSkillsRating.push(
                    <div className="w3-row w3-margin" key={`skillRate_${idx}`}>
                        <div className="w3-col m4">{item.skill}</div>
                        <div className="w3-col m4">
                            <Rating value={item.star_rating} readOnly />
                        </div>
                        <div className="w3-col m4">{item.rating}</div>
                    </div>,
                );
            });
        }

        let coveringLetter = null;
        if (previewData.covering_letter_text) {
            coveringLetter = (
                <div className="h7t-cover-letter">
                    <div className="h7t-ql-editor" dangerouslySetInnerHTML={{ __html: previewData.covering_letter_text }} />
                </div>
            );
        }

        return (
            <div>
                {resumeTopSection}
                {coveringLetter}
                {previewData.resume_file_path
                    ? (
                        <div>
                            <div className="w3-center w3-margin-bottom w3-margin-top">
                                <Button
                                    variant="contained"
                                    className="h7t-secondary-btn"
                                >
                                    <a href={previewData.resume_file_path} target="_new" className="w3-text-white" download>Download Resume</a>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {(workDetails && workDetails.length)
                                ? (
                                    <div className="w3-margin-top" style={{ marginBottom: 30 }}>
                                        <strong>Experience</strong>
                                        <ul>
                                            {workDetails}
                                        </ul>
                                    </div>
                                ) : null}
                            {(educationalDetails && educationalDetails.length)
                                ? (
                                    <div className="w3-margin-top" style={{ marginBottom: 30 }}>
                                        <strong>Education</strong>
                                        <ul>
                                            {educationalDetails}
                                        </ul>
                                    </div>
                                ) : null}
                            {(certificateDetails && certificateDetails.length)
                                ? (
                                    <div className="w3-margin-top" style={{ marginBottom: 30 }}>
                                        <strong>Certification</strong>
                                        <ul>
                                            {certificateDetails}
                                        </ul>
                                    </div>
                                ) : null}
                            {(userSkillsRating && userSkillsRating.length)
                                ? (
                                    <div className="w3-margin-top" style={{ marginBottom: 30 }}>
                                        <strong>Skills</strong>
                                        {userSkillsRating}
                                    </div>
                                ) : null}
                            {(profileLinks && profileLinks.length)
                                ? (
                                    <div className="w3-margin-top" style={{ marginBottom: 30 }}>
                                        <strong>Portfolio Links</strong>
                                        <ul>
                                            {profileLinks}
                                        </ul>
                                    </div>
                                ) : null}
                        </div>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
    };
}
export default connect(mapStateToProps, {
    getUserDetails,
})(PreviewResumeModal);
