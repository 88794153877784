import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Modal from '../Common/Modal';
import {
    postInviteRoleUser,
    setAppMessage,
    getListByRole,
    getCompanyPackageLimits,
    getListInvite,
} from '../../js/actions';
import { InputTextField } from '../Common/MaterialUI/HelperInputs';

class UserInviteModal extends Component {
    static propTypes = {
        roleId: PropTypes.number,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        getListByRole: PropTypes.func,
        postInviteRoleUser: PropTypes.func,
        currentUser: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            errors: {},
            formData: {
                email: '',
            },
            isDisabled: false,
            reachColabsLimit: false,
        };
        this.handleSendInvite = this.handleSendInvite.bind(this);
        this.handleChangeInvite = this.handleChangeInvite.bind(this);
    }

    componentDidMount() {
        this.setState({
            reachColabsLimit: 'checking',
        }, () => {
            this.props.getCompanyPackageLimits('colabs').then((response) => {
                if (response && !response.data) {
                    this.setState({
                        reachColabsLimit: true,
                    });
                } else {
                    this.setState({
                        reachColabsLimit: false,
                    });
                }
            });
        });
        if (this.props.roleId) {
            this.setState({ roleId: this.props.roleId });
        }
    }

    handleChangeInvite(evt) {
        const targetElement = $(evt.target).attr('id');
        const { errors, formData } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({
                errors,
            });
        }

        formData[targetElement] = $(evt.target).val();
        this.setState({
            formData,
        });
    }

    validateData(data) {
        const errors = {};
        const emailFilter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let isValid = true;

        if (data.email && !emailFilter.test(data.email)) {
            errors.email = 'Please enter a valid email.';
        }

        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                errors[key] = 'This field is mandatory.';
            }
            if (errors[key]) {
                isValid = false;
            }
        });

        if (Object.keys(errors).length > 0 && !isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    handleSendInvite(e) {
        e.preventDefault();
        const { formData, roleId } = this.state;
        formData.role_id = roleId;
        if (!this.validateData(formData)) {
            return false;
        }
        this.setState({ errors: {}, isDisabled: true });
        this.props.postInviteRoleUser(formData).then((response) => {
            const resp = response && response.data;
            this.setState({ isDisabled: false });
            if (resp && resp.success) {
                const { currentUser } = this.props;
                if (currentUser && currentUser.user && currentUser.user.email == formData.email) {
                    this.props.getListInvite();
                }
                this.props.setAppMessage('success', 'User invited successfully!');
                $('.close-button').click();
                this.props.getListByRole(roleId);
            } else if (resp && resp.data) {
                this.setState({
                    errors: resp.data,
                });
            }
        });
    }

    render() {
        const { onModalClose } = this.props;
        const {
            formData, errors, isDisabled, reachColabsLimit,
        } = this.state;

        return (
            <div>
                <Modal modalCls="w3-show" onClose={onModalClose} widthCls="chp-small-dlg">
                    {(reachColabsLimit === 'checking')
                        ? (
                            <div className="w3-center w3-padding-32">
                                Checking...
                            </div>
                        ) : (reachColabsLimit === true)
                            ? (
                                <div className="w3-text-red w3-center w3-padding-32">
                                    Sorry! You have reached the package limit. Upgrade to a new plan to invite more users!
                                </div>
                            ) : (
                                <form onSubmit={e => this.handleSendInvite(e)} noValidate className="form-horizontal">
                                    <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">Invite User</h4>
                                    <InputTextField
                                        autoFocus
                                        label="Email Id"
                                        name="email"
                                        id="email"
                                        required
                                        value={formData.email}
                                        onChange={this.handleChangeInvite}
                                        errors={errors.email}
                                    />
                                    <div className="w3-bar w3-panel">
                                        <Button variant="contained" className="w3-margin-right h7t-secondary-btn" onClick={onModalClose}>Close</Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className={`${isDisabled ? 'w3-disabled' : ''} h7t-primary-btn`}
                                            disabled={isDisabled}
                                        >
                                            Send Invite
                                        </Button>
                                    </div>
                                </form>
                            )
                    }
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = (state && state.currentUser && state.currentUser.data) ? state.currentUser.data : (state && state.currentUser) ? state.currentUser : '';
    return {
        currentUser,
    };
}
export default connect(mapStateToProps, {
    postInviteRoleUser,
    setAppMessage,
    getListByRole,
    getCompanyPackageLimits,
    getListInvite,
})(UserInviteModal);
