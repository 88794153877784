import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import { FormHelperText, Button } from '@material-ui/core';

import JobDescriptionModal from '../Dashboard/JobDescriptionModal';
import {
    getCurrentJobList,
    //getAllCurrentJobList,
    postUpdateJobPosition,
    postAddJobPosition,
    getCurrentWorkflows,
    getSelectedWorkflows,
    setAppMessage,
    getMetaData,
    getApplicationTitleList,
    getCompanyPackageLimits,
    getCurrentOfficeList,
    getJobTitleList,
    getActiveJobs,
    getApplicationStatusById,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { InputTextField, InputSelectField, CheckboxMUI, SearchDropdown } from '../Common/MaterialUI/HelperInputs';
import { LoadingIcon } from '../Common/HelperComponents';
import { getPermissions } from '../Common/Constants';

class JobAddEditModal extends Component {
    static propTypes = {
        metaData: PropTypes.object,
        currentPage: PropTypes.number,
        jobEditData: PropTypes.object,
        getMetaData: PropTypes.func,
        getCompanyPackageLimits: PropTypes.func,
        currentUser: PropTypes.object,
        onModalClose: PropTypes.func,
        setAppMessage: PropTypes.func,
        currentWorkflows: PropTypes.object,
        selectedWorkflows: PropTypes.object,
        getCurrentJobList: PropTypes.func,
        postAddJobPosition: PropTypes.func,
        getCurrentWorkflows: PropTypes.func,
        getSelectedWorkflows: PropTypes.func,
        //getAllCurrentJobList: PropTypes.func,
        postUpdateJobPosition: PropTypes.func,
        getCurrentOfficeList: PropTypes.func,
        currentOfficeList: PropTypes.object,
        applicationTitleList: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {
                job_title: '',
                universities: '',
                certifications: '',
                description: '',
                office_id: '',
                skills_required: [],
                min_experience_required: '',
                max_experience_required: '',
                positions_open: '',
                level_of_hiring: '',
                remote_Jobs: false,
                visible_to_Managment: false,
                workflow_id: '',
                salary_min: '',
                salary_max: '',
                showJobDescription: '',
                project_description: '',
                education: '',
                domain: '',
            },
            office_list: [],
            workflow_list: [],
            searchKey: '',
            reachJobLimit: false,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSkillChange = this.handleSkillChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCheckBoxRemoteJobs = this.handleCheckBoxRemoteJobs.bind(this);
        this.handleCheckBoxManagment = this.handleCheckBoxManagment.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeProjectDescription = this.handleChangeProjectDescription.bind(this);
        this.handleJD = this.handleJD.bind(this);
        this.handleOfficeChange = this.handleOfficeChange.bind(this);
    }

    componentDidMount() {
        const { jobEditData } = this.props;
        if (jobEditData && !jobEditData.id) {
            this.setState({
                reachJobLimit: 'checking',
            }, () => {
                this.props.getCompanyPackageLimits('jobs').then((response) => {
                    let packageLimit = false;
                    if (response && !response.data) {
                        packageLimit = true;
                    }
                    this.setState({
                        reachJobLimit: packageLimit,
                    });
                });
            });
        }

        let { formData } = this.state;
        const data = {
            page: 1,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID,
        };
        let selectedSkills = [];

        if(jobEditData && Object.keys(jobEditData).length) {
            if (!Array.isArray(jobEditData.skills_required)) {
                jobEditData.skills_required = jobEditData.skills_required ? jobEditData.skills_required.split(',') : [];
            }
            selectedSkills = jobEditData.skills_required.slice(0);
        }

        if (jobEditData && jobEditData.id) {
            data.company_id = jobEditData.company_id;
            data.office_id = jobEditData.office_id;
            formData = Object.assign({}, jobEditData);
        } else if(jobEditData && Object.keys(jobEditData).length){
            formData = Object.assign({}, jobEditData);
        } else if (!Object.keys(jobEditData).length) {
            formData.office_id = OFFICE_ID;
        }
        const skillsList = {};
        skillsList.type = 'skills';
        skillsList.ids = selectedSkills;
        this.props.getMetaData(skillsList).then(() => {
            this.forceUpdate();
        });
        this.props.getCurrentOfficeList();
        this.props.getApplicationTitleList();
        this.props.getSelectedWorkflows(data);
        this.setState({ formData });
        this.props.getActiveJobs();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedWorkflows && nextProps.selectedWorkflows.data) {
            const workFlowList = [{ val: '', name: 'Select workflow' }];
            nextProps.selectedWorkflows.data.map((workflow) => {
                workFlowList.push({
                    val: workflow.id,
                    name: workflow.name,
                });
            });
            this.setState({
                workflow_list: workFlowList,
            });
        }
        if (nextProps.currentOfficeList && nextProps.currentOfficeList.data) {
            const officeList = [{ val: '', name: 'Select Office' }];
            nextProps.currentOfficeList.data.map((office) => {
                officeList.push({
                    val: office.id,
                    name: office.name,
                });
            });
            this.setState({
                office_list: officeList,
            });
        }
    }

    handleChangeDescription(content) {
        const { formData, errors } = this.state;
        formData.description = (content == '<p><br></p>') ? '' : content;
        errors.description = '';
        this.setState({ formData, errors });
    }

    handleChangeProjectDescription(content) {
        const { formData, errors } = this.state;
        formData.project_description = (content == '<p><br></p>') ? '' : content;
        errors.project_description = '';
        this.setState({ formData, errors });
    }

    handleJD(showJobDescription) {
        const isValid = this.validateData(showJobDescription);
        if(isValid) {
            this.setState({ showJobDescription });
        }
    }

    handleOfficeChange(event) {
        const { formData, errors } = this.state;
        const { currentUser: { user } } = this.props;
        formData.office_id = event.target.value;
        formData.workflow_id = '';
        errors.office_id = '';
        this.setState({ formData, errors }, () => {
            const data = {
                page: 1,
                company_id: user.current_company_id,
                office_id: formData.office_id,
            };
            this.props.getSelectedWorkflows(data);
        });
    }

    handleCheckBoxRemoteJobs(event) {
        const { formData } = this.state;
        formData.remote_Jobs = event.target.checked;
        this.setState({ formData });
    }

    handleCheckBoxManagment(event) {
        const { formData } = this.state;
        formData.visible_to_Managment = event.target.checked;
        this.setState({ formData });
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { formData, errors } = this.state;
        formData[name] = value;
        if (errors[name]) {
            errors[name] = null;
        }
        this.setState({ formData, errors });
    }

    updateResponse(response, successMessage, status) {
        if (response.success) {
            let { currentPage } = this.props;
            if (!status) {
                currentPage = 1;
            }
            this.props.setAppMessage('success', successMessage);
            this.props.getCurrentJobList(currentPage);
            //this.props.getAllCurrentJobList('all');
            this.props.getCurrentWorkflows();
            this.props.getActiveJobs();
            if(this.props.applicantId) {
                const { applicantId,getApplicationStatusById } = this.props;
                getApplicationStatusById(applicantId);
            }
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                errors: response.data,
            });
        }
    }

    handleSkillChange(option) {
        const { formData, errors } = this.state;
        const value = [];
        if (option && Array.isArray(option)) {
            if (option.length) {
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        }
        formData.skills_required = value;

        if (errors.skills_required) {
            errors.skills_required = null;
        }
        const skillsList = {};
        skillsList.type = 'skills';
        skillsList.ids = value;
        this.props.getMetaData(skillsList).then(() => {
            this.setState({
                formData,
                errors,
                searchKey: '',
            });
        });
    }

    handleSearch(evt, val) {
        if (evt && evt.type === 'change') {
            const { formData } = this.state;
            this.setState({ searchKey: val });
            const skillsList = {};
            skillsList.type = 'skills';
            skillsList.search = val;
            skillsList.ids = formData.skills_required;
            this.props.getMetaData(skillsList).then(() => {
                this.forceUpdate();
            });
        }
    }

    validateData(data) {
        let isValid = true;
        const { errors } = this.state;
        const ignoreList = [
            'category_title',
            'deleted_at',
            'universities',
            'certifications',
            'visible_to_Managment',
            'remote_Jobs',
            'showJobDescription',
            'project_description',
            'education',
            'domain',
        ];

        const formData = Object.assign({}, data);

        if (parseInt(formData.max_experience_required, 10) <= parseInt(formData.min_experience_required, 10)) {
            errors.max_experience_required = `Max Experience must be atleast ${parseInt(formData.min_experience_required, 10) + 1}`;
        } else {
            errors.max_experience_required = '';
        }

        if (parseInt(formData.salary_max, 10) <= parseInt(formData.salary_min, 10)) {
            errors.salary_max = 'CTC Max must be greater than CTC Min';
        }

        if (formData.positions_open == 0) {
            errors.positions_open = 'Positions open must be greater than 0';
        }
        if (formData.skills_required && formData.skills_required.length == 0) {
            errors.skills_required = 'This field is mandatory';
        }

        const salary = ['salary_min','salary_max'];
        const numberFields = ['min_experience_required','max_experience_required','positions_open'];

        Object.keys(formData).forEach((key) => {
             if (ignoreList.indexOf(key) < 0) {
                const regExp = /^\d+[.]?\d*$/;
                if (salary.indexOf(key) !== -1) {
                    if (formData[key] && !regExp.test(formData[key])) {
                        errors[key] = 'Enter a valid number';
                    } 
                }
                const numberRegEXP = /^\d+$/;
                if (numberFields.indexOf(key) !== -1) {
                    if (formData[key] && !numberRegEXP.test(formData[key])) {
                        errors[key] = 'Enter a valid number';
                    } 
                }
                if (typeof (formData[key]) === 'string') {
                    if (!formData[key].match(/\w/)) {
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (formData[key] === '') {
                    errors[key] = 'This field is mandatory';
                }
            }
            if (errors[key]) {
                isValid = false;
            }
        });
        if (Object.keys(errors).length > 0 && !isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    handleFormSubmit(evt, status) {
        evt.preventDefault();
        const { formData, errors } = this.state;
        if (!this.validateData(formData)) {
            return false;
        }

        formData.skills_required = Array.isArray(formData.skills_required) ? formData.skills_required.join() : formData.skills_required;

        formData.status = status ;

        if (formData && formData.id) {
            formData.job_id = formData.id;
            this.props.postUpdateJobPosition(formData).then((resp) => {
                const response = resp && resp.data;
                if (response) {
                    this.updateResponse(response, 'Job edited successfully!', true);
                    this.props.getJobTitleList();
                }
            });
        } else {
            this.props.postAddJobPosition(formData).then((resp) => {
                const response = resp && resp.data;
                if (response) {
                    this.updateResponse(response, 'Job created successfully!', false);
                    this.props.getJobTitleList();
                }
            });
        }
        return false;
    }

    render() {
        const {
            formData, errors, reachJobLimit, showJobDescription, searchKey, job_description_active_tab
        } = this.state;
        const { onModalClose, applicationTitleList, metaData, activeJobs, currentUser, currentUser: { companies } } = this.props;
        let inProgress = false;
        let active = false;
        if (applicationTitleList && applicationTitleList.data) {
            inProgress = applicationTitleList.data.some(job => job.job_id == formData.id);
        }
        if(activeJobs && activeJobs.data) {
            active = activeJobs.data.some(job => job.id == formData.id);
        }
    
        const levelOptions = [
            { val: null, name: 'select your option' },
            { val: 'Junior', name: 'Junior' },
            { val: 'Mid', name: 'Mid' },
            { val: 'Senior', name: 'Senior' },
        ];

        const optionSkills = [];

        if (metaData) {
            const { metaData: { skills } } = this.props;
            if (skills && skills.length) {
                skills.forEach((skill) => {
                    optionSkills.push({ val: skill.term, name: skill.term });
                });
            }
        }

        const permission = getPermissions(currentUser);

        return (
            <Modal modalCls="w3-show" onClose={onModalClose}>
                {(reachJobLimit === 'checking')
                    ? (
                        <LoadingIcon />
                    ) : (reachJobLimit === true)
                        ? (
                            <div className="w3-text-red w3-center w3-padding-32">
                                Sorry! You have reached the package limit. Upgrade to a new plan to add more jobs!
                            </div>
                        ) : (
                            <form className="form-horizontal">
                                <h4 className="h7t-margin-10 w3-padding h7t-modal-title" id="myModalLabel">{formData.id ? 'Edit Job' : 'Create Job'}</h4>
                                <InputTextField
                                    autoFocus
                                    label="Job Title"
                                    name="job_title"
                                    id="job_title"
                                    value={formData.job_title}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.job_title}
                                    disabled={active}
                                    required
                                />

                                <div className="w3-row w3-panel">
                                    <label className="control-label">Job Description <span className="w3-text-red">*</span></label>
                                    <ReactQuill
                                        name="description"
                                        id="description"
                                        readOnly={active}
                                        isMandatory="true"
                                        value={formData.description ? formData.description : ''}
                                        style={{ height: '100px', marginBottom: '10px' }}
                                        onChange={this.handleChangeDescription}
                                        errors={errors.description}
                                        className="w3-text-black"
                                    />
                                    <div style={{ paddingTop: '30px' }}>
                                        <FormHelperText error={errors.description} className="w3-margin-left">{errors.description}</FormHelperText>
                                    </div>
                                </div>

                                <div className="w3-row w3-panel">
                                    <label className="control-label">Project Description</label>
                                    <ReactQuill
                                        name="project_description"
                                        id="project_description"
                                        isMandatory="true"
                                        value={formData.project_description ? formData.project_description : ''}
                                        style={{ height: '100px', marginBottom: '10px' }}
                                        onChange={this.handleChangeProjectDescription}
                                        errors={errors.project_description}
                                        className="w3-text-black"
                                    />
                                    <div style={{ paddingTop: '30px' }}>
                                        <FormHelperText error={errors.project_description} className="w3-margin-left">{errors.project_description}</FormHelperText>
                                    </div>
                                </div>

                                <div className="w3-row">
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Education"
                                            name="education"
                                            id="education"
                                            value={formData.education}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.education}
                                        />
                                    </div>
                                    
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Domain"
                                            name="domain"
                                            id="domain"
                                            value={formData.domain}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.domain}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <SearchDropdown
                                        required
                                        multiple
                                        id="skills_required"
                                        label="Skills Required"
                                        options={optionSkills}
                                        value={formData.skills_required ? formData.skills_required : undefined}
                                        errors={errors.skills_required}
                                        onChange={(evt, val) => {
                                            this.handleSkillChange(val)
                                        }}
                                        searchKey={searchKey}
                                        onSearch={this.handleSearch}
                                        disabled={active}
                                    />
                                </div>
                                <div className="w3-row">
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Universities"
                                            name="universities"
                                            id="universities"
                                            value={formData.universities}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.Universities}
                                        />
                                    </div>
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Certifications"
                                            name="certifications"
                                            id="certifications"
                                            value={formData.certifications}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.Certifications}
                                        />
                                    </div>
                                </div>
                                <div className="w3-row">
                                    <div className="w3-col">
                                        <InputSelectField
                                            label="Select Office"
                                            name="officeList"
                                            id="officeList"
                                            value={formData.office_id}
                                            onChange={(e) => this.handleOfficeChange(e)}
                                            options={this.state.office_list}
                                            errors={errors.office_id}
                                            disabled={ inProgress || active }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="w3-row">
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Min Experience Required"
                                            name="min_experience_required"
                                            id="min_experience_required"
                                            placeholder="Min Experience in Years"
                                            value={formData.min_experience_required}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.min_experience_required}
                                            disabled={ inProgress || active }
                                            required
                                        />
                                    </div>
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Max Experience Required"
                                            name="max_experience_required"
                                            id="max_experience_required"
                                            placeholder="Max Experience in Years"
                                            value={formData.max_experience_required}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.max_experience_required}
                                            disabled={ active }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="w3-row">
                                    <div className="w3-col s6">
                                        <div className="w3-col s6">
                                            <InputTextField
                                                label="CTC Min (LPA)"
                                                id="salary_min"
                                                name="salary_min"
                                                value={formData.salary_min}
                                                onChange={(e) => this.handleChange(e)}
                                                errors={errors.salary_min}
                                                disabled={ active }
                                                required
                                            />
                                        </div>
                                        <div className="w3-col s6">
                                            <InputTextField
                                                label="CTC Max (LPA)"
                                                id="salary_max"
                                                name="salary_max"
                                                value={formData.salary_max}
                                                onChange={(e) => this.handleChange(e)}
                                                errors={errors.salary_max}
                                                disabled={ active }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="w3-col s6">
                                        <InputTextField
                                            label="Positions Open"
                                            name="positions_open"
                                            id="positions_open"
                                            value={formData.positions_open}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.positions_open}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="w3-row">
                                    <div className="w3-col s6">
                                        <InputSelectField
                                            label="Select Workflow"
                                            name="workflow_id"
                                            id="workflow_id"
                                            value={formData.workflow_id}
                                            onChange={(e) => this.handleChange(e)}
                                            className={`${inProgress ? 'w3-disabled' : ''}`}
                                            errors={errors.workflow_id}
                                            options={this.state.workflow_list}
                                            disabled={inProgress || active }
                                            required
                                        />
                                    </div>
                                    <div className="w3-col s6">
                                        <InputSelectField
                                            label="Level of Hiring"
                                            name="level_of_hiring"
                                            id="level_of_hiring"
                                            value={formData.level_of_hiring}
                                            onChange={(e) => this.handleChange(e)}
                                            errors={errors.level_of_hiring}
                                            options={levelOptions}
                                            disabled={active}
                                            required
                                        />
                                    </div>
                                </div>

                                <div style={{ padding: '5px 18px' }}>
                                    <CheckboxMUI
                                        name="remote_Jobs"
                                        id="remote_Jobs"
                                        checked={formData.remote_Jobs}
                                        value={formData.remote_Jobs}
                                        label="Remote Jobs"
                                        onChange={this.handleCheckBoxRemoteJobs}
                                        disabled={active ? true : false}
                                    />
                                </div>                 
                                <div className="w3-bar w3-panel">
                                    <Button
                                        variant="contained"
                                        onClick={evt => this.handleFormSubmit(evt, formData.id && formData.status === 'saved'?'saved':formData.id ? 'unpublish':'saved')}
                                        className={`w3-margin-right h7t-margin-10 ${permission == 'Hiring Manager' ? 'h7t-primary-btn' : 'h7t-secondary-btn'}` }
                                        >
                                        {formData.id && formData.status === 'saved' ? 'Save as Draft' : formData.id ? 'UnPublish' : 'Save as Draft'}
                                    </Button>
                                    {permission !== 'Hiring Manager' &&
                                    <Button
                                        variant="contained"
                                        onClick={evt => this.handleFormSubmit(evt,'active')}
                                        className="h7t-margin-10 h7t-primary-btn"
                                        >
                                        {formData.id && formData.status === 'saved' ? 'Publish' : formData.id ? 'Update' : 'Publish'}
                                    </Button> }
                                    <Button
                                        variant="contained"
                                        className="h7t-margin-10 h7t-secondary-btn w3-right"
                                        onClick ={() => this.handleJD(this.state.formData)}
                                        >
                                        Preview
                                    </Button>
                                </div>
                                <div>
                                    {showJobDescription
                                        ? (
                                            <JobDescriptionModal employerJdView
                                                jobDescription={showJobDescription}
                                                onModalClose={() => this.setState({ showJobDescription: '' }) }
                                            />
                                        ) : null}
                                </div>
                            </form>
                        )
                }
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentWorkflows: state.currentWorkflows,
        selectedWorkflows: state.selectedWorkflows,
        currentUser,
        metaData: state.meta,
        listCountries: state.listCountries,
        listStates: state.listStates,
        applicationTitleList: state.applicationTitleList,
        currentOfficeList: state.currentOfficeList,
        activeJobs: state.activeJobs,
    };
}

export default connect(mapStateToProps, {
    getCurrentJobList,
    postAddJobPosition,
    //getAllCurrentJobList,
    postUpdateJobPosition,
    getCurrentWorkflows,
    getSelectedWorkflows,
    setAppMessage,
    getMetaData,
    getApplicationTitleList,
    getCompanyPackageLimits,
    getCurrentOfficeList,
    getJobTitleList,
    getActiveJobs,
    getApplicationStatusById,
})(JobAddEditModal);
