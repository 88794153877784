import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import {
    getCurrentWorkflows,
    postWorkflowEdit,
    setAppMessage,
    postWorkflowCreate,
    getCompanyPackageLimits,
    postWorkflowStateCreateTemplate,
} from '../../js/actions';
import Modal from '../Common/Modal';
import { InputTextField } from '../Common/MaterialUI/HelperInputs';

class WorkflowModal extends Component {
    static propTypes = {
        getCurrentWorkflows: PropTypes.func,
        postWorkflowCreate: PropTypes.func,
        getCompanyPackageLimits: PropTypes.func,
        postWorkflowEdit: PropTypes.func,
        hiringEditData: PropTypes.object,
        setAppMessage: PropTypes.func,
        onModalClose: PropTypes.func,
        currentUser: PropTypes.object,
        handleRow: PropTypes.func,
        templateList: PropTypes.object,
        postWorkflowStateCreateTemplate: PropTypes.func,
        userRole: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {
                name: '',
                description: '',
                department: '',
            },
            reachWorkflowLimit: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { hiringEditData } = this.props;
        if (!hiringEditData.id) {
            this.setState({
                reachWorkflowLimit: 'checking',
            }, () => {
                this.props.getCompanyPackageLimits('workflow').then((response) => {
                    if (response && !response.data) {
                        this.setState({
                            reachWorkflowLimit: true,
                        });
                    } else {
                        this.setState({
                            reachWorkflowLimit: false,
                        });
                    }
                });
            });
            return;
        }
        this.setState({
            // formData: Object.assign({}, this.state.formData, this.props.hiringEditData),
            formData: Object.assign({}, this.props.hiringEditData),
        });
    }

    handleChange(evt) {
        const targetElement = $(evt.target).attr('id');
        const { formData, errors } = this.state;

        if (errors[targetElement]) {
            errors[targetElement] = null;
            this.setState({ errors });
        }

        formData[targetElement] = $(evt.target).val();
        // formData[targetElement] = formData[targetElement].replace(/\s\s+/g, ' ');
        this.setState({ formData });
    }

    updateResponse(response, successMessage) {
        const { currentPage } = this.props;
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getCurrentWorkflows(currentPage);
            this.props.handleRow(response.data);
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                errors: response.data,
            });
            this.props.getCurrentWorkflows(currentPage);
        }
    }

    validateData() {
        const { formData, errors } = this.state;
        let hasErrors = false;
        const ignoreList = [
            'count_jobs_used',
            'last_name',
        ];
        Object.keys(formData).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (formData[key]) === 'string') {
                    if (!formData[key].match(/\w/)) {
                        hasErrors = true;
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return formData;
    }

    handleSubmit(evt) {
        evt.preventDefault();
        const validData = this.validateData();
        let roleList = this.props.userRole.data.find((roleUser) => {
            return roleUser.permission_type == 'HR';
        });
        if (!roleList) {
            const errorMsg = 'Invite a HR to add workflow';
            this.props.setAppMessage('fail', errorMsg);
            return;
        }

        if (validData) {
            if (validData.id) {
                validData.workflow_id = validData.id;
                this.props.postWorkflowEdit(validData).then((resp) => {
                    const response = resp && resp.data;
                    this.updateResponse(response, 'Workflow edited successfully');
                });
            } else {
                this.props.postWorkflowCreate(validData).then((resp) => {
                    const response = resp && resp.data;
                    if (response && response.success) {
                        const { templateList, userRole } = this.props;
                        if (templateList && templateList.data) {
                            const states = templateList.data.find((item) => {
                                return item.template_id === 'initial_template';
                            });
                            if (states) {
                                states.workflow_id = response.data.id;
                                states.actor_role_id = roleList.id;
                                this.props.postWorkflowStateCreateTemplate(states);
                            }
                        }
                    }
                    this.updateResponse(response, 'Workflow added successfully');
                });
            }
        }
        return false;
    }

    render() {
        const { formData, errors, reachWorkflowLimit } = this.state;
        const { onModalClose } = this.props;

        return (
            <Modal modalCls="w3-show" onClose={onModalClose} widthCls="chp-small-dlg">
                {(reachWorkflowLimit === 'checking')
                    ? (
                        <div className="w3-center w3-padding-32">
                            Checking...
                        </div>
                    ) : (reachWorkflowLimit === true)
                        ? (
                            <div className="w3-text-red w3-center w3-padding-32">
                                Sorry! You have reached the package limit. Upgrade to a new plan to add more workflows!
                            </div>
                        ) : (
                            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                <h4 className="h7t-margin-10 w3-padding h7t-modal-title" id="myModalLabel">{formData.id ? 'Edit Workflow' : 'Create Workflow'}</h4>
                                <InputTextField
                                    autoFocus
                                    label="Name"
                                    name="name"
                                    id="name"
                                    required
                                    value={(formData.name) ? formData.name : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.name}
                                />

                                <InputTextField
                                    multiline
                                    rows="4"
                                    label="Description"
                                    name="description"
                                    id="description"
                                    required
                                    value={(formData.description) ? formData.description : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.description}
                                />

                                <InputTextField
                                    label="Department"
                                    name="department"
                                    id="department"
                                    required
                                    value={(formData.department) ? formData.department : ''}
                                    onChange={(e) => this.handleChange(e)}
                                    errors={errors.department}
                                />

                                <div className="w3-bar w3-panel">
                                    <Button variant="contained" className="w3-margin-right h7t-margin-10 h7t-secondary-btn" onClick={onModalClose}>Close</Button>
                                    <Button variant="contained" className="h7t-margin-10 h7t-primary-btn" onClick={this.handleSubmit}>{formData.id ? 'Update' : 'Add Workflow'}</Button>
                                </div>
                            </form>
                        )
                }
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser.user,
    };
}
export default connect(mapStateToProps, {
    getCurrentWorkflows,
    postWorkflowEdit,
    postWorkflowCreate,
    setAppMessage,
    getCompanyPackageLimits,
    postWorkflowStateCreateTemplate,
})(WorkflowModal);
