import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Modal from '../Common/Modal';
import {
    getManage,
    setAppMessage,
    postCreateRole,
    postEditRole,
} from '../../js/actions';
import { InputTextField, InputSelectField } from '../Common/MaterialUI/HelperInputs';

class UserRoleAddEditModal extends Component {
    static propTypes = {
        getManage: PropTypes.func,
        onModalClose: PropTypes.func,
        roleUserEdit: PropTypes.object,
        setAppMessage: PropTypes.func,
        postCreateRole: PropTypes.func,
        handleOnSuccess: PropTypes.func,
        postEditRole: PropTypes.func,
        permissions: PropTypes.object,
    };

    constructor(args) {
        super(args);

        this.state = {
            errors: {},
            formData: {
                name: '',
                permission_type: '',
            },
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetError = this.resetError.bind(this);
    }

    componentDidMount() {
        const { roleUserEdit } = this.props;
        if (roleUserEdit && roleUserEdit.id) {
            this.setState({ formData: Object.assign({}, roleUserEdit) });
        }
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { errors, formData } = this.state;

        if (errors[name]) {
            errors[name] = null;
            this.setState({
                errors,
            });
        }
        formData[name] = value;
        this.setState({ formData });
    }

    updateResponse(response, successMessage) {
        if (response && response.success) {
            this.props.setAppMessage('success', successMessage);
            this.props.getManage('getAllRoles');
            this.props.handleSelect(response.data, true);
            $('.close-button').click();
        } else if (response && response.data) {
            this.setState({
                errors: response.data,
            });
        }
    }

    validateData(data) {
        const { errors } = this.state;
        let isValid = true;
        const ignoreList = [
            'actorRoleId',
        ];
        Object.keys(data).forEach((key) => {
            if (key !== 'deleted_at' && ignoreList.indexOf(key) < 0) {
                if (typeof (data[key]) === 'string') {
                    if (!data[key].match(/\w/)) {
                        errors[key] = 'This field is mandatory';
                    }
                }
                if (!data[key]) {
                    errors[key] = 'This field is mandatory.';
                }
            }
            if (errors[key]) {
                isValid = false;
            }
        });

        if (Object.keys(errors).length > 0 && !isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    handleFormSubmit(evt) {
        evt.preventDefault();
        const { formData } = this.state;
        if (!this.validateData(formData)) {
            return false;
        }
        if (formData && formData.id) {
            this.props.postEditRole(formData).then((resp) => {
                const response = resp && resp.data;
                this.updateResponse(response, 'Role edited successfully!');
            });
        } else {
            this.props.postCreateRole(formData).then((resp) => {
                const response = resp && resp.data;
                this.updateResponse(response, 'Role added successfully!');
            });
        }
        return false;
    }

    resetError() {
        this.state.errors = {};
    }

    render() {
        const { errors, formData } = this.state;
        const { onModalClose, permissions } = this.props;

        const permissionSelect = [
            { val: null, name: 'Select Permission Type' },
            { val: 'Panel Member', name: 'Panel Member' },
            { val: 'HR', name: 'HR' },
            { val: 'Hiring Manager', name: 'Hiring Manager' },
        ];

        if (permissions && permissions.length) {
            if (permissions.indexOf('Management') !== -1) {
                permissionSelect.push({ val: 'Management', name: 'Management' });
            }
        }

        return (
            <div>
                <Modal modalCls="w3-show" widthCls="chp-small-dlg" onClose={onModalClose} title={formData.id ? 'Edit Role' : 'Add Role'}>
                    <form className="form-horizontal" noValidate onSubmit={this.handleFormSubmit}>
                        <InputTextField
                            autoFocus
                            label="Role Name"
                            name="name"
                            id="name"
                            required
                            value={formData.name}
                            onChange={this.handleChange}
                            errors={errors && errors.name}
                        />

                        <InputSelectField
                            label="Permission Type"
                            name="permission_type"
                            id="permission_type"
                            required
                            value={formData.permission_type}
                            onChange={this.handleChange}
                            errors={errors && errors.permission_type}
                            options={permissionSelect}
                        />

                        <div className="w3-bar w3-margin-top w3-panel">
                            <Button
                                variant="contained"
                                className="w3-margin-right h7t-secondary-btn"
                                onClick={onModalClose}
                            >
                                Close
                            </Button>
                            <Button variant="contained" type="submit" className="h7t-primary-btn">{formData.id ? 'Update' : 'Add Role'}</Button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
    getManage,
    setAppMessage,
    postCreateRole,
    postEditRole,
})(UserRoleAddEditModal);
