import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { postWorkflowStateEdit, getWorkflowState } from '../../js/actions';
import Tooltip from '@material-ui/core/Tooltip';

const ListViewCard = (({
    value,
    onEdit,
    onDelete,
    customCls,
    currentWorkflows,
}) => {
    const disabledCls = currentWorkflows && currentWorkflows.data.find((item) => {
        return item.id == value.workflow_id;
    });
    return (
        <div className={`w3-padding w3-margin-bottom w3-bar w3-display-container ${customCls}`}>
            <div className="w3-bar-item">
                {value.state_name} {value.display_order === -1 && <span className="w3-text-gray">(Default step present in all workflows.)</span>}
                <span className="w3-row w3-text-gray w3-tiny w3-margin-left"
                >{value.display_order > 0 ? ` Weightage : ${value.weight}` : null}</span>
            </div>
            <div className="w3-right w3-bar-item">
                {(value.state_type !== 'End' && value.state_type !== 'end')
                    ? (
                        <Tooltip title="Edit">
                            <button
                                style={{ marginBottom: 0 }}
                                type="button"
                                className="w3-button w3-padding-small w3-hover-teal icon-border"
                                onClick={() => onEdit(value)}
                            >
                                <span className="ion-edit" />
                            </button>
                        </Tooltip>
                    ) : null}
                {((value.state_type !== 'End' && value.state_type !== 'end') && (value.state_type !== 'Start' && value.state_type !== 'start'))
                    ? (
                        <Tooltip title="Delete">
                            <button
                                style={{ marginBottom: 0 }}
                                type="button"
                                disabled={disabledCls && disabledCls.count_jobs_used}
                                className="w3-button w3-padding-small w3-hover-red icon-border"
                                onClick={() => onDelete(value)}
                            >
                                <span className="ion-trash-a" />
                            </button>
                        </Tooltip>
                    ) : null}
            </div>
        </div>
    );
});

const SortableItem = SortableElement(({
    value,
    onEdit,
    onDelete,
    currentWorkflows,
}) => {
    return <ListViewCard customCls="h7t-table-data h7t-hover-move-arrow" value={value} onEdit={onEdit} onDelete={onDelete} currentWorkflows={currentWorkflows}/>;
});

const SortWorkFlowList = SortableContainer(({
    items,
    onEdit,
    onDelete,
    currentWorkflows,
}) => {
    return (
        <div className="w3-col m12">
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${index}`} 
                    index={index} 
                    value={value}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    currentWorkflows={currentWorkflows}
                />
            ))}
        </div>
    );
});

class WorkflowListView extends Component {
    static propTypes = {
        workflowState: PropTypes.object,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        params: PropTypes.object,
    };

    constructor(args) {
        super(args);
        this.state = {
            inProcess: [],
        };
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    componentDidMount() {
        const { workflowState } = this.props;
        this.setWorkflowStates(workflowState);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.workflowState !== this.props.workflowState) {
            this.setWorkflowStates(newProps.workflowState);
        }
    }

    onSortEnd({ oldIndex, newIndex }) {
        if (oldIndex !== newIndex) {
            const { workflowState, params } = this.props;
            const { inProcess } = this.state;
            const oldTargetStates = inProcess[oldIndex].target_states.slice(0);
            const changedStates = [];
            if (newIndex === 0 || oldIndex === 0) {
                if (workflowState && workflowState.data) {
                    const startState = workflowState.data.find((WStates) => {
                        if (WStates.state_type === 'Start' || WStates.state_type === 'start') {
                            return true;
                        }
                    });
                    if (startState) {
                        if (newIndex === 0) {
                            const oldIndexState = Object.assign({}, inProcess[oldIndex]);
                            oldIndexState.target_states = startState.target_states;
                            changedStates.push(oldIndexState);
                            startState.target_states = inProcess[oldIndex - 1].target_states;
                            changedStates.push(startState);
                        } else if (oldIndex === 0) {
                            startState.target_states = oldTargetStates;
                            changedStates.push(startState);
                        }
                    }
                }
            }
            if (newIndex !== 0) {
                let position = 0;
                if (newIndex < oldIndex) {
                    position = 1;
                }

                const newIndexStateTarget = inProcess[newIndex - position].target_states.slice(0);
                const oldState = Object.assign({}, inProcess[oldIndex]);
                oldState.target_states = newIndexStateTarget;
                changedStates.push(oldState);

                const newIndexState = Object.assign({}, inProcess[newIndex - position]);
                let targetIndex = -1;
                if (newIndex !== inProcess.length - 1) {
                    targetIndex = newIndexState.target_states.indexOf(inProcess[newIndex + 1 - position].id);
                } else {
                    const acceptState = inProcess[newIndex].target_states.find((target) => {
                        return !inProcess[oldIndex].target_states.includes(target);
                    });
                    targetIndex = newIndexState.target_states.indexOf(acceptState);
                }
                if (targetIndex !== -1) {
                    newIndexState.target_states[targetIndex] = inProcess[oldIndex].id;
                    changedStates.push(newIndexState);
                }
            }
            if (oldIndex !== 0) {
                const beforeOldIndex = Object.assign({}, inProcess[oldIndex - 1]);
                beforeOldIndex.target_states = oldTargetStates;
                changedStates.push(beforeOldIndex);
            }

            const sortedList = arrayMove(inProcess, oldIndex, newIndex);
            const finalData = [];
            sortedList.map((list, idx) => {
                if (list.display_order !== idx + 2) {
                    const dataIndex = changedStates.findIndex((changed) => {
                        return changed.id === list.id;
                    });
                    if (dataIndex !== -1) {
                        changedStates[dataIndex].display_order = idx + 2;
                        finalData.push(changedStates[dataIndex]);
                        changedStates.splice(dataIndex, 1);
                    } else {
                        const orderData = Object.assign({}, list);
                        orderData.display_order = idx + 2;
                        finalData.push(orderData);
                    }
                }
            });
            const updatedData = finalData.concat(changedStates);
            for (let i = 0; i < updatedData.length; i++) {
                const data = Object.assign({}, updatedData[i]);
                data.workflow_state_id = data.id;
                this.props.postWorkflowStateEdit(data).then(() => {
                    if (i === updatedData.length - 1) {
                        this.props.getWorkflowState(params.workflowId);
                    }
                });
            }
        }
    }

    setWorkflowStates(workflowState) {
        if (workflowState && workflowState.data && workflowState.data.length) {
            const inProcess = workflowState.data.filter((wState) => {
                if (wState.state_type === 'In process') {
                    return true;
                }
            });
            this.setState({
                inProcess,
            });
        }
    }

    render() {
        const { workflowState, onEdit, onDelete } = this.props;
        const { inProcess } = this.state;
        const listView = [];
        const startCard = [];
        const endCards = [];
        let rejectedCard = null;

        if (workflowState && workflowState.data && workflowState.data.length) {
            workflowState.data.map((wState) => {
                if (wState.state_type !== 'In process') {
                    const cardType = (
                        <div className="w3-col m12" key={`card_view_${wState.state_name}`}>
                            <ListViewCard
                                value={wState}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                customCls="h7t-table-data"
                                currentWorkflows={this.props.currentWorkflowsList}
                            />
                        </div>
                    );
                    if ((wState.state_type === 'Start') || (wState.state_type === 'start')) {
                        startCard.push(cardType);
                    } else if ((wState.state_type === 'End') || (wState.state_type === 'end')) {
                        if (wState.display_order === -1) {
                            rejectedCard = cardType;
                        } else {
                            endCards.push(cardType);
                        }
                    }
                }
            });

            listView.push(
                <SortWorkFlowList
                    key="workflow_list_view"
                    distance={5}
                    items={inProcess}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onSortEnd={this.onSortEnd}
                    currentWorkflows={this.props.currentWorkflowsList}
                />,
            );
        }

        return (
            <div className="w3-row-padding">
                <div className="w3-col m8">
                    {startCard}
                    {listView}
                    {endCards}
                </div>
                <div className="w3-col m4">
                    {rejectedCard}
                    <div className="w3-row w3-margin-bottom">
                        <ul>
                            <li>Steps cannot be added or deleted to hiring workflows if already in use.</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
    postWorkflowStateEdit,
    getWorkflowState,
})(WorkflowListView);
