import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
    Grid, Drawer, Divider, List, Button, Typography, Slider,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { InputSelectField } from '../Common/MaterialUI/HelperInputs';
import { applicantFilterConfig } from '../Common/Constants';


class ApplicantFilters extends Component {
    static propTypes = {
        applicationTitleList: PropTypes.object,
        applicationStatusList: PropTypes.object,
        handleJobFilters: PropTypes.func,
        clearFilterValues: PropTypes.func,
    };

    constructor(args) {
        super(args);
        this.state = {
            right: false,
            filterOn: {
                job_id: '',
                notice_period: '',
                step: '',
                owner: '',
                search: '',
                status: 'all',
                ratingRange: applicantFilterConfig.ratingInitialRange,
                assigned_to: '',
            },
            marks: [],
        };
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.triggerFilter = this.triggerFilter.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    componentDidMount() {
        const { marks } = this.state;
        for (let i = 0; i <= applicantFilterConfig.ratingMaxLimit; i = (i + applicantFilterConfig.ratingOffset)) {
            marks.push({ value: i, label: i });
        }
        this.setState({ marks });
    }

    toggleDrawer = open => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({
            right: open,
        });
    };

    handleClearFilter() {
        const filterOn = {};
        filterOn.job_id = '';
        filterOn.notice_period = '';
        filterOn.step = '';
        filterOn.ratingRange = '';
        filterOn.owner = '';
        filterOn.status = 'all';
        filterOn.assigned_to = '';
        const filterData = Object.assign({}, filterOn);
        filterData.ratingRange = applicantFilterConfig.ratingInitialRange;
        this.setState({
            filterOn: filterData,
            right: false,
        });
        this.props.clearFilterValues(filterOn);
    }


    handleSelectFilter(event) {
        const { name, value } = event.target;
        const { filterOn } = this.state;
        if (event) {
            filterOn[name] = value;
        } else {
            filterOn[name] = '';
        }
        this.setState({ filterOn });
    }

    triggerFilter() {
        const { filterOn } = this.state;
        this.props.handleJobFilters(filterOn);
        this.setState({
            right: false,
        });
    }

    handleSliderChange(event, newValue) {
        const { filterOn } = this.state;
        filterOn.ratingRange = newValue;
        this.setState({
            filterOn
        });
    }

    render() {
        const {
            applicationTitleList,
            applicationStatusList,
            filterStatus,
        } = this.props;
        const {
            right, filterOn, marks
        } = this.state;
        const jobRoleSelect = [{ val: '', name: '-Select Job Role-' }];
        const stepSelect = [{ val: '', name: '-Select Step-' }];
        const noticePeriodSelect = [
            { val: '', name: '-Select Notice Period-' },
            { val: 'Immediatly Available', name: 'Immediatly Available' },
            { val: 'Serving Notice Period', name: 'Serving Notice Period' },
            { val: '1 Month', name: '1 Month' },
            { val: '2 Months', name: '2 Months' },
            { val: '3 Months', name: '3 Months' },
            { val: 'More than 3 Months', name: 'More than 3 Months' },
        ];
        
        const ownerSelect = [{ val: '', name: '-Select Owner-' }];
        const assignedToSelect = [{ val: '', name: '-Select Assignee-' }];
        let statusSelect = '';
        if(filterStatus == 'archive') {
            statusSelect = [
                { val: 'all', name: 'All' },
                { val: 'active', name: 'New' },
                { val: 'rejected', name: 'Rejected' },
            ];
        } else {
            statusSelect = [
                { val: 'all', name: 'All' },
                { val: 'active', name: 'In Progress' },
                { val: 'hired', name: 'Hired' },
                { val: 'rejected', name: 'Rejected' },
                { val: 'Job-Accepted', name: 'Job-Accepted'},
                { val: 'Job-Declined', name: 'Job-Declined'}
            ];
        }

        if (applicationStatusList && applicationStatusList.data) {
            if (applicationStatusList.data.steps && applicationStatusList.data.steps.length) {
                applicationStatusList.data.steps.filter((obj, id, arr) => (
                    arr.findIndex((item) => item.current_status === obj.current_status) === id
                )).map((item) => {
                    stepSelect.push({ val: item.current_status, name: item.current_status });
                });
            }
            if (applicationStatusList.data.owners && applicationStatusList.data.owners.length) {
                applicationStatusList.data.owners.map((user) => {
                    ownerSelect.push({ val: user.id, name: user.display_name });
                });
            }
            if (applicationStatusList.data.assigned_to && applicationStatusList.data.assigned_to.length) {
                applicationStatusList.data.assigned_to.map((assignee) => {
                    assignedToSelect.push({ val: assignee.id, name: assignee.display_name });
                });
            }
        }

        if (applicationTitleList && applicationTitleList.data && applicationTitleList.data.length) {
            applicationTitleList.data.map((item) => {
                jobRoleSelect.push({ val: item.job_id, name: item.job_title });
            });
        }

        const sideList = (
            <div
                className="h7t-filter-container"
                role="presentation"
            >
                <h3>Filters</h3>
                <InputSelectField
                    label="Job Role"
                    id="job_id"
                    name="job_id"
                    value={filterOn.job_id}
                    options={jobRoleSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Notice Period"
                    id="notice_period"
                    name="notice_period"
                    value={filterOn.notice_period}
                    options={noticePeriodSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                {filterStatus !== 'archive' ?
                    <div>
                        <InputSelectField
                            label="Step"
                            id="step"
                            name="step"
                            value={filterOn.step}
                            options={stepSelect}
                            onChange={e => this.handleSelectFilter(e)}
                        /> 
                        <Divider className="h-margin-16" />
                    </div>
                : null}
                <div className="w3-panel">
                    <Typography id="range-slider" gutterBottom className="w3-text-gray">
                        Rating
                    </Typography>
                    <Slider
                        value={filterOn.ratingRange}
                        onChange={this.handleSliderChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        marks={marks}
                        step={applicantFilterConfig.ratingStepValue}
                        min={applicantFilterConfig.ratingMinLimit}
                        max={applicantFilterConfig.ratingMaxLimit}
                    />
                </div>
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Assgined to"
                    id="assigned_to"
                    name="assigned_to"
                    value={filterOn.assigned_to}
                    options={assignedToSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Owner"
                    id="owner"
                    name="owner"
                    value={filterOn.owner}
                    options={ownerSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <InputSelectField
                    label="Status"
                    id="status"
                    name="status"
                    value={filterOn.status}
                    options={statusSelect}
                    onChange={e => this.handleSelectFilter(e)}
                />
                <Divider className="h-margin-16" />
                <List>
                    <Grid container justify="space-between" alignItems="center">
                        <Button
                            variant="contained"
                            size="small"
                            className="h7t-secondary-btn"
                            onClick={this.handleClearFilter}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={this.triggerFilter}
                            className="w3-center h7t-primary-btn"
                        >
                                Filter
                        </Button>
                    </Grid>
                </List>
            </div>
        );

        return (
            <div>
                <IconButton
                    className="w3-right h7t-cursor-pointer"
                    style={{ marginTop: '12px', marginRight: '20px' }}
                    onClick={this.toggleDrawer(true)}
                >
                    <FilterListIcon />
                </IconButton>
                <Drawer
                    anchor="right"
                    open={right}
                    onClose={this.toggleDrawer(false)}
                >
                    {sideList}
                </Drawer>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        applicationTitleList: state.applicationTitleList,
        applicationStatusList: state.applicationStatusList,
    };
}
export default connect(mapStateToProps, {
})(ApplicantFilters);
