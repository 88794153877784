import React, { Component } from 'react';
import { InputBase, IconButton, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

export default class SearchInputBase extends Component {
    constructor(args) {
        super(args);
        this.state = {
        };
    }

    render() {
        const { customClass, placeholder, onChange, value, size, error } = this.props;

        return (
        <div>
            {/* <div className={`search-input-base ${customClass}`}>
                {/* <InputBase
                    className="input-base"
                    placeholder={placeholder || 'Search'}
                    inputProps={{ 'aria-label': 'Search' }}
                    value={value}
                    onChange={onChange}
                />
                <IconButton
                    type="submit"
                    aria-label="search"
                    className="icon-button"
                >
                    <SearchIcon />
                </IconButton> 
            </div> */}
                 <FormControl 
                    className="search-width w3-margin-top" 
                    variant="outlined" 
                    size={size || 'small'}
                 > 
                    <InputLabel htmlFor="search-box-input">Search</InputLabel>
                        <OutlinedInput
                            id="search-box-input"
                            placeholder={placeholder || 'Search'}
                            inputProps={{ 'aria-label': 'Search' }}
                            className="search-radius"
                            value={value}
                            onChange={onChange}
                            error={error}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        type="submit"
                                        aria-label="search"
                                        className="icon-button"
                                        edge="end"
                                    >
                                    <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                        {error && <FormHelperText error id="h7t-search_input_error">{error}</FormHelperText>}
                </FormControl>
            </div>
        );
    }
}
