import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { postCreateCompanyAndOffice, getCurrentUser } from '../../js/actions';
import OfficeEditModal from '../ManageCompany/OfficeListEditModal';
import CompanyForm from '../ManageCompany/CompanyForm';
import Modal from '../Common/Modal';

class CompanyOfficeForm extends Component {
    static propTypes = {
        onModalClose: PropTypes.func,
        postCreateCompanyAndOffice: PropTypes.func,
    };

    constructor(args) {
        super(args);

        this.state = {
            showModal: 'company',
            company_data: {
                name: '',
                type: '',
                website: '',
                logo_url: '',
                sub_domain: '',
                allowed_domains: '',
            },
            office_data: {
                name: '',
                address_line_1: '',
                address_line_2: '',
                address_line_3: '',
                latitude: '',
                longitude: '',
                country: '',
                state: '',
                city: '',
                pin_code: '',
                phone_number: '',
                time_zone: '',
                currency_code: '',
                preference_order: '',
            },
            company_errors: null,
            office_errors: null,
        };

        this.handleNextForm = this.handleNextForm.bind(this);
        this.handleSaveForm = this.handleSaveForm.bind(this);
    }

    handleNextForm(company) {
        console.log('calling insied next form  -=', company);
        this.setState({
            company_data: company,
            showModal: 'office',
        });
    }

    handleSaveForm(office) {
        this.setState({ office_data: office }, () => {
            const { office_data, company_data } = this.state;
            const formData = new FormData();
            formData.append('office_data', JSON.stringify(office_data));
            formData.append('company_data', JSON.stringify(company_data));
            if (company_data.logo_url && company_data.logo_url.preview) {
                formData.append('logo_url', company_data.logo_url);
            }

            this.props.postCreateCompanyAndOffice(formData).then((resp) => {
                const response = resp && resp.data;
                if (!response.success) {
                    let showModal = '';
                    const companyErrors = response.data.company_errors;
                    const officeErrors = response.data.office_errors;

                    if (companyErrors) {
                        showModal = 'company';
                    } else if (officeErrors) {
                        showModal = 'office';
                    }

                    this.setState({
                        company_errors: companyErrors,
                        office_errors: officeErrors,
                        showModal,
                    });
                } else if (response.success) {
                    this.props.getCurrentUser().then(() => {
                        const companyId = response.data.company_data.id;
                        const officeId = response.data.office_data.id;
                        const url = `/e/${companyId}/${officeId}/dashboard`;
                        this.props.onModalClose();
                        this.props.history.push({
                            pathname: url,
                            state: {
                                companyId,
                                officeId
                            }
                        });
                    }).catch((err) => {
                        alert('Something went wrong!');
                        window.location.reload();
                    });
                }
            });
        });
    }

    render() {
        let modalContent = [];

        const { onModalClose } = this.props;
        const {
            showModal, company_data, company_errors, office_data, office_errors,
        } = this.state;
        if (showModal === 'company') {
            modalContent = (
                <div>
                    <h4 className="h7t-margin-10 w3-padding" id="myModalLabel">Add Company</h4>
                    <CompanyForm
                        company_data={company_data}
                        company_errors={company_errors}
                        createCompany={this.handleNextForm}
                        buttonVal="Add Company"
                    />
                </div>
            );
        } else {
            modalContent = (
                <div>
                    <OfficeEditModal
                        office_data={office_data}
                        office_errors={office_errors}
                        saveCompanyOffice={this.handleSaveForm}
                        onModalClose={onModalClose}
                    />
                </div>
            );
        }

        return (
            <div>
                <Modal modalCls="w3-show" onClose={onModalClose}>
                    <div className="w3-container h7t-padding-mobile-view w3-margin-bottom w3-margin-top">
                        {modalContent}
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, {
    postCreateCompanyAndOffice,
    getCurrentUser,
})(CompanyOfficeForm));
